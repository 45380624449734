export const Reports = [
  { 
    name: 'Companies usages', 
    value: 'clients_montly_usage',
    description: "Report for getting amount of checks (by type) used by companies in the chosen period.",
    row_description: "Company",
    columns: [
      { name: 'ID', description: 'Company ID' },
      { name: 'Company', description: 'Company Name' },
      { name: 'Owner email', description: 'Company main user email' },
      { name: 'Brand', description: 'Brand name' },
      { name: '...{Check title}', description: 'Check usage count *' }
    ],
    notes: [
      "* Filter by submitted date if `reference` type, else use completed date"
    ]
  },
  { 
    name: 'Companies checks usages', 
    value: 'checks_usage',
    description: "Report for getting amount of checks (by type) used by companies in the chosen period.",
    row_description: "Company Check",
    columns: [
      { name: 'company_id', description: 'Company ID' },
      { name: 'company_name', description: 'Company Name' },
      { name: 'brand', description: 'Brand name' },
      { name: 'poc_name', description: 'Company main user name' },
      { name: 'poc_email', description: 'Company main user email' },
      { name: 'check_type', description: 'Check title' },
      { name: 'check_slug', description: 'Check code' },
      { name: 'credits', description: 'Company check credits' },
      { name: 'pricing', description: 'Company check pricing if custom, else check pricing' },
      { name: 'active_checks', description: 'Count of check type in company (not canceled)' },
      { name: 'canceled_checks', description: 'Count of check type canceled in company' },
      { name: 'total_checks', description: 'Total count of check type' },
    ]
  },
  { 
    name: 'Companies credits',
    value: 'clients_credits',
    description: "Report for getting checks credits for each company",
    row_description: "Company",
    columns: [
      { name: 'ID', description: 'Company ID' },
      { name: 'Company', description: 'Company Name' },
      { name: 'Owner email', description: 'Company main user email' },
      { name: '...{Check title}', description: 'Company check credit' }
    ],
    notes: [
      "Date filters are ignored"
    ]
  },
  { 
    name: 'Sign Ups', 
    value: 'sign_ups',
    description: "Report for getting sign-ups for the choosen period.",
    row_description: "Company",
    columns: [
      { name: 'ID', description: 'Company ID' },
      { name: 'Company', description: 'Company Name' },
      { name: 'Created at', description: 'Company creation date' },
      { name: 'User name', description: 'User name' },
      { name: 'User email', description: 'User email' },
      { name: 'User phone number', description: 'User contact number' },
      { name: 'Email verified', description: 'Email verified' },
      { name: 'UTM source', description: 'UTM source' },
      { name: 'Last login', description: 'Last login date' }
    ]
  },
  { 
    name: 'Active users', 
    value: 'active_users',
    description: "Report for getting verified users.",
    row_description: "User",
    columns: [
      { name: 'Company', description: 'Company Name' },
      { name: 'User ID', description: 'Company creation date' },
      { name: 'User name', description: 'User name' },
      { name: 'User email', description: 'User email' },
      { name: 'User phone number', description: 'User contact number' }
    ],
    notes: [
      "Date filters are ignored"
    ]
  },
  { 
    name: 'Low usage report', 
    value: 'low_usage',
    description: "Report for getting company checks with low credits (< 10).",
    row_description: "Company Check",
    columns: [
      { name: 'ID', description: 'Company ID' },
      { name: 'Company', description: 'Company Name' },
      { name: 'Owner email', description: 'Company main user email' },
      { name: 'Check type', description: 'Check title' },
      { name: 'Credit left', description: 'Remaining credits' }
    ],
    notes: [
      "Date filters are ignored"
    ]
  },
  { 
    name: 'Spend / Commission report', 
    value: 'spend_commission',
    description: "Report for getting company check usages and cost/pricing margins.",
    row_description: "Company Check",
    columns: [
      { name: 'Company ID', description: 'Company ID' },
      { name: 'Company name', description: 'Company Name' },
      { name: 'Sign-up date', description: 'Company created date' },
      { name: 'Account Manager', description: 'Sales rep. configures in admin' },
      { name: 'Payment Type', description: 'Company payment type (casual / internal / enterprise)' },
      { name: 'Check title', description: 'Check title' },
      { name: 'First used on', description: 'Date of first usage of check type' },
      { name: 'Last used on', description: 'Date of last usage of check type' },
      { name: 'Volume', description: 'Amount of checks launched' },
      { name: 'Unit pricing', description: 'Check price. If custom pricing set, use it. Else, use default check type pricing' },
      { name: 'Total pricing', description: '[Unit pricing] * [Volume]' },
      { name: 'Unit cost', description: 'Check type cost' },
      { name: 'Total cost', description: '[Unit cost] * [Volume]' },
      { name: 'Unit revenue', description: '[Unit pricing] - [Unit cost]' },
      { name: 'Total revenue', description: '([Unit pricing] - [Unit cost]) * [Volume]' }
    ],
    notes: [
      "If company has a currency different than 'NZD', cost and revenue might not be accurate as check costs are only set in 'NZD'"
    ]
  },
  { 
    name: 'Checks volume', 
    value: 'checks_volume_report',
    description: "Report for getting volume of checks (by type) launched in the chosen period.",
    row_description: "Check Type",
    columns: [
      { name: 'Check Type', description: 'Check title' },
      { name: 'Volume', description: 'Checks launched' }
    ]
  }
]