import { useEffect, useState } from 'react';
import { BundleEdit } from './components/BundleEdit/BundleEdit';
import { List } from 'components/List/List';
import Alert from 'components/Alert/Alert';
import { useSelector } from 'react-redux';
import { selectForms } from 'api/forms-slice';
import { selectBundles } from 'api/bundles-slice';
import { selectCompany } from 'api/company-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';

export const Bundles = ({
  setLoading    = () => {},
  getForms      = () => {},
  getBundles    = () => {},
  createBundle  = () => {},
  updateBundle  = () => {},
  deleteBundle  = () => {},
}) => {

  const forms = useSelector(selectForms);
  const bundles = useSelector(selectBundles);
  const company = useSelector(selectCompany)
  const companyChecks = useSelector(selectCompanyChecks)

  const [bundleId, setBundleId] = useState();

  const [showDeletionAlert, setShowDeletionAlert] = useState(false);

  // Initialize
  useEffect(() => {
    if(!company?.id) return;

    setLoading(true);
    Promise.all([getForms(), getBundles()]).then(_ => setLoading(false))
  }, [company])

  // Create bundle
  const onCreate = async() => {
    setLoading(true);
    const { payload: newBundle } = await createBundle({ name: 'New Bundle' });
    setBundleId(newBundle.id);
    setLoading(false);
  } 

  // Update bundle
  const onUpdate = async(data) => {
    setLoading(true);

    // Clean details with no type
    data.checks_data = data.checks_data?.filter(check => check.type);

    await updateBundle(bundleId, data);
    setLoading(false);
  }

  const onDelete = async() => {
    setLoading(true);
    setShowDeletionAlert(false);
    setBundleId(null);
    await deleteBundle(bundleId);
    setLoading(false);
  }

  return (
    <div className='u-flex-box'>
      <Alert
        show={showDeletionAlert}
        title={`Are you sure you want to delete the bundle?`}
        ok='Delete'
        onOk={onDelete}
        cancel='Cancel' 
        onCancel={() => setShowDeletionAlert(false)}
      />
      <List 
        title='Bundles'
        subtitle='Configure your companies bundles, which are groups of checks that can be launched together to save time.'
        className='u-margin-right--large'
        onNew={onCreate}
      >
        <List.Category title='Bundles'>
          {bundles.map(bundle => (
            <List.Item
              key={bundle.id}
              title={bundle.name}
              value={bundle.id}
              onClick={setBundleId}
            />
          ))}
        </List.Category>
      </List>
      {bundleId && 
        <BundleEdit
          bundle={bundles.find(bundle => bundle.id === bundleId)}
          company={company}
          companyChecks={companyChecks}
          forms={forms}
          onUpdate={onUpdate}
          onDelete={() => setShowDeletionAlert(true)}
          onClose={() => setBundleId(null)}
        />
      }
    </div>
  )
}
