import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { useForm, useWatch } from 'react-hook-form';
import { General } from '../General/General';
import { Clicktrought } from '../Clicktrought/Clicktrought';
import { Emails } from '../Emails/Emails';
import { LeadGeneration } from '../LeadGeneration/LeadGeneration';
import { Reminders } from '../Reminders/Reminders';
import { Documents } from '../Documents/Documents';
import { IconButton } from 'components/IconButton/IconButton';
import { Button, EditableTitle } from 'components/FormComponents';
import { MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { useSendBrandPreviewEmailMutation, useSendCandidatePreviewEmailMutation, useSendRefereePreviewEmailMutation } from "api/user-api";
import styles from './BrandingEdit.module.scss';
import cn from 'classnames';

export const BrandingEdit = ({
  user,
  brand,
  onClose           = () => {},
  setLoading        = () => {},
  messagePopUp      = () => {},
  updateBrand       = () => {},
  changeDefault     = () => {},
  getBrandUploads   = () => {},
  onDelete          = () => {},
  removeBrandUpload = () => {},
  addBrandUpload    = () => {}
}) => {

  const [sendBrandPreview] = useSendBrandPreviewEmailMutation();
  const [sendCandidatePreviewEmail] = useSendCandidatePreviewEmailMutation();
  const [sendRefereePreviewEmail] = useSendRefereePreviewEmailMutation();

  const { control, register, reset, watch, handleSubmit, formState: { errors } } = useForm({ defaultValues: brand });

  const nameValue = useWatch({ control, name: 'name' });

  const values = useWatch({ control })

  const [tab, setTab] = useState('general');

  useEffect(() => reset(brand), [brand])

  // Send brand preview
  const onSendBrandPreview = async() => {
    setLoading(true);
    await sendBrandPreview({ id: user.id, brandData: values });
    messagePopUp('Preview email sent. Check your inbox', MESSAGE_STATE_SUCCESS);
    setLoading(false)
  }

  // Send candidate email preview
  const onSendCandidatePreviewEmail = async() => {
    setLoading(true);
    await sendCandidatePreviewEmail({ id: user.id, brandData: values });
    messagePopUp('Preview email sent. You can expected in your inbox soon', MESSAGE_STATE_SUCCESS);
    setLoading(false);
  }

  // Send referee email preview
  const onSendRefereePreviewEmail = async() => {
    setLoading(true);
    await sendRefereePreviewEmail({ id: user.id, brandData: values });
    messagePopUp('Preview email sent. You can expected in your inbox soon', MESSAGE_STATE_SUCCESS);
    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(updateBrand)} className={cn(styles.root, 'card', 'card-with-border', 'card--medium')}>
      <div>
        <div className={cn('u-margin--large', 'u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
          <div>
            <div className={cn('u-flex-box', 'u-flex-align-center')}>
              <FeatherIcon icon="star" size={20} onClick={() => changeDefault(brand.id)} className={cn(styles.defaultIcon, {[styles.defaultIconActive]: brand.default})}/>
              <EditableTitle register={register} name='name' value={nameValue} />
            </div>
            <p className='t-small'>Configure and manage your brand below</p>
          </div>
          {!brand.default && <IconButton icon='trash-2' onClick={() => onDelete(brand.id)} tip='Delete brand' /> }
        </div>
        <Tabs onSelect={setTab} activeKey={tab} defaultActiveKey="general" className="tabs padding-small">
          <Tab eventKey="general" title="General">
            <General 
              brand={brand}
              control={control}
              errors={errors}
              register={register}
              messagePopUp={messagePopUp}
              sendBrandPreview={onSendBrandPreview}
            />
          </Tab>
          <Tab eventKey="clicktrough" title="Clickthrough Websites">
            <Clicktrought
              control={control}
              register={register}
              watch={watch}
            />
          </Tab>
          <Tab eventKey="email" title="Communication">
            <Emails 
              control={control}
              register={register}
              sendCandidateEmailPreview={onSendCandidatePreviewEmail}
              sendRefereeEmailPreview={onSendRefereePreviewEmail}
            />
          </Tab>
          <Tab eventKey="lead_generation" title="Engagement">
            <LeadGeneration 
              control={control}
              register={register}
            />
          </Tab>
          <Tab eventKey="documents" title="Documents">
            <Documents
              brand={brand}
              control={control}
              register={register}
              getBrandUploads={getBrandUploads}
              removeBrandUpload={removeBrandUpload}
              addBrandUpload={addBrandUpload}
              setLoading={setLoading}
            />
          </Tab>
          <Tab eventKey="reminders" title="Reminders">
            <Reminders
              control={control}
              register={register}
            />
          </Tab>
        </Tabs>
      </div>
      <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
        <Button onClick={onClose} type='secondary'>Cancel</Button>
        <Button submit>Save</Button>
      </div>
    </form>
  )
}