import { createContext, useMemo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './List.module.scss';
import { SearchInput, Button } from 'components/FormComponents'
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';

const ListContext = createContext(null);

const useListContext = () => {
  const context = useContext(ListContext)
  if (!context)
    throw new Error(`Lists compound components cannot be rendered outside the Lists component`)
  
  return context
}

export const List = ({ 
  title,
  subtitle,
  children,
  className,
  onNew,
  tip
}) => {

  const [searchQuery, setSearchQuery] = useState('');

  const contextValue = useMemo(() => ({ searchQuery: searchQuery.toLowerCase() }), [searchQuery])

  return (
    <ListContext.Provider value={contextValue}>
      <div className={cn(styles.list, 'card', 'card-with-border', { [className]: className })}>
        <div className={cn(styles.content, 'card_content', 'u-flex-box', 'u-flex-justify-start')}>
          <div className={styles.header}>
            <h1 className='title-2'>{title}</h1>
            {!!onNew &&
              <Button placement='right' tip={tip} onClick={() => onNew(true)}><FeatherIcon icon='plus' size={18} className='u-margin-right' />Add</Button>
            }
          </div>
          <p className={cn(styles.subtitle, 't-subtitle u-margin-top--small')}>{subtitle}</p>
          <SearchInput className='u-width-100 u-margin-top--large' placeholder='Search...' onChange={setSearchQuery}/>
          <div className={styles.items}>
            {children}
          </div>
        </div>
      </div>
    </ListContext.Provider>
  )
}

List.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onNew: PropTypes.func
};


const CategoryContext = createContext(null);

const useCategoryContext = () => {
  const context = useContext(CategoryContext)
  if (!context)
    throw new Error(`Category compound components cannot be rendered outside the Category component`)
  
  return context
}

export const Category = ({ title, withCount=false, children }) => {
  const [childrenCount, setChildrenCount] = useState(0);

  const contextValue = ({
    withCount,
    incrementCategoryCount: () => setChildrenCount(curr => curr + 1),
    decreaseCategoryCount: () => setChildrenCount(curr => curr - 1),
  })

  return (
    <CategoryContext.Provider value={contextValue}>
      {title && <h3 className={styles.itemHeader}>{title} {withCount && `(${childrenCount})`}</h3>}
      {children}
    </CategoryContext.Provider>
  )
  
}

Category.propTypes = {
  title: PropTypes.string,
  withCount: PropTypes.bool
};
List.Category = Category;


export const Item = ({ title, value, onClick, icon, iconClass }) => {
  const { searchQuery } = useListContext();
  const { withCount, incrementCategoryCount, decreaseCategoryCount } = useCategoryContext();

  const [show, setShow] = useState(true);

  useEffect(() => setShow(title.toLowerCase().includes(searchQuery)), [searchQuery, title]);

  useEffect(() => {
    if(!withCount) return;
    
    if(show) incrementCategoryCount();
    return () => { if(show) decreaseCategoryCount() }
  }, [show, withCount])

  if(show) return (
    <li onClick={() => onClick(value)} className={cn(styles.item, 't-body')}>
      {title} {icon && <FeatherIcon className={cn(styles.itemIcon, 'u-margin-left--small', iconClass)} size={20} icon={icon} />}
    </li>
  )
  else return null
}

Item.propTypes = {
  onClick: PropTypes.func
};
List.Item = Item;