import { useEffect } from 'react';
import cn from 'classnames';
import styles from './Loading.module.scss';

export const Loading = ({ active }) => {

  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'auto';
  }, [active])

  return (
    <div className={cn(styles.root, { [styles.rootActive]: active})}>
      <div className={styles.spinner}>
        <div className={styles.doubleBounce1}></div>
        <div className={styles.doubleBounce2}></div>
      </div>
    </div>
  )
}