import { useEffect, useState } from 'react';
import * as Integration from 'components/Integrations/index';
import { SearchInput } from 'components/FormComponents';
import { PartnersCodes } from 'constants/partners';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectPartners } from 'api/partner-slice';
import styles from './Integrations.module.scss';

const PartnersConfig = [
  {
    name: 'account_api',
    logo: 'assets/images/partners/account.svg',
    title: 'Account API',
    description: 'Learn more about our Account API integration',
  },
  {
    name: 'tas',
    logo: 'assets/images/partners/talent.svg',
    title: 'Talent App Store',
    description: 'Learn more about our Talent App Store integration',
  },
  {
    name: 'bullhorn',
    logo: 'assets/images/partners/bullhorn.svg',
    title: 'Bullhorn',
    description: 'Learn more about our Bullhorn integration',
  },
  {
    name: 'jazzhr',
    logo: 'assets/images/partners/jazzhr.svg',
    title: 'JazzHr',
    description: 'Learn more about out JazzHR integration',
  },
  {
    name: 'teamtailor',
    logo: 'assets/images/partners/teamtailor.svg',
    title: 'Teamtailor',
    description: 'Learn more about our Teamtailor integration',
  },
  {
    name: 'job_adder',
    logo: 'assets/images/partners/job_adder.svg',
    title: 'JobAdder',
    description: 'Learn more about our JobAdder integration',
  },
  {
    name: 'lever',
    logo: 'assets/images/partners/lever.svg',
    title: 'Lever',
    description: 'Learn more about our Lever integration',
  },
  {
    name: 'knowmy',
    logo: 'assets/images/partners/knowmy.svg',
    title: 'KnowMy',
    description: 'Learn more about our Know My integration',
  },
  {
    name: 'ukg',
    logo: 'assets/images/partners/ukg.svg',
    title: 'UKG',
    description: 'Learn more about our workday integration',
  },
  {
    name: 'livehire',
    logo: 'assets/images/partners/livehire.svg',
    title: 'Livehire',
    description: 'Learn more about our livehire integration',
  },
  {
    name: 'onboarded',
    logo: 'assets/images/partners/onboarded.svg',
    title: 'Onboarded',
    description: 'Learn more about our onboarded integration',
  },
  {
    name: 'bambooHR',
    logo: 'assets/images/partners/bambooHR.svg',
    title: 'BambooHR',
    description: 'Learn more about our bambooHR integration',
  },
  {
    name: 'pageup',
    logo: 'assets/images/partners/pageUp.svg',
    title: 'PageUp',
    description: 'Learn more about our PageUp integration',
  },
  {
    name: 'smartrecruiters',
    logo: '/assets/images/smartrecruiters_logo.svg',
    title: 'SmartRecruiters',
    description: 'Learn more about our SmartRecruiters integration',
  }
]

export const Integrations = ({ 
  setLoading           = () => {},
  getPartnersConfig    = () => {},
  popUpMessage         = () => {},
  onCompanyUpdate      = () => {},
  connectJobAdder      = () => {},
  disconnectJobAdder   = () => {},
  connectBullhorn      = () => {},
  disconnectBullhorn   = () => {},
  connectLever         = () => {},
  disconnectLever      = () => {},
  connectKnowMy        = () => {},
  disconnectKnowMy     = () => {},
  connectLivehire      = () => {},
  disconnectLivehire   = () => {},
  onRevokePartnerToken = () => {}
}) => {

  const partners = useSelector(selectPartners);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setLoading(true);
    getPartnersConfig().then(_ => setLoading(false))
  }, [])

  const revokeToken = async(partner) => {
    setLoading(true);
    await onRevokePartnerToken(partner);
    setLoading(false);
  }

  const getPartnerIntegration = (config) => {
    if(!partners.fetched) return;

    switch(config.name) {
      case PartnersCodes.AccountApi:
        return (
          <Integration.AccountApiIntegration 
            key={config.name}
            config={config}
            integration={partners.account_api}
            revokeToken={revokeToken}
          />
        );
      case PartnersCodes.TAS:
        return (
          <Integration.TasIntegration
            key={config.name}
            config={config}
            integration={partners.tas}
            setLoading={setLoading}
            onCompanyUpdate={onCompanyUpdate}
          />
        );
      case PartnersCodes.JobAdder:
        return (
          <Integration.JobAdderIntegration
            key={config.name}
            config={config}
            integration={partners.job_adder}
            connect={connectJobAdder}
            disconnect={disconnectJobAdder}
            setLoading={setLoading}
            popUpMessage={popUpMessage}
          />
        );
      case PartnersCodes.JazzHR:
        return (
          <Integration.JazzIntegration 
            key={config.name}
            config={config}
            integration={partners.jazzhr}
            revokeToken={revokeToken}
          />
        );
      case PartnersCodes.Teamtailor:
        return ( 
          <Integration.TeamtailorIntegration 
            key={config.name}
            config={config}
            integration={partners.teamtailor}
            revokeToken={revokeToken}
          />
        );
      case PartnersCodes.Bullhorn:
        return (
          <Integration.BullhornIntegration
            key={config.name}
            config={config}
            integration={partners.bullhorn}
            connect={connectBullhorn}
            disconnect={disconnectBullhorn}
            setLoading={setLoading}
            popUpMessage={popUpMessage}
            revokeToken={revokeToken}
          />
        );
      case PartnersCodes.Onboarded:
        return (
          <Integration.OnboardedIntegration 
            key={config.name}
            config={config}
            integration={partners.onboarded}
            revokeToken={revokeToken}
          />
        );
      case PartnersCodes.Lever:
        return (
          <Integration.LeverIntegration
            key={config.name}
            config={config}
            integration={partners.lever}
            connect={connectLever}
            disconnect={disconnectLever}
            setLoading={setLoading}
            popUpMessage={popUpMessage}
            updateCompany={onCompanyUpdate}
          />
        );
      case PartnersCodes.UKG:
        return (
          <Integration.UkgIntegration 
            key={config.name}
            config={config}
            integration={partners.ukg}
            revokeToken={revokeToken}
          />
        );
      case PartnersCodes.KnowMy:
        return (
          <Integration.KnowMyIntegration
            key={config.name}
            config={config}
            integration={partners.knowmy}
            connect={connectKnowMy}
            disconnect={disconnectKnowMy}
            setLoading={setLoading}
            popUpMessage={popUpMessage}
          />
        );
      case PartnersCodes.BambooHR:
        return (
          <Integration.BambooHRIntegration
            key={config.name}
            config={config}
            integration={partners.bambooHR}
            setLoading={setLoading}
            updateCompany={onCompanyUpdate}
          />
        );
      case PartnersCodes.PageUp:
        return (
          <Integration.PageUpIntegration
            key={config.name}
            config={config}
            integration={partners.pageup}
            onCompanyUpdate={onCompanyUpdate}
            setLoading={setLoading}
          />
        );
      case PartnersCodes.Livehire: 
        return (
          <Integration.LivehireIntegration
            key={config.name}
            config={config}
            integration={partners.livehire}
            setLoading={setLoading}
            popUpMessage={popUpMessage}
            connectLivehire={connectLivehire}
            disconnectLivehire={disconnectLivehire}
          />
        );
      case PartnersCodes.SmartRecruiters: 
        return( 
          <Integration.SmartRecruitersIntegration 
            key={config.name}
            config={config}
            integration={partners.smartrecruiters}
            setLoading={setLoading}
            popUpMessage={popUpMessage}
            connectLivehire={connectLivehire}
            disconnectLivehire={disconnectLivehire}
          /> 
        )
    }
  };

  return (
    <div>
      <div>
        <div className={cn('card--large', 'card', 'card-with-border', 'u-margin-bottom')}>
          <div className={cn('d-flex', 'justify-content-between', 'align-items-center', 'u-padding--large')}>
            <div>
              <h1 className='title-2'>Integrations</h1>
              <p className='t-small u-margin-0'>Configure and activate integrations between Checkmate and other software platforms. To request an integration, please contact <a href="mailto:team@checkmate.tech">team@checkmate.tech</a>.</p>
            </div>
            <SearchInput placeholder="Search for an integration" className='u-width-25' onChange={setSearchQuery} />
          </div>
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {PartnersConfig
          .filter(partner => partner.title.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
          .map(config => getPartnerIntegration(config))
        }
      </div>
    </div>
  )
}