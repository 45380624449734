import { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import styles from './HistoryRow.module.scss';
import cn from 'classnames';
import { useLocale } from 'hooks/locale';

const CheckHistoryTypes = {
  CompletedCheck: 1,
  CompletedReferee: 2,
  CompletedRefereeIpError: 3,
  CompletedCandidate: 4,
  UpdatedEmail: 5,
  UpdatedContactNumber: 6,
  UpdatedTeamName: 7,
  UpdatedJobRole: 8,
  RequestSentCandidate: 9,
  RequestOverdueCandidate: 10,
  RequestSentReferee: 11,
  RequestOverdueReferee: 12,
  NewReferee: 13,
  NewCheck: 14,
  BackgroundCheckSubmitted: 15,
  BackgroundCheckProcessing: 16,
  BackgroundCheckCompleted: 17,
  BackgroundCheckRejected: 18,
  BackgroundCheckUpdated: 19,
  CandidateEmailFailed: 20,
  RefereeEmailFailed: 21,
  CheckCanceled: 22,
  CandidateEmailOpened: 23,
  RefereeEmailOpened: 24,
  CandidateEmailClicked: 25,
  RefereeEmailClicked: 26,
  CustomNote: 27,
  CustomInfo: 28,
  CustomSuccess: 29,
  CustomWarning: 30,
  CustomError: 31,
  CheckDeclined: 32,
  CheckAddNotes: 33,
  CheckUpdateStatus: 34,
  RefereeDeleted: 35,
  SubCheckCompleted: 36,
  CandidateSMSOpened: 37,
  RefereeSMSOpened: 38,
  RefereeReplacement: 39,
  ReminderCandidate : 40,
  ReminderReferee : 41,
  UpdatedCandidateTeam: 42,
  CheckResult: 43,
};

export const HistoryRow = ({
  entry,
  candidate,
  companyChecks,
  referees,
  className,
  company,
  disableLinks = false,
  onCandidateRequest = () => {},
  onRefereeRequest = () => {},
}) => {

  const [icon, setIcon] = useState("loader");
  const [description, setDescription] = useState("");

  const { dateSlashFormat } = useLocale(company?.locale);

  useEffect(() => {
    const checkHuman = entry.check_type ? companyChecks?.find(companyCheck => companyCheck.type === entry.check_type)?.check_type.title : null;
    const userName = <b className={styles.userRef}>@{entry.user_name || "User"}&#160;</b>
    const refCandidate = disableLinks ? <b className={cn(styles.userRef, 'u-padding-right--xSmall')}>{candidate.name}</b> : <Link to={`/check/${candidate.id}/candidate`}>{candidate.name}</Link>
    const backgroundChecks = disableLinks ? null : <Link to={`/check/${candidate.id}/background_checks`}>View</Link>
    const country = (entry.country && entry.country !== 'Not specified.') ? `from ${entry.country}` : '';
    const reportType = entry.check_type ? entry.check_type : null;

    const referee = entry.referee_id ? referees.find(r => r.id === entry.referee_id) : null
    const refReferee = referee ? 
    (disableLinks ?
      <b className={cn(styles.userRef, 'u-padding-right--xSmall')}>{referee.name}</b> :
      <Link to={`/check/${candidate.id}/${reportType === 'custom_reference' ? 'reference' : 'referees'}/${referee.id}`}>{referee.name}</Link>
    ) : null
    const referenceLink = referee && !disableLinks ? <Link to={`/check/${candidate.id}/${reportType === 'custom_reference' ? 'reference' : 'reports'}/${referee.id}`}>View check</Link> : null

    let description, ref;
    switch(entry.type){
      case CheckHistoryTypes.CompletedCheck: {
        setIcon("check-circle");
        description = <p>Your check is completed for {refCandidate}. {referenceLink}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CompletedReferee: {
        setIcon("check-circle");
        description = <p>Reference check completed by {refReferee} {country}. {referenceLink}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CompletedRefereeIpError: {
        setIcon("alert-triangle");
        description = <p>Reference check completed by {refReferee} {country} using the same IP address as the candidate {refCandidate}. {referenceLink}</p>
        setDescription(description);   
        break;
      }
      case CheckHistoryTypes.CompletedCandidate: {
        setIcon("check-circle");
        if(referees && referees.length > 0) {
          var referees_links = referees.map(referee => (disableLinks ? 
            <b className={cn(styles.userRef, 'u-padding-right--xSmall')}>{referee.name}</b> :
            <Link key={referee.id} to={`/check/${candidate.id}/${reportType === 'custom_reference' ? 'reference' : 'referees'}/${referee.id}`}>{referee.name}</Link>
          ))
          description = <p>Candidate {refCandidate} successfully provided referee details for <span>{referees_links.reduce((prev, curr) => [prev, ' & ', curr])}</span> {country}</p>
        } else {
          description = <p>Candidate {refCandidate} successfully provided referee details. {country}</p>
        }
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.UpdatedEmail: {
        setIcon("mail");
        description = <p>{userName} updated {referee ? refReferee : refCandidate}'s email.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.UpdatedContactNumber: {
        setIcon("phone");
        description = <p>{userName} updated {referee ? refReferee : refCandidate}'s phone number.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.UpdatedTeamName: {
        setIcon("edit");
        description = <p>{userName} updated {refCandidate}'s team information.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.UpdatedJobRole: {
        setIcon("edit");
        description = <p>{userName} updated {refCandidate}'s job title.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RequestSentCandidate: {
        setIcon("send");
        description = <p>{userName} sent candidate {refCandidate} a new request.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RequestOverdueCandidate: {
        setIcon("alert-circle");
        let sendRequest = candidate.status === 'Candidate' && <a onClick={() => onCandidateRequest}>Resend request</a> 
        description = <p>Check for candidate {refCandidate} overdue. {sendRequest}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RequestSentReferee: {
        setIcon("send");
        description = <p>{userName} sent referee {refReferee} a new request.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RequestOverdueReferee: {
        setIcon("alert-circle");
        let sendRequest = referee && !referee.completed && <a onClick={() => onRefereeRequest(referee.id)}>Resend request</a>
        description = <p>Reference request for referee {refReferee} overdue. {sendRequest}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CandidateEmailFailed: {
        setIcon("alert-circle");
        description = <p>Email couldn't be send to {refCandidate}. You tried to send to a recipient that has been marked as inactive.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RefereeEmailFailed: {
        setIcon("alert-circle");
        description = <p>Email couldn't be send to referee {refReferee}. You tried to send to a recipient that has been marked as inactive.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.NewReferee: {
        setIcon("user-plus");
        description = <p>{userName} added new referee {refReferee}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.NewCheck: {
        setIcon("check-circle");
        ref = entry.check_type === CheckHistoryTypes.CheckReference ? refCandidate : backgroundChecks;
        description = <p>{userName} created a new {checkHuman} for candidate. {ref}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.BackgroundCheckSubmitted: {
        setIcon("check-circle");
        description = <p>{refCandidate} has completed the background application.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.BackgroundCheckProcessing: {
        setIcon("loader");
        description = <p>{refCandidate}`s {checkHuman} is being processed. {backgroundChecks}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.BackgroundCheckCompleted: {
        setIcon("check-circle");
        description = <p>{refCandidate}`s {checkHuman} has been completed. {backgroundChecks}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.BackgroundCheckRejected: {
        setIcon("alert-circle");
        description = <p>{checkHuman} has been declined. New request sent to {refCandidate}.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.BackgroundCheckUpdated: {
        setIcon("refresh-ccw");
        description = <p>{refCandidate} updated {checkHuman} declined fields. {backgroundChecks}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CheckCanceled: {
        setIcon("alert-circle");
        description = <p>{userName} canceled {refCandidate}`s {checkHuman}.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CandidateEmailOpened: {
        setIcon("info");
        description = <p>{refCandidate} has opened the email.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CandidateSMSOpened: {
        setIcon("info");
        description = <p>{refCandidate} has opened the SMS.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RefereeSMSOpened: {
        setIcon("info");
        description = <p>{refReferee} has opened the SMS.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RefereeEmailOpened: {
        setIcon("info");
        description = <p>{refReferee} has opened the email.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CandidateEmailClicked: {
        setIcon("info");
        description = <p>{refCandidate} has clicked a link in the email.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RefereeEmailClicked: {
        setIcon("info");
        description = <p>{refReferee} has clicked a link in the email.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CustomNote: {
        setIcon("edit");
        setDescription(<p>{userName}: <span className={styles.wrap}>{entry.message}</span></p>);
        break;
      }
      case CheckHistoryTypes.CustomInfo: {
        setIcon("info");
        setDescription(<p>{userName}: <span className={styles.wrap}>{entry.message}</span></p>);
        break;
      }
      case CheckHistoryTypes.CustomSuccess: {
        setIcon("check-circle");
        setDescription(<p>{userName}: <span className={styles.wrap}>{entry.message}</span></p>);
        break;
      }
      case CheckHistoryTypes.CustomWarning: {
        setIcon("alert-triangle");
        setDescription(<p>{userName}: <span className={styles.wrap}>{entry.message}</span></p>);
        break;
      }
      case CheckHistoryTypes.CustomError: {
        setIcon("alert-circle");
        setDescription(<p>{userName}: <span className={styles.wrap}>{entry.message}</span></p>);
        break;
      }
      case CheckHistoryTypes.CheckDeclined: {
        setIcon("alert-circle");
        setDescription(<p>Check declined with reason: "<span className={styles.wrap}>{entry.message}</span>"</p>);
        break;
      }
      case CheckHistoryTypes.CheckAddNotes: {
        setIcon("info");
        setDescription(<p>Checkmate notes for {refCandidate}: "<span className={styles.wrap}>{entry.message}</span>"</p>);
        break;
      }
      case CheckHistoryTypes.SubCheckCompleted: {
        setIcon("check-circle");
        description = <p>{refCandidate}`s {checkHuman} has a new partial result. {backgroundChecks}</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CheckUpdateStatus: {
        setIcon("info");
        setDescription(<p>{userName} <span className={styles.wrap}>marked the approval status of {checkHuman} as {entry.message}</span></p>);
        break;
      }
      case CheckHistoryTypes.RefereeDeleted: {
        setIcon("alert-circle");
        description = <p>{userName} deleted referee <b className={cn(styles.userRef, 'u-padding-left--small')}><span className={styles.wrap}>{entry.message}</span></b>.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.RefereeReplacement: {
        setIcon("alert-circle");
        description = <p>{userName} asked {refCandidate} to replace {refReferee}.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.ReminderCandidate: {
        setIcon("info");
        description = <p>Reminder sent to @{refCandidate}.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.ReminderReferee: {
        setIcon("info");
        description = <p>Reminder sent to @{refReferee}.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.UpdatedCandidateTeam: {
        setIcon("edit");
        description = <p>{userName} updated {refCandidate}'s team.</p>
        setDescription(description);
        break;
      }
      case CheckHistoryTypes.CheckResult: {
        setIcon("info");
        description = <p>{checkHuman} report downloaded by <span style={{ paddingLeft: '3px'}}> {userName}</span></p>
        setDescription(description);
        break;
      }
      default: {
        setIcon("help-circle");
        setDescription("Unknown action.");
      }
    }
  }, [candidate, referees, entry, companyChecks?.length]);

  return (
    <div className={cn(styles.root, { [className]: className })} >
      <div className={styles.date}>
        <Moment format={dateSlashFormat}>{entry.date}</Moment>
      </div>
      <div className={styles.time}>
        <Moment format="@HH:mm">{entry.date}</Moment>
      </div>
      <FeatherIcon icon={icon} className={styles.icon} />
      <div className={styles.description}>
        {description}
      </div>
    </div>
  )
}
