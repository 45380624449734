import axios from 'axios';
import { Buffer } from 'buffer';

export function uploadSigned(url, data, content_type, base64=false){
  let params = {
    method: 'PUT',
    url,
    data,
    headers:  {
      'Content-Type': content_type
    }
  }

  if(base64) {
    params["data"] = Buffer.from(data.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    params["headers"]["ContentEncoding"] = "base64";
  }

  return new Promise((resolve, reject) => {
    axios(params)
    .then(response => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    });
  });
}