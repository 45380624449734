import SignatureCanvas from 'react-signature-canvas';
import styles from './Signature.module.scss';
import { useRef, useState } from 'react';
import { uploadSigned } from 'helpers/aws-s3';
import { useEffect } from 'react';
import { Loader } from 'components/Loader/Loader';
import cn from 'classnames';

export const Signature = ({ 
  signatureId,
  modelParams = {},
  metadata = {},
  className,
  bucketFolder = 'answers',
  useModel = false,
  onChange     = () => {},
  getUpload    = () => {},
  signUpload   = () => {},
  createUpload = () => {},
  deleteUpload = () => {},
}) => {

  const [value, setValue] = useState(null);

  const [loading, setLoading] = useState(false);

  const [empty, setEmpty] = useState(true);
  const [image, setImage] = useState(null);
  const signatureRef = useRef(null);

  useEffect(() => {
    if(!value) {
      setImage(null);
      return;
    }

    if(useModel) {
      setImage(value.url);
    } else {
      getUpload(value).then((upload) => setImage(upload.url))
    }


  }, [value])

  useEffect(() => setValue(signatureId), [signatureId])

  /** 
   * --- On upload ---
   * 1) Get file meta
   * 2) Sign upload
   * 3) Upload to s3
   * 4) Create upload
   * 5) Set value
   */

  const handleSignature = async() => {
    setLoading(true);

    const params = {
      file_name: "signature.png",
      file_type: "image/png",
      file_ext: "png",
      bucket_folder: bucketFolder
    }

    const signedUpload = await signUpload(params);

    const url = signatureRef.current.toDataURL();

    await uploadSigned(signedUpload.url, url, "image/png", true);

    const uploadModel = {
      ...modelParams,
      metadata,
      file_name: signedUpload.file_name,
      file_type: signedUpload.file_type,
      hash_key: signedUpload.key,
    }

    const upload = await createUpload(uploadModel);
    onChange(useModel ? upload : upload.id);
    setLoading(false);
  }

  const handleClear = async() => {
    setLoading(true);
    if (value) {
      const id = useModel ? value.id : value;
      await deleteUpload(id);
    } else {
      signatureRef.current.clear();
      setEmpty(signatureRef.current.isEmpty());
    }
    setImage(null);
    onChange(null);
    setLoading(false);
  }

  return (
    <>
      <div className={cn(styles.root, {[className]: className}, 'd-flex flex-column t-color-secondary t-tiny')}>
        <p>Please draw your initials or signature below and when satisfied press the green button on the top right. You can also clear using the red button</p>
        {loading && (
          <div className={styles.load}>
            <Loader />
          </div>
        )}
        {!empty && !value &&  <button type='button' className={cn(styles.button, styles.buttonPrimary)} onClick={handleSignature}>Confirm Signature</button>} 
        {(!empty || image) && <button type='button' className={cn(styles.button, styles.buttonSecondary)} onClick={handleClear}>Redo</button>}
        {image 
          ? <div className={styles.image}><img src={image} alt='signature' /></div>
          : <SignatureCanvas
              canvasProps={{ className: styles.canvas }} 
              ref={signatureRef}
              onEnd={() => setEmpty(signatureRef.current.isEmpty())}
            />
        }
        
      </div>
    </>
  )
}