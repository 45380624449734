import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { Button, EditableTitle } from 'components/FormComponents';
import { useEffect, useState } from 'react';
import { IconButton } from 'components/IconButton/IconButton';
import styles from './SectionedFormEdit.module.scss';
import { FieldTypes } from 'constants/field-types-enum';
import LinkShare from '../../LinkShare/LinkShare';
import { createSlider } from '@typeform/embed';
import cn from 'classnames';
import { ToggableSection } from '../ToggableSection/ToggableSection';
import { FormSection } from '../FormSection/FormSection';
import { FormSectionEdit } from '../FormSectionEdit/FormSectionEdit';

export const SectionedFormEdit = ({
  form,
  onCopy    = () => {},
  onSave    = () => {},
  onDelete  = () => {},
  onClose   = () => {},
}) => {

  const { control, register, setValue, unregister, reset, watch, handleSubmit, formState: { errors } } = useForm({ defaultValues: form });
  const { fields, append, move, remove } = useFieldArray({ control, name: 'fields', keyName: 'key' });

  const nameValue = useWatch({ control, name: 'name' });

  const [linkOpened, setLinkOpened] = useState(false);

  useEffect(() => reset(form), [form]);

  const onError = (error) => {
    console.log("Validation Error");
    console.log(error);
  }

  const showPreview = () => { 
    const { open } = createSlider(form.typeform_id, {
      hidden: {
        candidate: "Candidate",
        referee: "Referee",
        job: "Job"
      },
      hideHeaders: true,
      hideFooter: true
    });
    open()
  }

  const partFields = (part) => {
    return fields.filter(field => field.check_part === part);
  }

  const addField = () => {
    append({
      order: fields.length + 1,
      text: '',
      check_part: 'CUSTOM',
      type: FieldTypes.FreeText,
      required: true,
      options: { required: true, options: [{ value: '' }] }
    });
  }

  const removeField = (index) => {
    for(let i=index + 1; i <= fields.length - 1; i++) setValue(`fields[${i}].order`, i);
    remove(index);
  }

  return (
    <form onSubmit={handleSubmit(onSave, onError)} className={cn(styles.root, 'card', 'card-with-border', 'card--medium')}>
      <div className='u-margin--large'>
        <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center',)}>
          <div className='u-width-50'>
            <div className={cn('u-flex-box', 'u-flex-align-center')}>
              <EditableTitle register={register} name='name' value={nameValue} />
            </div>
            <p className='t-small'>{form?.fields.length} questions / {Math.ceil(form?.fields.length / 3)} minutes to complete</p>
          </div>
          <div>
            <LinkShare open={linkOpened} link={`https://checkmatetech.typeform.com/to/${form?.typeform_id}?candidate=Candidate&referee=Referee&job=Job`} title="Share this form" onClose={() => setLinkOpened(false)}/>
            <IconButton icon="copy" tip="Copy" className='u-margin-right--small' disabled={!form.id} onClick={onCopy}/>
            <IconButton icon="share-2" disabled={!form?.typeform_id} tip="Share Check" className='u-margin-right--small' onClick={() => setLinkOpened(!linkOpened)}/> 
            <IconButton icon="play" tip="Preview Check" disabled={!form?.typeform_id} className='u-margin-right--small' onClick={showPreview}/>
            <IconButton icon="user-plus" tip="New Check" className='u-margin-right--small' disabled={!form?.typeform_id} onClick={() => navigate(`/new_check/${form.typeform_id}`) }/>
            <IconButton icon="trash-2" tip="Delete Check" className='u-margin-right--small' onClick={onDelete}/>
          </div>
        </div>
      </div>
      <hr />
      <div className='u-margin--large'>
        <ToggableSection title={<h1 className='title-4'>Part 1. Validation <span className='t-small'>({partFields('VALIDATION').length} questions)</span></h1>} >
          <FormSection fields={partFields('VALIDATION')} />
        </ToggableSection>
        <hr className='divider' />
        <ToggableSection title={<h1 className='title-4'>Part 2. Custom <span className='t-small'>({partFields('CUSTOM').length} questions)</span></h1>} >
          <FormSectionEdit 
            fields={partFields('CUSTOM')}
            control={control} 
            errors={errors}
            unregister={unregister}
            watch={watch}
            register={register}
            setValue={setValue}
            moveField={move}
            addField={addField}
            removeField={removeField}
          />
        </ToggableSection>
      </div>
      <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
        <Button onClick={onClose} type='secondary'>Cancel</Button>
        <Button submit>Save</Button>
      </div>
    </form>
  )
}