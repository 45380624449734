import { useRef } from 'react';
import styles from './LinkShare.module.scss';
import cn from 'classnames';
import { useClickOutside } from 'hooks/click-outside';
import { useClipboard } from 'hooks/clipboard';

export const LinkShare = ({
  open, 
  link,
  title,
  buttonText = 'Copy',
  onClose = () => {}
}) => {

  const ref = useRef(null);
  const linkRef = useRef(null);

  useClickOutside(ref, onClose);
  const copyToClipboard = useClipboard(linkRef);

  return (
    <div ref={ref} className={cn(styles.root, {[styles.disabled]: !open })}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <a className={styles.copy} onClick={copyToClipboard}>{buttonText}</a>
      </div>
      <input ref={linkRef} value={link} readOnly />
    </div>
  )
}

export default LinkShare