import styles from './FormSection.module.scss';
import { FieldTypeDetails } from 'constants/field-types-enum';
import { getTextWithReferences } from 'helpers/form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import cn from 'classnames';

const INDEX_LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export const FormSection = ({ fields }) => (
  fields?.map(field => (
    <div className={cn('u-margin-y d-flex', 'justify-content-between', 'align-items-start')}>
      <div className='d-flex align-items-start u-width-75'><span className={styles.order}>{field.order}</span> <p className={cn('u-margin-0', 'u-padding-left--small', styles.padding)}>{getTextWithReferences(field.text)}</p></div>
      <FormQuestionType field={field} />
    </div>
  ))
)

const FormQuestionType = ({ field }) => {

  const options = field?.options?.options?.map((option, i) => (
    <div key={i}>{INDEX_LETTERS[i] + '. ' + option.value}</div>
  ))

  return (
    <OverlayTrigger trigger={options ? ['hover', 'focus'] : 'none'} overlay={<Tooltip>{options}</Tooltip>}>
      <div className={styles.padding}>
        <p className='title-6 u-margin-0'>{FieldTypeDetails[field.type]?.title}</p>
      </div>
    </OverlayTrigger>
  )
}