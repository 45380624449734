import React, { useState, useRef } from 'react';
import styles from './CreditsBalanceDetails.module.scss';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/FormComponents';
import { useClickOutside } from '../../hooks/click-outside';
import cn from 'classnames';


const Check = ({logo, name, credits}) => (
  <div className={styles.checkRow}>
    <div className="u-flex-box u-flex-align-center">
      <img className={styles.checkRowLogo} src={logo}/>
      <span className={styles.checkRowName}>{name}</span>
    </div>
    <div className={styles.checkRowCredits}>
      {credits < 0 && 
        <span className={cn(styles.checkRowCredits, styles.checkRowCreditsUnlimited)}>Unlimited</span> 
      }
      {credits === 0 && 
        <span className={cn(styles.checkRowCredits, styles.checkRowCreditsZero)}>Run out</span> 
      }
      {credits > 0 && 
        <span className={cn(styles.checkRowCredits, styles.checkRowCredits)}>{credits} left</span> 
      }
    </div>
  </div>
)


export const CreditsBalanceDetails = ({ companyChecks }) => {
  const wrapperRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  useClickOutside(wrapperRef, () => { setShowMenu(false) })

  return (
    <div className={styles.root} ref={wrapperRef}>
      <Button 
        className={styles.button} 
        noUppercase
        onClick={() => setShowMenu(!showMenu)}
      >
        Balance <FeatherIcon className={styles.chevron} icon="chevron-down"/>
      </Button>
      
      {showMenu && 
        <div className={styles.dropdownMenu}>
          {companyChecks.map(companyCheck => {
            return (
              <Check
                key={companyCheck.type}
                logo={companyCheck.check_type.logo} 
                name={companyCheck.check_type.title} 
                credits={companyCheck.credits}
              />
            )
          })}

          <Button noUppercase small className='u-width-100 u-margin-top u-margin-bottom--small' onClick={() => window.open('https://www.checkmate.tech/company/contact', '_blank')}>
            Order more checks
          </Button>
        </div>
      }
    </div>
  )
}