import { useEffect, useState } from 'react';
import { CustomButton } from '../CustomButton/CustomButton';
import { FilePicker } from 'components/FormComponents';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';

export const UploadIdModal = ({ 
  brand,
  candidate,
  selectedIdType,
  visible,
  uploads    = [],
  onClose    = () => {},
  signUpload = () => {},
  onUpload   = () => {},
  onDelete   = () => {},
}) => {

  const [idUpload, setIdUpload] = useState();
  const [complementaryIdUpload, setComplementaryIdUpload] = useState();

  useEffect(() => {
    if(!selectedIdType) return;

    const idUpload = uploads.find(u => u.metadata.id_type === selectedIdType.code)
    setIdUpload(idUpload);

    if(selectedIdType.complementary_id) {
      setComplementaryIdUpload(uploads.find(u => u.metadata.id_type === selectedIdType.complementary_id.code));
    }
  }, [uploads, selectedIdType])

  const onSubmit = () => {
    setIdUpload();
    setComplementaryIdUpload();
    onClose();
  }

  const handleDelete = () => {
    if(!idUpload) return;

    onDelete(idUpload.id)
    setIdUpload();
  }

  const handleDeleteComplementaryId = () => {
    if(!complementaryIdUpload) return;

    onDelete(complementaryIdUpload.id)
    setComplementaryIdUpload();
  }

  return (
    <AnimatedModal title='ID Upload' visible={visible} onClose={onClose}>
      <p className='t-small u-margin-top'>Please upload {selectedIdType?.prefix ? selectedIdType?.prefix : ''} your {selectedIdType.title}</p>
      <FilePicker
        useModel
        accept={{ 'application/pdf': ['.pdf'], 'image/*': ['.jpeg', '.png'] }}
        modelParams={{ candidate_id: candidate.id }}
        metadata={{ id_type: selectedIdType?.code }}
        bucketFolder="background_applications"
        value={idUpload}
        signUpload={signUpload}
        createUpload={onUpload}
        deleteUpload={handleDelete}
        onChange={setIdUpload}
      />

      {selectedIdType?.complementary_id &&
        <>
          <p className='t-small u-margin-top--large'>Please upload {selectedIdType.complementary_id.prefix ? selectedIdType.complementary_id.prefix : ''} your {selectedIdType.complementary_id.title}</p>
          <FilePicker
            useModel
            accept={{ 'application/pdf': ['.pdf'], 'image/*': ['.jpeg', '.png'] }}
            modelParams={{ candidate_id: candidate.id }}
            metadata={{ id_type: selectedIdType.complementary_id.code }}
            bucketFolder="background_applications"
            value={complementaryIdUpload}
            signUpload={signUpload}
            createUpload={onUpload}
            deleteUpload={handleDeleteComplementaryId}
            onChange={setComplementaryIdUpload}
          />
        </>
      }

      <CustomButton 
        brand={brand}
        className='u-margin-top--large'
        onClick={onSubmit}
        disabled={!idUpload || (selectedIdType?.complementary_id && !complementaryIdUpload)}
        small
      >
        Done
      </CustomButton>
    </AnimatedModal>
  )

}