import { useMemo } from 'react';
import cn from 'classnames';
import styles from './Steps.module.scss';

export const Steps = ({ 
  brand, 
  steps, 
  currentStep 
}) => {

  const stepsArray = useMemo(() => {
    return Array.from(Array(steps).keys())
  }, [steps]);

  return (
    <div className={cn(styles.root, 'u-margin-bottom')}>
      {stepsArray.map(i => (
        <span
          key={i}
          style={i <= currentStep ? { backgroundColor: brand.color_button, color: '#FFF', border: 'none' } : {} }
          className={cn(styles.step)}
        >
          {i + 1}
        </span>
      ))}
    </div>
  )
}