import { Button } from "components/FormComponents";
import styles from './Alert.module.scss';
import cn from 'classnames';

const Alert = ({ 
  show = false,
  title,
  message,
  ok,
  cancel,
  params = {},
  onOk = () => {},
  onCancel = () => {}
}) => {

  return (
    <div className={cn(styles.root, {[styles.rootHidden]: !show })}>
      <div className={styles.content}>
        <div className={styles.contentTitle}>{title}</div>
        <div className={styles.contentMessage}>{message}</div>
        <div className={styles.contentButtons}>
          { cancel && <Button type="secondary" onClick={onCancel} className="cancel-btn button">{cancel}</Button> }
          { ok && <Button onClick={() => onOk(params)} className="button">{ok}</Button> }
        </div>
      </div>
    </div>
  );
}

export default Alert;