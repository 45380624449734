import { useState, useEffect } from 'react';
import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS, MESSAGE_STATE_INFO } from 'constants/message-app-state-contants';
import { Button } from 'components/FormComponents';
import { useNavigate } from 'react-router-dom';
import { AppNavbar } from './AppNavbar/AppNavbar';
import AppMessage from './AppMessage/AppMessage';
import { Loading } from 'components/Loading/Loading';
import { EmailVerificationAlert } from 'components/EmailVerificationAlert/EmailVerificationAlert';
import { Modal } from 'components/Modal/Modal';
import { useParams } from "react-router-dom";
import cookie from 'react-cookies';
import styles from './AppPage.module.scss';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthentication, logout, stopInterpretation, authenticate } from 'api/authentication-slice';
import { selectUser, getUser, resendUserEmailValidation } from 'api/user-slice';
import { getUserPermissions, selectPermissions } from 'api/user-permissions-slice';
import { selectCompany, getCompany } from 'api/company-slice';
import { selectApp, messagePopUp, messagePopOut } from 'api/app-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { getCompanyChecks } from 'api/company-checks-slice';

const AppPage = ({
  fillOnScroll,
  loading,
  children,
  requiredPermissions = [],
}) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const authentication = useSelector(selectAuthentication);
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const app = useSelector(selectApp);
  const companyChecks = useSelector(selectCompanyChecks);
  const permissions = useSelector(selectPermissions);

  const [fetchingUser, setFetchingUser] = useState(true);
  const [sendingEmailValidation, setSendingEmailValidation] = useState(false);
  const [verificationAlert, setVerificationAlert] = useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);

  let { validate } = useParams();

  useEffect(() => {
    if(validate) {
      dispatch(
        messagePopUp({
          text: 'Your email address has been successfully verified', 
          state: MESSAGE_STATE_SUCCESS,
          hide: true
        })
      );
    }

    dispatch(authenticate());
  }, [])

  useEffect(() => {
    if(!authentication.checked) return;
    
    if(!authentication.logged) {
      navigate(`/sign_in?redirect_uri=${window.location.pathname}`);
      return;
    }

    dispatch(getUser(authentication.user_id));
    dispatch(getCompany(authentication.company_id));
    dispatch(getUserPermissions());
    dispatch(getCompanyChecks(authentication.company_id));

  }, [authentication.checked])


  useEffect(() => {
    if(!user.id) return
    
    setFetchingUser(false);

    if(!user.verified) {
      dispatch(
        messagePopUp({
          text: 'Please validate your email address. {{Resend email confirmation}}', 
          state: MESSAGE_STATE_ERROR,
          hide: false,
          params: 'SEND_VERIFY_EMAIL'
        })
      );
    }

    if(!user.verified && user.overdue)
      setVerificationAlert(true);

    if(cookie.load('admin-token') && user) {
      dispatch(
        messagePopUp({
          text: `You are interpreting ${user.name}. {{Stop interpreting}}`, 
          state: MESSAGE_STATE_INFO,
          hide: false,
          params: 'STOP_INTERPRETING'
        })
      );
    }
  }, [user])

  useEffect(() => {
    if(!requiredPermissions || permissions.length < 1) return;

    if(requiredPermissions.filter(permission => !permissions.includes(permission)).length > 0)
      navigate('/dashboard');
  }, [permissions.length, requiredPermissions])

  const signOut = async() => {
    await dispatch(logout());
    navigate('/sign_in');
  }

  const handleMessageClick = (params) => {
    switch(params){
      case 'SEND_VERIFY_EMAIL':
        resendEmail();
        break;
      case 'STOP_INTERPRETING':
        dispatch(stopInterpretation());
        dispatch(messagePopOut());
        navigate(cookie.load('admin-route'));
        break;
    }
  }

  const resendEmail = async() => {
    setSendingEmailValidation(true);
    dispatch(
      messagePopUp({
        text: 'Please validate your email address.', 
        state: MESSAGE_STATE_ERROR,
        hide: false,
      })
    );
    if(!user.verified){
      await dispatch(resendUserEmailValidation(user.id));
      setEmailVerificationSent(true);
    }
    setSendingEmailValidation(false);
  }

  return(
    <div className={styles.root}>
      <Loading active={loading || fetchingUser || sendingEmailValidation}></Loading>
      <AppNavbar
        user={user}
        company={company}
        authentication={authentication}
        companyChecks={companyChecks}
        app={app}
        fillOnScroll={fillOnScroll}
        onSignOut={signOut}
      />
      <AppMessage {...app.message} onClick={handleMessageClick}/>
      <Modal show={verificationAlert} disableClose>
        <EmailVerificationAlert resend={resendEmail} sent={emailVerificationSent} onSignOut={signOut} />
      </Modal>
      {!fetchingUser && children}
    </div>
  )
}

const Header = ({ 
  title, 
  subtitle, 
  className,
  ctaText, 
  ctaClick = () => {}
}) => (
  <header className={cn(styles.header, { [className]: className })}>
    <div>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
    {ctaText &&
      <Button onClick={ctaClick}>{ctaText}</Button>
    }
  </header>
)

AppPage.Header = Header;
export default AppPage; 