import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { saveAs } from 'file-saver';
import cookie from 'react-cookies';

export const subChecksApi = createApi({
  reducerPath: 'subChecksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/sub_checks`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    updateSubCheck: builder.mutation({
      query: ({ id, params }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: params,
      }),
      transformResponse: ({ result }) => result
    }),
    deleteSubCheck: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      transformResponse: ({ result }) => result
    }),
    getSubCheckResult: builder.query({
      queryFn: async ({ subCheck, check, candidate, isFile=true }, api, extraOptions, baseQuery) => {
        const { data: result } = await baseQuery({
          url: `/${subCheck.id}/result`,
          method: 'GET',
          responseHandler: async (response) => {
            if(isFile) {
              const blob = await response.blob();
              return blob;
            } else {
              const jsonResponse = await response.json();
              return jsonResponse.result;
            }
          },
          cache: "no-cache",
        });
        if(isFile)
          saveAs(result, `${candidate.first_name}_${candidate.last_name}-${check.type}-${subCheck.title}`);
        else 
          window.open(result, '_blank');

        return { data: null }
      }
    }),
  })
})

export const {
  useUpdateSubCheckMutation,
  useDeleteSubCheckMutation,
  useLazyGetSubCheckResultQuery
} = subChecksApi
