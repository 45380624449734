import React, { useEffect, useState } from 'react';
import { HistoryRow } from 'components/HistoryRow/HistoryRow';
import { AddHistoryModal } from './components/AddHistoryModal/AddHistoryModal';
import { Button } from 'components/FormComponents';
import { useGetCandidateHistoryQuery } from 'api/candidates-api';
import styles from './History.module.scss';
import cn from 'classnames';

export const History = ({
  company,
  candidate,
  referees,
  user,
  companyChecks,
  targetNewRecord,
  createRecord = () => {},
  onCandidateRequest = () => {},
  onRefereeRequest = () => {},
  setLoading = () => {},
}) => {
  const [entries, setEntries] = useState([]);
  const [addNoteModal, setAddNoteModal] = useState(false);

  const { data: entriesData, isLoading: isLoadingRecords } = useGetCandidateHistoryQuery(candidate.id)

  useEffect(() => { if(entriesData) setEntries(entriesData) }, [entriesData])
  useEffect(() => { setLoading(isLoadingRecords) }, [isLoadingRecords])

  useEffect(() => {
    if(targetNewRecord) setAddNoteModal(true);
  }, [targetNewRecord])

  const handleAddRecord = async(data) => {
    setLoading(true);
    const { data: result } = await createRecord({ id: candidate.id, data });
    setEntries([result, ...entries]);
    setLoading(false);
  }

  return (
    <div>
      <AddHistoryModal 
        visible={addNoteModal} 
        onClose={() => setAddNoteModal(false)} 
        company={company}
        candidate={candidate}
        companyChecks={companyChecks}
        user={user}
        onSave={handleAddRecord}
      />
      <div className={cn('d-flex', 'u-width-100', 'justify-content-end', 'u-margin-bottom--large')}>
        <Button onClick={() => setAddNoteModal(true)}>Add History Record</Button>
      </div>
      {entries.map(entry => {
        return (
          <HistoryRow
            key={entry.id}
            entry={entry}
            company={company}
            companyChecks={companyChecks}
            className={styles.row}
            candidate={candidate}
            referees={referees}
            onCandidateRequest={onCandidateRequest}
            onRefereeRequest={onRefereeRequest}
          />
        )})
      }
    </div>
  )
}
