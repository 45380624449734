import { useState, } from 'react';
import styles from './TwoFactorAuthentication.module.scss';
import AsyncImage from 'components/async-image/async-image';
import { CodeInput } from 'components/CodeInput/CodeInput';
import { MESSAGE_STATE_ERROR, MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import { IconButton } from 'components/IconButton/IconButton';
import cn from 'classnames';
import { useGet2FASecretQuery } from 'api/user-api';

export const TwoFactorAuthentication = ({
  user, 
  setLoading = () => {}, 
  onEnable2FA = () => {}, 
  onUserUpdate = () => {}, 
  messagePopUp = () => {}
}) => {

  const { data: mfaData } = useGet2FASecretQuery(user.id)

  const [ configured, setConfigured ] = useState(user.two_factor_auth_enabled);

  const disabled = () => {
    onUserUpdate({ two_factor_auth_secret: null });
    setConfigured(false);
  }

  const verifyTokenAndEnable = async(code) => {
    setLoading(true);
    const { payload: result } = await onEnable2FA({ secret: mfaData.result.secret, code });
    if(result.succed) {
      setConfigured(true);
      messagePopUp('Two Factor Authentication is now activated in your account', MESSAGE_STATE_SUCCESS);
    } else {
      messagePopUp('Two Factor Authentication could not be configured. Try with a new code', MESSAGE_STATE_ERROR);
    }
    setLoading(false);
  }

  return (
    <div>
      <div className={styles.configuration}>
        {configured ?
          <div className={styles.header}>
            2FA is configured <IconButton icon='shield-off' className='u-margin-left' tip="Disabled 2FA" onClick={disabled} />
          </div> :
          <>
            <div className={styles.header}>
              Setting up 2FA
            </div>        
            <div className={styles.description}>
              Two factor authentication is a good way of increasing account security, by adding a second step to the normal Checkmate login flow. 
              Generate a code using your mobile device to log into your acoount.
            </div>
            <div className="u-margin-top">
              <div className={styles.step}>
                <span className={styles.stepOrder}>1</span>
                <p>Download & install Google Authenticator app or any other of your preference.</p>
              </div>
              <div className={styles.step}>
                <span className={styles.stepOrder}>2</span>
                <p>Scan the following QR Code or copy the secret key.</p>
              </div>
              <div className={cn(styles.step, styles.qrWrapper)}>
                <AsyncImage className={styles.qrImage} src={mfaData?.result?.qr} />
                <div className={styles.secretCode}>{mfaData?.result?.secret}</div>
              </div>
              <div className={styles.step}>
                <span className={styles.stepOrder}>3</span>
                <p>Enter generated code.</p>
              </div>
              <div>
                <CodeInput className='u-margin-left--large' onComplete={verifyTokenAndEnable} autoFocus={false} />
              </div>
            </div>
          </>   
        }
      </div>
    </div>
  )
} 