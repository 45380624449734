import { InputGroup, Input } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import Alert from 'components/Alert/Alert';
import { IntegrationCard } from '..';
import { useState } from 'react';
import { Partners } from 'constants/partners';

export const AccountApiIntegration = ({ 
  config, 
  integration,
  revokeToken = () => {}
}) => {

  const [showDeletionAlert, setShowDeletionAlert] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(integration.api_key);
  }

  const handleRevokeToken = () => {
    setShowDeletionAlert(false);
    revokeToken(Partners.AccountApi)
  }

  return (
    <IntegrationCard {...config}>
      <Alert
        show={showDeletionAlert}
        title='Are you sure you want to revoke your current API token?'
        message="You're about to revoke your current API token. This will stop all the integrations that are using this token. Are you sure you want to continue?"
        ok='Revoke'
        onOk={handleRevokeToken}
        cancel='Cancel'
        onCancel={() => setShowDeletionAlert(false)}
      />
      <InputGroup title='API key' className='u-margin-top'>
        <div className='d-flex align-items-center justify-content-between'>
          <Input className='u-width-100' inputProps={{ value: integration.api_key }} />
          <IconButton icon='clipboard' className='u-margin-left--small' tip='Copy' placement='bottom' onClick={copy}/>
          <IconButton icon='trash-2' className='u-margin-left--small' type='delete' tip='Revoke' placement='bottom' onClick={() => setShowDeletionAlert(true)} />
        </div>
      </InputGroup>
    </IntegrationCard>
  )
}