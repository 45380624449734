import { useEffect, useState} from 'react';
import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import Moment from 'react-moment';
import LinkShare from '../../../../LinkShare/LinkShare';
import { Button } from 'components/FormComponents';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import styles from './CombinedReportHeader.module.scss';
import cn from 'classnames';

export const CombinedReportHeader = ({
  company,
  responses,
  referees,
  candidate,
  check,
  isStatic,
  intelligent,
  onDownloadClick = () => {}
}) => {

  const [linkOpened, setLinkOpened] = useState(false);
  const [charData, setCharData] = useState({});

  useEffect(() => {
    let chartData = [{ name: '11'}, { name: '12' }, { name: '13' }, { name: '14' }, { name: '15' }, { name: '16' }, { name: '17' }, { name: '18' }, { name: '19' }, { name: '20' } ];

    referees.forEach((referee) => {
      const answers = responses[referee.id].answers;

      chartData[0][referee.name] = answers[11]
      chartData[1][referee.name] = answers[12]
      chartData[2][referee.name] = answers[13]
      chartData[3][referee.name] = answers[14]
      chartData[4][referee.name] = answers[15]
      chartData[5][referee.name] = answers[16]
      chartData[6][referee.name] = answers[17]
      chartData[7][referee.name] = answers[18]
      chartData[8][referee.name] = answers[19]
      chartData[9][referee.name] = answers[20]
    });

    setCharData(chartData);
  }, [referees, responses])

  return(
    <div className={cn(styles.root, 'u-padding--large')}>
      {isStatic &&
        <>
          <div className={cn('d-flex', 'justify-content-between', 'align-items-center', 'u-margin-bottom--large')}>
            <div className='title-5'>{company.name}</div>
            <div className='d-flex align-items-center'>Powered by <img className='u-margin-left--small' style={{ height: '25px' }} src="assets/images/checkmate/icon-label.svg" alt="checkmate"/></div>
          </div>
          <div className={cn('u-margin-bottom', 'd-flex', 'justify-content-start', 'align-items-start', styles.candidate)}>
            <div>
              <div className='t-tiny u-bold'>REFERENCE CHECK FOR</div>
              <div className='title-3'>{candidate.name}</div>
            </div>
            <div>
              <p className='t-tiny u-bold'>ROLE</p>
              <p className='title-5 u-margin-top--small'>{candidate.job_role}</p>
            </div>
            <div>
              <p className='t-tiny u-bold'>TEAM</p>
              <p className='title-5 u-margin-top--small'>{candidate.team_name}</p>
            </div>
            <div>
              <p className='t-tiny u-bold'>CREATE ON</p>
              <p className='title-5 u-margin-top--small'><Moment format="DD/MM/YYYY">{candidate.create_date}</Moment></p>
            </div>
            <div>
              <p className='t-tiny u-bold'>COMPLETED ON</p>
              <p className='title-5 u-margin-top--small'><Moment format="DD/MM/YYYY">{check.completed_at}</Moment></p>
            </div>
          </div>
          <hr />
        </>
      }
      <div className='d-flex justify-content-between align-items-center u-margin-bottom--large'>
        <div>
          <div className='t-tiny u-bold'>COMBINED REPORT</div>
          <div className='title-3'>{`${check.details?.referees}x Referees`}</div>
          <div className='t-small'>{referees.map(referee => referee.name).join(' & ')}</div>
        </div>
        <div>
          {!isStatic &&
            <div className="d-flex justify-content-end align-items-center">
              <LinkShare open={linkOpened} link={`${process.env.REACT_APP_WEB_URL}/company/report/combined/${candidate.token}`} title="Share this report" onClose={() => setLinkOpened(false)} />
              <Button onClick={() => onDownloadClick(candidate.id, true)} icon='download'>Download</Button>
            </div>
          }
        </div>
      </div>
      <div className='u-flex-box u-flex-justify-between u-flex-align-stretch'>
        <div className={styles.verificationCard}>
          <div className={styles.title}><FeatherIcon icon="mail"/> email address</div>
          {referees.map(referee => (
              <div className={styles.referee} key={referee.id}>
                <div className={styles.title}>{referee.name}</div>
                <div className={cn(styles.value, 'u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
                  <a href={"mailto:"+referee.email}>{referee.email}</a>
                  <FeatherIcon icon={referee.email === candidate.email ? "alert-triangle" : "check-circle"}/>
                </div>
              </div>
            ))
          }
        </div>
        <div className={styles.verificationCard}>
          <div className={styles.title}><FontAwesomeIcon icon={faLinkedin} size="2x"/> linkedin profile</div>
          {referees.map(referee => (
              <div className={styles.referee} key={referee.id}>
                <div className={styles.title}>{referee.name}</div>
                <div className={cn(styles.value, 'u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
                  <a href={`mailto:${referee.linkedin_profile}`}>{referee.linkedin_profile ? referee.linkedin_profile : '-'}</a>
                  <FeatherIcon icon={referee.linkedin_profile ? "check-circle" : "alert-triangle"}/>
                </div>
              </div>
            ))
          }
        </div>
        <div className={styles.verificationCard}>
          <div className={styles.title}><FeatherIcon icon="shield"/> ip address</div>
          {referees.map(referee => (
              <div className={styles.referee} key={referee.id}>
                <div className={styles.title}>{referee.name}</div>
                <div className={cn(styles.value, 'u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
                  <div className={styles.staticValue}>{referee.ip}</div>
                  <div className={styles.error}>
                    {referee.ip === candidate.ip ? 'MATCH' : ''}
                    <FeatherIcon icon={referee.ip === candidate.ip ? "alert-circle" : "check-circle"}/>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      {intelligent && 
        <div className={cn(styles.summary, 'u-flex-box', 'u-flex-justify-between', 'u-flex-align-stretch')}>
          <div className={cn(styles.criticalResponses, styles.section)}>
            <div className={styles.title}>Critical Responses</div>
            <div className={styles.answersQuantity}>5 answers</div>
            <div className={styles.content}>
              <div className="u-flex-box u-flex-justify-between u-flex-align-center">
                <p className={styles.question}>The referees would recommend {candidate.first_name}</p>
                <div className={styles.answers}>
                  {referees.map(referee => (
                    <FeatherIcon key={referee.id} icon={responses[referee.id].answers[32] === 'Yes' ? "check-circle" : "alert-circle"}/>
                  ))}
                </div>
              </div>
              <div className="u-flex-box u-flex-justify-between u-flex-align-center">
                <p className={styles.question}>The referees would rehire {candidate.first_name}</p>
                <div className={styles.answers}>
                  {referees.map(referee => (
                    <FeatherIcon key={referee.id} icon={responses[referee.id].answers[35] === 'Yes' ? "check-circle" : "alert-circle"}/>
                  ))}
                </div>
              </div>
              <div className="u-flex-box u-flex-justify-between u-flex-align-center">
                <p className={styles.question}>{candidate.first_name} can perform a job safely </p>
                <div className={styles.answers}>
                  {referees.map(referee => (
                    <FeatherIcon key={referee.id} icon={responses[referee.id].answers[21] === 'Yes' ? "alert-circle" : "check-circle"}/>
                  ))}
                </div>
              </div>
              <div className="u-flex-box u-flex-justify-between u-flex-align-center">
                <p className={styles.question}>No history of formal warnings</p>
                <div className={styles.answers}>
                  {referees.map(referee => (
                    <FeatherIcon key={referee.id} icon={responses[referee.id].answers[24] === 'Yes' ? "alert-circle" : "check-circle"}/>
                  ))}
                </div>
              </div>
              <div className="u-flex-box u-flex-justify-between u-flex-align-center">
                <p className={styles.question}>No history of workplace accidents</p>
                <div className={styles.answers}>
                  {referees.map(referee => (
                    <FeatherIcon key={referee.id} icon={responses[referee.id].answers[28] === 'Yes' ? "alert-circle" : "check-circle"}/>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={cn(styles.skills, styles.section)}>
            <div className={styles.title}>Skills</div>
            <div className={styles.answersQuantity}>10 answers</div>
            <div className="u-margin-top">
              <LineChart width={350} height={170} data={charData} margin={{ top: 5, right: 15, bottom: 5 }}>
                <CartesianGrid horizontal={false} />
                <XAxis dataKey="name" label={{ value: 'Questions', position: 'insideBottom', offset: 0 }} tick={{ fontSize: 10 }} style={{ fontFamily: 'inter' }} />
                <YAxis domain={[0, 10]} interval={0} label={{ value: 'Exemplary', position: 'center', angle: -90 }} tick={{ fontSize: 10 }} />
                <Tooltip content={<CustomTooltip />} />
                {referees.map(referee => (
                  <Line type="monotone" dataKey={referee.name} stroke="#bc9bff" fill="#bc9bff" />
                ))}
              </LineChart>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const CustomTooltip = ({ active, payload, label }) => {

  const getQuestion = (label) => {
    switch(label) {
      case '11':
        return 'Attendance, Reliability and Punctuality';
      case '12':
        return 'Personal Presentation';
      case '13':
        return 'Communication Skills';
      case '14':
        return 'Technical Competency';
      case '15':
        return 'Initiative and Willingness';
      case '16':
        return 'Teamwork and engagement with colleagues';
      case '17':
        return 'Ability to meet expectations and KPIS';
      case '18':
        return 'Ability to learn and adapt';
      case '19':
        return 'Organisation and time management skills';
      case '20':
        return 'Support for others';
    }
  }

  if (active && payload && payload.length) {
    return (
      <div className="chartTooltip">
        <p className="chartTooltipLabel">{getQuestion(label)}</p>
        {payload.map(data => (
          <p className="chartTooltipValue">{`${data.name}: ${data.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};