import { CollapsibleGroup, CollapsibleBlock } from 'components/CollapsibleBlock/CollapsibleBlock';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Button } from 'components/FormComponents';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { IconButton } from 'components/IconButton/IconButton';
import { FieldTypes, TypeformFieldTypesOptions, CustomFieldTypesOptions } from 'constants/field-types-enum';
import { Select, Textarea } from 'components/FormComponents';
import { DateSettings } from './SettingsComponents/DateSettings/DateSettings';
import { DefaultSettings } from './SettingsComponents/DefaultSettings/DefaultSettings';
import { MultipleChoiceSettings } from './SettingsComponents/MultipleChoiceSettings/MultipleChoiceSettings';
import { OpinionScaleSettings } from './SettingsComponents/OpinionScaleSettings/OpinionScaleSettings';
import styles from './FormSectionEdit.module.scss';

export const FormSectionEdit = ({
  fields = [],
  control,
  errors,
  customField = false,
  watch       = () => {},
  register    = () => {},
  unregister  = () => {},
  setValue    = () => {},
  addField    = () => {},
  moveField   = () => {},
  removeField = () => {}
}) => {


  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return;

    let first, last;

    if(source.index > destination.index) {
      first = destination.index;
      last = source.index;
    } else {
      first = source.index;
      last = destination.index;
    }

    moveField(source.index, destination.index);
    for(let i=first; i <= last; i++) setValue(`fields[${i}].order`, i+1);
  }

  return (
    <div className='u-margin-top'>
      <CollapsibleGroup newStartOpen={true}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='fields'>
            {({ innerRef, droppableProps, placeholder }) => (
              <div className='u-width-100' {...droppableProps} ref={innerRef}>
                {fields.map((field) => (
                  <FieldConfig
                    key={field.key}
                    index={field.order - 1}
                    formField={field}
                    register={register}
                    watch={watch}
                    control={control}
                    errors={errors?.fields?.[field.order - 1]}
                    remove={removeField}
                    unregister={unregister}
                    customField={customField}
                  />
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </CollapsibleGroup>
      <Button className='u-width-100 u-margin-top' type='secondary-reverse' onClick={addField}><FeatherIcon size={18} className='u-margin-right' icon='plus' />Add Field</Button>
    </div>
  )
}


const FieldConfig = ({
  formField,
  index,
  register,
  control,
  watch,
  remove,
  unregister,
  errors,
  customField,
}) => {

  const registerName = `fields.${index}`;

  const field = useWatch({ control, name: `fields.${index}` });

  const typeValue = useWatch({ control, name: `${registerName}.type` });

  // Remove options.options if not MultipleChoice
  useEffect(() => {
    if (typeValue === FieldTypes.MultipleChoice) return;
    unregister(`${registerName}.options.options`);
  }, [typeValue])

  const renderSettings = () => {
    if (field.locked) return;

    switch (field?.type) {
      case FieldTypes.MultipleChoice:
        return <MultipleChoiceSettings register={register} name={registerName} field={field} control={control} errors={errors} />
      case FieldTypes.OpinionScale:
        return <OpinionScaleSettings register={register} name={registerName} field={field} watch={watch} />
      case FieldTypes.Date:
        return <DateSettings register={register} name={registerName} field={field} />
      case FieldTypes.Statement:
        return null;
      default:
        return <DefaultSettings register={register} name={registerName} field={field} />
    }
  }

  return (
    <Draggable draggableId={formField.key} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <CollapsibleBlock className='u-margin-bottom' keyCode={`${index}`}>
            <CollapsibleBlock.Header>
              <span className={styles.order}>{field.order}</span>
              <Select register={register} name={`${registerName}.type`} className={styles.typeSelector} value={field.type}>
                {customField ? 
                  CustomFieldTypesOptions.map(fieldType => (
                    <Select.Item
                      key={fieldType.key}
                      value={fieldType.key}
                      description={fieldType.subTitle}
                    >
                      {fieldType.title}
                    </Select.Item>
                  ))
                  : 
                  TypeformFieldTypesOptions.map(fieldType => (
                    <Select.Item
                      key={fieldType.key}
                      value={fieldType.key}
                      description={fieldType.subTitle}
                    >
                      {fieldType.title}
                    </Select.Item>
                  ))
                }
              </Select>
              <Textarea
                register={register}
                name={`${registerName}.text`}
                validators={{ required: true }}
                error={errors?.text?.type}
                noErrorMessage
                placeholder='Enter question...'
                className={styles.textInput}
                dynamicHeight={true}
              />
              <IconButton tip='Delete Field' className={styles.removeButton} onClick={() => remove(index)} icon='trash-2' />
            </CollapsibleBlock.Header>
            <CollapsibleBlock.Content>
              {renderSettings()}
            </CollapsibleBlock.Content>
          </CollapsibleBlock>
        </div>
      )}
    </Draggable>
  )
}