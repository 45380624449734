import { useState } from 'react';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { useDocumentTitle } from 'hooks/document-title';
import { useGetCheckTypesQuery } from 'api/check-types-api';
import { List } from 'components/List/List';
import { CheckTypeEdit } from './CheckTypeEdit/CheckTypeEdit';

const AdminCheckTypes = () => {

  useDocumentTitle('Check Types');

  const [selectedCheckType, setSelectedCheckType] = useState()

  const { data: checkTypes, isLoading } = useGetCheckTypesQuery();

  return (
    <AdminPage loading={isLoading} title='Check Type'>
      <div className='u-padding--large d-flex align-items-start'>
        <List 
          title='Check Types'
          subtitle='All our products. Click to configure'
          className='u-margin-right--large u-flex-align-self-normal'
          onNew={() => {}}
        >
          <List.Category title='Types'>
            {checkTypes?.map(checkType => (
              <List.Item
                key={checkType.id}
                title={checkType.title}
                value={checkType}
                onClick={setSelectedCheckType}
              />
            ))}
          </List.Category>
        </List>
        {selectedCheckType && 
          <CheckTypeEdit 
            checkType={selectedCheckType}
            onClose={() => setSelectedCheckType(null)}
          />
        }
      </div>
    </AdminPage>
  )
}

export default AdminCheckTypes;
