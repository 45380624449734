import { IconButton } from 'components/IconButton/IconButton';
import styles from './ToggableSection.module.scss';
import { Collapse } from 'react-bootstrap';
import cn from 'classnames';
import { useState } from 'react';


export const ToggableSection = ({ 
  title,
  children,
}) => {

  const [opened, setOpened] = useState();

  return (
    <div>
      <div className={cn('d-flex', 'justify-content-between', 'align-items-center')}>
        {title}
        <IconButton className={cn(styles.toggleBtn, {[styles.toggleBtnOpened]: opened})} icon="chevron-down" onClick={() => setOpened(opened => !opened)} />
      </div>
      <Collapse in={opened}>
        <div>
          {children}
        </div>
      </Collapse>
    </div>
  )
}