import { useSelector } from 'react-redux';
import { Loading } from 'components/Loading/Loading';
import styles from './SmartRecruitersAuthorization.module.scss';
import { useState, useEffect } from 'react';
import { useDocumentTitle } from 'hooks/document-title';
import { Button } from 'components/FormComponents';
import { useConnectSmartRecruitersMutation } from 'api/partner-api';
import { useQueryParams } from 'hooks/query-params';
import cn from 'classnames';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import { selectAuthentication } from 'api/authentication-slice';

const SmartRecruitersAuthorization = () => {

  useDocumentTitle('SmartRecruiter Authorization');

  const navigate = useNavigate();

  const [redirectUri, companyId] = useQueryParams(['redirect', 'companyId']);

  const auth = useSelector(selectAuthentication);

  const [connectSmartRecruiters] = useConnectSmartRecruitersMutation();

  const [loading, setLoading] = useState(false);

  // Check authentication
  useEffect(() => {
    if(!auth.checked || auth.logged) return;

    const state = Buffer.from(window.location.search).toString('base64');
    navigate(`/sign_in?redirect_uri=smartrecruiters&state=${state}`)
  }, [auth]);

  const reject = () => {
    window.close();
  }

  const accept = async() => {
    setLoading(true);
    await connectSmartRecruiters(companyId);
    window.location.href = redirectUri;
  }

  return (
    <div className={styles.root}>
      <Loading active={loading} />
      <div className={cn('card u-margin-top', 'card-with-shadow', styles.card)}>
        <div className={cn('card_content', styles.content)}>
          <img onClick={() => window.location.replace('https://www.checkmate.tech')} className={cn(styles.logo, 'u-margin-bottom--large')} src='/assets/images/checkmate/icon-label.svg' alt='Checkmate Logo' />
          <h2 className='design-marker title-2 u-margin-bottom--large'>Authorize</h2>
            <>
              <img className={cn(styles.logo, 'u-margin-bottom--large')} src='/assets/images/smartrecruiters_logo.svg' alt='Client Logo' />
              <p><b>SmartRecruiters</b> is requesting the following permissions</p>
              <ul className={styles.scopes}>
                  <li>Offline access to your Checkmate data.</li>
                  <li>Read and write access to your candidates.</li>
              </ul>
              <div className='u-flex-box u-flex-justify-between u-width-100'>
                <Button className='u-width-40' onClick={accept}>Accept</Button>
                <Button className='u-width-40' type='secondary' onClick={reject}>Cancel</Button>
              </div>
            </>
        </div>
      </div>
    </div>
  )
}

export default SmartRecruitersAuthorization;