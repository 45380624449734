import { ChecksSelector } from "components/ChecksSelector/ChecksSelector"
import { useForm, useWatch, Controller } from 'react-hook-form';
import { Button, EditableTitle } from 'components/FormComponents';
import { IconButton } from "components/IconButton/IconButton";
import { useApiUploadActions } from 'hooks/upload-actions';
import cn from 'classnames';
import { useEffect } from "react";

export const BundleEdit = ({
  bundle,
  company,
  forms,
  companyChecks,
  onUpdate     = () => {},
  onDelete     = () => {},
  onClose      = () => {},
}) => {

  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions()

  const { register, control, handleSubmit, reset } = useForm();

  const nameValue = useWatch({ control, name: 'name' });

  useEffect(() => {
    if(!bundle) return;

    reset({ name: bundle.name });
  }, [bundle])

  return (
    <form className={cn('card', 'card-with-border', 'card--small')}>
      <div>
        <div className={cn('u-margin--large', 'u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
          <div>
            <h3 className={cn('title-6', 'u-padding-bottom--small')}>({bundle.id})</h3>
            <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
              <EditableTitle register={register} name='name' value={nameValue} />
            </div>
            <p className='t-small'>Configure and manage your bundle below</p>
          </div>
          <IconButton icon='trash-2' onClick={onDelete} tip='Delete bundle' />
        </div>
        <div className={cn('background-secondary', 'u-padding--large', 'card_border--top')}>
          <Controller 
            control={control} 
            name='checks_data' 
            render={({ field: { onChange } }) => (
              <ChecksSelector
                defaultValues={bundle.checks_data}
                company={company}
                companyChecks={companyChecks}
                forms={forms}
                getUpload={getUpload}
                signUpload={signUpload}
                createUpload={createUpload}
                deleteUpload={deleteUpload}
                onChange={data => onChange(data.checks)}
                allowUploads
              />
            )}
          />
        </div>
      </div>
      <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
        <Button onClick={onClose} type='secondary'>Cancel</Button>
        <Button onClick={() => handleSubmit(onUpdate)()}>Save</Button>
      </div>
    </form>  
  )
}