export const countries = [
  { name: 'Australia', value: 'Australia' },
  { name: 'United States of America', value: 'United States of America' },
  { name: 'New Zealand', value: 'New Zealand' },

  { name: 'Afghanistan', value: 'Afghanistan' },
  { name: 'Albania', value: 'Albania' },
  { name: 'Algeria', value: 'Algeria' },
  { name: 'Andorra', value: 'Andorra' },
  { name: 'Angola', value: 'Angola' },
  { name: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { name: 'Argentina', value: 'Argentina' },
  { name: 'Armenia', value: 'Armenia' },
  // { name: 'Australia', value: 'Australia' },
  { name: 'Austria', value: 'Austria' },
  { name: 'Azerbaijan', value: 'Azerbaijan' },
  { name: 'The Bahamas', value: 'Bahamas' },
  { name: 'Bahrain', value: 'Bahrain' },
  { name: 'Bangladesh', value: 'Bangladesh' },
  { name: 'Barbados', value: 'Barbados' },
  { name: 'Belarus', value: 'Belarus' },
  { name: 'Belgium', value: 'Belgium' },
  { name: 'Belize', value: 'Belize' },
  { name: 'Benin', value: 'Benin' },
  { name: 'Bhutan', value: 'Bhutan' },
  { name: 'Bolivia', value: 'Bolivia' },
  { name: 'Bosnia and Herzegovina', value: 'Bosnia-Herzegovina' },
  { name: 'Botswana', value: 'Botswana' },
  { name: 'Brazil', value: 'Brazil' },
  { name: 'Brunei', value: 'Brunei Darussalam' },
  { name: 'Bulgaria', value: 'Bulgaria' },
  { name: 'Burkina Faso', value: 'Burkina Faso' },
  { name: 'Burundi', value: 'Burundi' },
  { name: 'Cabo Verde', value: 'Cabo Verde' },
  { name: 'Cambodia', value: 'Cambodia' },
  { name: 'Cameroon', value: 'Cameroon, Republic of' },
  { name: 'Canada', value: 'Canada' },
  { name: 'Central African Republic', value: 'Central African Republic' },
  { name: 'Chad', value: 'Chad' },
  { name: 'Chile', value: 'Chile' },
  { name: 'China', value: 'China, Peoples Republic of' },
  { name: 'Colombia', value: 'Colombia' },
  { name: 'Comoros', value: 'Comoros' },
  { name: 'Congo Democratic Republic of the', value: 'Democratic Republic Of The Congo' },
  { name: 'Congo Republic of the', value: 'Republic Of Congo' },
  { name: 'Costa Rica', value: 'Costa Rica' },
  { name: 'Côte d’Ivoire', value: 'Côte d’Ivoire' },
  { name: 'Croatia', value: 'Croatia' },
  { name: 'Cuba', value: 'Cuba' },
  { name: 'Cyprus', value: 'Cyprus' },
  { name: 'Czech Republic', value: 'Czech Republic' },
  { name: 'Denmark', value: 'Denmark' },
  { name: 'Djibouti', value: 'Djibouti' },
  { name: 'Dominica', value: 'Dominica' },
  { name: 'Dominican Republic', value: 'Dominican Republic' },
  { name: 'East Timor (Timor-Leste)', value: 'East Timor' },
  { name: 'Ecuador', value: 'Ecuador' },
  { name: 'Egypt', value: 'Egypt' },
  { name: 'El Salvador', value: 'El Salvador' },
  { name: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { name: 'Eritrea', value: 'Eritrea' },
  { name: 'Estonia', value: 'Estonia' },
  { name: 'Ethiopia', value: 'Ethiopia' },
  { name: 'Fiji', value: 'Fiji' },
  { name: 'Finland', value: 'Finland' },
  { name: 'France', value: 'France' },
  { name: 'Gabon', value: 'Gabon' },
  { name: 'Gambia', value: 'Gambia' },
  { name: 'Georgia', value: 'Georgia' },
  { name: 'Germany', value: 'Germany' },
  { name: 'Ghana', value: 'Ghana' },
  { name: 'Greece', value: 'Greece' },
  { name: 'Grenada', value: 'Grenada' },
  { name: 'Guatemala', value: 'Guatemala' },
  { name: 'Guinea', value: 'Guinea' },
  { name: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { name: 'Guyana', value: 'Guyana' },
  { name: 'Haiti', value: 'Haiti' },
  { name: 'Honduras', value: 'Honduras' },
  { name: 'Hongkong', value: 'Hongkong' },
  { name: 'Hungary', value: 'Hungary' },
  { name: 'Iceland', value: 'Iceland' },
  { name: 'India', value: 'India' },
  { name: 'Indonesia', value: 'Indonesia' },
  { name: 'Iran', value: 'Iran' },
  { name: 'Iraq', value: 'Iraq' },
  { name: 'Ireland', value: 'Ireland' },
  { name: 'Israel', value: 'Israel' },
  { name: 'Italy', value: 'Italy' },
  { name: 'Jamaica', value: 'Jamaica' },
  { name: 'Japan', value: 'Japan' },
  { name: 'Jordan', value: 'Jordan' },
  { name: 'Kazakhstan', value: 'Kazakhstan' },
  { name: 'Kenya', value: 'Kenya' },
  { name: 'Kiribati', value: 'Kiribati' },
  { name: 'Korea North', value: 'Korea North' },
  { name: 'Korea South', value: 'Korea South' },
  { name: 'Kosovo', value: 'Kosovo' },
  { name: 'Kuwait', value: 'Kuwait' },
  { name: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { name: 'Laos', value: 'Laos' },
  { name: 'Latvia', value: 'Latvia' },
  { name: 'Lebanon', value: 'Lebanon' },
  { name: 'Lesotho', value: 'Lesotho' },
  { name: 'Liberia', value: 'Liberia' },
  { name: 'Libyan Arab Republic', value: 'Libyan Arab Republic' },
  { name: 'Liechtenstein', value: 'Liechtenstein' },
  { name: 'Lithuania', value: 'Lithuania' },
  { name: 'Luxembourg', value: 'Luxembourg' },
  { name: 'Macau', value: 'Macau' },
  { name: 'Macedonia', value: 'Macedonia' },
  { name: 'Madagascar', value: 'Madagascar' },
  { name: 'Malawi', value: 'Malawi' },
  { name: 'Malaysia', value: 'Malaysia' },
  { name: 'Maldives', value: 'Maldives' },
  { name: 'Mali', value: 'Mali' },
  { name: 'Malta', value: 'Malta' },
  { name: 'Marshall Islands', value: 'Marshall Islands' },
  { name: 'Mauritania', value: 'Mauritania' },
  { name: 'Mauritius', value: 'Mauritius' },
  { name: 'Mexico', value: 'Mexico' },
  { name: 'Micronesia Federated States of', value: 'Micronesia - Federated States of' },
  { name: 'Moldova', value: 'Moldova' },
  { name: 'Monaco', value: 'Monaco' },
  { name: 'Mongolia', value: 'Mongolia' },
  { name: 'Montenegro', value: 'Montenegro' },
  { name: 'Morocco', value: 'Morocco' },
  { name: 'Mozambique', value: 'Mozambique' },
  { name: 'Burma (Myanmar)', value: 'Burma (Myanmar)' },
  { name: 'Namibia', value: 'Namibia' },
  { name: 'Nauru', value: 'Nauru' },
  { name: 'Nepal', value: 'Nepal' },
  { name: 'Netherlands', value: 'Netherlands' },
  // { name: 'New Zealand', value: 'New Zealand' },
  { name: 'Nicaragua', value: 'Nicaragua' },
  { name: 'Niger', value: 'Niger' },
  { name: 'Nigeria', value: 'Nigeria' },
  { name: 'Norway', value: 'Norway' },
  { name: 'Oman', value: 'Oman' },
  { name: 'Pakistan', value: 'Pakistan' },
  { name: 'Palau', value: 'Palau' },
  { name: 'Panama', value: 'Panama' },
  { name: 'Papua New Guinea', value: 'Papua New Guinea' },
  { name: 'Paraguay', value: 'Paraguay' },
  { name: 'Peru', value: 'Peru' },
  { name: 'Philippines', value: 'Philippines' },
  { name: 'Poland', value: 'Poland' },
  { name: 'Portugal', value: 'Portugal' },
  { name: 'Qatar', value: 'Qatar' },
  { name: 'Romania', value: 'Romania' },
  { name: 'Russia', value: 'Russia' },
  { name: 'Rwanda', value: 'Rwanda' },
  { name: 'Saint Kitts and Nevis', value: 'St Kitts-Nevis' },
  { name: 'Saint Lucia', value: 'St Lucia' },
  { name: 'Saint Vincent and the Grenadines', value: 'St Vincent and the Grenadines' },
  { name: 'Samoa, American', value: 'Samoa, American' },
  { name: 'Samoa, Western', value: 'Samoa, Western' },
  { name: 'San Marino', value: 'San Marino' },
  { name: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { name: 'Saudi Arabia', value: 'Saudi Arabia' },
  { name: 'Senegal', value: 'Senegal' },
  { name: 'Serbia', value: 'Serbia' },
  { name: 'Seychelles', value: 'Seychelles' },
  { name: 'Sierra Leone', value: 'Sierra Leone' },
  { name: 'Singapore', value: 'Singapore' },
  { name: 'Slovakia', value: 'Slovakia' },
  { name: 'Slovenia', value: 'Slovenia' },
  { name: 'Solomon Islands', value: 'Solomon Islands' },
  { name: 'Somalia', value: 'Somalia' },
  { name: 'South Africa', value: 'South Africa' },
  { name: 'South Sudan', value: 'South Sudan' },
  { name: 'Spain', value: 'Spain' },
  { name: 'Sri Lanka', value: 'Sri Lanka' },
  { name: 'Sudan', value: 'Sudan' },
  { name: 'Surinam', value: 'Surinam' },
  { name: 'Swaziland', value: 'Swaziland' },
  { name: 'Sweden', value: 'Sweden' },
  { name: 'Switzerland', value: 'Switzerland' },
  { name: 'Syria', value: 'Syria' },
  { name: 'Taiwan', value: 'Taiwan' },
  { name: 'Tajikistan', value: 'Tajikistan' },
  { name: 'Tanzania', value: 'Tanzania' },
  { name: 'Thailand', value: 'Thailand' },
  { name: 'Togo', value: 'Togo' },
  { name: 'Tonga', value: 'Tonga' },
  { name: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { name: 'Tunisia', value: 'Tunisia' },
  { name: 'Turkey', value: 'Turkey' },
  { name: 'Turkmenistan', value: 'Turkmenistan' },
  { name: 'Tuvalu', value: 'Tuvalu' },
  { name: 'Uganda', value: 'Uganda' },
  { name: 'Ukraine', value: 'Ukraine' },
  { name: 'United Arab Emirates', value: 'United Arab Emirates' },
  { name: 'United Kingdom', value: 'United Kingdom' },
  // { name: 'United States of America', value: 'United States of America' },
  { name: 'Uruguay', value: 'Uruguay' },
  { name: 'Uzbekistan', value: 'Uzbekistan' },
  { name: 'Vanuatu', value: 'Vanuatu' },
  { name: 'Vatican City State', value: 'Vatican City State' },
  { name: 'Venezuela', value: 'Venezuela' },
  { name: 'Vietnam', value: 'Vietnam' },
  { name: 'Yemen', value: 'Yemen' },
  { name: 'Zambia', value: 'Zambia' },
  { name: 'Zimbabwe', value: 'Zimbabwe' }
];

export const countryNumberCodes = [
  { name: "Other", code: "+", id: "NC" },
  { name: "Afghanistan", code: "+93", id: "AF" },
  { name: "Albania", code: "+355", id: "AL" },
  { name: "Algeria", code: "+213", id: "DZ" },
  { name: "Andorra", code: "+376", id: "AD" },
  { name: "Antigua and Barbuda", code: "+1-268", id: "AG" },
  { name: "Argentina", code: "+54", id: "AR" },
  { name: "Armenia", code: "+374", id: "AM" },
  { name: "Australia", code: "+61", id: "AU" },
  { name: "Austria", code: "+43", id: "AT" },
  { name: "Azerbaijan", code: "+994", id: "AZ" },
  { name: "Bahamas", code: "+1-242", id: "BS" },
  { name: "Bahrain", code: "+973", id: "BH" },
  { name: "Bangladesh", code: "+880", id: "BD" },
  { name: "Barbados", code: "+1-246", id: "BB" },
  { name: "Belarus", code: "+375", id: "BY" },
  { name: "Belgium", code: "+32", id: "BE" },
  { name: "Belize", code: "+501", id: "BZ" },
  { name: "Benin", code: "+229", id: "BJ" },
  { name: "Bhutan", code: "+975", id: "BT" },
  { name: "Bolivia", code: "+591", id: "BO" },
  { name: "Bosnia and Herzegovina", code: "+387", id: "BA" },
  { name: "Botswana", code: "+267", id: "BW" },
  { name: "Brazil", code: "+55", id: "BR" },
  { name: "Brunei", code: "+673", id: "BN" },
  { name: "Bulgaria", code: "+359", id: "BG" },
  { name: "Burkina Faso", code: "+226", id: "BF" },
  { name: "Burundi", code: "+257", id: "BI" },
  { name: "Cambodia", code: "+855", id: "KH" },
  { name: "Cameroon", code: "+237", id: "CM" },
  { name: "Canada", code: "001", id: "CA" },
  { name: "Cape Verde", code: "+238", id: "CV" },
  { name: "Central African Republic", code: "+236", id: "CF" },
  { name: "Chad", code: "+235", id: "TD" },
  { name: "Chile", code: "+56", id: "CL" },
  { name: "China", code: "+86", id: "CN" },
  { name: "Colombia", code: "+57", id: "CO" },
  { name: "Comoros", code: "+269", id: "KM" },
  { name: "Costa Rica", code: "+506", id: "CR" },
  { name: "Croatia", code: "+385", id: "HR" },
  { name: "Cuba", code: "+53", id: "CU" },
  { name: "Cyprus", code: "+357", id: "CY" },
  { name: "Czech Republic", code: "+420", id: "CZ" },
  { name: "Democratic Republic of the Congo", code: "+243", id: "CD" },
  { name: "Denmark", code: "+45", id: "DK" },
  { name: "Djibouti", code: "+253", id: "DJ" },
  { name: "Dominica", code: "+1-767", id: "DM" },
  { name: "East Timor", code: "+670", id: "TL" },
  { name: "Ecuador", code: "+593", id: "EC" },
  { name: "Egypt", code: "+20", id: "EG" },
  { name: "El Salvador", code: "+503", id: "SV" },
  { name: "Equatorial Guinea", code: "+240", id: "GQ" },
  { name: "Eritrea", code: "+291", id: "ER" },
  { name: "Estonia", code: "+372", id: "EE" },
  { name: "Ethiopia", code: "+251", id: "ET" },
  { name: "Fiji", code: "+679", id: "FJ" },
  { name: "Finland", code: "+358", id: "FI" },
  { name: "France", code: "+33", id: "FR" },
  { name: "Gabon", code: "+241", id: "GA" },
  { name: "Gambia", code: "+220", id: "GM" },
  { name: "Georgia", code: "+995", id: "GE" },
  { name: "Germany", code: "+49", id: "DE" },
  { name: "Ghana", code: "+233", id: "GH" },
  { name: "Greece", code: "+30", id: "GR" },
  { name: "Grenada", code: "+1-473", id: "GD" },
  { name: "Guatemala", code: "+502", id: "GT" },
  { name: "Guinea", code: "+224", id: "GN" },
  { name: "Guinea-Bissau", code: "+245", id: "GW" },
  { name: "Guyana", code: "+592", id: "GY" },
  { name: "Haiti", code: "+509", id: "HT" },
  { name: "Honduras", code: "+504", id: "HN" },
  { name: "Hong Kong", code: "+852", id: "HK" },
  { name: "Hungary", code: "+36", id: "HU" },
  { name: "Iceland", code: "+354", id: "IS" },
  { name: "India", code: "+91", id: "IN" },
  { name: "Indonesia", code: "+62", id: "ID" },
  { name: "Iran", code: "+98", id: "IR" },
  { name: "Iraq", code: "+964", id: "IQ" },
  { name: "Ireland", code: "+353", id: "IE" },
  { name: "Israel", code: "+972", id: "IL" },
  { name: "Italy", code: "+39", id: "IT" },
  { name: "Jamaica", code: "+1-876", id: "JM" },
  { name: "Japan", code: "+81", id: "JP" },
  { name: "Jordan", code: "+962", id: "JO" },
  { name: "Kazakhstan", code: "+7", id: "KZ" },
  { name: "Kenya", code: "+254", id: "KE" },
  { name: "Kiribati", code: "+686", id: "KI" },
  { name: "Kosovo", code: "+383", id: "XK" },
  { name: "Kuwait", code: "+965", id: "KW" },
  { name: "Kyrgyzstan", code: "+996", id: "KG" },
  { name: "Laos", code: "+856", id: "LA" },
  { name: "Latvia", code: "+371", id: "LV" },
  { name: "Lebanon", code: "+961", id: "LB" },
  { name: "Lesotho", code: "+266", id: "LS" },
  { name: "Liberia", code: "+231", id: "LR" },
  { name: "Libya", code: "+218", id: "LY" },
  { name: "Liechtenstein", code: "+423", id: "LI" },
  { name: "Lithuania", code: "+370", id: "LT" },
  { name: "Luxembourg", code: "+352", id: "LU" },
  { name: 'Macau', code: "+853", id: "MO" },
  { name: "Macedonia", code: "+389", id: "MK" },
  { name: "Madagascar", code: "+261", id: "MG" },
  { name: "Malawi", code: "+265", id: "MW" },
  { name: "Malaysia", code: "+60", id: "MY" },
  { name: "Maldives", code: "+960", id: "MV" },
  { name: "Mali", code: "+223", id: "ML" },
  { name: "Malta", code: "+356", id: "MT" },
  { name: "Marshall Islands", code: "+692", id: "MH" },
  { name: "Mauritania", code: "+222", id: "MR" },
  { name: "Mauritius", code: "+230", id: "MU" },
  { name: "Mexico", code: "+52", id: "MX" },
  { name: "Micronesia", code: "+691", id: "FM" },
  { name: "Moldova", code: "+373", id: "MD" },
  { name: "Monaco", code: "+377", id: "MC" },
  { name: "Mongolia", code: "+976", id: "MN" },
  { name: "Montenegro", code: "+382", id: "ME" },
  { name: "Morocco", code: "+212", id: "MA" },
  { name: "Mozambique", code: "+258", id: "MZ" },
  { name: "Myanmar", code: "+95", id: "MM" },
  { name: "Namibia", code: "+264", id: "NA" },
  { name: "Nauru", code: "+674", id: "NR" },
  { name: "Nepal", code: "+977", id: "NP" },
  { name: "Netherlands", code: "+31", id: "NL" },
  { name: "New Zealand", code: "+64", id: "NZ" },
  { name: "Nicaragua", code: "+505", id: "NI" },
  { name: "Niger", code: "+227", id: "NE" },
  { name: "Nigeria", code: "+234", id: "NG" },
  { name: "North Korea", code: "+850", id: "KP" },
  { name: "Norway", code: "+47", id: "NO" },
  { name: "Oman", code: "+968", id: "OM" },
  { name: "Pakistan", code: "+92", id: "PK" },
  { name: "Palau", code: "+680", id: "PW" },
  { name: "Panama", code: "+507", id: "PA" },
  { name: "Papua New Guinea", code: "+675", id: "PG" },
  { name: "Paraguay", code: "+595", id: "PY" },
  { name: "Peru", code: "+51", id: "PE" },
  { name: "Philippines", code: "+63", id: "PH" },
  { name: "Poland", code: "+48", id: "PL" },
  { name: "Portugal", code: "+351", id: "PT" },
  { name: "Qatar", code: "+974", id: "QA" },
  { name: "Romania", code: "+40", id: "RO" },
  { name: "Russia", code: "+7", id: "RU" },
  { name: "Rwanda", code: "+250", id: "RW" },
  { name: "Saint Kitts and Nevis", code: "+1-869", id: "KN" },
  { name: "Saint Lucia", code: "+1-758", id: "LC" },
  { name: "Saint Vincent and the Grenadines", code: "+1-784", id: "VC" },
  { name: "Samoa", code: "+685", id: "WS" },
  { name: "San Marino", code: "+378", id: "SM" },
  { name: "Sao Tome and Principe", code: "+239", id: "ST" },
  { name: "Saudi Arabia", code: "+966", id: "SA" },
  { name: "Senegal", code: "+221", id: "SN" },
  { name: "Serbia", code: "+381", id: "RS" },
  { name: "Seychelles", code: "+248", id: "SC" },
  { name: "Sierra Leone", code: "+232", id: "SL" },
  { name: "Singapore", code: "+65", id: "SG" },
  { name: "Slovakia", code: "+421", id: "SK" },
  { name: "Slovenia", code: "+386", id: "SI" },
  { name: "Solomon Islands", code: "+677", id: "SB" },
  { name: "Somalia", code: "+252", id: "SO" },
  { name: "South Africa", code: "+27", id: "ZA" },
  { name: "South Korea", code: "+82", id: "KR" },
  { name: "South Sudan", code: "+211", id: "SS" },
  { name: "Spain", code: "+34", id: "ES" },
  { name: "Sri Lanka", code: "+94", id: "LK" },
  { name: "Sudan", code: "+249", id: "SD" },
  { name: "Suriname", code: "+597", id: "SR" },
  { name: "Swaziland", code: "+268", id: "SZ" },
  { name: "Sweden", code: "+46", id: "SE" },
  { name: "Switzerland", code: "+41", id: "CH" },
  { name: "Syria", code: "+963", id: "SY" },
  { name: "Taiwan", code: "+886", id: "TW" },
  { name: "Tajikistan", code: "+992", id: "TJ" },
  { name: "Tanzania", code: "+255", id: "TZ" },
  { name: "Thailand", code: "+66", id: "TH" },
  { name: "Togo", code: "+228", id: "TG" },
  { name: "Tonga", code: "+676", id: "TO" },
  { name: "Trinidad and Tobago", code: "+1-868", id: "TT" },
  { name: "Tunisia", code: "+216", id: "TN" },
  { name: "Turkey", code: "+90", id: "TR" },
  { name: "Turkmenistan", code: "+993", id: "TM" },
  { name: "Tuvalu", code: "+688", id: "TV" },
  { name: "Uganda", code: "+256", id: "UG" },
  { name: "Ukraine", code: "+380", id: "UA" },
  { name: "United Arab Emirates", code: "+971", id: "AE" },
  { name: "United Kingdom", code: "+44", id: "GB" },
  { name: "United States", code: "+1", id: "US" },
  { name: "Uruguay", code: "+598", id: "UY" },
  { name: "Uzbekistan", code: "+998", id: "UZ" },
  { name: "Vanuatu", code: "+678", id: "VU" },
  { name: "Vatican", code: "+379", id: "VA" },
  { name: "Venezuela", code: "+58", id: "VE" },
  { name: "Vietnam", code: "+84", id: "VN" },
  { name: "Yemen", code: "+967", id: "YE" },
  { name: "Zambia", code: "+260", id: "ZM" },
  { name: "Zimbabwe", code: "+263", id: "ZW" }
];

export const Alpha3Countries = [
  { name: "Afghanistan", code: "AFG" },
  { name: "Åland Islands", code: "ALA" },
  { name: "Albania", code: "ALB" },
  { name: "Algeria", code: "DZA" },
  { name: "American Samoa", code: "ASM" },
  { name: "Andorra", code: "AND" },
  { name: "Angola", code: "AGO" },
  { name: "Anguilla", code: "AIA" },
  { name: "Antarctica", code: "ATA" },
  { name: "Antigua and Barbuda", code: "ATG" },
  { name: "Argentina", code: "ARG" },
  { name: "Armenia", code: "ARM" },
  { name: "Aruba", code: "ABW" },
  { name: "Australia", code: "AUS" },
  { name: "Austria", code: "AUT" },
  { name: "Azerbaijan", code: "AZE" },
  { name: "Bahamas", code: "BHS" },
  { name: "Bahrain", code: "BHR" },
  { name: "Bangladesh", code: "BGD" },
  { name: "Barbados", code: "BRB" },
  { name: "Belarus", code: "BLR" },
  { name: "Belgium", code: "BEL" },
  { name: "Belize", code: "BLZ" },
  { name: "Benin", code: "BEN" },
  { name: "Bermuda", code: "BMU" },
  { name: "Bhutan", code: "BTN" },
  { name: "Bolivia, Plurinational State of", code: "BOL" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BES" },
  { name: "Bosnia and Herzegovina", code: "BIH" },
  { name: "Botswana", code: "BWA" },
  { name: "Bouvet Island", code: "BVT" },
  { name: "Brazil", code: "BRA" },
  { name: "British Indian Ocean Territory", code: "IOT" },
  { name: "Brunei Darussalam", code: "BRN" },
  { name: "Bulgaria", code: "BGR" },
  { name: "Burkina Faso", code: "BFA" },
  { name: "Burundi", code: "BDI" },
  { name: "Cambodia", code: "KHM" },
  { name: "Cameroon", code: "CMR" },
  { name: "Canada", code: "CAN" },
  { name: "Cape Verde", code: "CPV" },
  { name: "Cayman Islands", code: "CYM" },
  { name: "Central African Republic", code: "CAF" },
  { name: "Chad", code: "TCD" },
  { name: "Chile", code: "CHL" },
  { name: "China", code: "CHN" },
  { name: "Christmas Island", code: "CXR" },
  { name: "Cocos (Keeling) Islands", code: "CCK" },
  { name: "Colombia", code: "COL" },
  { name: "Comoros", code: "COM" },
  { name: "Congo", code: "COG" },
  { name: "Congo, the Democratic Republic of the", code: "COD" },
  { name: "Cook Islands", code: "COK" },
  { name: "Costa Rica", code: "CRI" },
  { name: "Côte d'Ivoire", code: "CIV" },
  { name: "Croatia", code: "HRV" },
  { name: "Cuba", code: "CUB" },
  { name: "Curaçao", code: "CUW" },
  { name: "Cyprus", code: "CYP" },
  { name: "Czech Republic", code: "CZE" },
  { name: "Denmark", code: "DNK" },
  { name: "Djibouti", code: "DJI" },
  { name: "Dominica", code: "DMA" },
  { name: "Dominican Republic", code: "DOM" },
  { name: "Ecuador", code: "ECU" },
  { name: "Egypt", code: "EGY" },
  { name: "El Salvador", code: "SLV" },
  { name: "Equatorial Guinea", code: "GNQ" },
  { name: "Eritrea", code: "ERI" },
  { name: "Estonia", code: "EST" },
  { name: "Ethiopia", code: "ETH" },
  { name: "Falkland Islands (Malvinas)", code: "FLK" },
  { name: "Faroe Islands", code: "FRO" },
  { name: "Fiji", code: "FJI" },
  { name: "Finland", code: "FIN" },
  { name: "France", code: "FRA" },
  { name: "French Guiana", code: "GUF" },
  { name: "French Polynesia", code: "PYF" },
  { name: "French Southern Territories", code: "ATF" },
  { name: "Gabon", code: "GAB" },
  { name: "Gambia", code: "GMB" },
  { name: "Georgia", code: "GEO" },
  { name: "Germany", code: "DEU" },
  { name: "Ghana", code: "GHA" },
  { name: "Gibraltar", code: "GIB" },
  { name: "Greece", code: "GRC" },
  { name: "Greenland", code: "GRL" },
  { name: "Grenada", code: "GRD" },
  { name: "Guadeloupe", code: "GLP" },
  { name: "Guam", code: "GUM" },
  { name: "Guatemala", code: "GTM" },
  { name: "Guernsey", code: "GGY" },
  { name: "Guinea", code: "GIN" },
  { name: "Guinea-Bissau", code: "GNB" },
  { name: "Guyana", code: "GUY" },
  { name: "Haiti", code: "HTI" },
  { name: "Heard Island and McDonald Islands", code: "HMD" },
  { name: "Holy See (Vatican City State)", code: "VAT" },
  { name: "Honduras", code: "HND" },
  { name: "Hong Kong", code: "HKG" },
  { name: "Hungary", code: "HUN" },
  { name: "Iceland", code: "ISL" },
  { name: "India", code: "IND" },
  { name: "Indonesia", code: "IDN" },
  { name: "Iran, Islamic Republic of", code: "IRN" },
  { name: "Iraq", code: "IRQ" },
  { name: "Ireland", code: "IRL" },
  { name: "Isle of Man", code: "IMN" },
  { name: "Israel", code: "ISR" },
  { name: "Italy", code: "ITA" },
  { name: "Jamaica", code: "JAM" },
  { name: "Japan", code: "JPN" },
  { name: "Jersey", code: "JEY" },
  { name: "Jordan", code: "JOR" },
  { name: "Kazakhstan", code: "KAZ" },
  { name: "Kenya", code: "KEN" },
  { name: "Kiribati", code: "KIR" },
  { name: "Korea, Democratic People's Republic of", code: "PRK" },
  { name: "Korea, Republic of", code: "KOR" },
  { name: "Kuwait", code: "KWT" },
  { name: "Kyrgyzstan", code: "KGZ" },
  { name: "Lao People's Democratic Republic", code: "LAO" },
  { name: "Latvia", code: "LVA" },
  { name: "Lebanon", code: "LBN" },
  { name: "Lesotho", code: "LSO" },
  { name: "Liberia", code: "LBR" },
  { name: "Libya", code: "LBY" },
  { name: "Liechtenstein", code: "LIE" },
  { name: "Lithuania", code: "LTU" },
  { name: "Luxembourg", code: "LUX" },
  { name: "Macao", code: "MAC" },
  { name: "Macedonia, the former Yugoslav Republic of", code: "MKD" },
  { name: "Madagascar", code: "MDG" },
  { name: "Malawi", code: "MWI" },
  { name: "Malaysia", code: "MYS" },
  { name: "Maldives", code: "MDV" },
  { name: "Mali", code: "MLI" },
  { name: "Malta", code: "MLT" },
  { name: "Marshall Islands", code: "MHL" },
  { name: "Martinique", code: "MTQ" },
  { name: "Mauritania", code: "MRT" },
  { name: "Mauritius", code: "MUS" },
  { name: "Mayotte", code: "MYT" },
  { name: "Mexico", code: "MEX" },
  { name: "Micronesia, Federated States of", code: "FSM" },
  { name: "Moldova, Republic of", code: "MDA" },
  { name: "Monaco", code: "MCO" },
  { name: "Mongolia", code: "MNG" },
  { name: "Montenegro", code: "MNE" },
  { name: "Montserrat", code: "MSR" },
  { name: "Morocco", code: "MAR" },
  { name: "Mozambique", code: "MOZ" },
  { name: "Myanmar", code: "MMR" },
  { name: "Namibia", code: "NAM" },
  { name: "Nauru", code: "NRU" },
  { name: "Nepal", code: "NPL" },
  { name: "Netherlands", code: "NLD" },
  { name: "New Caledonia", code: "NCL" },
  { name: "New Zealand", code: "NZL" },
  { name: "Nicaragua", code: "NIC" },
  { name: "Niger", code: "NER" },
  { name: "Nigeria", code: "NGA" },
  { name: "Niue", code: "NIU" },
  { name: "Norfolk Island", code: "NFK" },
  { name: "Northern Mariana Islands", code: "MNP" },
  { name: "Norway", code: "NOR" },
  { name: "Oman", code: "OMN" },
  { name: "Pakistan", code: "PAK" },
  { name: "Palau", code: "PLW" },
  { name: "Palestinian Territory, Occupied", code: "PSE" },
  { name: "Panama", code: "PAN" },
  { name: "Papua New Guinea", code: "PNG" },
  { name: "Paraguay", code: "PRY" },
  { name: "Peru", code: "PER" },
  { name: "Philippines", code: "PHL" },
  { name: "Pitcairn", code: "PCN" },
  { name: "Poland", code: "POL" },
  { name: "Portugal", code: "PRT" },
  { name: "Puerto Rico", code: "PRI" },
  { name: "Qatar", code: "QAT" },
  { name: "Réunion", code: "REU" },
  { name: "Romania", code: "ROU" },
  { name: "Russian Federation", code: "RUS" },
  { name: "Rwanda", code: "RWA" },
  { name: "Saint Barthélemy", code: "BLM" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SHN" },
  { name: "Saint Kitts and Nevis", code: "KNA" },
  { name: "Saint Lucia", code: "LCA" },
  { name: "Saint Martin (French part)", code: "MAF" },
  { name: "Saint Pierre and Miquelon", code: "SPM" },
  { name: "Saint Vincent and the Grenadines", code: "VCT" },
  { name: "Samoa", code: "WSM" },
  { name: "San Marino", code: "SMR" },
  { name: "Sao Tome and Principe", code: "STP" },
  { name: "Saudi Arabia", code: "SAU" },
  { name: "Senegal", code: "SEN" },
  { name: "Serbia", code: "SRB" },
  { name: "Seychelles", code: "SYC" },
  { name: "Sierra Leone", code: "SLE" },
  { name: "Singapore", code: "SGP" },
  { name: "Sint Maarten (Dutch part)", code: "SXM" },
  { name: "Slovakia", code: "SVK" },
  { name: "Slovenia", code: "SVN" },
  { name: "Solomon Islands", code: "SLB" },
  { name: "Somalia", code: "SOM" },
  { name: "South Africa", code: "ZAF" },
  { name: "South Georgia and the South Sandwich Islands", code: "SGS" },
  { name: "South Sudan", code: "SSD" },
  { name: "Spain", code: "ESP" },
  { name: "Sri Lanka", code: "LKA" },
  { name: "Sudan", code: "SDN" },
  { name: "Suriname", code: "SUR" },
  { name: "Svalbard and Jan Mayen", code: "SJM" },
  { name: "Swaziland", code: "SWZ" },
  { name: "Sweden", code: "SWE" },
  { name: "Switzerland", code: "CHE" },
  { name: "Syrian Arab Republic", code: "SYR" },
  { name: "Taiwan, Province of China", code: "TWN" },
  { name: "Tajikistan", code: "TJK" },
  { name: "Tanzania, United Republic of", code: "TZA" },
  { name: "Thailand", code: "THA" },
  { name: "Timor-Leste", code: "TLS" },
  { name: "Togo", code: "TGO" },
  { name: "Tokelau", code: "TKL" },
  { name: "Tonga", code: "TON" },
  { name: "Trinidad and Tobago", code: "TTO" },
  { name: "Tunisia", code: "TUN" },
  { name: "Turkey", code: "TUR" },
  { name: "Turkmenistan", code: "TKM" },
  { name: "Turks and Caicos Islands", code: "TCA" },
  { name: "Tuvalu", code: "TUV" },
  { name: "Uganda", code: "UGA" },
  { name: "Ukraine", code: "UKR" },
  { name: "United Arab Emirates", code: "ARE" },
  { name: "United Kingdom", code: "GBR" },
  { name: "United States", code: "USA" },
  { name: "United States Minor Outlying Islands", code: "UMI" },
  { name: "Uruguay", code: "URY" },
  { name: "Uzbekistan", code: "UZB" },
  { name: "Vanuatu", code: "VUT" },
  { name: "Venezuela, Bolivarian Republic of", code: "VEN" },
  { name: "Viet Nam", code: "VNM" },
  { name: "Virgin Islands, British", code: "VGB" },
  { name: "Virgin Islands, U.S.", code: "VIR" },
  { name: "Wallis and Futuna", code: "WLF" },
  { name: "Western Sahara", code: "ESH" },
  { name: "Yemen", code: "YEM" },
  { name: "Zambia", code: "ZMB" },
  { name: "Zimbabwe", code: "ZWE" }
]