import React, { createContext, useContext, useState, useEffect } from 'react';
import { Tabs as BootstrapTabs, Tab as BootstrapTab } from 'react-bootstrap';
import { Select } from 'components/FormComponents';
import cn from 'classnames';
import styles from './Tabs.module.scss';
import { useMobileSize } from 'hooks/mobile-size';

// Create TabContext
const TabContext = createContext();

const useTabContext = () => {
  const context = useContext(TabContext);
  if (!context) throw new Error("Tab components must be used within a TabContext");
  return context;
};

export const TabSelect = ({ children, className, defaultActiveKey, onSelect }) => {
  const { mobile } = useMobileSize();
  const [currentTab, setCurrentTab] = useState(defaultActiveKey);

  const setTab = (key) => {
    setCurrentTab(key);
    if (onSelect) onSelect(key);
  };

  const renderSelect = () => (
    <Select
      value={currentTab}
      inputProps={{ onChange: (e) => setTab(e.target.value) }}
      className={cn(styles.selectDropdown)}
    >
      {React.Children.toArray(children)
        .filter(React.isValidElement) // Ensure only valid elements are processed
        .map((child) => (
          <Select.Item key={child.props.eventKey} value={child.props.eventKey}>
            {child.props.title}
          </Select.Item>
        ))}
    </Select>
  );

  const renderTabs = () => (
    <BootstrapTabs
      id="accountTabs"
      onSelect={setTab}
      activeKey={currentTab}
      defaultActiveKey={defaultActiveKey}
      className={className}
      mountOnEnter
      unmountOnExit
    >
      {React.Children.toArray(children)
        .filter(React.isValidElement) // Filter out invalid children
        .map((child) =>
          React.cloneElement(child, {
            eventKey: child.props.eventKey,
            title: child.props.title,
            disabled: child.props.disabled,
          })
        )}
    </BootstrapTabs>
  );

  return (
    <TabContext.Provider value={{ currentTab, setTab }}>
      {mobile ? renderSelect() : renderTabs()}
      <div className={cn(styles.dropdownTabContent)}>
        {mobile &&
          React.Children.toArray(children)
            .filter(React.isValidElement) // Ensure child is a valid element
            .find((child) => child.props.eventKey === currentTab)?.props.children}
      </div>
    </TabContext.Provider>
  );
};

export const TabItem = ({ eventKey, title, children }) => {
  const { currentTab } = useTabContext();
  return currentTab === eventKey ? <div>{children}</div> : null;
};

TabSelect.Tab = BootstrapTab;