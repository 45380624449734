import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { messagePopUp } from 'api/app-slice';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { useBulkImportMutation } from 'api/candidates-api';
import { getForms } from 'api/forms-slice';
import AppPage from 'layouts/AppPage/AppPage';
import { useCSVReader } from "react-papaparse";
import { ChecksSelector } from 'components/ChecksSelector/ChecksSelector';
import styles from "./BulkImport.module.scss";
import { Button } from 'components/FormComponents';
import { CsvTableDetails } from 'components/CsvTableDetails/CsvTableDetails';
import { useDocumentTitle } from 'hooks/document-title';
import { Permissions } from 'constants/permissions';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { selectForms } from 'api/forms-slice';
import { selectCompany } from 'api/company-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { getBrands, selectBrands } from 'api/brands-slice';
import { InputGroup, Select } from 'components/FormComponents';


export const BulkImport = () => {

  useDocumentTitle('Bulk Import');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { type } = useParams();

  const forms = useSelector(selectForms);
  const company = useSelector(selectCompany);
  const companyChecks = useSelector(selectCompanyChecks);
  const brands = useSelector(selectBrands)

  const [bulkImport] = useBulkImportMutation()

  const selectorRef = useRef(null);
  
  const [loading, setLoading] = useState(true);

  const [csvData, setCsvData] = useState();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState();

  const [valid, setValid] = useState(false);

  const [selectedBrandId, setSelectedBrandId] = useState();

  const { CSVReader } = useCSVReader();

  // Initialization
  useEffect(() => { 
    dispatch(getBrands()),
    dispatch(getForms()).
  then(() => setLoading(false)) }, []);

  const handleSubmit = async(checks) => {
    setLoading(true);
    let data = { csv_data: csvData, checks, brand_id: selectedBrandId, renewal: type == 'renewals' };

    const { data: response } = await bulkImport(data);

    setLoading(false);
    if (response.error) {
      dispatch(messagePopUp({ text: response.error.message, state: MESSAGE_STATE_ERROR, hide: true }));
    } else {
      dispatch(messagePopUp({ text: `${response.result} candidates processed`, state: MESSAGE_STATE_SUCCESS, hide: true }));
      navigate("/dashboard");
    }
  }

  return (
    <AppPage loading={loading} requiredPermissions={[Permissions.CandidateWrite]}>
      <div className={styles.root}>
        <AppPage.Header 
          title={`${type === 'renewals' ? 'Renewals' : 'Candidates'} Import`}
          subtitle="Use a spreadsheet to upload multiple candidates at once. Get started by downloading the
                    template spreadsheet below. Once the candidate details are 
                    imported you can choose the check types you need"
          className='u-divider-bottom-border'
        />
        <div className={styles.body}>
          <div className={cn(styles.content, 'card', 'card-with-border', 'u-margin-y--large')}>
            <div className={cn('card_content')}>
              <div className={cn(styles.dataPicker, 'u-width-100')}>
                {csvData ? (
                  <CsvTableDetails
                    csvData={csvData}
                    onDrop={() => setCsvData(null)}
                  />
                ) : (
                  <div className={cn(styles.container, 'u-width-100')}>
                    <CSVReader
                      onUploadAccepted={results => {
                        setCsvData(results.data);
                        setZoneHover(false);
                      }}
                      onDragOver={event => {
                        event.preventDefault();
                        setZoneHover(true);
                      }}
                      onDragLeave={event => {
                        event.preventDefault();
                        setZoneHover(false);
                      }}
                    >
                      {({
                        getRootProps,
                        acceptedFile,
                        ProgressBar,
                        getRemoveFileProps,
                        Remove,
                      }) => (
                        <>
                          <div {...getRootProps()} style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}>
                            {acceptedFile ? (
                              <>
                                <div style={styles.file}>
                                  <div style={styles.info}>
                                    <span style={styles.size}>
                                      {acceptedFile.size}
                                    </span>
                                    <span style={styles.name}>
                                      {acceptedFile.name}
                                    </span>
                                  </div>
                                  <div style={styles.progressBar}>
                                    <ProgressBar />
                                  </div>
                                  <div
                                    {...getRemoveFileProps()}
                                    style={styles.remove}
                                    onMouseOver={event => {
                                      event.preventDefault();
                                      setRemoveHoverColor("red");
                                    }}
                                    onMouseOut={event => {
                                      event.preventDefault();
                                      setRemoveHoverColor("red");
                                    }}
                                  >
                                    <Remove color={removeHoverColor} />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className={styles.dropzone}>Drop CSV file here or click to upload.</div>
                            )}
                          </div>
                        </>
                      )}
                    </CSVReader>
                    <a 
                      className="u-link dark" 
                      href={type === 'renewals' ? 
                        'https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/renewals_bulk_import.csv' :
                        'https://checkmate-prod.s3-ap-southeast-2.amazonaws.com/assets/candidates_bulk_import.csv'
                      }
                    >
                      Download template
                    </a>
                  </div>
                )}
                <InputGroup title='Branding' className='u-margin-top'>
                  <Select 
                    inputProps={{ value: selectedBrandId, onChange: (e) => setSelectedBrandId(e.target.value) }}
                    value={selectedBrandId}
                    useDefault
                  >
                    {brands.map(brand => (
                      <Select.Item key={brand.id} value={brand.id}>{brand.name}</Select.Item>
                    ))}
                  </Select>
                </InputGroup>
              </div>

              
              <div className='u-divider' />
              <ChecksSelector
                ref={selectorRef}
                forms={forms}
                company={company}
                companyChecks={companyChecks}
                setValidity={setValid}
                onSubmit={handleSubmit}
                bulk={true}
              />
              <Button className="u-width-100" onClick={() => selectorRef.current.submit()} disabled={!valid || !csvData}>
                Import
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AppPage>
  );
};

export default BulkImport
