import React, { useState, useRef } from 'react';
import styles from './CopyEmail.module.scss';
import { useClickOutside } from '../../hooks/click-outside';
import cn from 'classnames';

const CopyEmail = ({ open, title, emailLink, setEmailLinkOpened }) => {

  const handleLinkCopy = () => {
    linkInput.current.select();
    document.execCommand("copy");
    setCopied(true)
  }

  const [copied, setCopied] = useState(false)
  const linkInput = useRef();
  const emailPopupRef = useRef();

  const button_text = copied ? "email copied" : "copy email"

  useClickOutside(emailPopupRef, () => setEmailLinkOpened(false));

  return (
    <div ref={emailPopupRef} className={cn(styles.copyEmail, { [styles.disabled]: !open })}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
          <a className={styles.copy} onClick={handleLinkCopy}>{button_text}</a>
      </div>
      <input ref={linkInput} value={emailLink} readOnly/>
    </div>
  )

}

export default CopyEmail;