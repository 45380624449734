import { useState } from 'react';
import styles from './FileContentsPicker.module.scss';
import FeatherIcon from 'feather-icons-react';
import { useDropzone } from 'react-dropzone';
import { Loader } from 'components/Loader/Loader';
import cn from 'classnames';

export const FileContentsPicker = ({
  className,
  maxSize = 20971520,
  accept,
  onChange = () => {},
}) => {

  const [fileName, setFileName] = useState();
  const [loading, setLoading] = useState(false);

  /** 
   * --- On upload ---
   * 1) Get file meta
   * 2) Sign upload
   * 3) Upload to s3
   * 4) Create upload
   * 5) Set value
   */
   const onDrop = async(files) => {
    if(files.length < 1) return;

    setLoading(true);

    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setFileName(file.name);
      onChange(e.target.result);
    };
    reader.readAsDataURL(file);
    setLoading(false);
  }

  const onRemove = async() => {
    setFileName(null);
    onChange(null);
    setLoading(false)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, maxSize })

  return (
    <div className={cn(styles.root, { [className]: className })}>
      {loading && <Loader /> }
      
      {(fileName && !loading) && 
        <div className={styles.fileWrapper}>
          <FeatherIcon icon="file" size={40} />
          <p>{fileName}</p>
          <div className={styles.removeFileIconWrapper} onClick={onRemove}>
            <FeatherIcon className={styles.removeFileIcon} icon="trash-2" size={50} />
          </div>
        </div>
      }

      {(!fileName && !loading) && 
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} />
          <FeatherIcon icon='upload' size={30} className={styles.icon} />
          <p className={styles.placeholder}>Drop your documents here, or <span className='u-link dark'>browse</span></p>
        </div>
      }
    </div>
  )
}
