import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const companyChecksApi = createApi({
  reducerPath: 'companyChecksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/company_checks`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    updateCompanyCheck: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: ({ result }) => result
    }),
    deleteCompanyCheck: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE'
      })
    }),
    createCompanyCheck: builder.mutation({
      query: (data) => ({
        url: '/',
        method: 'POST',
        body: data
      }),
      transformResponse: ({ result }) => result
    }),
  })
})

export const {
  useUpdateCompanyCheckMutation,
  useDeleteCompanyCheckMutation,
  useCreateCompanyCheckMutation
} = companyChecksApi