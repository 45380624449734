import { useEffect } from 'react';

export const useOAuth = ({
  authUri,
  clientId, 
  responseType,
  redirectUri,
  scope,
  extraParams = {},
  callbackAction,
  callback = () => {},
  onError = () => {}
}) => {

  const openAuthorization = () => {
    const uriParams = {
      ...extraParams,
      client_id: clientId,
      response_type: responseType,
      scope: scope,
      redirect_uri: redirectUri
    }

    let oauth_url = `${authUri}?`;
    
    Object.keys(uriParams).forEach(paramKey => {
      oauth_url += `${paramKey}=${uriParams[paramKey]}&`
    });

    const width = 800;
    const height = 600;
    const leftOffset = window.screenLeft + (window.innerWidth - width) / 2;
    const topOffset = window.screenTop + (window.innerHeight - height) / 2;
    const windowParams = `width=${width},height=${height},top=${topOffset},right=${leftOffset},resizable,scrollbars=yes`;
    window.open(oauth_url, '_blank', windowParams);
  }

  useEffect(() => {
    const handleCallback = (e) => {
      if(e?.data && e?.data['action'] === callbackAction) { 
        if(e.data?.code) callback(e.data.code)
        else if(e.data?.error) onError(e.data)
      }
    }

    window.addEventListener('message', handleCallback);

    return () => {
      window.removeEventListener('message', handleCallback);
    };
  }, [callback])


  return openAuthorization;
}