import React, { useEffect } from 'react';
import cn from 'classnames';
import { Button, MultipleChoice, InputGroup, Select, Toggle } from 'components/FormComponents';
import { useForm, Controller, useWatch } from 'react-hook-form';
import styles from './Notifications.module.scss';
import { useGetNotificationSettingQuery, useUpdateNotificationSettingMutation } from 'api/notification-settings-slice';

export const Notifications = ({
  setLoading = () => {},
}) => {

  const { data: notificationSettings, isLoading } = useGetNotificationSettingQuery();
  const [updateNotificationSetting] = useUpdateNotificationSettingMutation()

  const { register, handleSubmit, reset, control } = useForm();

  const frequencyValue = useWatch({ control, name: 'frequency' })
  const batchEveryValue = useWatch({ control, name: 'batch_every' })

  useEffect(() => { setLoading(isLoading) }, [isLoading])

  useEffect(() => {
    if(!notificationSettings) return;
    reset(notificationSettings.result);
  }, [notificationSettings]);


  const onSubmit = async(data) => {
    setLoading(true);
    await updateNotificationSetting(data);
    setLoading(false);
  }

  return (
    <form className={cn('u-flex-box', 'u-flex-justify-center')} onSubmit={handleSubmit(onSubmit)}>
      <div className={cn('card', 'card-with-border', 'card--medium')}>
        <div className='u-margin--large'>
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <h1 className='title-2'>Notifications</h1>
          </div>
          <p className='t-small'>Configure the notifications that are sent to you from Checkmate.</p>
        </div>
        <div className={cn('background-secondary', 'u-padding', 'card_border--top')}>
          <div className={cn('card', 'card-with-border', 'u-padding')}>
            <InputGroup title='Frequency' className={cn({['u-margin-0']: frequencyValue !== 'batched'})}>
              <Controller
                control={control}
                name='frequency'
                render={({ field: { onChange, value } }) => (
                  <MultipleChoice 
                    value={value}
                    onChange={onChange}
                    radioButtonStyle
                    options={{
                      options: [
                        { name: "Instant", value: "instant" },
                        { name: "Batched", value: "batched" },
                        { name: "Never", value: "never" },
                      ]
                    }}
                  />
                )}
              />
            </InputGroup>
            {frequencyValue === 'batched' && 
              <InputGroup title='I want to receive batched notifications every' className='u-margin-0'>
                <Select name='batch_every' register={register} value={batchEveryValue} useDefault>
                  <Select.Item value={1}>24 hours</Select.Item>
                  <Select.Item value={3}>36 hours</Select.Item>
                  <Select.Item value={7}>1 week</Select.Item>
                </Select>
              </InputGroup>
            }
          </div>
          {frequencyValue !== 'never' && 
            <div className={cn('card', 'card-with-border', 'u-padding', 'u-margin-top')}>
              <InputGroup title='Notify me when' className='u-margin-0'>
                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>New candidate has been created</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a candidate has been created by someone else at the company</p>
                  </div>
                  <Toggle name='new_check' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Candidate is overdue</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a candidate becomes overdue</p>
                  </div>
                  <Toggle name='candidate_overdue' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Candidate is completed</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when all candidate checks are completed</p>
                  </div>
                  <Toggle name='candidate_completed' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Candidate nominated referee</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a candidate nominated referee</p>
                  </div>
                  <Toggle name='candidate_nominated_referee' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Candidate is rejected</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a candidate becomes rejected</p>
                  </div>
                  <Toggle name='candidate_rejected' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>A referee is overdue</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a candidate's referee becomes overdue</p>
                  </div>
                  <Toggle name='referee_overdue' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Each referee gets completed</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a referee has respond to the reference form</p>
                  </div>
                  <Toggle name='referee_completed' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Each background check gets completed</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when each individual background check is completed</p>
                  </div>
                  <Toggle name='background_check_completed' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Background check has result</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a background check has been marked "With results"</p>
                  </div>
                  <Toggle name='checks_with_result' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Other candidates are updated</p>
                    <p className='t-small u-margin-bottom--0'>Get notified on actions for candidates that someone else created on the company</p>
                  </div>
                  <Toggle name='all_company_notifications' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Email could not be delivered</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a Candidate or Referee email bounced.</p>
                  </div>
                  <Toggle name='bounce_email_notifications' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Candidate's referee ip match</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a candidate's referee ip match</p>
                  </div>
                  <Toggle name='candidate_referee_ip_match' register={register}/>
                </div>

                <div className={styles.notification}>
                  <div>
                    <p className='u-margin-bottom--tiny'>Candidate's referee follow up answer</p>
                    <p className='t-small u-margin-bottom--0'>Get notified when a candidate's referee follow up answer</p>
                  </div>
                  <Toggle name='referee_follow_up' register={register}/>
                </div>
              </InputGroup>
            </div>
          }
        </div>
        <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-end')}>
          <Button submit>Save</Button>
        </div>
      </div>
    </form>
  )
}
