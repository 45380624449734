import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import { stringDate } from 'helpers/date';
import cookie from 'react-cookies';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/companies`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getCompanyUsers: builder.query({
      query: ({ id, params }) => `/${id}/users?${toQueryParams(params)}`
    }),
    getCompany: builder.query({
      query: (id) => `/${id}`,
      transformResponse: ({ result }) => result
    }),
    createCompanyOnBehalf: builder.mutation({
      query: (data) => ({
        url: '/on_behalf',
        method: 'POST',
        body: data,
      }),
      transformResponse: ({ result }) => result
    }),
    updateCompany: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: ({ result }) => result
    }),
    getCompanyChecks: builder.query({
      query: (id) => `/${id}/company_checks`,
      transformResponse: ({ result }) => result
    }),
    getCompanyOwner: builder.query({
      query: (id) => `/${id}/owner`,
      transformResponse: ({ result }) => result
    }),
    getCompanies: builder.query({
      query: (params) => `/?${toQueryParams(params)}`
    }),
    getAllCompaniesList: builder.query({
      query: () => '/list'
    }),
    getSSOAdminPortalUrl: builder.query({
      query: () => '/sso'
    }),
    getCompanyBillingInfo: builder.query({
      query: (id) => `/${id}/billing`,
      transformResponse: ({ result }) => result
    }),
    getCompanyUsageReport: builder.query({
      queryFn: async ({ id, data }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/${id}/reports/usage?${toQueryParams(data)}`,
          responseHandler: async (response) => {
            const blob = await response.blob();
            return blob;
          },
          cache: "no-cache",
        });
        saveAs(file, `${id}_usage_report_${stringDate()}`);
        return { data: null }
      }
    })
  })
})

export const {
  useCreateCompanyOnBehalfMutation,
  useGetCompanyBillingInfoQuery,
  useLazyGetCompanyUsageReportQuery,
  useUpdateCompanyMutation,
  useGetCompanyChecksQuery,
  useGetCompanyQuery,
  useGetCompanyOwnerQuery,
  useLazyGetCompaniesQuery,
  useGetCompanyUsersQuery,
  useLazyGetCompanyUsersQuery,
  useGetAllCompaniesListQuery,
  useLazyGetSSOAdminPortalUrlQuery
} = companyApi
