import { saveAs } from 'file-saver';
import styles from './QuestionResponses.module.scss';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import Moment from 'react-moment';
import { FieldTypes } from 'constants/field-types-enum';
import { Button } from 'components/FormComponents';

export const QuestionResponses = ({
  order,
  field, 
  params = {},
}) => {

  const download = (answer) => {
    const split = answer.split(".");
    const name = `answer_${field.order}.${split[split.length - 1]}`;
    saveAs(answer, name);
  }

  const formatQuestion = (text) => {
    if(!text) return;

    const textWithLinks = text.replace(/\[((?!\]).)*\]\(((?!\)).)*\)/g, (match) => {
      const [, text] = (/\[([^)]+)\]/).exec(match);
      const [, link] = (/\(([^)]+)\)/).exec(match);
  
      return `<a href="${link}" class='u-link dark' target="_blank">${text}</a>`
    });

    const textWithVars = textWithLinks.replace(/{{((?!}).)*}}/g, (match) => {
      const m =  match.replace(/[{}]/g, '');
      const value = params[m];
      const text = value ? value : (isNaN(m) ? m : `Q${m} answer`);

      return `<b>${text}</b>`
    });
      
    return textWithVars;
  }

  const formattedAnswer = (field, answer) => {
    if(!answer) return;

    switch(field.type) {
      case FieldTypes.OpinionScale:
        const customSteps = field.options.use_custom_steps;
        const stepsStart = customSteps ? field.options.steps_start : 1;
        const stepsEnd = customSteps ? field.options.steps_end : 10;

        let scales = [];
        for(let i=stepsStart; i <= stepsEnd; i++) { scales.push(i) }
        return (
          <div className={styles.scale}>
            <span className={cn(styles.scaleLabel, styles.scaleLabelLeft)}>{field.options.label_start}</span>
            {scales.map(s => (
              <span key={s} className={cn(styles.scaleNumber, {[styles.scaleNumberSelected]: s == answer})}>{s}</span>
            ))}
            <span className={cn(styles.scaleLabel, styles.scaleLabelRight)}>{field.options.label_end}</span>
          </div>
        );
      case FieldTypes.StarRating:
        let ratings = [1,2,3,4,5];
        return (
          <div className={styles.rating}>
            {ratings.map(s => (
              <span key={s} className={cn({ [styles.starFilled]: s <= answer })}><FeatherIcon icon="star"/></span>
            ))}
          </div>
        )
      case FieldTypes.MultipleChoice:
        const answers = Array.isArray(answer) ? answer : answer.split(' / ');
        let notInOptionAnswer;
        let optionsValues = field.options.options.map(option => option.value)
        answers.forEach(answer => {
          if(!optionsValues.includes(answer)) notInOptionAnswer = answer;
        })
        return (
          <div className={styles.multipleChoice}>
            {field.options.options.map(option => {
              if(option !== '')
                return <span key={option.value} className={cn(styles.multipleChoiceOption, {[styles.multipleChoiceOptionSelected]: answers.indexOf(option.value) >= 0})}>{option.value}</span>
            })}
            {notInOptionAnswer &&
              <span className={cn(styles.multipleChoiceOption, styles.multipleChoiceOptionSelected)}>{notInOptionAnswer}</span>
            }
          </div>
        )
      case FieldTypes.Date:
        return(
          <span><Moment format="MMMM Do, YYYY">{answer}</Moment></span>
        )
      case FieldTypes.Upload:
        return(
          <span><Button className={styles.upload} onClick={() => download(answer)} icon='download' small type='secondary'>View document</Button></span>
        )
      case FieldTypes.Signature:
        return (
          <img className={styles.signature} src={answer} />
        )
      default:
        return (
          <span className={styles.text}>{answer}</span>
        )
    }
  }

  return (
    <div className={styles.root}>
      <div className={cn(styles.question, 'u-flex-box', 'u-flex-justify-start')}>
        <div className={styles.questionOrder}>{order || field.order}</div>
        <div className={styles.questionText} dangerouslySetInnerHTML={{ __html: formatQuestion(field.text) }} />
      </div>
      {field.type !== FieldTypes.Statement &&
        <div className={styles.answers}>
          {field.answers.map((answer, i) => (
            <>
              <div key={i} className={cn('card', 'card-with-border', 'u-padding--small', styles.answer)}>
                <span class={cn('badge', 'badgeSmall', 'u-margin-bottom--small', styles.badge)}>{answer.name}</span>
                <p className={cn('u-margin-0', 't-small')}>{formattedAnswer(field, answer.answer)}</p>
              </div>
              {answer.subFields?.length > 0 &&
                <div className={styles.subAnswers}>
                  {answer.subFields?.map((subfield, i) => (
                    <div key={i} className={cn('u-margin-left--large', 'u-margin-top')}>
                      <div className={cn(styles.question)}>
                        <p className={styles.questionText}>
                          <span className='t-tiny'>{order || field.order}.{String.fromCharCode(65 + i)}. </span>
                          <div className='d-inline-block' dangerouslySetInnerHTML={{ __html: formatQuestion(subfield.text) }} />
                        </p>
                      </div>
                      <div className={cn('card', 'card-with-border', 'u-padding--small', styles.subAnswer)}>
                        <p className={cn('u-margin-0', 't-small')}>{formattedAnswer(subfield, subfield.answer)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </>
          ))}
        </div>
      }
    </div>
  )
}