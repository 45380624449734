import { KeyValueModal } from './../KeyValueModal/KeyValueModal';
import DeviceDetector from "device-detector-js";

export const MetadataDetailsModal = ({
  userAgent,
  country,
  visible,
  onClose = () => {},
}) => {
  const deviceInfo = (new DeviceDetector()).parse(userAgent);

  return (
    <KeyValueModal
      title="Metadata Details" 
      visible={visible} 
      onClose={onClose} 
      data={{
        "Client type": deviceInfo?.client?.type,
        "Client Name": deviceInfo?.client?.name,
        "Client Version": deviceInfo?.client?.version,
        "OS Name": deviceInfo?.os?.name,
        "OS Version": deviceInfo?.os?.version,
        "Device Type": deviceInfo?.device?.type,
        "Device Brand": deviceInfo?.device?.brand,
        "IP Country": country
      }}
    />
  ) 
}
