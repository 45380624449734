import { AnimatedModal } from '../AnimatedModal/AnimatedModal';
import styles from './KeyValueModal.module.scss';

export const KeyValueModal = ({ 
  title, 
  visible = false,
  data = {},
  onClose = () => {},
}) => {

  const renderValue = (value) => {
    switch(typeof value) {
      case 'boolean': 
        return `${value}`
      case 'object':
        return JSON.stringify(value);
      default: 
        return value;
    }
  }

  return (
    <AnimatedModal visible={visible} onClose={onClose} className={styles.root} title={title}>
      {data && typeof(data) === 'object' && Object.entries(data).map(([key, value]) => (
        <div className={styles.row} key={key}>
          <div className={styles.rowDescription}>{key?.replaceAll('_', ' ')}:</div>
          <div className={styles.rowValue}>{renderValue(value)}</div>
        </div>
      ))}
    </AnimatedModal>
  )
}