import React from 'react';
import cn from 'classnames';

export const CustomLink = ({ brand, children, className, href }) => (
  <a
    style={(brand?.brand_active && brand?.color_active) ? { color: brand.color_link } : null}
    className={cn({ [className]: className })}
    href={href}
    target='_blank'
  >
    {children}
  </a>
)