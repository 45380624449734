import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import styles from './IconStatusButton.module.scss';
import cn from 'classnames';

export const IconStatusButton = ({
  tooltip,
  prefix,
  icon,
  status,
  className,
  onClick = () => {}
}) => {
  return (
    <OverlayTrigger placement='bottom' overlay={<Tooltip>{tooltip}</Tooltip>} >
      <div className={cn(styles.root, 'u-pointer', 'checkmate-icon-status-button', 'u-status-hover', `u-status-${status}`, { [className]: className})} onClick={onClick}>
        {prefix === "fa" ? <FontAwesomeIcon icon={icon} /> : <FeatherIcon icon={icon} /> }
      </div>
    </OverlayTrigger>
  )
}