import styles from './CheckTableHeader.module.scss';
import cn from 'classnames';

const CheckTableHeader = ({ 
  title,
  order, 
  name,
  className,
  sortFunction = () => {}
}) => (
  <p className={cn(styles.root, { [styles.desc]: order === 1, [styles.asc]: order === -1, [className]: className })} onClick={() => sortFunction(name)}>
  {title}
  </p>
)

export default CheckTableHeader;