import { useEffect, useRef, useState } from 'react';
import { errorRouter } from 'helpers/error-router';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { createWidget } from '@typeform/embed';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { now } from 'helpers/date';
import { shouldSubmitCandidate } from 'utils/checks-utils';
import styles from './CandidateReference.module.scss';
import '@typeform/embed/build/css/widget.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCandidateCheck, selectCandidateApplication, submitCandidateApplication, getCandidateReferenceTypeformParams } from 'api/candidate-application-slice';
import { useUserAgent } from 'hooks/user-agent';

const CandidateReference = () => {

  const { user_agent } = useUserAgent();
  const { token, replacement } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { application: { check_types, candidate_checks }, fetched } = useSelector(selectCandidateApplication);

  const [loading, setLoading] = useState(true);
  const [referenceCheck, setReferenceCheck] = useState(null);

  const formRef = useRef(null);

  // Set reference check
  useEffect(() => {
    if(!fetched) return;
    
    setReferenceCheck(candidate_checks.find(check => check.type === 'reference'));
  }, [fetched])

  // Instantiate Typeform
  useEffect(() => {
    if(!referenceCheck) return;
    dispatch(getCandidateReferenceTypeformParams({ token, id: referenceCheck.id, replacement })).then(({ payload }) => {
      setLoading(false);
      if(!payload) 
        navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
      else { 
        const { typeform_id, hidden } = payload;
        createWidget(typeform_id, {
          container: formRef.current, 
          hidden,
          onSubmit: async() => {
            setLoading(true);
            if(shouldSubmitCandidate(referenceCheck, candidate_checks, check_types)) {
              await dispatch(submitCandidateApplication({ token, user_agent }));
              setLoading(false);
              navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);
            } else {
              await dispatch(updateCandidateCheck({ token, id: referenceCheck.id, data: { submitted_at: now() }}));
              setLoading(false);
              navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
            }
          }
        })
      }}, 
      error => navigate(errorRouter(error))
    )
  }, [referenceCheck]);

  return (
    <CandidatePage token={token} loading={loading} fullScreen={true}>
      <div ref={formRef} className={styles.form}></div>
    </CandidatePage>
  )
}

export default CandidateReference
