import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const idVerificationsApi = createApi({
  reducerPath: 'idVerificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/id_verifications`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getIdVerificationTypes: builder.query({
      query: () => `/types`,
      transformResponse: ({ result }) => result
    }),
    verifyId: builder.mutation({
      query: ({ candidateId, data }) => ({
        url: '/',
        method: 'POST',
        body: {
          candidate_id: candidateId,
          entity_data: data
        }
      }),
      transformResponse: ({ result }) => result
    }),
    getIdVerification: builder.query({
      query: (candidateId) => `/${candidateId}`,
      transformResponse: ({ result }) => result
    }),
  })
})

export const {
  useGetIdVerificationQuery,
  useGetIdVerificationTypesQuery,
  useVerifyIdMutation,
} = idVerificationsApi
