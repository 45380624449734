import { useState, useContext, useRef, useEffect, createContext } from "react";	
import styles from "./StepByStepGuide.module.scss";	
import { CustomButton } from "../CustomButton/CustomButton";	
import cn from "classnames";	

const GuideContext = createContext(null);	

export const StepByStepGuide = ({	
  initialStep = 0,	
  withNavigationButtons = false,	
  children,	
  lastButtonText = "Finish",	
  onCompleted = () => { },	
  brand,	
}) => {	
  const ref = useRef(null);	

  const [activeStep, setActiveStep] = useState(initialStep);	

  const [childrenLength, setChildrenLength] = useState(0);	
  const [hasNext, setHasNext] = useState(true);	
  const [hasPrevious, setHasPrevious] = useState(false);	

  const nextStep = () => {	
    if (hasNext) setActiveStep((current) => current + 1);	
  };	
  const previousStep = () => {	
    if (hasPrevious) setActiveStep((current) => current - 1);	
  };	

  // Calculate childrens	
  useEffect(() => {	
    if (ref.current) setChildrenLength([...ref.current.children].length);	
  }, [children]);	

  // Set navigation items	
  useEffect(() => {	
    setHasPrevious(activeStep > 0);	
    setHasNext(activeStep < childrenLength - 1);	
  }, [activeStep, childrenLength]);	

  const contextValue = {	
    activeStep,	
    withNavigationButtons,	
    hasNext,	
    hasPrevious,	
    lastButtonText,	
    nextStep,	
    previousStep,	
    onCompleted,	
    brand	
  };	

  return (	
    <GuideContext.Provider value={contextValue}>	
      <div ref={ref} className={styles.root}>	
        {children}	
      </div>	
    </GuideContext.Provider>	
  );	
};	

export const Navigation = () => {	
  const {	
    hasNext,	
    hasPrevious,	
    nextStep,	
    previousStep,	
    lastButtonText,	
    onCompleted,	
    brand	
  } = useContext(GuideContext);	

  return (	
    <div className={styles.navigation}>	
      <CustomButton hasPrevious={hasPrevious} action={previousStep} brand={brand}>Previous</CustomButton>	
      {hasNext ?	
        <CustomButton action={nextStep} brand={brand}>Next</CustomButton> : <CustomButton action={onCompleted} brand={brand}>{lastButtonText}</CustomButton>	
      }	
    </div>	
  );	
};	

export const Step = ({ children }) => {	
  const ref = useRef(null);	
  const [order, setOrder] = useState();	

  const { activeStep, withNavigationButtons } = useContext(GuideContext);	

  useEffect(() => {	
    if (ref.current)	
      setOrder([...ref.current.parentElement.children].indexOf(ref.current));	
  }, [ref.current]);	

  return (	
    <div	
      ref={ref}	
      className={cn(styles.step, 'u-padding--large', {	
        [styles.stepBefore]: activeStep > order,	
        [styles.stepAfter]: activeStep < order,	
      })}	
    >	
      {children}	
      {withNavigationButtons && <Navigation />}	
    </div>	
  );	
};	

StepByStepGuide.Step = Step;