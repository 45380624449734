import { useEffect, useState } from 'react';
import { useScript } from 'hooks/load-script';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { now } from 'helpers/date';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCandidateCheck, selectCandidateApplication } from 'api/candidate-application-slice';

const CandidateIdVerificationCheck = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { application: { candidate_checks }, fetched } = useSelector(selectCandidateApplication);

  const { token } = useParams();
  const { REACT_APP_GETID_URL, REACT_APP_GETID_SDK_KEY } = process.env;
  const [ loading, setLoading ] = useState(true);
  const [ idCheck, setIdCheck ] = useState(null);
  const [ amlCheck, setAmlCheck ] = useState(null);

  // Set verification checks
  useEffect(() => {
    if(!fetched) return;

    setIdCheck(candidate_checks.find(check => check.type === 'id_verification'))
    setAmlCheck(candidate_checks.find(check => check.type === 'aml_verification'))
  }, [fetched])


  // Load GetId script
  useScript('https://cdn.getid.cloud/sdk/getid-web-sdk-v5.min.js', () => { setLoading(false) });

  // On finished loading, init
  useEffect(() => {
    if((!idCheck && !amlCheck) || loading) return;

    let verificationTypes = ['data-extraction'];
    let flow = [
      {
        component: 'DocumentPhoto',
        showRules: true,
        interactive: true,
        enableCheckPhoto: true,
      }
    ];

    if(idCheck) { 
      verificationTypes.push("face-matching");
      flow.push(
        {
          component: 'Selfie',
          showRules: true,
          enableCheckPhoto: true,
        },
        {
          component: 'Liveness', 
        }
      )
    }
    
    if(amlCheck) verificationTypes.push("watchlists");

    const getidToken = window.getidWebSdk.createPublicTokenProvider(REACT_APP_GETID_URL, REACT_APP_GETID_SDK_KEY);
    const config = {
      apiUrl: REACT_APP_GETID_URL,
      containerId: 'getid-component',
      verificationTypes,
      flow,
      onComplete: async({ id }) => {
        setLoading(true);
        if(idCheck)  await dispatch(updateCandidateCheck({ token, id: idCheck.id,  data: { submitted_at: now(), job_at: now(), provider_id: id }}));
        if(amlCheck) await dispatch(updateCandidateCheck({ token, id: amlCheck.id, data: { submitted_at: now(), job_at: now(), provider_id: id }}));
        setLoading(false);
        navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
      }
    }
    
    window.getidWebSdk.init(config, getidToken);
  }, [idCheck, amlCheck, loading])
  
  return ( 
    <CandidatePage loading={loading} token={token} title="Online Identification">
      <div id='getid-component'></div>
    </CandidatePage>
  )
}

export default CandidateIdVerificationCheck