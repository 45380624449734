import { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useWatch } from "react-hook-form";
import { EditableSettings } from "components/EditableSettings/EditableSettings";
import cn from 'classnames';
import { InputGroup, FilePicker, Button } from "components/FormComponents";
import { useApiUploadActions } from 'hooks/upload-actions';
import styles from './Documents.module.scss';

export const Documents = ({
  brand,
  control,
  register,
  getBrandUploads   = () => {},
  removeBrandUpload = () => {},
  addBrandUpload    = () => {},
  setLoading        = () => {},
}) => {

  const { signUpload, createUpload, deleteUpload } = useApiUploadActions()

  const documentsActiveValue = useWatch({ control, name: 'documents_active' })

  const [upload, setUpload] = useState();

  // Refresh values when brand change
  useEffect(() => {
    setLoading(true);
    getBrandUploads(brand.id).then(_ => setLoading(false));
  }, [brand.id]);

  // Delete upload
  const handleDocumentDelete = async(id) => {
    setLoading(true);
    await deleteUpload(id);
    removeBrandUpload(brand.id, id);
    setLoading(false);
  }

  // Handle new document
  const addToDocuments = () => {
    addBrandUpload(brand.id, upload);
    setUpload(null);
  } 
  
  return (
    <div className='background-secondary u-padding'>
      <div className={cn('card', 'card-with-border', 'u-padding')}>
        <EditableSettings title='Use documents' open={documentsActiveValue} toggleable register={register} name='documents_active' className="u-margin-0">
          <InputGroup title='Add Document'>
            <div className={cn('card', 'card-with-border', 'u-padding', 'u-flex-box', 'u-flex-direction-vertically')}>
              <FilePicker
                modelParams={{ brand_id: brand.id }}
                bucketFolder="company_documents"
                accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'] }}
                value={upload}
                signUpload={signUpload}
                createUpload={createUpload}
                onChange={setUpload}
                onDelete={() => handleDocumentDelete(upload?.id)}
                fixWidth
                useModel
              />
              <Button onClick={addToDocuments} className='u-margin-top' disabled={!upload}>Add</Button>
            </div>
          </InputGroup>
          <InputGroup title='Documents' className="u-margin-top--large">
            <div className={cn('card', 'card-with-border', 'background-secondary', 'u-padding', 'u-margin-top')}>
              {brand.uploads?.map(document => (
                <div key={document.id} className={styles.document}>
                  <div className={cn('u-flex-box', 'u-flex-align-center')}>
                    <FeatherIcon icon='file' className={cn(styles.icon, 'u-margin-right--small')} />
                    <span>{document.file_name}</span>
                  </div>
                  <FeatherIcon icon="trash-2" className={cn(styles.icon, 'u-pointer')} onClick={() => handleDocumentDelete(document.id)} />
                </div>
              ))}
            </div>
          </InputGroup>
        </EditableSettings>
      </div>
    </div>
  )
}