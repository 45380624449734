import { useEffect, useState } from 'react';
import AppPage from 'layouts/AppPage/AppPage';
import styles from './Reporting.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLazyGetChecksQuery, useBulkExportMutation, useGetChecskWidgetCountQuery } from 'api/candidate-checks-api';
import { Loader } from 'components/Loader/Loader';
import { ReportingFilters } from './components/ReportingFilters/ReportingFilters';
import { ReportingWidget } from './components/ReportingWidget/ReportingWidget';
import { CheckTable } from './components/CheckTable/CheckTable';
import { BulkChecksActionBar } from './components/BulkChecksActionBar/BulkChecksActionBar';
import { SearchInput } from 'components/FormComponents';
import { useDocumentTitle } from 'hooks/document-title';
import { useLocale } from 'hooks/locale';
import { useSelector } from 'react-redux';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectCompany } from 'api/company-slice';

const Reporting = () => {

  useDocumentTitle('Reporting');

  const companyChecks = useSelector(selectCompanyChecks);
  const company = useSelector(selectCompany);

  const [loading, setLoading] = useState(true);

  const [selectedIds, setSelectedIds] = useState([]);
  const [useAllForBulk, setUseAllForBulk] = useState(false);

  const [pagination, setPagination] = useState({ page: 1, records_per_page: 15, total_pages: 1, total_records: 0 })
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({ type: null, status: null })

  const [checks, setChecks] = useState([]);

  const [getChecks] = useLazyGetChecksQuery({type: filters.type, params: { ...filters, search: query, page: 1 }})
  const { data: widgetData } = useGetChecskWidgetCountQuery()
  const [bulkExport] = useBulkExportMutation();
  const { dateSlashFormat } = useLocale(company?.locale);

  // On filter change
  useEffect(() => {
    setChecks([]);
    setLoading(true);
    fetchMoreChecks(1);
  }, [filters, query])

  const fetchMoreChecks = async (page = pagination.page) => {
    if (!filters.type) return;

    const response = await getChecks({
      type: filters.type, 
      params: { ...filters, search: query, page }
    }).unwrap();

    // Replace if fetching first page
    setChecks(curr => (page === 1 ? response.result.candidate_checks : [...curr, ...response.result.candidate_checks]));
    setPagination(response.result.pagination);
    setLoading(false);
  }

  const handleBulkExport = async () => {
    setLoading(true);
    await bulkExport({ type: filters.type, ids: selectedIds, useAll: useAllForBulk });
    setLoading(false);
  }

  const percentage = (value, total) => {
    if (!total) return 0;
    return Math.round(value / total * 100)
  }

  return (
    <AppPage loading={loading}>
      {companyChecks?.length > 0 &&
        <div className={styles.root}>
          <BulkChecksActionBar selectedIds={selectedIds} onExport={handleBulkExport} onUseAllChange={setUseAllForBulk} />
          <div>
            <AppPage.Header 
              title='Reporting'
              subtitle='View and report on your performance in Checkmate across candidates, references, and checks'
            />
            <div className={styles.widgetContainer}>
              <ReportingWidget type="total" value={widgetData?.result?.total} title="Active Checks">
                <ReportingWidget.Badge hexColor='#5922BE'>Total Checks</ReportingWidget.Badge>
                <ReportingWidget.Value>{widgetData?.result?.total}</ReportingWidget.Value>
                <ReportingWidget.Label>Active Checks</ReportingWidget.Label>
              </ReportingWidget>
              <ReportingWidget>
                <ReportingWidget.Badge hexColor='#2ebf5e'>Completed</ReportingWidget.Badge>
                <ReportingWidget.Value>{widgetData?.result?.completed}</ReportingWidget.Value>
                <ReportingWidget.Label>{`${percentage(widgetData?.result?.completed, widgetData?.result?.total)}% Complete`}</ReportingWidget.Label>
              </ReportingWidget>
              <ReportingWidget>
                <ReportingWidget.Badge hexColor='#33373b'>Average Turnaround</ReportingWidget.Badge>
                <ReportingWidget.Value>{widgetData?.result?.avgtimediff}h</ReportingWidget.Value>
                <ReportingWidget.Label>For Candidate</ReportingWidget.Label>
              </ReportingWidget>
            </div>
            <div className={styles.filters}>
              <SearchInput placeholder="Search for a candidate" className={styles.search} onChange={setQuery} />
              <ReportingFilters companyChecks={companyChecks} onChange={setFilters} />
            </div>
          </div>
          <div className={styles.results}>
            <CheckTable checkType={companyChecks.find(companyCheck => companyCheck.type === filters.type)?.check_type} setLoading={setLoading} onSelectedRowsChange={setSelectedIds}>
              <CheckTable.Header />
              <CheckTable.Rows>
                <InfiniteScroll
                  dataLength={checks.length}
                  next={() => fetchMoreChecks(pagination.page + 1)}
                  hasMore={pagination.page !== pagination.total_pages}
                  loader={<Loader />}
                >
                  {checks.map(check => <CheckTable.Row key={check.id} candidateCheck={check} dateFormat={dateSlashFormat} />)}
                </InfiniteScroll>
              </CheckTable.Rows>
            </CheckTable>
          </div>
        </div>
      }
    </AppPage>
  )
}

export default Reporting