import { useEffect, useState } from "react";
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { shouldSubmitCandidate } from 'utils/checks-utils';
import styles from "./CandidateQuestionnaire.module.scss";
import { Form } from 'components/Form/Form/Form';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectCandidateApplication, submitCandidateApplication, saveCheckFormAnswers } from 'api/candidate-application-slice';
import { useCandidateApplicationUploadActions } from 'hooks/upload-actions';
import { useUserAgent } from "hooks/user-agent";

const CandidateQuestionnaire = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user_agent } = useUserAgent();
  const { token } = useParams();

  const { application: { candidate, candidate_checks, brand, check_types, forms }, fetched } = useSelector(selectCandidateApplication);

  const { getUpload, signUpload, createUpload, deleteUpload } = useCandidateApplicationUploadActions(token);

  const [loading, setLoading] = useState(false);
  const [questionnaireCheck, setQuestionnaireCheck] = useState();
  const [form, setForm] = useState();

  // Set questionnaire form if checks
  useEffect(() => {
    if (!fetched) return;
    const check = candidate_checks.find((check) => check.type === 'candidate_questionnaire');
    setQuestionnaireCheck(check);
    setForm(forms.find(form  => form.id === check.details.form_id));
  }, [fetched]);

  const onSubmit = async(data) => {
    setLoading(true);
    await dispatch(saveCheckFormAnswers({ token, id: questionnaireCheck.id, params: { answers: data } }))
    if(shouldSubmitCandidate(questionnaireCheck, candidate_checks, check_types)) {
      await dispatch(submitCandidateApplication({ token, user_agent }));
      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);
    } else {
      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    }
  };

  return (
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <CandidatePage.Card className={styles.root}>
          <img src='assets/images/icons/id_icon.svg' className={styles.icon} />
          <h1 className="title-4 u-padding-top u-padding-bottom--large">{form?.name}</h1>
          <Form
            candidateId={candidate.id}
            form={form}
            brand={brand}
            onSubmit={onSubmit}
            getCandidateUpload={getUpload}
            deleteCandidateUpload={deleteUpload}
            signCandidateUpload={signUpload}
            createCandidateUpload={createUpload}
          />
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  );
};

export default CandidateQuestionnaire
