import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const linkedinApi = createApi({
  reducerPath: 'linkedinApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/linkedin`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getLinkedinProfile: builder.query({
      query: (code) => `?code=${code}`,
      transformResponse: ({ result }) => result
    })
  })
})

export const {
  useLazyGetLinkedinProfileQuery
} = linkedinApi
