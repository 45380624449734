import { Button } from 'components/FormComponents';
import FeatherIcon from 'feather-icons-react';
import styles from './EmailVerificationAlert.module.scss';

export const EmailVerificationAlert = ({
  sent,
  resend,
  onSignOut = () => {}
}) => (

  <div className={styles.root}>
    <div className={styles.description}>
      <img src="assets/images/email-illustration-pink.png" alt="email-illustration-pink"/>
      <h1>Please verify your email.</h1>
      {sent ? 
        <>
          <p>To continue using Checkmate, please click the link in the verification email that we sent to your inbox.</p>
          <div className={styles.confirmationButton}><FeatherIcon icon="check-circle"/> Email confirmation sent</div>
        </> :
        <>
          <p>To continue using Checkmate, please verify your email by clicking the link in the confirmation email that we sent to your inbox.</p>
          <Button className={styles.button} onClick={resend}>Resend email verification</Button>
        </>
      }
      <a className="u-link dark" onClick={onSignOut}>Sign out</a>
      {sent &&
        <div className={styles.support}>Still don't see it? Email us at <a href="mailto:support@checkmate.tech">support@checkmate.tech</a></div>
      }
    </div>
  </div>
)