import { Fragment, useEffect, useState, useRef } from 'react';
import { useGetApplicationQuery, useStartApplicationByTokenMutation } from 'api/partner-api';
import { Button } from 'components/FormComponents';
import { Select, InputGroup, Input } from 'components/FormComponents';
import { ChecksSelector, SelectorTypes } from 'components/ChecksSelector/ChecksSelector';
import { ToggleSwitchInput } from 'components/FormComponents/ToggleSwitchInput/ToggleSwitchInput';
import useLocalStorage from 'react-use-localstorage';
import { Loading } from 'components/Loading/Loading';
import { Loader } from 'components/Loader/Loader';
import styles from './AssessmentChecks.module.scss';
import { useParams } from 'react-router-dom';


export const AssessmentChecks = () => {

  const { token, check_type: checkType } = useParams();

  const { data: application, isLoading: isLoadingApplication } = useGetApplicationQuery(token);

  const [startApplication] = useStartApplicationByTokenMutation();

  const selectorRef = useRef(null);

  const [company, setCompany] = useState(null);
  const [forms, setForms] = useState({});
  const [companyChecks, setCompanyChecks] = useState([]);
  const [checks, setChecks] = useState([]); 
  const [check, setCheck] = useState();
  const [brands, setBrands] = useState([]);
  const [brandsDocuments, setBrandsDocuments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const [brandId, setBrandId] = useState(null);
  const [brandDocuments, setBrandDocuments] = useState([]);

  const [ccNotificationEmailsEnabled, setCcNotificationEmailsEnabled] = useLocalStorage('ccNotificationEmailsEnabled', "false");
  const [ccNotificationEmails, setCcNotificationEmails] = useLocalStorage('ccNotificationEmails', "");
  

  useEffect(() => {
    if(!application) return;

    if(application.candidate.partner_application_started) 
      setInProgress(true);

    setForms(application.forms);
    setChecks(application.checks);
    setCompany(application.company);
    setCompanyChecks(application.company_checks);
    setBrands(application.brands);
    setBrandsDocuments(application.uploads);
  }, [application])

  // Set brand documents
  useEffect(() => {
    setBrandDocuments(brandsDocuments.filter(doc => doc.brand_id === brandId));
  }, [brandId])

  // Get current check based on type
  useEffect(() => {
    if(checks.length < 1) return;
    setCheck(checks.find(check => check.type === checkType))
  }, [checks.length])


  const submit = async(selectorValues) => {
    setLoading(true);
    // Initialize data
    let data = { candidate: {}, checks: [] };
    // Set brandId if given
    if(brandId) data.candidate.brand_id = brandId
    // Set ccEmails if given
    if(ccNotificationEmails) data.candidate.cc_notification_emails = ccNotificationEmails;
    // Set checks
    if(checkType === SelectorTypes.BACKGROUND_CHECKS) {
      data.checks = selectorValues;
    } else { 
      const checkData = selectorValues.find(checkData => checkData.type === checkType);
      data.checks = [{ id: check.id, type: checkData.type, details: { ...checkData.details, ...check.details } }]
    }
    // Submit
    const { data: result } = await startApplication({ token, params: data });
    setInProgress(result);
    setLoading(false);
  }

  if(loading || isLoadingApplication) return <Loading active={true}/>

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {inProgress ? 
          <div className={styles.inProgressWrapper}>
            <Loader />
            <div className={styles.inProgressMessage}>
              Checks have started. We will update the status once is completed.
            </div>
          </div>
          :
          <Fragment>
            {brands.length > 1 &&
              <InputGroup title='Branding'>
                <Select inputProps={{ value: brandId, onChange: (e) => setBrandId(e.target.value) }} value={brandId}>
                  {brands.map(brand => (
                    <Select.Item key={brand.id} value={brand.id}>{brand.name}</Select.Item>
                  ))}
                </Select>
              </InputGroup>
            }

            <ToggleSwitchInput
              text="Notification List"
              subtext='Add external emails to get notified about status updates'
              onChange={e => setCcNotificationEmailsEnabled(e.target.checked)}
              value={JSON.parse(ccNotificationEmailsEnabled)}
            >
              <InputGroup title='Emails'>
                <Input 
                  inputProps={{ value: ccNotificationEmails, onChange: (e) => setCcNotificationEmails(e.target.value)}}
                  placeholder='email_1@example.com,email_2@example.com'
                  className='u-width-100 u-margin-right--small'
                />
              </InputGroup>
            </ToggleSwitchInput>

            <ChecksSelector
              ref={selectorRef}
              type={checkType}
              form={check?.details?.typeform_id}
              forms={forms}
              documents={brandDocuments}
              company={company}
              companyChecks={companyChecks}
              onSubmit={submit}
            />
            <Button className='u-width-100 u-margin-top--large' onClick={() => selectorRef.current.submit()}>Start</Button> 
          </Fragment>
        }
      </div>
    </div>
  )
}

export default AssessmentChecks