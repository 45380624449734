import styles from './MultipleChoice.module.scss';
import FeatherIcon from 'feather-icons-react';
import { Input } from './../Input/Input';
import cn from 'classnames';
import { useState } from 'react';

const removeValue = (array, value) => {
  const index = array.indexOf(value);
  if (index < 0) return array;
  array.splice(index, 1);
  return array;
}

export const MultipleChoice = ({
  options: {
    multipleSelection,
    options,
    otherOption,
  },
  value,
  onChange,
  disabled,
  radioButtonStyle = false,
}) => {

  // States to handle other option
  const [otherOptionEnabled, setOtherOptionEnabled] = useState(false);
  const [otherOptionValue, setOtherOptionValue] = useState('');

  // On option clicked (except for "Other")
  const handleOptionSelected = (selectedValue) => {
    if (disabled) return
    if (multipleSelection) {
      if (!value) {
        onChange([selectedValue])
      } else if (value.includes(selectedValue)) {
        onChange(removeValue(value, selectedValue));
      } else {
        onChange([...value, selectedValue])
      }
    } else {
      setOtherOptionEnabled(false);
      onChange(selectedValue)
    }
  }

  // Check if option selected
  const isSelected = (optionValue) => {
    if (!value) return false;

    if (multipleSelection) {
      return value.includes(optionValue);
    } else {
      return value === optionValue
    }
  }

  // On "Other" option clicked
  const handleOtherOptionClick = () => {
    if (multipleSelection) {
      if (otherOptionEnabled) {
        onChange(removeValue(value, otherOptionValue));
        setOtherOptionEnabled(false);
      } else {
        setOtherOptionEnabled(true);
      }
    } else {
      onChange(otherOptionValue);
      setOtherOptionEnabled(true);
    }
  }

  const handleOtherOptionInputChange = (e) => {
    const newValue = e.target.value;

    if (multipleSelection) {
      if (!value) {
        onChange([newValue])
      } else {
        const otherOptionIndex = value.indexOf(otherOptionValue);
        if (otherOptionIndex < 0) {
          onChange([...value, newValue])
        } else {
          onChange([...value.slice(0, otherOptionIndex), newValue, ...value.slice(otherOptionIndex + 1)])
        }
      }
    } else {
      onChange(newValue)
    }

    setOtherOptionValue(newValue)
  }

  return (
    <div className={cn(styles.root, { [styles.rootRadioButton]: radioButtonStyle })}>
      {options?.map(option => (
        <Option
          name={option.name}
          key={option.value}
          value={option.value}
          selected={isSelected(option.value)}
          multipleSelection={multipleSelection}
          onClick={handleOptionSelected}
          disabled={disabled}
        />
      ))}
      {otherOption &&
        <div className={cn(styles.option, styles.otherOption, { [styles.optionSelected]: otherOptionEnabled })}>
          <div className={styles.title} onClick={handleOtherOptionClick}>
            {multipleSelection ?
              <div className={styles.checkbox}><FeatherIcon className={styles.checkIcon} icon='check' size={13} /></div> :
              <div className={styles.radioButton}></div>
            }
            Other
          </div>
          {otherOptionEnabled &&
            <Input className={styles.input} inputProps={{ value: otherOptionValue, onChange: handleOtherOptionInputChange }} />
          }
        </div>
      }
    </div>
  )
}

const Option = ({
  name,
  value,
  selected = false,
  multipleSelection = false,
  onClick,
  disabled
}) => (
  <div className={cn(styles.option, { [styles.optionSelected]: selected, [styles.disabled]: disabled })} onClick={() => onClick(value)}>
    {multipleSelection ?
      <div className={styles.checkbox}><FeatherIcon className={styles.checkIcon} icon='check' size={13} /></div> :
      <div className={styles.radioButton}></div>
    }
    {name || value}
  </div>
)