export const Partners = {
  Snaphire: 1,
  JobAdder: 2,
  JazzHR: 4,
  Teamtailor: 5,
  Onboarded: 6,
  Bullhorn: 7,
  Lever: 8,
  UKG: 9,
  AccountApi: 10,
  SmartRecruiters: 12
}

export const PartnersHuman = {
  0: '-',
  1: 'Snaphire',
  2: 'Job Adder',
  4: 'JazzHR',
  5: 'TeamTailor',
  6: 'Onboarded',
  7: 'Bullhorn',
  8: 'Lever',
  9: 'UKG',
  10: 'Account',
  11: 'BackyCheck',
  12: 'SmartRecruiters'
}

export const PartnersCodes = {
  AccountApi: 'account_api',
  TAS: 'tas',
  JobAdder: 'job_adder',
  JazzHR: 'jazzhr',
  Teamtailor: 'teamtailor',
  Bullhorn: 'bullhorn',
  Onboarded: 'onboarded',
  Lever: 'lever',
  UKG: 'ukg',
  KnowMy: 'knowmy',
  BambooHR: 'bambooHR',
  PageUp: 'pageup',
  Livehire: 'livehire',
  SmartRecruiters: 'smartrecruiters'
}

export const PartnersEnum = {
  1: PartnersCodes.TAS,
  2: PartnersCodes.JobAdder,
  4: PartnersCodes.JazzHR,
  5: PartnersCodes.Teamtailor,
  6: PartnersCodes.Onboarded,
  7: PartnersCodes.Bullhorn,
  8: PartnersCodes.Lever,
  9: PartnersCodes.UKG,
  10: PartnersCodes.AccountApi,
  11: PartnersCodes.PageUp,
  12: PartnersCodes.SmartRecruiters
}
