import { useEffect, useRef, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { IconButton } from 'components/IconButton/IconButton';
import { QuestionsAnswers } from './components/QuestionsAnswers/QuestionsAnswers';
import { ReportHeader } from './components/ReportHeader/ReportHeader';
import { ReportStaticHeader } from './components/ReportStaticHeader/ReportStaticHeader';
import { ReportSummary } from './components/ReportSummary/ReportSummary';
import styles from './RefereeReport.module.scss';

export const RefereeReport = ({
  form,
  candidate,
  referee,
  company,
  isStatic,
  referenceCheck,
  answers,
  empty,
  onDownloadClick      = () => {},
  downloadQuestionFile = () => {},
}) => {

  const topRef = useRef();

  const [tabKey, setTabKey] = useState(2);

  useEffect(() => { if(form?.intelligent) setTabKey(1) }, [form])

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  return (
    <div className={styles.root}>
      <div ref={topRef} className={styles.topRef} />
      {!empty &&
        <div className="content">
          {isStatic ? 
            <ReportStaticHeader referee={referee} candidate={candidate} company={company}/> : 
            <ReportHeader referee={referee} referenceCheck={referenceCheck} candidate={candidate} empty={empty} onDownloadClick={() => onDownloadClick(referee.id)}/>
          }
          <Tabs onSelect={setTabKey} activeKey={tabKey} defaultActiveKey={1} className="tabs">
            {form?.intelligent &&
              <Tab eventKey={1} title="Summary">
                <ReportSummary referee={referee} candidate={candidate} answers={answers}/>
              </Tab>
            }
            <Tab eventKey={2} title="Questions">
              <QuestionsAnswers form={form} referee={referee} candidate={candidate} answers={answers} downloadQuestionFile={downloadQuestionFile}/>
            </Tab>
          </Tabs>
        </div>
      }
      <IconButton className={styles.topButton} icon='arrow-up' tip='Back to the top' onClick={scrollToTop}/>
    </div>
  );

}
