import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Button.module.scss';
import FeatherIcon from 'feather-icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const Button = ({
  id,
  className,
  type = 'primary',
  submit = false,
  small = false,
  style = null,
  icon,
  disabled = false,
  children,
  buttonProps = {},
  tip,
  placement = 'bottom',
  onClick = () => {}
}) => (
  <OverlayTrigger trigger={tip === undefined ? 'none' : ['hover', 'focus']} placement={placement} overlay={<Tooltip>{tip}</Tooltip>}>
    <button 
      id={id}
      className={cn(styles.root, styles[`root--${type}`], {
        [styles.rootSmall]: small,
        [className]: className,
      })}
      type={submit ? 'submit' : 'button'}
      disabled={disabled} 
      onClick={onClick}
      style={style}
      {...buttonProps}
    >
      {icon && <FeatherIcon className={styles.icon} icon={icon} />}
      {children}
    </button>
  </OverlayTrigger>
)

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'outline', 'secondary', 'secondary-reverse', 'link', 'success', 'delete', 'warn']),
  small: PropTypes.bool,
  style: PropTypes.object,
  icon: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  buttonProps: PropTypes.object,
};