import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, Input, InputGroup, PhoneNumberPicker } from 'components/FormComponents';
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

export const CandidateUpdateModal = ({
  candidate,
  visible, 
  onClose   = () => {},
  onSubmit  = () => {}
}) => {

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm({ shouldUnregister: true });

  useEffect(() => { reset(candidate) }, [candidate])

  return (
    <AnimatedModal visible={visible} onClose={onClose} title='Update candidate'>
      <form style={{ width: '400px'}} className='u-margin-top' onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title='First Name'>
          <Input 
            name='first_name'
            register={register}
            validators={{ required: true }}
            error={errors?.first_name?.type}
            inputProps={{ tabIndex: 1 }}
          />
        </InputGroup>
        <InputGroup title='Last Name'>
          <Input 
            name='last_name'
            register={register}
            validators={{ required: true }}
            error={errors?.last_name?.type}
            inputProps={{ tabIndex: 2 }}
          />
        </InputGroup>
        <InputGroup title='Email'>
          <Input 
            name='email'
            register={register}
            validators={{ required: true }}
            error={errors?.email?.type}
            inputProps={{ tabIndex: 3 }}
          />
        </InputGroup>
        <InputGroup title='Contact Number' className='u-margin-bottom--large'>
          <Controller
            control={control}
            name='contact_number'
            render={({ field: { onChange, value } }) => (
              <PhoneNumberPicker value={value} onChange={onChange} tabIndex={4} />
            )}
          />
        </InputGroup>
        <InputGroup title='Job Title' className='u-margin-bottom--large'>
          <Input 
            name='job_role'
            placeholder='Mastermind'
            register={register}
            inputProps={{ tabIndex: 5 }}
          />
        </InputGroup>
        <InputGroup title='Department' tooltip='Previously team' className='u-margin-bottom--large'>
          <Input 
            name='team_name'
            placeholder='Oceans 11'
            register={register}
            inputProps={{ tabIndex: 6 }}
          />
        </InputGroup>
        <InputGroup title='Reference Code' className='u-margin-bottom--large'>
          <Input 
            name='reference_code'
            placeholder='REFCODE12'
            register={register}
            inputProps={{ tabIndex: 7 }}
          />
        </InputGroup>
        <Button className="u-width-100 u-margin-top" submit>Update</Button>
      </form>
    </AnimatedModal>
  )

}