import { Integrations } from 'constants/integrations';
import styles from './IntegrationsSelector.module.scss';

export const IntegrationsSelector = ({ 
  companyIntegrations, 
  onSelect = () => {}
}) => {
  if(!companyIntegrations) return null;

  return (
    Integrations.map(integration => {
      if(!companyIntegrations[integration.code]) return;
      else return (
        <div key={integration.code} className={styles.integration} onClick={() => onSelect(integration.code)}>
          <img src={integration.logo} className={styles.logo} />
          <h1 className={styles.name}>{integration.name}</h1>
        </div>
      )
    })
  )
}