import { useEffect, useState } from 'react';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { CustomButton } from '../components/CustomButton/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { getAmlResult, selectCandidateApplication } from 'api/candidate-application-slice';

const CandidateElVerificationCheck = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { application: { candidate_checks, fetched } } = useSelector(selectCandidateApplication);

  const { token, code } = useParams();
  const [ loading, setLoading ] = useState(true);
  const [ emlCheck, setEmlCheck ] = useState(null);

   // Set verification checks
   useEffect(() => {
    if(!code) {
      setEmlCheck(candidate_checks.find(check => check.type === 'el_verification'))
    }
  }, [fetched])


  const handleAmlVerification = async (id) => {
    const { payload: result } = await dispatch(getAmlResult(id));
    setLoading(false);
    window.open(result.url,"_self");
  }

  // On finished loading, init
  useEffect(() => {
    if(emlCheck) {
      handleAmlVerification(emlCheck.id);
    };
  }, [emlCheck, loading])

  const handleClose = () => {
    navigate(`/form_submission/candidate/menu/${token}`);
  }

  return ( 
    <CandidatePage token={token}>
      <CandidatePage.View>
        {code &&
          <CandidatePage.Card className={cn('d-flex', 'flex-column', 'align-items-center')}>
            <h3>Thank you for completing your verification</h3>
            <p className="u-text-centered u-margin-top">
              Your AML Identity Verification has been successfully submitted.
            </p>
            <CustomButton small onClick={handleClose}>Close</CustomButton>
          </CandidatePage.Card>
        }
      </CandidatePage.View>
    </CandidatePage>
  )
}

export default CandidateElVerificationCheck