import styles from './Checkbox.module.scss';
import cn from 'classnames';
import { useState } from 'react';
import { useEffect } from 'react';

export const Checkbox = ({
  label,
  name,
  className,
  validators = {},
  value,
  checked,
  register = () => {},
  onClick  = () => {},
}) => {
  const [style, setStyle] = useState(false);

  const handleClick = () => {
    onClick(!style)
    setStyle(!style);
  }
  useEffect(() => {
    setStyle(checked);
  }, [checked])

  return (
    <div className={cn('d-flex', 'align-items-center', 'u-padding-bottom--small', 'u-padding-top--small', {[className]: className} )}>
      <input
        type="checkbox"
        name={name}
        onClick={handleClick}
        value={value}
        className={cn(style ? styles.checkboxActive : styles.checkbox)}
        {...register(name, validators)}
      />
      <span className='t-small u-padding-left--small text-color-primary'>{label}</span>
    </div>
  )
}