import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import VerificationCard from '../VerificationCard/VerificationCard';
import LinkShare from 'components/LinkShare/LinkShare';
import { IconButton } from 'components/IconButton/IconButton';
import FeatherIcon from 'feather-icons-react';
import InputValidator from 'helpers/input-validator';
import { RefereeMetadataDetailsModal } from '../RefereeMetadataDetailsModal/RefereeMetadataDetailsModal';
import styles from './ReportHeader.module.scss';

export const ReportHeader = ({
  candidate,
  referee,
  referenceCheck,
  empty,
  onDownloadClick = () => {}
}) => {

  const [link, setLink] = useState();
  const [linkOpen, setLinkOpen] = useState(false);
  const [showMetadataDetails, setShowMetadataDetails] = useState(false);

  const [companyMailValidator, setCompanyMailValidator] = useState(); 
  const [linkedinValidator, setLinkedinValidator] = useState();
  const [ipValidator, setIpValidator] = useState();

  useEffect(() => {
    if(!empty) setLink(`${process.env.REACT_APP_WEB_URL}/company/report/referee/${referee?.token}`)
  }, [empty, referee])

  useEffect(() => {
    if(!referee) return; 

    if(referee.email === candidate.email) {
      setCompanyMailValidator(<div className="verification error"><FeatherIcon icon="alert-circle"/> Email addresses match</div>);
    } else if (InputValidator.CompanyEmailValidator(referee.email)) {
      setCompanyMailValidator(<div className="verification success"><FeatherIcon icon="check-circle"/> Registered Company Email</div>);
    } else {
      setCompanyMailValidator(<div className="verification warn"><FeatherIcon icon="alert-circle"/> Non company mail</div>);
    }

    if(referee.linkedin_profile) {
      setLinkedinValidator(<div className="verification success"><FeatherIcon icon="check-circle"/> Verified Linkedin</div>);
    } else {
      setLinkedinValidator(<div className="verification warn"><FeatherIcon icon="alert-circle"/> No information provided</div>);
    }

    if(referee.ip !== candidate.ip) {
      setIpValidator(<div className="verification success"><FeatherIcon icon="check-circle"/> Unique IP Address</div>);
    } else {
      setIpValidator(<div className="verification error"><FeatherIcon icon="alert-circle"/> IP address matches candidate</div>);
    }
  }, [referee])

  return (
    <div className={styles.root}>
      <RefereeMetadataDetailsModal 
        referee={referee} 
        referenceCheck={referenceCheck} 
        visible={showMetadataDetails} 
        onClose={() => setShowMetadataDetails(false)}
      />
      <div className={styles.titles}>
        <div className={styles.name}>
          <div className={styles.label}>Referee</div>
          <div className={styles.value}>{referee.name}</div>
          <div className={styles.details}>{referee.role} @ {referee.company_name}</div>
          {referee.answered_on_behalf_user && 
            <div className={styles.details}>Answered on behalf of by: {referee.answered_on_behalf_user}</div>
          }
        </div>
        <div>
          <div className="d-flex align-items-center">
            {referee.user_agent && <IconButton icon='monitor' tip='Metadata details' onClick={() => setShowMetadataDetails(true)} className='u-margin-right--small'/>}
            <LinkShare open={linkOpen} link={link} title="Share this report" onClose={() => setLinkOpen(false)}/>
            <IconButton icon='share-2' tip='Link' onClick={() => setLinkOpen(true)} className='u-margin-right--small'/>
            <IconButton icon='download' tip='Download' onClick={onDownloadClick} />
          </div>
        </div>
      </div>
      <div className={styles.verificationCards}>
        <VerificationCard>
          <div className="title"><FeatherIcon icon="mail"/> email address</div>
          <a href={"mailto:"+referee.email}>{referee.email}</a>
          {companyMailValidator}
        </VerificationCard>
        <VerificationCard>
          <div className="title"><FontAwesomeIcon icon={faLinkedin} size="2x"/> linkedin profile</div>
          <a href={"mailto:"+referee.linkedin_profile}>{referee.linkedin_profile ? referee.linkedin_profile : '-'}</a>
          {linkedinValidator}
        </VerificationCard>
        <VerificationCard>
          <div className="title"><FeatherIcon icon="shield"/> ip address</div>
          <div className="static-value">{referee.ip}</div>
          {ipValidator}
        </VerificationCard>
      </div>
    </div>
  )
}