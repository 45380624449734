export const useClipboard = (ref) => {

  const copyToClipboard = () => {
    if(!ref.current) return;

    ref.current.select();
    document.execCommand("copy");
  }

  return copyToClipboard;

}