import { useEffect } from 'react';

export const useLinkedinCallback = (callback = () => {}, dependencies = []) => {

  useEffect(() => {
    const handleLinkedinCallback = (event) => {
      if(event?.data['from'] && event?.data['for'] === 'checkmate' && event?.data['from'] === 'linkedin') {
        callback(event.data['code']);
      }
    } 

    window.addEventListener('message', handleLinkedinCallback);
    return () => window.removeEventListener("message", handleLinkedinCallback);
  }, dependencies);
}