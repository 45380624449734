import { useForm, Controller } from 'react-hook-form';
import { InputGroup, Input, PhoneNumberPicker } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import { Button } from 'components/FormComponents';
import cn from 'classnames';

export const NewRefereeForm = ({
  company,
  onSubmit = () => {},
  onClose  = () => {}
}) => {

  const { register, control, handleSubmit, formState: { errors } } = useForm();

  return (
    <div className={cn('card', 'card-with-border')}>
      <div className={cn('u-padding', 'd-flex', 'justify-content-between', 'align-items-center')}>
        <div className='title-2'>New Referee</div>
        <IconButton icon='x' tip='Close' className='u-margin-right' onClick={onClose}/>
      </div>
      <hr className='divider u-margin-0' />
      <form className='u-padding' onSubmit={handleSubmit(onSubmit)}>
        <div className='inline u-margin-bottom'>
          <InputGroup title='First Name'>
            <Input 
              name='first_name'
              placeholder='e.g. Brad'
              register={register}
              validators={{ required: true }}
              error={errors?.first_name?.type}
              inputProps={{ tabIndex: 1 }}
            />
          </InputGroup>
          <InputGroup title='Last Name'>
            <Input 
              name='last_name'
              placeholder='e.g. Pitt'
              register={register}
              validators={{ required: true }}
              error={errors?.last_name?.type}
              inputProps={{ tabIndex: 2 }}
            />
          </InputGroup>
        </div>
        <InputGroup title='Email Address' className='u-margin-bottom--large'>
          <Input 
            name='email'
            placeholder='e.g. brad@checkmate.tech'
            register={register}
            validators={{ required: true }}
            error={errors?.email?.type}
            inputProps={{ tabIndex: 3 }}
          />
        </InputGroup>

        <InputGroup title='Contact Number' className='u-margin-bottom--large'>
          <Controller
            control={control}
            name='contact_number'
            render={({ field: { onChange, value } }) => (
              <PhoneNumberPicker 
                value={value} 
                onChange={onChange} 
                tabIndex={4} 
                defaultCountry={company?.country}
                error={errors?.contact_number?.type}
              />
            )}
          />
        </InputGroup>
        <InputGroup title='Where did referee and candidate work together?' className='u-margin-bottom--large'>
          <Input 
            name='company_name'
            placeholder='e.g. Checkmate'
            register={register}
            error={errors?.company_name?.type}
            validators={{ required: true }}
            inputProps={{ tabIndex: 5 }}
          />
        </InputGroup>
        <InputGroup title='Referee relationship with candidate' className='u-margin-bottom--large'>
          <Input 
            name='role'
            placeholder='e.g. General Manager'
            register={register}
            validators={{ required: true }}
            inputProps={{ tabIndex: 6 }}
            error={errors?.role?.type}
          />
        </InputGroup>
        <InputGroup title='Relationship length' className='u-margin-bottom--large'>
          <Input 
            name='working_period'
            placeholder='e.g. 2 years'
            register={register}
            validators={{ required: true }}
            inputProps={{ tabIndex: 7 }}
            error={errors?.working_period?.type}
          />
        </InputGroup>
        <InputGroup title="Candidate's role while working with referee" className='u-margin-bottom--large'>
          <Input 
            name='candidate_role'
            placeholder='e.g. Developer'
            register={register}
            validators={{ required: true }}
            inputProps={{ tabIndex: 8 }}
            error={errors?.candidate_role?.type}
          />
        </InputGroup>
        <InputGroup title="Reason why candidate left" className='u-margin-bottom--large'>
          <Input 
            name='reason_for_leaving'
            placeholder='e.g. Found a new opportunity'
            register={register}
            validators={{ required: true }}
            inputProps={{ tabIndex: 9 }}
            error={errors?.reason_for_leaving?.type}
          />
        </InputGroup>

        <Button large className="u-width-100" submit>Save</Button>
      </form>
    </div>
  )
}
