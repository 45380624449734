export function errorRouter(error){
  var message = error.error && error.error.message ? error.error.message : 'Unknown'
  switch(error.status){
    case 500: {
      return '/checkmate_error?message=' + message;
    }
    case 404: {
      return '/not_found_error?message=' + message;
    }
    case 401: {
      return '/sign_in';
    }
    case 400: {
      return '/bad_request?message=' + message;
    }
    default:{
      return '/checkmate_error?message=' + message;
    }
  }
}
