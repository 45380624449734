import styles from './ToggleSwitch.module.scss';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';

export const ToggleSwitch = ({
  label,
  name,
  className,
  tooltip,
  inputProps,
  disabled=false,
  register = () => {}
}) => {

  return (
    <div className={cn(styles.root, { [className]: className })}>
      {label && 
        <label>
          {label}
          {tooltip &&
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{tooltip}</Tooltip>}>
              <div className={styles.tooltip}><FeatherIcon icon="help-circle"/></div>
            </OverlayTrigger>
          }
        </label>
      }
      <div className={styles.slider}>
        <input disabled={disabled} type='checkbox' {...register(name)} {...inputProps} /> 
        <span></span>
      </div>
    </div>
  )
}

ToggleSwitch.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.object
};