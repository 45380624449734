import cn from 'classnames';
import { Select, Button } from 'components/FormComponents';
import { useEffect, useState } from 'react';
import styles from './RoleUsersTab.module.scss';
import { IconButton } from 'components/IconButton/IconButton';
import { useFieldArray } from 'react-hook-form';

export const RoleUsersTab = ({
  control,
  role,
  users = [],
}) => {

  const { fields: userFields, append: appendUser, remove: removeUser } = useFieldArray({ control, name: `users`, keyName: 'key' });

  const [selectedUserId, setSelectedUserId] = useState();
  const [usersNotInRole, setUsersNotInRole] = useState([]);

  useEffect(() => {   
    const userIds = role.users;
    setUsersNotInRole(users.filter(user => !userIds?.includes(user.id)))
  }, [role])

  const addUser = async() => {
    appendUser({id: selectedUserId});
    setUsersNotInRole(usersNotInRole.filter(user => user.id !== selectedUserId));
    setSelectedUserId(undefined);
  }

  const deleteUser = async(userId, index) => {
    removeUser(index);
    const user = users.find(user => user.id === userId);
    setUsersNotInRole([...usersNotInRole, user]);
  }

  return (
    <div className='background-secondary u-padding-top'>
      <div className={cn('card', 'card-with-border', 'u-margin-x', styles.userSelector)}>
        <Select 
          inputProps={{ value: selectedUserId, onChange: (e) => setSelectedUserId(e.target.value) }}
          value={selectedUserId}
          placeholder='Select user..'
          className='u-margin-right--large'
        >
          {usersNotInRole.map(user => (
            <Select.Item key={user.id} value={user.id}>{user.name}</Select.Item>
          ))}
        </Select>
        <Button disabled={!selectedUserId} icon='user-plus' onClick={addUser}>Add</Button>
      </div>
      <div className={cn(styles.usersHeader)}>
        <div className={styles.usersHeaderContent}>
          <div>Name</div> 
          <div>Email</div>
        </div>
      </div>
      <div className={styles.userRows}>
        {userFields.map((userField, index) => {
          const user = users.find(user => user.id === userField.id);
          return (
            <div key={userField.id} className={cn('card', 'card-with-border', 'u-margin-x', styles.userRow)}>
              <div className={styles.column}>{user.name}</div>
              <div className={styles.column}>{user.email}</div>
              <div className={styles.column}><IconButton icon='trash-2' tip='Remove user from role' onClick={() => deleteUser(user.id, index)} /></div>
            </div>
          )
        })}
        {userFields.length < 1 &&
          <div className={cn('u-text-centered', 'text-secondary')}>There are currently no users assigned to this role...</div>
        }
      </div>
    </div>
  )
}