import { useEffect, useState } from 'react';

export const useTimer = (seconds, callback = () => {}) => {

  const [timer, setTimer] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (active) {
      interval = setInterval(() => {
        const newTimer = timer - 1;
        if(newTimer <= 0) setActive(false);
        else setTimer(newTimer);
      }, 1000);
    } else {
      callback();
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [active]);

  return () => {
    setActive(true);
    setTimer(seconds);
  }
}