import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Select, InputGroup, Button, Checkbox } from 'components/FormComponents';
import styles from './DataProtection.module.scss';
import cn from 'classnames';

export const DataProtection = ({ 
  company,
  setLoading    = () => {},
  updateCompany = () => {}
}) => {

  const { handleSubmit, reset, register, control } = useForm();

  const dataRetentionDaysValue = useWatch({ control, name: 'data_retention_days' });
  const totalDeletionValue = useWatch({ control, name: 'data_retention_total_deletion' });

  const onSubmit = async(data) => {
    setLoading(true);
    await updateCompany(data)
    setLoading(false);
  }

  useEffect(() => {
    if(!company) return;
    
    reset({
      data_retention_days: company.data_retention_days,
      data_retention_total_deletion: company.data_retention_total_deletion
    })
  }, [company])

  return (
    <form className={styles.root} onSubmit={handleSubmit(onSubmit)}>
      <div className={cn('card--medium', 'card', 'card-with-border')}>
        <div className='u-padding--large u-divider-bottom-border'>
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <h1 className='title-2'>Data Protection</h1>
          </div>
          <p className='t-small'>Control how Checkmate manages, and retains your candidate and check result data.</p>
        </div>
        <div className={cn(styles.content, 'background-secondary', 'u-padding', styles.body)}>
          <div className={cn('card', 'card-with-border', 'u-padding')}>
            <div>
              <p>All candidate identification and personal information is automatically deleted after 90 days. This cannot be changed.</p>
              <p>Checkmate will retain a record of a check being completed with the candidates first and last name along with a date recorded for the purposes of ongoing monitoring and renewals, however the report file will be deleted.</p>
              <Checkbox checked={totalDeletionValue} name='data_retention_total_deletion' register={register} label={<span className='u-bold'>Don't keep any candidate information</span>} /> 
              <InputGroup title='I would like all reports containing personal information (including Reference Checks and Backgrounds Checks) to be permanently deleted from Checkmate after:'>
                <Select name='data_retention_days' register={register} value={dataRetentionDaysValue}>
                  <Select.Item value={30}>30 days</Select.Item>
                  <Select.Item value={90}>90 days</Select.Item>
                  <Select.Item value={180}>180 days</Select.Item>
                  <Select.Item value={365}>12 months</Select.Item>
                  <Select.Item value={540}>18 months</Select.Item>
                </Select>
              </InputGroup>
              <p>
                It is your responsibility as the employer to ensure you are only retaining information that is necessary for the purposes of employment and aligns with your local privacy legislation. If you have any questions or concerns please contact our team team@checkmate.tech.
              </p>
            </div>
          </div>
        </div>
        <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-end')}>
          <Button submit>Agreed & Save</Button>
        </div>
      </div>
    </form>
  )
}