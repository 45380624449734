import { useState } from 'react';	
import { updateCandidateCheck, selectCandidateApplication, submitCandidateApplication } from 'api/candidate-application-slice';	
import { CandidateCheckScreens } from 'constants/candidate_screens';	
import CandidatePage from 'layouts/CandidatePage/CandidatePage';	
import { StepByStepGuide } from 'components/StepByStepGuide/StepByStepGuide';	
import styles from './CandidateAcc.module.scss';	
import { now } from 'helpers/date';	
import cn from 'classnames';	
import { useNavigate, useParams } from 'react-router-dom';	
import { useDispatch, useSelector } from 'react-redux';	
import { useUserAgent } from 'hooks/user-agent';	

const CandidateAcc = () => {	

  const navigate = useNavigate();	
  const dispatch = useDispatch();	

  const { token } = useParams();	
  const { user_agent } = useUserAgent();	

  const { application: { candidate, brand, candidate_checks } } = useSelector(selectCandidateApplication);	

  const [loading, setLoading] = useState(false);	

  // Complete assessment	
  const markAsCompleted = async () => {	
    setLoading(true);	
    const acc_check = candidate_checks.find(check => check.type === 'acc');	
    await dispatch(updateCandidateCheck({ token, id: acc_check.id, data: { submitted_at: now() }}));	

    if (candidate_checks.length === 1) {	
      await dispatch(submitCandidateApplication({ token, user_agent }));	
      setLoading(false);	
      navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);	
    } else {	
      setLoading(false);	
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);	
    }	
  }	

  return (	
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand} title="ACC Claim Report History">	
      <StepByStepGuide withNavigationButtons={true} onCompleted={markAsCompleted} brand={brand}>	
        <StepByStepGuide.Step>	
          <div className={styles.step}>	
            <h1 styles={styles.mainTitle}>👋 Hi {candidate.first_name}</h1>	
            <p className={cn(styles.p18, 'u-margin-top')}>To get started, you'll need to log in to your personal MyACC account and request the report. Enter checks@checkmate.tech under the employer email address where the report should be sent. Follow the instructions below and we'll guide you through the process.</p>	
            <div className={cn(styles.card, 'u-margin-top--large')}>	
              <h1 className={styles.title}>Step 1: Sign in or create your MyACC account</h1>	
              <p className={cn(styles.p16, 'u-margin-top--small')}>Click the log in or sign up buttons below to get started.	
                <br /><br />	
                Once you’ve either logged in, or signed up to your account, then click the ‘next step’ button below.</p>	
              <div className='u-margin-top u-flex-align-center u-width-100'>	
                <div className={styles.accLinkWrapper}>	
                  <a className={styles.accLink} href="https://my.acc.co.nz/login" target="_blank">	
                    Log into	
                    <img src="assets/images/icons/acc_logo.svg" />	
                  </a>	
                  <a className={styles.accLink} href="https://www.acc.co.nz/register-for-myacc-and-generate-a-pre-employment-report/" target="_blank">	
                    Sign up to My	
                    <img src="assets/images/icons/acc_logo.svg" />	
                  </a>	
                </div>	
              </div>	
            </div>	
          </div>	
        </StepByStepGuide.Step>	
        <StepByStepGuide.Step>	
          <div className={styles.step}>	
            <div className={styles.card}>	
              <h1 className={styles.title}>Step 2: Click on the ‘Injuries’ tab in the main menu</h1>	
              <p className={cn(styles.p16, 'u-margin-top--small')}>Once you’ve accessed your account, click the ‘Injuries’ tab at the top of the page in the main menu, then click the ‘next step’ button below.</p>	
              <img src="assets/images/acc_step_2.png" className="u-width-100 u-margin-top--small" />	
            </div>	
          </div>	
        </StepByStepGuide.Step>	
        <StepByStepGuide.Step>	
          <div className={styles.step}>	
            <div className={styles.card}>	
              <h1 className={styles.title}>Step 3: Click on the ‘Create pre-employment check’ text</h1>	
              <p className={cn(styles.p16, 'u-margin-top--small')}>Scroll down the page until you get to the Pre-employment section, then click on the ‘Create pre-employment check’ text. Then click the ‘next step’ button below.</p>	
              <img src="assets/images/acc_step_3.png" className="u-width-100 u-margin-top--small" />	

            </div>	
          </div>	
        </StepByStepGuide.Step>	
        <StepByStepGuide.Step>	
          <div className={styles.step}>	
            <div className={styles.card}>	
              <h1 className={styles.title}>Step 4: Email the pre-employment check to ‘checks@checkmate.tech’</h1>	
              <p className={cn(styles.p16, 'u-margin-top--small')}>Enter ‘checks@checkmate.tech’ into the email address input field, confirm that you’ve reviewed your claims and that you consent to all this information being sent as a pre-employment check to the email address provided and click send. Then press the ‘next step’ button below.</p>	
              <img src="assets/images/acc_step_4.png" className="u-width-100 u-margin-top--small" />	
            </div>	
          </div>	
        </StepByStepGuide.Step>	
        <StepByStepGuide.Step>	
          <div className={styles.step}>	
            <div className={styles.card}>	
              <h1 className={styles.title}>😎 You’re all done</h1>	
              <p className={cn(styles.p16, 'u-margin-top--small')}>	
                Thanks for your time. If you have any questions about the process, please don’t hesitate to reach out to <a href="team@checkmate.tech" className={cn(styles.email, 'u-bold')}>team@checkmate.tech</a>	
              </p>	
            </div>	
          </div>	
        </StepByStepGuide.Step>	
      </StepByStepGuide>	
    </CandidatePage>	
  )	
}	

export default CandidateAcc