import { CombinedQuestionAnswerVerificationRow } from '../CombinedQuestionAnswerVerificationRow/CombinedQuestionAnswerVerificationRow';
import { CombinedQuestionAnswers } from '../CombinedQuestionAnswers/CombinedQuestionAnswers';
import { CombinedQuestionSkill } from '../CombinedQuestionSkill/CombinedQuestionSkill';
import styles from './CombinedQuestionsAnswers.module.scss';
import { useEffect, useState } from 'react';

const VALIDATION_QUESTIONS = [
  'Where did you work together?',
  'What was your relationship?',
  'How long did you work together?',
  "What was {{candidate}}'s role?",
  'Why did {{candidate}} finish their employment?'
]

export const CombinedQuestionsAnswers = ({
  form,
  responses,
  referees,
  candidate
}) => {

  const [validationQuestions, setValidationQuestions] = useState([]);
  const [performanceQuestions, setPerformanceQuestions] = useState([]);
  const [skillsQuestions, setSkillsQuestions] = useState([]);
  const [complianceQuestions, setComplianceQuestions] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);

  const [refereesNames, setRefereesNames] = useState([]);


  const getIgnoredQuestions = () => {
    let ignoredQuestions = [];
    if(form.logic_jumps.length > 0){
      const expressionJumps = form.logic_jumps.filter(logic_jump => logic_jump.operator !== 'always');
      const expressionsElse = form.logic_jumps.filter(logic_jump => logic_jump.operator === 'always');
      expressionJumps.forEach(expressionJump => {
        let trueExpression = false;
        referees.forEach(referee => {
          const answers = responses[referee.id]['answers'];
          switch(expressionJump.operator){
            case 'is':
              if(expressionJump.value === answers[expressionJump.field])
                trueExpression = true;
              break;
            case 'lower_than':
              trueExpression = (answers[expressionJump.field] < expressionJump.value);
              break;
            case 'greater_equal_than':
              trueExpression = (answers[expressionJump.field] >= expressionJump.value);
              break;
            default:
              break;
          }
        })

        const elseOrder = expressionsElse.find(logic_jump => logic_jump.from === expressionJump.from);

        if(trueExpression) {
          for(let i = expressionJump.to; i < elseOrder.to; i++){ // In case there are multiple conditions for the same question
            if(ignoredQuestions.includes(i)) {
              const indexToRemove = ignoredQuestions.indexOf(i);
              ignoredQuestions.splice(indexToRemove, 1);
            }
          }
        } else {
          for(let i = expressionJump.from + 1; i < elseOrder.to; i++){
            ignoredQuestions.push(i);
          }
        }
      });
    }

    return ignoredQuestions;
  }


  useEffect(() => {
    if(!form || !referees || !responses) return;

    let order = 0;

    // VALIDATION
    let extraQuestion = false;
    let answers = [ [], [], [], [], [], [] ]
    referees.forEach(referee => {
      if(form?.type === 'type:intelligent' && responses[referee.id].answers[5] === 'Asked to leave')
        extraQuestion = true;
      answers[0].push({candidate: referee.company_name, referee: responses[referee.id].answers[1]});
      answers[1].push({candidate: referee.role, referee: responses[referee.id].answers[2]});
      answers[2].push({candidate: referee.working_period, referee: responses[referee.id].answers[3]});
      answers[3].push({candidate: referee.candidate_role, referee: responses[referee.id].answers[4]});
      answers[4].push({candidate: referee.reason_for_leaving, referee: responses[referee.id].answers[5]});
      answers[5].push({referee: responses[referee.id].answers[6] ? responses[referee.id].answers[6] : 'N/A'});
    });

    let validationQuestions = [];

    VALIDATION_QUESTIONS.forEach((validationQuestion, i) => {
      order += 1;
      validationQuestions.push(
        <CombinedQuestionAnswerVerificationRow key={order} question={validationQuestion} order={order} answers={answers[i]} candidateName={candidate.first_name}/>
      );
    })
    
    if(extraQuestion) {
      order += 1;
      validationQuestions.push(
        <CombinedQuestionAnswerVerificationRow key={order} question="Could you please explain why was asked to leave?" order={order} answers={answers[5]} noCandidateRow />
      );
    }
    setValidationQuestions(validationQuestions);

    const ignoredQuestions = getIgnoredQuestions();

    // PERFORMANCE
    const performanceFields = form?.fields.filter(field => field.check_part === 'PERFORMANCE');
    const performanceRows = performanceFields.map(field => {
      if(!ignoredQuestions.includes(field.order)){
        let answers = []
        order++;
        referees.forEach(referee => {
          let answer = responses[referee.id].answers[field.order];
          answers.push({name: referee.first_name, answer: answer ? answer : 'N/A'})
        });
        return <CombinedQuestionAnswers key={field.order} order={order} field={field} answers={answers} candidateName={candidate.first_name}/>
      }
    })
    setPerformanceQuestions(performanceRows);

    // SKILLS
    const skillsFields = form?.fields.filter(field => field.check_part === 'SKILL');
    const skillsRows = skillsFields.map(field => {
      let answers = []
      order++;
      referees.forEach(referee => {
        answers.push({name: referee.first_name, answer: responses[referee.id].answers[field.order]})
      });
      return <CombinedQuestionSkill key={field.order} order={order} field={field} answers={answers}/>
    })
    setSkillsQuestions(skillsRows);

    // COMPLIANCE
    const complianceFields = form?.fields.filter(field => field.check_part === 'COMPLIANCE');
    const complianceRows = complianceFields.map(field => {
      if(!ignoredQuestions.includes(field.order)){
        let answers = []
        order++;
        referees.forEach(referee => {
          let answer = responses[referee.id].answers[field.order];
          answers.push({name: referee.first_name, answer: answer ? answer : 'N/A'})
        });
        return <CombinedQuestionAnswers key={field.order} order={order} field={field} answers={answers} candidateName={candidate.first_name}/>
      }
    })
    setComplianceQuestions(complianceRows);

    // CUSTOM
    const customFields = form?.fields.filter(field => field.check_part === 'CUSTOM');
    const customRows = customFields.map(field => {
      if(!ignoredQuestions.includes(field.order)){
        let answers = []
        order++;
        referees.forEach(referee => {
          let answer = responses[referee.id].answers[field.order];
          answers.push({name: referee.first_name, answer: answer ? answer : 'N/A'})
        });
        return <CombinedQuestionAnswers key={field.order} order={order} field={field} answers={answers} candidateName={candidate.first_name}/>
      }
    })
    setCustomQuestions(customRows);
  }, [form, referees, responses]);

  useEffect(() => {
    if(!referees) return;

    setRefereesNames(referees.map(referee => ({ referee: referee.first_name })))
  }, [referees])

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <CombinedQuestionAnswerVerificationRow header answers={refereesNames} noCandidateRow/>
        { validationQuestions }
      </div>
      { performanceQuestions.length > 0 &&
        <div className={styles.section}>
          <div className={styles.header}>JOB PERFORMANCE</div>
          { performanceQuestions }
        </div>
      }
      { skillsQuestions.length > 0 &&
        <div className={styles.section}>
          <div className={styles.header}>SKILLS</div>
          <div className="u-flex-box u-flex-wrap u-flex-align-stretch u-flex-justify-between">
            { skillsQuestions }
          </div>
        </div>
      }
      { complianceQuestions.length > 0 &&
        <div className={styles.section}>
          <div className={styles.header}>COMPLIANCE</div>
          { complianceQuestions }
        </div>
      }
      { customQuestions.length > 0 &&
        <div className={styles.section}>
          <div className={styles.header}>CUSTOM</div>
          { customQuestions }
        </div>
      }
    </div>
  )
}