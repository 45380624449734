import { AnimatedModal } from "components/AnimatedModal/AnimatedModal";
import { useForm, useWatch } from 'react-hook-form';
import { InputGroup, Input, Button } from "components/FormComponents";
import { useLazyGetSSOAdminPortalUrlQuery } from 'api/company-api';
import styles from './SingleSignOnModal.module.scss';

export const SingleSignOnModal = ({ 
  visible,
  company,
  setLoading            = () => {},
  onClose               = () => {},
  createSSOOrganization = () => {}
}) => {

  const { register, control, formState: { errors }, handleSubmit } = useForm();

  const domain = useWatch({ control, name: 'domain' })

  const [getSSOAdminPortalUrl] = useLazyGetSSOAdminPortalUrlQuery();

  const onSubmit = async(data) => {
    setLoading(true);
    await createSSOOrganization(data.domain)
    setLoading(false);
  }

  const openAdminPortal = async() => {
    setLoading(true);
    const response = await getSSOAdminPortalUrl().unwrap();
    window.location.replace(response.result);
  }

  return (
    <AnimatedModal title='Single Sign On' visible={visible} onClose={onClose}>
      {company.sso_organization_id ? 
        <div className={styles.root}>
          <InputGroup title='Organization ID'>
            <Input disabled={true} inputProps={{ value: company.sso_organization_id }} />
          </InputGroup>
          <InputGroup title='Domain'>
            <Input disabled={true} inputProps={{ value: company.custom_domain }} />
          </InputGroup>
          <Button onClick={openAdminPortal} className="u-width-100 u-margin-top--large">Configure</Button>
        </div> :
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
          <InputGroup title='Domain'>
            <Input name='domain' placeholder='checkmate.tech' register={register} validators={{ required: true }} error={errors?.domain?.type} />
          </InputGroup>
          <Button submit disabled={!domain} className="u-width-100 u-margin-top--large">Submit</Button>
        </form>
    
      }
      
    </AnimatedModal>
  )

} 