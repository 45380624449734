import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";


export const useQueryParams = (keys = []) => {
  const [searchParams, _] = useSearchParams();

  const [queryParams, setQueryParams] = useState([]);

  useEffect(() => {
    setQueryParams(keys.map(key => searchParams.get(key)))
  }, []);

  return queryParams;
}
