import { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { InputGroup, Input, Select, PhoneNumberPicker, ToggleSwitchInput } from 'components/FormComponents';
import { CustomFields } from 'views/Account/tabs/CustomFields/components/CustomFields/CustomFields';
import cn from 'classnames';

export const CandidateDetailsForm = forwardRef(({
  company         = {},
  defaultValues   = {},
  brands          = [],
  teams           = [],
  customFields    = [],
  documents       = [],
  bypass          = false,
  className,
  onSubmit        = () => {},
  setInvalid      = () => {},
  onBrandChanged  = () => {}
}, ref) => {

  const { register, watch, control, setValue, handleSubmit, formState: { errors } } = useForm({ defaultValues, shouldUnregister: true });

  const [ccNotificationEmailsEnabled, setCcNotificationEmailsEnabled] = useState(false)
  const [documentsEnabled, setDocumentsEnabled] = useState(false);

  const [selectedBrand, setSelectedBrand] = useState();
  const brand_id = useWatch({ control, name: 'brand_id' });
  const team_id = useWatch({ control, name: 'team_id' });
  const document_id = useWatch({ control, name: 'document_id' });

  // Set selected brand
  useEffect(() => {
    if(brand_id) {
      const brand = brands.find(brand => brand.id === brand_id);
      setSelectedBrand(brand)
      onBrandChanged(brand)
    } else {
      onBrandChanged(null)
    }
  }, [brand_id])

  // Nullify cc emails if toggled off
  useEffect(() => {
    if(!ccNotificationEmailsEnabled) setValue('cc_notification_emails', '');
  }, [ccNotificationEmailsEnabled])

  // Set validation
  useEffect(() => {
    setInvalid(Object.keys(errors).length > 0)
  }, [errors])

  // Expose functions
  useImperativeHandle(ref, () => ({
    submit: () => { handleSubmit(onSubmit)() }
  }));

  return (
    <form className={cn({ [className]: className })}>
      <div className={cn('card', 'card-with-border', 'u-padding', 'u-margin-bottom')}>
        <div className='inline u-margin-bottom'>
          <InputGroup title='First Name *'>
            <Input 
              name='first_name'
              placeholder='e.g. Brad'
              register={register}
              validators={{ required: true }}
              error={errors?.first_name?.type}
              inputProps={{ tabIndex: 1 }}
            />
          </InputGroup>
          <InputGroup title='Last Name *'>
            <Input 
              name='last_name'
              placeholder='e.g. Pitt'
              register={register}
              validators={{ required: true }}
              error={errors?.last_name?.type}
              inputProps={{ tabIndex: 2 }}
            />
          </InputGroup>
        </div>
        <InputGroup title='Email Address *' className='u-margin-bottom--large'>
          <Input 
            name='email'
            placeholder='e.g. brad@checkmate.tech'
            register={register}
            validators={{ required: true }}
            error={errors?.email?.type}
            inputProps={{ tabIndex: 3 }}
          />
        </InputGroup>
        <InputGroup title='Contact Number' className='u-margin-bottom--large'>
          <Controller
            control={control}
            name='contact_number'
            render={({ field: { onChange, value } }) => (
              <PhoneNumberPicker value={value} onChange={onChange} tabIndex={4} defaultCountry={company?.country} />
            )}
          />
        </InputGroup>
        <InputGroup title='Job Title' className='u-margin-bottom--large'>
          <Input 
            name='job_role'
            placeholder='Mastermind'
            register={register}
            inputProps={{ tabIndex: 5 }}
          />
        </InputGroup>
        <InputGroup title='Department' className='u-margin-bottom--large'>
          <Input 
            name='team_name'
            placeholder='Oceans 11'
            register={register}
            inputProps={{ tabIndex: 6 }}
          />
        </InputGroup>
        <InputGroup title='Reference Code' className='u-margin-bottom--large'>
          <Input 
            name='reference_code'
            placeholder='REFCODE12'
            register={register}
            inputProps={{ tabIndex: 7 }}
          />
        </InputGroup>
        {teams.length > 1 &&
          <InputGroup title='Team' className='u-margin-bottom--large'>
            <Select name='team_id' placeholder='Team' register={register} value={team_id} useDefault>
              {teams.map(team => (
                <Select.Item key={team.id} value={team.id}>{team.name}</Select.Item>
              ))}
            </Select>
          </InputGroup>
        }
        <InputGroup title='Branding' className='u-margin-bottom--large'>
          <Select name='brand_id' placeholder='Brand' register={register} value={brand_id} useDefault>
            {brands.map(brand => (
              <Select.Item key={brand.id} value={brand.id}>{brand.name}</Select.Item>
            ))}
          </Select>
        </InputGroup>
      </div>
      {customFields.length > 0 &&
        <div className={cn('card', 'card-with-border', 'u-padding', 'u-margin-bottom')}>
          <div className="u-width-100">
            <CustomFields fields={customFields} register={register} control={control} watch={watch} />
          </div>
        </div>
      }
      <ToggleSwitchInput
        text="Notification List"
        subtext='Add external emails to get notified about status updates'
        onChange={e => setCcNotificationEmailsEnabled(e.target.checked)}
        value={JSON.parse(ccNotificationEmailsEnabled)}
      >
        <InputGroup title='Emails'>
          <Input 
            name='cc_notification_emails'
            placeholder='email_1@example.com,email_2@example.com'
            register={register}
          />
        </InputGroup>
      </ToggleSwitchInput>
      {(!bypass && selectedBrand?.documents_active && documents.length > 0) &&
        <ToggleSwitchInput
          text="Attach documents"
          subtext='Select a document you wish to send to the candidate'
          onChange={e => setDocumentsEnabled(e.target.checked)}
          value={JSON.parse(documentsEnabled)}
        >
          {documentsEnabled && 
            <InputGroup title='Documents'>
              <Select name='document_id' placeholder='Document' register={register} value={document_id}>
                {documents.map(document => (
                  <Select.Item key={document.id} value={document.id}>{document.file_name}</Select.Item>
                ))}
              </Select>
            </InputGroup>
          }
        </ToggleSwitchInput>
      }
    </form>
  )
})