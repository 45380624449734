import { useState, useEffect } from 'react';
import Alert from 'components/Alert/Alert';
import { Input, PhoneNumberPicker, InputGroup, Button } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import cn from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectCompany } from 'api/company-slice';

export const RefereeDetailsForm = ({
  referee,
  onUpdate      = () => {},
  deleteReferee = () => {},
  onClose       = () => {}
}) => {

  const company = useSelector(selectCompany);

  const { register, reset, control, handleSubmit } = useForm({ defaultValues: referee });
  const [showDeletionAlert, setShowDeletionAlert] = useState(false);

  // Reset candidate details
  useEffect(() => reset(referee), [referee]);

  const onSubmit = async(data) => {
    onUpdate(data, referee.completed);
  }  

   // Delete referee
   const handleDeletion = async() => {
    setShowDeletionAlert(false);
    onClose();
    deleteReferee(referee.id);
  }

  return ( 
    <div className={cn('card', 'card-with-border')}>
      <Alert
        show={showDeletionAlert}
        title={`Are you sure you want to delete ${referee?.first_name}?`}
        message='Referee deletion cannot be undone. Once deleted, we will adjust the number of referees for this candidate'
        ok='Delete'
        onOk={handleDeletion}
        cancel='Cancel'
        onCancel={() => setShowDeletionAlert(false)}
      />
      <div className={cn('u-padding', 'd-flex', 'justify-content-between', 'align-items-center')}>
        <div>
          <div className='t-small'>REFEREE</div>
          <div className='title-2'>{referee.name}</div>
        </div>
        <IconButton icon='trash-2' tip='Delete' className='u-margin-right' onClick={() => setShowDeletionAlert(true)} />
      </div>
      <hr className='divider u-margin-0' />
      <form className='u-padding' onSubmit={handleSubmit(onSubmit)}>
        <div className="inline u-margin-top">
          <InputGroup title='First name'>
            <Input name='first_name' register={register} validators={{ required: true }} disabled={referee.completed} placeholder='John' />
          </InputGroup>
          <InputGroup title='Last name'>
            <Input name='last_name' register={register} validators={{ required: true }} disabled={referee.completed} placeholder='Doe' />
          </InputGroup>
        </div>
        <InputGroup title='Email'>
          <Input name='email' register={register} validators={{ required: true }} placeholder='john@checkmate.com' />
        </InputGroup>
        <InputGroup title='Form'>
          <Input name='form_name' register={register} disabled={true} />
        </InputGroup>
        <InputGroup title='Contact Number'>
          <Controller
            control={control}
            name='contact_number'
            render={({ field: { onChange, value } }) => (
              <PhoneNumberPicker value={value} onChange={onChange} disabled={referee.completed} defaultCountry={company?.country} />
            )}
          />
        </InputGroup>
        <InputGroup title='Where did referee and candidate work together?'>
          <Input name='company_name' register={register} placeholder='Checkmate' disabled={referee.completed} />
        </InputGroup>
        <InputGroup title='Referee relationship with candidate'>
          <Input name='role' register={register} placeholder='Manager' disabled={referee.completed} />
        </InputGroup>
        <InputGroup title='Relationship length'>
          <Input name='working_period' register={register} placeholder='2 years' disabled={referee.completed} />
        </InputGroup>

        <Button className="u-width-100" submit>Update referee</Button>
      </form>
    </div> 
  ) 

}