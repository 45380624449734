import { Select, DatePicker, InputGroup } from 'components/FormComponents';
import { useEffect, useState } from 'react';
import { useWatch, Controller } from 'react-hook-form';
import { FieldTypes } from 'constants/field-types-enum';
import { countries } from 'constants/countries';
import { operatorTypes, typesWithLogic } from 'constants/logic';

export const LogicSettings = ({ 
  index,
  register,
  control,
  fields,
  blockBefore
}) => {

  const fieldName = `logic_jumps.${index}`;

  const fieldValue = useWatch({ control, name: `${fieldName}.field` });
  const operatorValue = useWatch({ control, name: `${fieldName}.operator` });
  const value = useWatch({ control, name: `${fieldName}.value` });
  const fromValue = useWatch({ control, name: `${fieldName}.from` });
  const toValue = useWatch({ control, name: `${fieldName}.to` });

  const [fieldOptions, setFieldOptions] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toFieldOptions, setToFieldOptions] = useState([]);

  // Field options
  useEffect(() => {
    setFieldOptions(fields.filter(field => typesWithLogic.includes(field.type) && field.order > blockBefore && field.order < fields.length));
  }, [fields])

  // Operator / From options
  useEffect(() => {
    const field = fields.find(field => field.order === fieldValue);
    if(!field) return;

    setOperatorOptions(operatorTypes.filter(operatorType => operatorType.allowedTypes.includes(field.type)));
    setFromOptions(fields.filter(field => field.order >= fieldValue));
  }, [fieldValue]);

  // To options
  useEffect(() => {
    if(!fromValue) return;

    setToFieldOptions(fields.filter(field => field.order > fromValue));
  }, [fromValue])


  const renderValueField = () => {
    const field = fields.find(field => field.order === fieldValue);
    if(!field) return;

    let start, end, scale;

    switch(field.type) {
      case FieldTypes.MultipleChoice:
        return (
          <Select name={`${fieldName}.value`} register={register} value={value} className='u-margin-top' useDefault>
            {field.options?.options.map(option => (
              <Select.Item key={option.value} value={option.value}>{option.value}</Select.Item>
            ))}
          </Select>
        );
      case FieldTypes.Date:
        return (
          <Controller
            control={control}
            name={`${fieldName}.value`}
            render={({ field: { onChange, value } }) => (
              <DatePicker value={value} onChange={onChange} outputFormat='YYYY-MM-DD' className='u-margin-top' />
            )}
          />
        );
      case FieldTypes.YesNo:
        return (
          <Select name={`${fieldName}.value`} register={register} value={value} className='u-margin-top' useDefault>
            <Select.Item value='Yes'>Yes</Select.Item>
            <Select.Item value='No'>No</Select.Item>
          </Select>
        );
      case FieldTypes.OpinionScale:
        start = field.options?.use_custom_steps && field.options.steps_start ? parseInt(field.options.steps_start) : 1;
        end = field.options?.use_custom_steps && field.options.steps_end ? parseInt(field.options.steps_end) : 10;
        scale = Array(end - start + 1).fill().map((_, index) => start + index);

        return (
          <Select name={`${fieldName}.value`} register={register} value={value} className='u-margin-top' useDefault>
            {scale.map(number => (
              <Select.Item key={number} value={number}>{number}</Select.Item>
            ))}
          </Select>
        );
      case FieldTypes.StarRating:
        end = field.options?.use_custom_stars ? parseInt(field.options?.stars) : 5;
        scale = Array(end - 2).fill().map((_, index) => 1 + index);

        return (
          <Select name={`${fieldName}.value`} register={register} value={value} className='u-margin-top' useDefault>
            {scale.map(number => (
              <Select.Item key={number} value={number}>{number}</Select.Item>
            ))}
          </Select>
        );
      case FieldTypes.Country:
        return (
          <Select name={`${fieldName}.value`} register={register} value={value} className='u-margin-top' useDefault>
            {countries.map(country => (
              <Select.Item key={country.value} value={country.value}>{country.name}</Select.Item>
            ))}
          </Select>
        );
    }
  }

  return (
    <>
      <InputGroup title='If' className='u-margin-bottom--large'>
        <Select name={`${fieldName}.field`} register={register} value={fieldValue} useDefault>
          {fieldOptions.map(field => (
            <Select.Item key={field.order} value={field.order}>{field.order}. {field.text}</Select.Item>
          ))}
        </Select>
      </InputGroup>
      <InputGroup title='Is' className='u-margin-bottom--large'>
        <Select name={`${fieldName}.operator`} register={register} value={operatorValue} useDefault>
          {operatorOptions.map(operatorOption => (
            <Select.Item key={operatorOption.value} value={operatorOption.value}>{operatorOption.label}</Select.Item>
          ))}
        </Select>
        {renderValueField()}
      </InputGroup>
      
      <InputGroup title='Then jump from' className='u-margin-bottom--small'>
        <Select name={`${fieldName}.from`} register={register} value={fromValue} useDefault>
          {fromOptions.map(field => (
            <Select.Item key={field.order} value={field.order}>{field.order}. {field.text}</Select.Item>
          ))}
        </Select>
      </InputGroup>
      <InputGroup title='to'>
        <Select name={`${fieldName}.to`} register={register} value={toValue} useDefault>
          {toFieldOptions.map(field => (
            <Select.Item key={field.order} value={field.order}>{field.order}. {field.text}</Select.Item>
          ))}
        </Select>
      </InputGroup>
    </>
  )
}