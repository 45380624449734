import React from 'react';
import styles from './FilePreview.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import cn from 'classnames';

export const FilePreview = ({ file, checked, rejected, className }) => {

  const open = (e) => {
    e.stopPropagation();
    window.open(file.url, '_blank');
  }

  const renderPreview = (file) => {
    if(!file) return;

    switch(file.file_type) { 
      case 'application/pdf':
      case 'image/pdf':
        return <FontAwesomeIcon icon="file-pdf" className={styles.pdfIcon} onClick={open}/>
      case 'image/tiff': 
        return <FontAwesomeIcon icon={faApple} className={styles.pdfIcon} onClick={open}/>
      default: 
        return <img src={file.url} className={styles.image}/>
    }
  }

  return (
    <div className={cn(styles.root, { [className]: className, [styles.rootRejected]: rejected })}>
      { checked && <FontAwesomeIcon icon="check-square" className={styles.checkedIcon} /> }
      { renderPreview(file) }
    </div>
  )

}