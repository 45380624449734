import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  partners: {}
}

export const getCompany = createAsyncThunk('getCompany', async(id) => {
  const res = await apiRequest(`companies/${id}`, {}, 'get', true);
  return res.result;
});

export const createCompany = createAsyncThunk('createCompany', async(params, { rejectWithValue }) => {
  try {
    return await apiRequest('companies', params, 'post', true);
  } catch(e) {
    return rejectWithValue(e)
  }
});

export const updateCompany = createAsyncThunk('updateCompany', async({ id, params }, { rejectWithValue }) => {
  try {
    const res = await apiRequest(`companies/${id}`, params, 'put', true);
    return res.result; 
  } catch(e) {
    return rejectWithValue(e)
  }
});

export const updateCompanyOwner = createAsyncThunk('updateCompanyOwner', async({ id, userId }) => {
  const res = await apiRequest(`companies/${id}/owner`, { user_id: userId }, 'put', true);
  return res.result;
});

export const createSSOOrganization = createAsyncThunk('createSSOOrganization', async(domain) => {
  const res = await apiRequest(`companies/sso`, { domain }, 'post');
  return res.result;
});

export const disableSSOOrganization = createAsyncThunk('disableSSOOrganization', async(domain) => {
  const res = await apiRequest(`companies/sso`, {}, 'delete');
  return res.result;
});

export const connectJobAdder = createAsyncThunk('connectJobAdder', async(code) => {
  const res = await apiRequest(`partners/jobadder`, { code }, 'post');
  return res.result;
});

export const disconnectJobAdder = createAsyncThunk('disconnectJobAdder', async() => {
  const res = await apiRequest(`partners/jobadder`, {}, 'delete');
  return res.result;
});

export const connectBullhorn = createAsyncThunk('connectBullhorn', async(params) => {
  const res = await apiRequest(`partners/bullhorn`, params, 'post');
  return res.result;
});

export const disconnectBullhorn = createAsyncThunk('disconnectBullhorn', async() => {
  const res = await apiRequest(`partners/bullhorn`, {}, 'delete');
  return res.result;
});

export const connectLivehire = createAsyncThunk('connectLivehire', async(params, { rejectWithValue }) => {
  try {
    const res = await apiRequest(`partners/livehire`, params, 'post');
    return res.result; 
  } catch(e) {
    return rejectWithValue(e)
  }
});

export const disconnectLivehire = createAsyncThunk('disconnectLivehire', async() => {
  const res = await apiRequest(`partners/livehire`, {}, 'delete');
  return res.result;
});

export const connectLever = createAsyncThunk('connectLever', async(code) => {
  const res = await apiRequest(`partners/lever`, { code }, 'post');
  return res.result;
});

export const disconnectLever = createAsyncThunk('disconnectLever', async() => {
  const res = await apiRequest(`partners/lever`, {}, 'delete');
  return res.result;
});

export const connectKnowMy = createAsyncThunk('connectKnowMy', async(code) => {
  const res = await apiRequest(`partners/knowmy`, { code }, 'post');
  return res.result;
});

export const disconnectKnowMy = createAsyncThunk('disconnectKnowMy', async() => {
  const res = await apiRequest(`partners/knowmy`, {}, 'delete');
  return res.result;
});

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(createCompany.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(updateCompany.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(updateCompanyOwner.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(createSSOOrganization.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(disableSSOOrganization.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(connectLivehire.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(disconnectLivehire.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(connectJobAdder.fulfilled, (state) => {
        state.partners.job_adder.enabled = true;
      })
      .addCase(disconnectJobAdder.fulfilled, (state) => {
        state.partners.job_adder.enabled = false;
      })
      .addCase(connectBullhorn.fulfilled, (state) => {
        state.partners.bullhorn.enabled = true;
      })
      .addCase(disconnectBullhorn.fulfilled, (state) => {
        state.partners.bullhorn.enabled = false;
      })
      .addCase(connectLever.fulfilled, (state) => {
        state.partners.lever.enabled = true;
      })
      .addCase(disconnectLever.fulfilled, (state) => {
        state.partners.lever.enabled = false;
      })
      .addCase(connectKnowMy.fulfilled, (state) => {
        state.partners.knowmy.enabled = true;
      })
      .addCase(disconnectKnowMy.fulfilled, (state) => {
        state.partners.knowmy.enabled = false;
      })
  }
});


export const selectCompany = (state) => state.company;


export default companySlice.reducer