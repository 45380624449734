import React from 'react';
import styles from "./CustomButton.module.scss";

export const CustomButton = ({
  children,
  hasPrevious = true,
  action = () => { },
  brand,
}) => {
  const buttonStyles = () => {
    let customStyles = {
      color: hasPrevious ? brand.color_button_text : "#666666",
      backgroundColor: hasPrevious ? brand.color_button : "#f9f9f9",
      border: hasPrevious ? brand.color_button : "#f9f9f9",
      zIndex: hasPrevious ? 2 : 1,
      cursor: hasPrevious ? "pointer" : "not-allowed"
    }
    return customStyles;
  }
  return (
    <span style={buttonStyles()} className={styles.button} onClick={action}>{children}</span>
  )
}

