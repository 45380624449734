import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = []

export const getUserPermissions = createAsyncThunk('getUserPermissions', async () => {
  const res = await apiRequest(`users/permissions`, {}, 'get');
  return res.result;
});

const userPermissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserPermissions.fulfilled, (state, action) => {
        return action.payload;
      })
  }
});

export const selectPermissions = (state) => state.permissions;


export default userPermissionsSlice.reducer