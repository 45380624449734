import React, { useState, useEffect } from 'react';
import { createSlider } from '@typeform/embed';
import '@typeform/embed/build/css/slider.css';
import { ToggleSwitchInput } from 'components/FormComponents/ToggleSwitchInput/ToggleSwitchInput';
import { InputGroup, Select, ToggleSwitch, Button, FilePicker } from 'components/FormComponents';
import styles from './ReferenceSelectorDetails.module.scss';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';

export const ReferenceSelectorDetails = ({ 
  index,
  append,
  remove,
  register,
  watch,
  credits=0,
  form, 
  forms, 
  targetForm, 
  type, 
  bulk, 
  documents,
  allowUploads = false,
  setValue = () => {},
  signUpload = () => {},
  createUpload = () => {},
  handleDeleteUpload = () => {},
}) => {

  const [enabled, setEnabled] = useState(false);

  const typeformId = watch(`checks.${index}.details.typeform_id`);
  const referees = watch(`checks.${index}.details.referees`);

  const [upload, setUpload] = useState();
  const [showFilePicker, setShowFilePicker] = useState(false);

  // Show preview
  const showPreview = () => { 
    const typeformId = watch(`checks.${index}.details.typeform_id`);
    const { open } = createSlider(typeformId, {
      hidden: {
        candidate: "Candidate",
        referee: "Referee",
        job: "Job"
      },
      hideHeaders: true,
      hideFooter: true
    });
    open()
  }

  const setUploadedDocument = () => {
    setValue(`checks.${index}.details.referee_document_id`, upload.id);
    setShowFilePicker(false); 
  }

  useEffect(() => {
    if(enabled && isNaN(index)) {
      append({ 
        type: 'reference',
        details: {
          referees: 2, 
          bypassed: false,
          backup_referee: false, 
          typeform_id: targetForm
        }
      })
    } else if (!enabled && index >= 0) {
      remove(index);
    }
  }, [enabled])

  useEffect(() => {
    if(index >= 0 && !enabled)  setEnabled(true);
    else if (isNaN(index) && enabled) setEnabled(false);
  }, [index])

  return (
    <ToggleSwitchInput
      text='Reference Check'
      subtext={(credits < 0 ? "Unlimited" : credits) + " remaining"}
      logo='https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/checkmate_check_logo.svg'
      onChange={e => setEnabled(e.target.checked)}
      value={enabled}
    >
      {enabled && index >= 0 &&
        <>
          <AnimatedModal className='u-width-small' title='Upload document' visible={showFilePicker} onClose={() => setShowFilePicker(false)}>
            <FilePicker
              value={upload}
              onChange={setUpload}
              accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'] }}
              useModel
              className='u-margin-y'
              signUpload={signUpload}
              createUpload={createUpload}
              deleteUpload={handleDeleteUpload}
            />
            <Button className="u-width-100 u-margin-top" onClick={setUploadedDocument}>Done</Button>
          </AnimatedModal>
          {!form &&
            <InputGroup title='Select reference type'>
              <Select name={`checks.${index}.details.typeform_id`} placeholder='Select..' register={register} value={typeformId} useDefault>
                {forms.customs?.slice().reverse().map(form => (
                  <Select.Item key={form.id} value={form.typeform_id}>{form.name}</Select.Item>
                ))}
                {forms.intelligent?.map(form => (
                  <Select.Item key={form.id} value={form.typeform_id}>{form.name}</Select.Item>
                ))}
                {forms.library?.map(form => (
                  <Select.Item key={form.id} value={form.typeform_id}>{form.name}</Select.Item>
                ))}
              </Select>
              {!type && <a className={styles.link} onClick={showPreview}>Preview Questions</a> }
            </InputGroup>          
          }
          <InputGroup title='Referees Required'>
            <Select name={`checks.${index}.details.referees`} placeholder='Select..' register={register} value={referees} useDefault>
              <Select.Item value={1}>1 Referee</Select.Item>
              <Select.Item value={2}>2 Referees</Select.Item>
              <Select.Item value={3}>3 Referees</Select.Item>
            </Select>
          </InputGroup>
          {(allowUploads || documents?.length > 0) &&
            <InputGroup title='Documents'>
              <Select name={`checks.${index}.details.referee_document_id`} placeholder='Document' register={register} value={watch(`checks.${index}.details.referee_document_id`)}>
                {upload && 
                  <Select.Item value={upload.id}>{upload.file_name}</Select.Item>
                }
                {documents?.map(document => (
                  <Select.Item key={document.id} value={document.id}>{document.file_name}</Select.Item>
                ))}
              </Select>
              {allowUploads && 
                <a className={styles.link} onClick={() => setShowFilePicker(true)}>Upload new document</a>
              }
            </InputGroup>
          }
          <ToggleSwitch 
            register={register}
            name={`checks.${index}.details.backup_referee`}
            tooltip="In the event that a referee is overdue, we'll automatically ask the candidate to chase up the current reference or nominate a substitute."
            label='Backup Referee'
          />
          <ToggleSwitch 
            register={register}
            name={`checks.${index}.details.referee_confirmation`}
            tooltip="Once a candidate has nominated referees you will need to confirm them before proceeding."
            label='Reference Confirmation'
          />
          {!bulk &&
            <ToggleSwitch 
              register={register}
              name={`checks.${index}.details.bypassed`}
              tooltip="You'll be able to enter the referee's contact details manually instead of needing the candidate to do so."
              label='Bypass the Candidate'
            />
          }
        </>
      }
    </ToggleSwitchInput>
  )  
}
