import { InputGroup, Input, Button } from "components/FormComponents";
import { IntegrationCard } from '..';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

export const BambooHRIntegration = ({
  config,
  integration,
  updateCompany = () => {},
  setLoading    = () => {}
}) => {

  const [enabled, setEnabled] = useState(integration.enabled);

  const { register, handleSubmit} = useForm({ defaultValues: integration });

  const onSave = async(data) => {
    setLoading(true);
    await updateCompany(data);
    setLoading(false);
  }

  useEffect(() => {
    if(enabled) return;

    setLoading(true);
    updateCompany({ bamboohr_domain: null, bamboohr_key: null }).then(() => {
      setLoading(false);
    })
  }, [enabled])

  return (
    <IntegrationCard {...config} enabled={enabled} onToggle={(e) => setEnabled(e.target.checked)}>
      {enabled && 
        <form className='u-margin-top u-width-100' onSubmit={handleSubmit(onSave)}>
          <InputGroup title='Enter your BambooHR Domain'>
            <Input name='bamboohr_domain' placeholder='company_domain' register={register} />
          </InputGroup>
          <InputGroup title='Enter your API Key'>
            <Input name='bamboohr_key' placeholder='Key' register={register} />
          </InputGroup>

          <InputGroup title='Ready State ID'>
            <Input name='bamboohr_ready_state' type='number' placeholder='Checkmate Ready' register={register} />
          </InputGroup>
          <InputGroup title='In-progress State ID'>
            <Input name='bamboohr_in_progress_state' type='number' placeholder='Checkmate In-progress' register={register} />
          </InputGroup>
          <InputGroup title='Complete State ID'>
            <Input name='bamboohr_completed_state' type='number' placeholder='Checkmate Completed' register={register} />
          </InputGroup>
          <Button type='secondary' className='u-width-100 u-margin-top--large' submit>
            Save
          </Button>
        </form>
      }
    </IntegrationCard>
  );
};
