import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import { stringDate } from 'helpers/date';
import cookie from 'react-cookies';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/reports`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getReport: builder.query({
      queryFn: async ({ id, params }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/${id}?${toQueryParams(params)}`,
          responseHandler: async (response) => {
            const blob = await response.blob();
            return blob;
          },
          cache: "no-cache",
        });
        saveAs(file, `${stringDate()}_${id}`);
        return { data: null }
      }
    })
  })
})

export const {
  useLazyGetReportQuery
} = reportsApi
