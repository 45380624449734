import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = []

export const getBrands = createAsyncThunk('getBrands', async() => {
  const res = await apiRequest(`brands`, {}, 'get');
  return res.result;
});

export const createBrand = createAsyncThunk('createBrand', async(name) => {
  const res = await apiRequest('brands', { name }, 'post');
  return res.result;
});

export const updateBrand = createAsyncThunk('updateBrand', async({id, data}) => {
  const res = await apiRequest(`brands/${id}`, data, 'put');
  return res;
});

export const deleteBrand = createAsyncThunk('deleteBrand', async(id) => {
  await apiRequest(`brands/${id}`, {}, 'delete');
  return id;
});

export const getBrandUploads = createAsyncThunk('getBrandUploads', async(id) => {
  const res = await apiRequest(`brands/${id}/uploads`, {}, 'get');
  return { id, uploads: res.result } 
});

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    removeBrandUpload(state, { payload }) {
      return state.map(brand => {
        if(brand.id === payload.brandId)
          brand = {...brand, uploads: brand.uploads.filter(upload => upload.id !== payload.uploadId) }
        return brand;
      })
    },
    addBrandUpload(state, { payload }) {
      return state.map(brand => {
        if(brand.id === payload.brandId)
          brand = {...brand, uploads: [...brand.uploads, payload.upload] }
        return brand;
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(getBrandUploads.fulfilled, (state, { payload }) => {
        return state.map(brand => brand.id === payload.id ? {...brand, uploads: payload.uploads } : brand)
      })
      .addCase(createBrand.fulfilled, (state, { payload }) => {
        return [...state, payload];
      })
      .addCase(deleteBrand.fulfilled, (state, { payload: id }) => { 
        return state.filter(brand => brand.id !== id);
      })
      .addCase(updateBrand.fulfilled, (state, { payload: response }) => {
        if(!response.error) {
          const updatedBrand = response.result;
          return state.map(brand => brand.id === updatedBrand.id ? {...brand, ...updatedBrand} : brand);
        }
      })
  }
});

export const selectBrands = (state) => state.brands;

export const { removeBrandUpload, addBrandUpload } = brandsSlice.actions

export default brandsSlice.reducer