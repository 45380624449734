import { useState, useEffect } from 'react';
import styles from './DateSelector.module.scss';
import PropTypes from 'prop-types';
import { useDateSelect } from "react-ymd-date-select";
import cn from 'classnames';
import moment from 'moment';

export const DateSelector = ({
    value,
    className,
    error = '',
    outputFormat = 'DD/MM/YYYY',
    onChange = () => { }
}) => {

    const [date, setDate] = useState(value ? moment(value, outputFormat).format('YYYY-MM-DD') : null);
    const dateSelect = useDateSelect(date, setDate, { firstYear: 1930, monthFormat: "MMMM" });

    useEffect(() => {
        if (!value) return;
        setDate(moment(value, outputFormat).format('YYYY-MM-DD'))
    }, [value])

    // Send date value in output format
    useEffect(() => {
        if(!date) return;
        if (date.includes('-')) {
            const newValue = moment(date).format(outputFormat);
            onChange(newValue)
        }
    }, [date])
    
    return (
        <div className={cn(styles.root, { [className]: className })}>
            <select
                value={dateSelect.monthValue}
                onChange={dateSelect.onMonthChange}
                className={styles.select}
            >
                <option key="" value="">Month</option>
                {dateSelect.monthOptions.map((monthOption) => (
                    <option key={monthOption.value} value={monthOption.value}>
                        {monthOption.label}
                    </option>
                ))}
            </select>

            <select value={dateSelect.dayValue} onChange={dateSelect.onDayChange} className={styles.select}>
                <option key="" value="">Day</option>
                {dateSelect.dayOptions.map((dayOption) => (
                    <option key={dayOption.value} value={dayOption.value}>
                        {dayOption.label}
                    </option>
                ))}
            </select>

            <select value={dateSelect.yearValue} onChange={dateSelect.onYearChange} className={styles.select}>
                <option key="" value="">Year</option>
                {dateSelect.yearOptions.map((yearOption) => (
                    <option key={yearOption.value} value={yearOption.value}>
                        {yearOption.label}
                    </option>
                ))}
            </select>
            {error && (
                <span className={styles.error}>{error}</span>
            )}
        </div>
    )
}

DateSelector.propTypes = {
    className: PropTypes.string,
    outputFormat: PropTypes.string
};