import { Loading } from 'components/Loading/Loading';
import styles from './Authorization.module.scss';
import { useState } from 'react';
import { useDocumentTitle } from 'hooks/document-title';
import { useLazyAuthorizeOAuthQuery } from 'api/authentication-api';
import { Button } from 'components/FormComponents';
import { useLazyGetOAuthClientQuery } from 'api/oauth-client-api';
import cn from 'classnames';
import { useEffect } from 'react';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Buffer } from 'buffer';
import { PermissionsHuman } from 'constants/permissions';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'hooks/query-params';
import { useSelector } from 'react-redux';
import { selectAuthentication } from 'api/authentication-slice';

const Authorization = () => {

  useDocumentTitle('Authorize');

  const navigate = useNavigate();
  const [ client_id, response_type, redirect_uri, scope, state ] = useQueryParams(['client_id', 'response_type', 'redirect_uri', 'scope', 'state']);

  const auth = useSelector(selectAuthentication);

  const [authorizeOAuth] = useLazyAuthorizeOAuthQuery()
  const [getOAuthClient] = useLazyGetOAuthClientQuery()

  const [clientApp, setClientApp] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  // Check authentication
  useEffect(() => {
    // If still authenticating, wait
    if(!auth.checked || !client_id) return;

    if(auth.logged) {
      // Get client application details
      getOAuthClient(client_id).unwrap().then(
        ({ result }) => {
          setClientApp(result);
          setLoading(false);
        },
        ({ data: response }) => {
          setError(response.error.log);
          setLoading(false);
        }
      )
    // If not logged, redirect to login with encrypted state
    } else {
      const state = Buffer.from(window.location.search).toString('base64');
      navigate(`/sign_in?redirect_uri=authorization&state=${state}`)
    }
  }, [auth && client_id]);

  // Check redirect url
  useEffect(() => {
    if(!clientApp) return;
    if(!redirect_uri) setError('Invalid redirect URI');
  }, [redirect_uri, clientApp])

  const reject = () => {
    redirect({
      error: 'access_denied',
      error_description: 'User declined authorization',
      state
    });
  }

  const accept = async() => {
    setLoading(true);
    try {
      const { result } = await authorizeOAuth({ client_id, response_type, redirect_uri, scope, state }).unwrap()
      redirect({ code: result.code, state });
    } catch({ status, error }) {
      redirect({ 
        error_code: status === 400 ? 'invalid_request' : 'server_error',
        error_description: status === 400 ? error.log : 'Server Error',
        state
      });
    } finally {
      setLoading(false);
    }
  }

  const redirect = (params={}) => {
    if(!redirect_uri) {
      setError('Invalid redirect URI');
      return;
    }

    let url = `${redirect_uri}?`;
    let keys = Object.keys(params);
    for(let i=0; i < keys.length; i++) {
      const key = keys[i];
      if(!params[key]) continue;
      url += `${key}=${encodeURI(params[key])}&`
    }

    window.location.replace(url);
  }

  return (
    <div className={styles.root}>
      <Loading active={loading} />
      <div className={cn('card u-margin-top', 'card-with-shadow', styles.card)}>
        <div className={cn('card_content', styles.content)}>
          <img onClick={() => window.location.replace('https://www.checkmate.tech')} className={cn(styles.logo, 'u-margin-bottom--large')} src='/assets/images/checkmate/icon-label.svg' alt='Checkmate Logo' />
          <h2 className='design-marker title-2 u-margin-bottom--large'>Authorize</h2>

          {error ? 
            <div className={cn(styles.errorWrapper, 'card', 'card-with-shadow')}>
              <FeatherIcon icon='frown' size={56} className='u-margin-bottom--small' />
              <h2 className='title-3'>Error !</h2>
              <p>{error}</p>
            </div> :
            <>
              { clientApp &&
                <>
                  <img className={cn(styles.logo, 'u-margin-bottom--large')} src={clientApp.logo_uri} alt='Client Logo' />
                  <p><b>{clientApp.name}</b> is requesting the following permissions</p>
                  <ul className={styles.scopes}>
                    {scope.split(',').map(scope => (
                      <li key={scope}>{PermissionsHuman[scope]}</li>
                    ))}
                  </ul>
                  <div className='u-flex-box u-flex-justify-between u-width-100'>
                    <Button className='u-width-40' onClick={accept}>Accept</Button>
                    <Button className='u-width-40' type='secondary' onClick={reject}>Cancel</Button>
                  </div>
                </>
              }
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default Authorization