// Remove value from Array
export const removeFromArray = (array, value) => {
  const index = array.indexOf(value);
  if (index < 0) return array;
  array.splice(index, 1);
  return array;
}


// Arrays Union (no duplicates)
export const union = (a, b) => {
  return [...new Set([...a, ...b])];
}


// Arrays Intersection 
export const intersection = (a, b) => {
  let t;
  if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
  return a.filter((e) => { return b.indexOf(e) > -1 });
}
