import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import cookie from 'react-cookies';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/users`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getUsers: builder.query({
      query: (params) => `?${toQueryParams(params)}`
    }),
    inviteUser: builder.mutation({
      query: (params) => ({
        url: '/',
        method: 'POST',
        body: params,
      })
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      transformResponse: ({ result }) => result
    }),
    sendUserRecoveryPasswordEmail: builder.mutation({
      query: (email) => ({
        url: '/emails/resetPassword',
        method: 'POST',
        body: { email }
      })
    }),
    get2FASecret: builder.query({
      query: (id) => `/${id}/2fa`
    }),
    updateUserPassword: builder.mutation({
      query: ({ id, current_password, new_password }) => ({
        url: `/${id}/password`,
        method: 'PUT',
        body: { current_password, new_password },
      })
    }),
    sendBrandPreviewEmail: builder.mutation({
      query: ({ id, brandData }) => ({
        url: `/${id}/emails/brandPreview`,
        method: 'POST',
        body: brandData
      })
    }),
    sendCandidatePreviewEmail: builder.mutation({
      query: ({ id, brandData }) => ({
        url: `/${id}/emails/candidatePreview`,
        method: 'POST',
        body: brandData
      })
    }),
    sendRefereePreviewEmail: builder.mutation({
      query: ({ id, brandData }) => ({
        url: `/${id}/emails/refereePreview`,
        method: 'POST',
        body: brandData
      })
    }),
    getUserTeams: builder.query({
      query: (id) => `/${id}/teams`,
      transformResponse: ({ result }) => result
    })
  })
})

export const {
  useGetUserTeamsQuery,
  useLazyGetUsersQuery,
  useInviteUserMutation,
  useDeleteUserMutation,
  useGet2FASecretQuery,
  useUpdateUserPasswordMutation,
  useSendBrandPreviewEmailMutation,
  useSendCandidatePreviewEmailMutation,
  useSendRefereePreviewEmailMutation,
  useSendUserRecoveryPasswordEmailMutation
} = userApi
