
export default class InputValidator{

  static EmailValidator(value){
    if(!value || value === ''){
      return true;
    }
    else{
      var reg_exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg_exp.test(value.toLowerCase());
    }
  }

  static ColorValidator(value){
    if(!value || value === ''){
      return false;
    }
    else{
      var reg_exp = /(^#[0-9a-fA-F]{6}$)|(^#[0-9a-fA-F]{3}$)/;
      return reg_exp.test(value.toLowerCase());
    }
  }

  static Password(value) { 
    if(!value) return false;
    var reg_exp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{10,30}$/;
    return reg_exp.test(value);
  }

  static ConfirmPasswordValidator(value, params){
    return (params.password === '' || params.password === value);
  }

  static MinCharValidator(value, params){
    return (value && value.length >= params.chars);
  }

  static UrlValidator(value){
    if(!value || value === '')
      return false;
    var reg_exp = /^(http)(s)?(:\/\/)(.*)\.(.{1,})$/;
    return reg_exp.test(value.toLowerCase());
  }

  static DateValidator(value) {
    if(!value || value === '') return true;
    
    var reg_exp = /^\d{2}\/\d{2}\/\d{4}$/;
    return reg_exp.test(value);    
  }

  static RequiredValidator(value){
    return !(!value || value.length < 1);
  }

  static NotNullValidator(value){
    return (value !== null && value !== undefined);
  }

  static CompanyEmailValidator(value){
    var common_domains = [
      '@gmail', 
      '@yahoo', 
      '@hotmail', 
      '@aol',
      '@outlook', 
      '@mail', 
      '@msn',
      '@wanadoo',
      '@orange',
      '@comcast',
      '@live',
      '@icloud',
      '@bigpond',
      '@proton',
      '@protonmail',
      '@me',
      '@mac',
      '@att',
      '@currently',
      '@rocketmail',
      '@centurylink',
      '@verizon',
      '@cox',
      '@microsoft',
    ];
    var domain = value.match(/@([^.]*)/);
    if(domain){
      domain = domain[0];
      var common = common_domains.find(common_domain => common_domain === domain.toLowerCase());
      if(common)
        return false;
      else
        return true;
    }
    return false;
  }

}
