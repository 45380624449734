import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { revokePartnerToken } from './partner-token-version-slice';
import { PartnersEnum } from 'constants/partners';

const initialState = {
  tas: {},
  job_adder: {},
  jazzhr: {},
  teamtailor: {},
  bullhorn: {},
  onboarded: {},
  lever: {},
  ukg: {},
  account_api: {},
  knowmy: {},
  bambooHR: {},
  pageup: {},
  livehire: {},

  fetched: false
}

export const getPartnersConfig = createAsyncThunk('getPartnersConfig', async() => {
  const res = await apiRequest('partners/config', {}, 'get');
  return res.result;
});

const parnerSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnersConfig.fulfilled, (_, { payload }) => {
        return {...payload, fetched: true };
      })
      .addCase(revokePartnerToken.fulfilled, (state, { payload }) => {
        state[PartnersEnum[payload.partner]].api_key = payload.token;
      })
  }
});

export const selectPartners = (state) => state.partners;

export default parnerSlice.reducer