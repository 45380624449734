import { AnimatedModal } from '../../../../AnimatedModal/AnimatedModal';
import DeviceDetector from "device-detector-js";
import styles from './RefereeMetadataDetailsModal.module.scss';
import cn from 'classnames';

export const RefereeMetadataDetailsModal = ({ 
  referee, 
  referenceCheck,
  visible,
  onClose = () => {}
}) => {

  const checkDeviceInfo = (new DeviceDetector()).parse(referenceCheck?.user_agent);
  const refereeDeviceInfo = (new DeviceDetector()).parse(referee?.user_agent);

  return ( 
    <AnimatedModal visible={visible} onClose={onClose} className={styles.root} title="Metadata Details">
      <div className={cn(styles.row, styles.rowTitle)}>
        <div className={styles.rowDescription}>Parameter</div>
        <div className={styles.rowValue}>Candidate</div>
        <div className={styles.rowValue}>Referee</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowDescription}>Client Type:</div>
        <div className={styles.rowValue}>{checkDeviceInfo?.client?.type}</div>
        <div className={styles.rowValue}>{refereeDeviceInfo?.client?.type}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowDescription}>Client Name:</div>
        <div className={styles.rowValue}>{checkDeviceInfo?.client?.name}</div>
        <div className={styles.rowValue}>{refereeDeviceInfo?.client?.name}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowDescription}>Client Version:</div>
        <div className={styles.rowValue}>{checkDeviceInfo?.client?.version}</div>
        <div className={styles.rowValue}>{refereeDeviceInfo?.client?.version}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowDescription}>OS Name:</div>
        <div className={styles.rowValue}>{checkDeviceInfo?.os?.name}</div>
        <div className={styles.rowValue}>{refereeDeviceInfo?.os?.name}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowDescription}>OS Version:</div>
        <div className={styles.rowValue}>{checkDeviceInfo?.os?.version}</div>
        <div className={styles.rowValue}>{refereeDeviceInfo?.os?.version}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowDescription}>Device Type:</div>
        <div className={styles.rowValue}>{checkDeviceInfo?.device?.type}</div>
        <div className={styles.rowValue}>{refereeDeviceInfo?.device?.type}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowDescription}>Device Brand:</div>
        <div className={styles.rowValue}>{checkDeviceInfo?.device?.brand}</div>
        <div className={styles.rowValue}>{refereeDeviceInfo?.device?.brand}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowDescription}>IP Country:</div>
        <div className={styles.rowValue}>{referenceCheck?.country}</div>
        <div className={styles.rowValue}>{referee?.ip_country}</div>
      </div>
    </AnimatedModal>
  )
}