import cn from 'classnames';
import styles from './CombinedReferees.module.scss';
import { IconButton } from 'components/IconButton/IconButton';
import { VerificationCard, Statuses } from './../VerificationCard/VerificationCard';
import { FormResponses } from 'components/Form/FormResponses/FormResponses';
import { useGetAnswersQuery } from 'api/candidate-checks-api';
import { useEffect, useMemo } from 'react';
import { meetsLogic } from 'constants/logic';
import InputValidator from 'helpers/input-validator';

export const CombinedReferees = ({
  candidate,
  referees,
  check,
  setLoading   = () => {},
  getPdfReport = () => {}
}) => {

useGetAnswersQuery

  const { data: response, isLoading } = useGetAnswersQuery({ id: check.id });

  useEffect(() => { setLoading(isLoading) }, [isLoading])

  const fields = useMemo(() => {
    if(!response?.result) return;

    // Set data
    const { form: { fields, logic_jumps }, answers } = response.result;

    // Get fields with logic
    let hasSubField = {};
    let ignoredFields = [];
    logic_jumps.forEach(logic => {
      hasSubField[logic.from] = !!hasSubField[logic.from] ? [...hasSubField[logic.from], logic] : [logic];
      for(let i=logic.from+1; i < logic.to; i++) { ignoredFields.push(i) }
    });
    
    // Filter ignored fields
    let data = fields.filter(f => !ignoredFields.includes(f.order))

    // Build data array
    return data.map(f => {
      // Copy object
      let field = {...f};

      // logic for sub-fields
      let logics = Object.keys(hasSubField).includes(`${field.order}`) ? hasSubField[field.order] : [];
      
      // Set field answers 
      field.answers = [];
      referees.forEach(referee => {
        // Add orders that should be add as sub-fields
        let subFieldsOrders = [];
        logics.forEach(logic => {
          const fieldToEval = fields.find(f => f.order === logic.field);
          const valueToEval = answers[referee.id][fieldToEval.id];
          if(meetsLogic(logic, valueToEval)) {
            for(let i=logic.from + 1; i < logic.to; i++) {
              subFieldsOrders = subFieldsOrders.filter(order => order !== i)
            }
          } else {
            for(let i=logic.from + 1; i < logic.to; i++) {
              if(!subFieldsOrders.includes(i)) {
                subFieldsOrders.push(i);
              }
            }
          }
        });

        // Add sub-fields
        let subFields = [];
        subFieldsOrders.forEach(order => {
          const field = fields.find(f => f.order == order);
          const value = answers[referee.id][field.id];
          subFields.push({...field, answer: value })
        });

        // Add referee answer to field answers
        field.answers.push({
          name: referee.name,
          answer: answers[referee.id][field.id],
          subFields
        })
      });

      return field;
    })
  }, [response])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={cn(styles.details, 'u-margin-right')}>
          <div className={styles.title}>COMBINED REPORT</div>
          <div className={styles.name}>{referees.map(referee => referee.name).join(' & ')}</div>
        </div>
        <div className={styles.actions}>
          <IconButton prefix='fa' icon='download' tip='Download Form' onClick={() => getPdfReport(check)} />
        </div>
      </div>
      <Header candidate={candidate} referees={referees} />
      {response &&
        <FormResponses form={response.result.form} fields={fields} />
      }
    </div>
  )
} 


const Header = ({ candidate, referees }) => (
  <div className={cn('d-flex', 'justify-content-between', 'u-padding--large', 'background-secondary', 'u-divider-bottom-border')}>
    <VerificationCard>
      <VerificationCard.Header icon="mail">email address</VerificationCard.Header>
      {referees.map((referee, i) => {
        let emailVerification;

        if(referee.email === candidate.email) 
          emailVerification = { status: Statuses.Error, msg: 'Email addresses match' }
        else if(InputValidator.CompanyEmailValidator(referee.email)) 
          emailVerification = { status: Statuses.Success, msg: 'Registered Company Email' }
        else 
          emailVerification = { status: Statuses.Warn, msg: 'Non company mail' }

        return (
          <div key={referee.id} className={cn('u-padding-y--small', {['u-divider-top-border']: i !== 0})}>
            <VerificationCard.Link href={`mailto:${referee.email}`}>{referee.email}</VerificationCard.Link>
            <VerificationCard.AlertMessage status={emailVerification.status}>{emailVerification.msg}</VerificationCard.AlertMessage>
          </div>
        )
      })}
    </VerificationCard>
    <VerificationCard>
      <VerificationCard.Header icon="linkedin" fa>linkedin profile</VerificationCard.Header>
      {referees.map((referee, i) => {
        const linkedinVerification = referee.linkedin_profile ? { status: Statuses.Success, msg: 'Verified Linkedin'} : { status: Statuses.Warn, msg: 'No information provided' }

        return (
          <div key={referee.id} className={cn('u-padding-y--small', {['u-divider-top-border']: i !== 0})}>
            <VerificationCard.Value>{referee.linkedin_profile ? referee.linkedin_profile : '-'}</VerificationCard.Value>
            <VerificationCard.AlertMessage status={linkedinVerification.status}>{linkedinVerification.msg}</VerificationCard.AlertMessage>
          </div>
        )
      })}
    </VerificationCard>
    <VerificationCard>
      <VerificationCard.Header icon="shield">ip address</VerificationCard.Header>
      {referees.map((referee, i) => {
        const ipVerification = referee.ip === candidate.ip ? { status: Statuses.Error, msg: 'IP address matches candidate'} : { status: Statuses.Success, msg: 'Unique IP Address' }

        return (
          <div key={referee.id} className={cn('u-padding-y--small', {['u-divider-top-border']: i !== 0})}>
            <VerificationCard.Value>{referee.ip}</VerificationCard.Value>
            <VerificationCard.AlertMessage status={ipVerification.status}>{ipVerification.msg}</VerificationCard.AlertMessage>
          </div>
        )
      })}
    </VerificationCard>
  </div>
)