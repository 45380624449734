import { useEffect, useState } from 'react';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { Link } from 'react-router-dom';
import { useLazyGetCompaniesQuery, useCreateCompanyOnBehalfMutation } from 'api/company-api';
import { Table } from 'components/Table/Table';
import { useDocumentTitle } from 'hooks/document-title';
import { NewCompanyModal } from './components/NewCompanyModal/NewCompanyModal';
import { useNavigate } from 'react-router-dom';

const AdminCompanies = ({}) => {

  useDocumentTitle('Companies')

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);

  const [showNewCompanyModal, setShowNewCompanyModal] = useState(false);

  const [getAllCompanies] = useLazyGetCompaniesQuery();
  const [createCompany] = useCreateCompanyOnBehalfMutation();

  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0
  })

  const fetchCompanies = async(params = {}) => {
    setLoading(true);
    const { result } = await getAllCompanies(params).unwrap();
    setPagination(result.pagination);
    setCompanies(result.companies);
    setLoading(false);
  }

  useEffect(() => { fetchCompanies() }, [])

  const handleCompanyCreation = async(data) => {
    setLoading(true);
    const { data: companyId } = await createCompany(data);
    setLoading(false);
    navigate(`/admin/companies/${companyId}`)
  }

  return (
    <AdminPage loading={loading} title='Companies' button buttonText='Create Company' onClick={() => setShowNewCompanyModal(true)}> 
      <NewCompanyModal visible={showNewCompanyModal} onSubmit={handleCompanyCreation} onClose={() => setShowNewCompanyModal(false)}/>
      <Table.Context onQueryUpdate={fetchCompanies} defaultQuery={{ page: 1 }}>
        <Table.Filters className='u-margin--large'>
          <Table.Search placeholder='Search for a company...' />
        </Table.Filters>
        <Table className='u-margin--large'>
          <Table.Head>
            <Table.Row>
              <Table.Header>ID</Table.Header>
              <Table.Header>Name</Table.Header>
              <Table.Header>Owner Email</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {companies.map(company => (
              <Table.Row key={company.id}>
                <Table.Col><Link to={`/admin/companies/${company.id}`} className='u-link dark'>{company.id}</Link></Table.Col>
                <Table.Col>{company.name}</Table.Col>
                <Table.Col>{company.owner_email}</Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Pagination {...pagination} />
        </Table>
      </Table.Context>
    </AdminPage>
  )
}

export default AdminCompanies 
