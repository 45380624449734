import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { IntegrationCard } from '..';
import { useState } from 'react';
import { useOAuth } from "hooks/oauth";

export const JobAdderIntegration = ({ 
  integration,
  config,
  connect      = () => {},
  disconnect   = () => {},
  setLoading   = () => {},
  popUpMessage = () => {},
}) => {

  const [enabled, setEnabled] = useState(integration.enabled);

  const onConnect = async(code) => {
    setLoading(true);
    try {
      await connect(code);
      popUpMessage("JobAdder integrated with Checkmate", MESSAGE_STATE_SUCCESS);
      setEnabled(true);
    } catch (e) {
      popUpMessage("JobAdder integration failed. Please check your credentials and try again.", MESSAGE_STATE_ERROR);
      setEnabled(false);
    } finally {
      setLoading(false);
    }
  }

  const openAuth = useOAuth({
    authUri: 'https://id.jobadder.com/connect/authorize',
    clientId: 'zr2anaoyhobedkxk4syecodq2a', 
    responseType: 'code',
    redirectUri: `${process.env.REACT_APP_WEB_URL}/assets/jobadder_callback.html`,
    scope: 'partner_ui_action offline_access read_jobapplication read write write_jobapplication',
    callbackAction: 'jobadder_callback',
    callback: onConnect
  });

  const toggleIntegration = async(e) => {
    const value = e.target.checked;
    if(integration.enabled === value) return;

    if(value) {
      openAuth();
    } else {
      setLoading(true);
      await disconnect();
      setLoading(false);
      setEnabled(false);
    }
  }

  return <IntegrationCard {...config} enabled={enabled} onToggle={toggleIntegration} />
};
