import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import { Fragment, useState, useEffect } from 'react';
import { ToggleLabel, Input, Select, InputGroup } from 'components/FormComponents';
import { Collapse } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import styles from './StarRatingSettings.module.scss';

export const StarRatingSettings = ({ register, name, field, watch }) => (
  <Fragment>
    <EditableSettings title='Settings' className='u-margin-top' startOpen={true}>
      <ToggleLabel register={register} name={`${name}.required`} label='Required' />
      <div className='u-divider u-margin-y--small' />
      <Fragment>
        <ToggleLabel register={register} name={`${name}.use_description`} label='Add description' />
        <Collapse in={field.use_description}>
          <div><Input register={register} name={`${name}.description`} className='u-margin-top--small' placeholder='Enter description...'/></div>
        </Collapse>
      </Fragment>
      <div className='u-divider u-margin-y--small' />
      <Fragment>
        <ToggleLabel register={register} name={`${name}.options.use_custom_stars`} label='Customise stars' />
        <Collapse in={field.options?.use_custom_stars}>
          <div className='u-margin-top'>
            <InputGroup title='Stars' className='u-margin-right--large'>
              <Select register={register} name={`${name}.options.stars`} className='u-margin-right' value={watch(`${name}.options.stars`)} useDefault>
                <Select.Item value='5'>5</Select.Item>
                <Select.Item value='7'>7</Select.Item>
                <Select.Item value='10'>10</Select.Item>
              </Select>
            </InputGroup>
            <div className='d-flex align-items-center u-margin-top'>
              <InputGroup title='Left Label' className='u-margin-right--large u-margin-bottom--0'>
                <Input name={`${name}.options.label_start`} placeholder='Enter label' register={register} />
              </InputGroup>
              <InputGroup title='Right Label' className='u-margin-left'>
                <Input name={`${name}.options.label_end`} placeholder='Enter label' register={register} />
              </InputGroup>
            </div>
          </div>
        </Collapse>
      </Fragment>
    </EditableSettings>
    <div className='u-divider u-margin-y--large' />
    <StarsPreview {...field.options} />
  </Fragment>
)

const StarsPreview = ({ use_custom_stars, stars, label_start, label_end }) => {

  const [starsArray, setStarsArray] = useState([]);

  useEffect(() => {
    const starsInt = use_custom_stars ? parseInt(stars) : 5;
    const array = Array(starsInt).fill().map((_, index) => index);
    setStarsArray(array);
  }, [use_custom_stars, stars])

  return (
    <div className={styles.starsPreview}>
      <div className={styles.starsWrapper}>
        {starsArray.map(index => (
          <FeatherIcon className={styles.star} key={index} icon='star' size={35} />
        ))}
      </div>
      <label className={styles.labelStart}>{label_start}</label>
      <label className={styles.labelEnd}>{label_end}</label>
    </div>
  )
}
