import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Button, EditableTitle, Select } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import cn from 'classnames';
import styles from './TeamConfig.module.scss';


export const TeamConfig = ({
  team,
  users = [],
  onUpdate = () => {},
  onClose  = () => {}
}) => {

  const { register, reset, control, handleSubmit } = useForm();

  const { fields: userFields, append: appendUser, remove: removeUser } = useFieldArray({ control, name: `users`, keyName: 'key' });

  const [selectedUserId, setSelectedUserId] = useState();
  const [usersNotInTeam, setUsersNotInTeam] = useState([]);

  const nameValue = useWatch({ control, name: 'name' });

  useEffect(() => {
    setUsersNotInTeam(users.filter(user => !team.users?.includes(user.id)));
    reset({ ...team, users: team.users.map(userId => ({ id: userId })) })
  }, [team])

  const addUser = async() => {
    appendUser({ id: selectedUserId });
    setUsersNotInTeam(usersNotInTeam.filter(user => user.id !== selectedUserId));
    setSelectedUserId(undefined);
  }

  const deleteUser = async(userId, index) => {
    removeUser(index);
    const user = users.find(user => user.id === userId);
    setUsersNotInTeam([...usersNotInTeam, user]);
  }

  const onSubmit = (data) => {
    data.users = data.users.map(user => user.id);
    onUpdate(team.id, data);
  }

  return (
    <form className={cn('card', 'card-with-border', 'card--small', 'u-width-50')} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className='u-padding--large u-divider-bottom-border'>
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <EditableTitle register={register} name='name' value={nameValue} />
          </div>
          <p className='t-small'>Manage this team users below</p>
        </div>
        <div className='background-secondary u-padding-top'>
          <div className={cn('card', 'card-with-border', 'u-margin-x', styles.userSelector)}>
            <Select 
              inputProps={{ value: selectedUserId, onChange: (e) => setSelectedUserId(e.target.value) }}
              value={selectedUserId}
              placeholder='Select user..'
              className='u-margin-right--large'
            >
              {usersNotInTeam.map(user => (
                <Select.Item key={user.id} value={user.id}>{user.name}</Select.Item>
              ))}
            </Select>
            <Button disabled={!selectedUserId} icon='user-plus' onClick={addUser}>Add</Button>
          </div>
          <div className={cn(styles.usersHeader)}>
            <div className={styles.usersHeaderContent}>
              <div>Name</div> 
              <div>Email</div>
            </div>
          </div>
          <div className={styles.userRows}>
            {userFields.map((userField, index) => {
              const user = users.find(user => user.id === userField.id);
              return (
                <div key={userField.id} className={cn('card', 'card-with-border', 'u-margin-x', styles.userRow)}>
                  <div className={styles.column}>{user.name}</div>
                  <div className={styles.column}>{user.email}</div>
                  <div className={styles.column}><IconButton icon='trash-2' tip='Remove user from team' onClick={() => deleteUser(user.id, index)} /></div>
                </div>
              )
            })}
            {userFields.length < 1 &&
              <div className={cn('u-text-centered', 'text-secondary')}>There are currently no users assigned to this team...</div>
            }
          </div>
        </div>
      </div>
      <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
        <Button onClick={onClose} type='secondary'>Cancel</Button>
        <Button submit>Save</Button>
      </div>
    </form>
  )  
}