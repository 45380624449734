import { AnimatedModal } from "components/AnimatedModal/AnimatedModal";
import { Input, InputGroup, Select, Button } from "components/FormComponents";
import { useForm } from "react-hook-form";
import { Currencies } from 'constants/currencies';
import { countries } from "constants/countries";


export const NewCompanyModal = ({ 
  visible,
  onClose = () => {},
  onSubmit = () => {}
}) => {

  const { register, watch, handleSubmit, formState: { errors } } = useForm();

  return (
    <AnimatedModal visible={visible} onClose={onClose} title='Create company' small>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5 className="u-margin-top--large">Company</h5>
        <hr className="u-margin-top--0" />

        <InputGroup title='Name *'>
          <Input 
            register={register}
            name='company.name'
            placeholder='e.g. Checkmate'
            validators={{ required: true }}
            error={errors?.company?.name?.type}
          />
        </InputGroup>
        <InputGroup title='Type *' className='u-width-100'>
          <Select name='company.user_type' register={register} value={watch('company.user_type')} useDefault>
            <Select.Item value='casual'>Casual PAYG</Select.Item>
            <Select.Item value='internal'>Internal</Select.Item>
            <Select.Item value='enterprise'>Enterprise</Select.Item>
          </Select>
        </InputGroup>
        <InputGroup title='Account manager *' className='u-width-100'>
          <Select name='company.account_manager' register={register} value={watch('company.account_manager')} className='u-capitalize' useDefault>
            {process.env.REACT_APP_ACCOUNT_MANAGERS.split(',').map(account_manager => (
              <Select.Item className='u-capitalize' value={account_manager}>{account_manager}</Select.Item>
            ))}
            <Select.Item value='other'>Other</Select.Item>
          </Select>
        </InputGroup>
        
        <h5 className="u-margin-top--large">Billing</h5>
        <hr className="u-margin-top--0" />

        <InputGroup title='Currency *' className='u-width-100'>
          <Select name='billing_info.currency' register={register} value={watch('billing_info.currency')} useDefault>
            <Select.Item value={Currencies.nzd}>NZD</Select.Item>
            <Select.Item value={Currencies.aud}>AUD</Select.Item>
            <Select.Item value={Currencies.usd}>USD</Select.Item>
          </Select>
        </InputGroup>
        <InputGroup title='Billing name'>
          <Input 
            register={register}
            name='billing_info.billing_name'
            placeholder='Checkmate Technology Limited'
            validators={{ required: true }}
            error={errors?.billing_info?.billing_name?.type}
          />
        </InputGroup>
        <InputGroup title='Email *'>
          <Input 
            register={register}
            name='billing_info.email'
            placeholder='billing@checkmate.tech'
            validators={{ required: true }}
            error={errors?.billing_info?.email?.type}
          />
        </InputGroup>
        <InputGroup title='Town'>
          <Input 
            register={register}
            name='billing_info.town'
            placeholder='Tauranga'
          />
        </InputGroup>
        <InputGroup title='Postcode'>
          <Input 
            register={register}
            name='billing_info.post_code'
            placeholder='3110'
          />
        </InputGroup>
        <InputGroup title='Country *'>
          <Select name='billing_info.country' register={register} value={watch('billing_info.country')} useDefault>
            {countries.map(country => (
              <Select.Item key={country.value} value={country.value}>{country.name}</Select.Item>
            ))}
          </Select>
        </InputGroup>

        <h5 className="u-margin-top--large">Main user</h5>
        <hr className="u-margin-top--0" />

        <div className="u-width-100 d-flex justify-content-between align-items-start">
          <InputGroup title='First name *' className="u-width-40">
            <Input 
              name='user.first_name'
              placeholder='John'
              register={register}
              validators={{ required: true }}
              error={errors?.user?.first_name?.type}
            />
          </InputGroup>
          <InputGroup title='Last name *' className="u-width-45">
            <Input 
              name='user.last_name'
              placeholder='Doe'
              register={register}
              validators={{ required: true }}
              error={errors?.user?.last_name?.type}
            />
          </InputGroup>
        </div>
        <InputGroup title='Email *'>
          <Input 
            register={register}
            name='user.email'
            placeholder='john@checkmate.tech'
            validators={{ required: true }}
            error={errors?.user?.email?.type}
          />
        </InputGroup>

        <Button submit className="u-width-100 u-margin-top--large">Create</Button>
      </form>
    </AnimatedModal>
  );    
}