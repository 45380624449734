import { createContext, useContext, useEffect, useState } from 'react';
import styles from './Table.module.scss';
import { Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Select, InputGroup, SearchInput, Checkbox, DatePicker } from 'components/FormComponents';
import cn from 'classnames';

const TableContext = createContext(null);

const useTableContext = () => {
  const context = useContext(TableContext)
  if (!context)
    throw new Error(`Table compound components cannot be rendered outside the Table component`)
  
  return context
}

const Context = ({ 
  children, 
  onQueryUpdate = () => {}, 
  defaultQuery = {},
}) => {

  const [queryParams, setQueryParams] = useState(defaultQuery);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [bulkUseAll, setBulkUseAll]   = useState(false);

  useEffect(() => {
    setSelectedAll(false);
    setSelectedIds([]);
    onQueryUpdate(queryParams)
  }, [queryParams])

  useEffect(() => {
    if(!selectedAll) setSelectedIds([])
  }, [selectedAll])

  const updateQueryParams = (params) => setQueryParams(curr => ({ ...curr, ...params }))

  const contextValue = { 
    queryParams, 
    updateQueryParams,
    selectedAll,
    setSelectedAll,
    selectedIds,
    setSelectedIds,
    bulkUseAll,
    setBulkUseAll
  }

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  )
}

export const Table = ({ children, className }) => (
  <div className={cn('card', 'card-with-border', styles.root, { [className]: className })}>
    <table className={styles.table}>
      {children}
    </table>
  </div>
)

const Placeholder = ({ children }) => <p className={styles.placeholder}>{children}</p>

const Head = ({ children }) => <thead className={styles.head}>{children}</thead>

const Header = ({ children, align = 'left', bulkSelector = false }) => {

  const { selectedAll, setSelectedAll } = useTableContext();

  return (
    <th className={cn(styles.header, { [styles.headerWithSelector]: bulkSelector })} style={{ textAlign: align }}>
      {bulkSelector ? 
        <Checkbox checked={selectedAll} onClick={() => setSelectedAll(curr => !curr)} label={<span style={{ fontSize: '14px' }}>{children}</span>} /> :
        children
      }
    </th>
  )
}

const Body = ({ children }) => <tbody className={styles.body}>{children}</tbody>

const Row = ({ children }) => <tr className={styles.row}>{children}</tr>

const Col = ({ children, align = 'left', bulkSelector, selectorId, className }) => {

  const [checked, setChecked] = useState(false);

  const { selectedAll, selectedIds, setSelectedIds } = useTableContext();

  useEffect(() => {
    if(!bulkSelector) return;

    if(selectedAll) setSelectedIds(curr => curr.includes(selectorId) ? curr : [...curr, selectorId])
  }, [selectedAll]);

  useEffect(() => {
    if(!bulkSelector) return;

    setChecked(selectedIds.includes(selectorId))
  }, [selectedIds])

  const select = (value) => {
    if(value) setSelectedIds(curr => curr.includes(selectorId) ? curr : [...curr, selectorId]);
    else      setSelectedIds(curr => curr.filter(val => val !== selectorId));
  }

  return (
    <td className={cn(styles.column, { [className]: className })} style={{ textAlign: align }}>
      {bulkSelector ? 
        <Checkbox checked={checked} onClick={select} label={children}/> :
        children
      }
    </td>
  )
}

const Pagination = ({ page, total_pages }) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [pages, setPages] = useState([]);

  useEffect(() => {
    if(total_pages < 10) {
      setPages(Array.from({length: total_pages}, (_, i) => i + 1))
    } else {
      if(page <= 5) {
        setPages(Array.from({ length: 10 }, (_, i) => i + 1))
      } else if ((page + 6) > total_pages ) {
        setPages(Array.from({ length: 10 }, (_, i) => i + (total_pages - 9)))
      } else {
        setPages(Array.from({ length: 10 }, (_, i) => i + (page - 5)))
      }
    }
  }, [page, total_pages])

  const setPage = (newPage) => {
    if(newPage === queryParams.page) return;
    updateQueryParams({ page: newPage })
  }

  const firstPage = () => {
    if(page === 1) return;
    setPage(1);
  }

  const previousPage = () => {
    if(page === 1) return;
    setPage(page - 1);
  }

  const nextPage = () => {
    if(page === total_pages) return;
    setPage(page + 1);
  }

  const lastPage = () => {
    if(page === total_pages) return;
    setPage(total_pages);
  }
  
  const selectPage = (pageToGo) => {
    if(page === pageToGo) return;
    setPage(pageToGo);
  }

  return (
    <tfoot className={styles.footer}>
      <tr>
        <td className={styles.pagination} colSpan="100%">
          <FeatherIcon 
            className={cn(styles.chevron, 'u-link', 'dark', { [styles.chevronDisabled]: page === 1 })} 
            icon='chevrons-left'
            size={18}
            onClick={firstPage}
          />
          <FeatherIcon 
            className={cn(styles.chevron, 'u-link', 'dark', { [styles.chevronDisabled]: page === 1 })}
            icon='chevron-left' 
            size={18}
            onClick={previousPage}
          />
          {pages.map((index) => (
            <span key={index} onClick={() => selectPage(index)} className={cn('u-link', 'dark', styles.paginationNumber, { [styles.paginationNumberSelected]: page === index })}>
              {index}
            </span>
          ))}
          <FeatherIcon 
            className={cn(styles.chevron, 'u-link', 'dark', { [styles.chevronDisabled]: page === total_pages })}
            icon='chevron-right'
            size={18}
            onClick={nextPage}
          />
          <FeatherIcon 
            className={cn(styles.chevron, 'u-link', 'dark', { [styles.chevronDisabled]: page === total_pages })}
            icon='chevrons-right'
            size={18}
            onClick={lastPage}
          />
        </td>
      </tr>
    </tfoot>
  )
}

const Filters = ({ children, className }) => <div className={cn(styles.filters, 'card', 'card-with-border', { [className]: className })}>{children}</div>
const LeftFilters = ({ children }) => <div className={styles.left}>{children}</div>
const RightFilters = ({ children }) => <div className={styles.right}>{children}</div>

const Search = ({ placeholder = 'Search...' }) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [query, setQuery] = useState('');

  useEffect(() => {
    if(query === queryParams.search) return;
    updateQueryParams({ search: query, page: 1 })
  }, [query])

  return (
    <InputGroup title='Search' className='u-no-margin-bottom'>
      <SearchInput
        placeholder={placeholder}
        className={styles.search}
        onChange={setQuery}
      />
    </InputGroup>
  )
}

const DateFilter = ({ title, filterName }) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [filterValue, setFilterValue] = useState(queryParams[filterName]);

  useEffect(() => {
    if(!filterValue) return;

    if(queryParams[filterName] === filterValue) return;

    updateQueryParams({ [filterName]: filterValue, page: 1 })
  }, [filterValue])

  return (
    <InputGroup title={title} className={cn(styles.filter, 'u-no-margin-bottom')}>
      <DatePicker onChange={setFilterValue} outputFormat='YYYY-MM-DD' />
    </InputGroup>
  )
}

const Filter = ({ title, filterName, options = [] }, useDefault=false) => {

  const { queryParams, updateQueryParams } = useTableContext();

  const [filterValue, setFilterValue] = useState(queryParams[filterName]);

  useEffect(() => {
    if(!filterValue) return;

    if(queryParams[filterName] === filterValue) return;

    updateQueryParams({ [filterName]: filterValue, page: 1 })
  }, [filterValue, options.length])

  return (
    <InputGroup title={title} className={cn(styles.filter, 'u-no-margin-bottom')}>
      <Select inputProps={{ value: filterValue, onChange: (e) => setFilterValue(e.target.value) }} value={filterValue} useDefault={useDefault}>
        {options.map(option => (
          <Select.Item key={option.value} value={option.value}>{option.name}</Select.Item>
        ))}
      </Select>
    </InputGroup>
  )
}



const BulkActions = ({ children }) => {
  const { selectedIds, bulkUseAll, setBulkUseAll } = useTableContext();

  return (
    <div className={cn(styles.bulkActions, { [styles.bulkActionsHidden]: selectedIds.length < 1 })}>
      <Badge className={styles.bulkActionsBadge} onClick={() => setBulkUseAll(!bulkUseAll)}>
        {bulkUseAll ? 'use all' : `${selectedIds.length} selected`}
      </Badge>
      <div className={styles.bulkActionsWrapper}>
        {children}
      </div>
    </div>
  )
}

const BulkAction = ({ icon, tip, onClick = () => {} }) => {
  const { selectedIds, bulkUseAll } = useTableContext();

  return (
    <OverlayTrigger placement='top' overlay={<Tooltip>{tip}</Tooltip>}>
      <div className={styles.bulkActionIconWrapper}>
        <FeatherIcon className={styles.bulkActionIcon} icon={icon} onClick={() => onClick(selectedIds, bulkUseAll)} />
      </div>
    </OverlayTrigger>
  )
}

Table.Context = Context;
Table.Head = Head;
Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.Col = Col;
Table.Pagination = Pagination;
Table.Filters = Filters;
Table.RightFilters = RightFilters;
Table.LeftFilters = LeftFilters;
Table.Search = Search;
Table.Filter = Filter;
Table.DateFilter = DateFilter;
Table.Placeholder = Placeholder;
Table.BulkActions = BulkActions;
Table.BulkAction = BulkAction;