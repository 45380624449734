import { Input, InputGroup, Textarea, Button, ToggleSwitch } from "components/FormComponents";
import { useWatch } from "react-hook-form";
import { EditableSettings } from "components/EditableSettings/EditableSettings";
import cn from 'classnames';

export const Emails = ({
  register,
  control,
  sendCandidateEmailPreview = () => {},
  sendRefereeEmailPreview = () => {}
}) => {

  const emailActiveValue = useWatch({ control, name: 'email_active' });
  const emailCandidateActiveValue = useWatch({ control, name: 'email_candidate_active' });
  const emailRefereeActiveValue = useWatch({ control, name: 'email_referee_active' });
  const smsActiveValue = useWatch({ control, name: 'sms_active' });
  const smsCandidateActiveValue = useWatch({ control, name: 'sms_candidate_active' });
  const smsRefereeActiveValue = useWatch({ control, name: 'sms_referee_active' });

  return (
    <div className='background-secondary u-padding'>
      <div className={cn('card', 'card-with-border', 'u-padding')}>
        <div className='u-margin-bottom'>
          <InputGroup>
            <ToggleSwitch 
              register={register}
              name='use_email_communication'
              label='Use email communication'
            />
          </InputGroup>

          <InputGroup>
            <ToggleSwitch 
              register={register}
              name='use_sms_communication'
              label='Use SMS communication'
            />
          </InputGroup>
        </div>
        <EditableSettings title='Use customized emails' open={emailActiveValue} toggleable register={register} name='email_active' className="u-margin-bottom">
          <EditableSettings title='Use customized emails for candidate' open={emailCandidateActiveValue} toggleable register={register} name='email_candidate_active'>
            <InputGroup title='Subject'>
              <Input register={register} name='email_candidate_subject' placeholder='Hi {{canidate}}, {{user}} has requested you complete a Checkmate application'/>
            </InputGroup>
            <InputGroup title='Body'>
              <Textarea name='email_candidate_copy' register={register} placeholder='Welcome to {{company}}, we request some background checks from you' inputProps={{ rows: 10 }} />
            </InputGroup>
            <div className={cn('u-flex-box', 'u-width-100', 'u-flex-justify-end')}>
              <Button type='secondary' className='u-margin-bottom--small' onClick={sendCandidateEmailPreview}>Preview candidate email</Button>
            </div>
          </EditableSettings>
          <EditableSettings title='Use customized emails for referees' open={emailRefereeActiveValue} toggleable register={register} name='email_referee_active'>
            <InputGroup title='Body'>
              <Textarea name='email_referee_copy' register={register} placeholder='Welcome to {{company}}, we request some background checks from you' inputProps={{ rows: 10 }} />
            </InputGroup>
            <div className={cn('u-flex-box', 'u-width-100', 'u-flex-justify-end')}>
              <Button type='secondary' className='u-margin-bottom--small' onClick={sendRefereeEmailPreview}>Preview referee email</Button>
            </div>
          </EditableSettings>
        </EditableSettings>

        <EditableSettings title='Use customized SMS' open={smsActiveValue} toggleable register={register} name='sms_active' className="u-margin-bottom">
          <EditableSettings title='Use customized SMS for candidate' className='u-margin-bottom' open={smsCandidateActiveValue} toggleable register={register} name='sms_candidate_active'>
            <InputGroup title='Body'>
              <Textarea name='sms_candidate_copy' register={register} placeholder='' inputProps={{ rows: 10 }} />
            </InputGroup>
          </EditableSettings>

          <EditableSettings title='Use customized SMS for referee' open={smsRefereeActiveValue} toggleable register={register} name='sms_referee_active'>
            <InputGroup title='Body'>
              <Textarea name='sms_referee_copy' register={register} placeholder='' inputProps={{ rows: 10 }} />
            </InputGroup>
          </EditableSettings>
        </EditableSettings>
      </div>
    </div>
  )
}