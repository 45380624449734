import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
  id: null,
  first_name: '',
  last_name: '',
  name: '',
  email: '',
  company_id: '',
  contact_number: '',
  verified: false,
  role: '',
  team: '',
  created_at: null,
  two_factor_auth_enabled: false
}


export const getUser = createAsyncThunk('getUser', async (id) => {
  const res = await apiRequest(`users/${id}`, {}, 'get');
  return res.result;
});

export const resendUserEmailValidation = createAsyncThunk('resendUserEmailValidation', async (id) => {
  const res = await apiRequest(`users/${id}/emails/validation`, {}, 'post');
  return res.result;
});

export const validateUser = createAsyncThunk('validateUser', async (token) => {
  const res = await apiRequest(`users/token/${token}/validate`, {}, 'get');
  return res.result;
})

export const resetUserPassword = createAsyncThunk('resetUserPassword', async ({ password, token, invitation = false }) => {
  let payload = { password }
  if(invitation) payload.verified = true;
  const res = await apiRequest(`users/token/${token}`, payload, 'put', true);
  return res.result;
})

export const updateUser = createAsyncThunk('updateUser', async ({ id, data }) => {
  const res = await apiRequest(`users/${id}`, data, 'put');
  return res.result;
})

export const enable2FA = createAsyncThunk('enable2FA', async ({ id, data }) => {
  const res = await apiRequest(`users/${id}/2fa`, data, 'post');
  return res.result;
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, { payload }) => {
        return payload;
      })
      .addCase(validateUser.fulfilled, (state) => {
        state.verified = true;
      })
      .addCase(updateUser.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(enable2FA.fulfilled, (state) => {
        state.two_factor_auth_enabled = true;
      })
  }
});


export const selectUser = (state) => state.user;


export default userSlice.reducer