import cn from 'classnames';
import styles from './MenuCard.module.scss';
import { useNavigate } from 'react-router-dom';

export const MenuCard = ({
  token,
  icon,
  title,
  linkTo,
  completed = false,
  blockOnComplete = false,
  rejected = false,
  fixed = false,
}) => {
  
  const navigate = useNavigate();

  const redirect = () => {
    if(blockOnComplete && completed) return;

    navigate(`/form_submission/candidate/${linkTo}/${token}`)
  }

  return (
    <div 
      onClick={redirect} 
      className={cn(styles.root, 'card', 'card-with-border', 'background-secondary', { 
        [styles.rootCompleted]: completed, 
        [styles.rootBlocked]: completed && blockOnComplete,
        [styles.rootRejected]: rejected && !fixed, 
        [styles.rootFixed]: fixed
      })}
    >
      <div className={styles.iconWrapper}>
        <img className={styles.icon} src={icon} />
      </div>
      <h1 className={cn(styles.title, 'title-4', 'u-text-centered')}>{title}</h1>
      <button className={styles.button}>
        {fixed && 'Fixed'}
        {rejected && !fixed && 'Rejected'}
        {completed && !rejected && 'Complete'}
        {!rejected && !completed && 'Incomplete'}
      </button>
    </div>
  )
}