import { createContext, useContext, useEffect, useState } from 'react';
import { Checkbox } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import styles from './CheckTable.module.scss';
import { Badge } from 'react-bootstrap';
import Moment from 'react-moment';
import { MetadataDetailsModal } from 'components/MetadataDetailsModal/MetadataDetailsModal';
import { KeyValueModal } from 'components/KeyValueModal/KeyValueModal';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import cn from 'classnames';


const CheckTableContext = createContext(null);


// Table
export const CheckTable = ({
  checkType,
  children,
  setLoading = () => { },
  onSelectedRowsChange = () => { }
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const selectRow = (id, value) => {
    setSelectedIds(selectedIds => {
      const index = selectedIds.indexOf(id);
      if (index < 0 && value) return [...selectedIds, id];
      else if (index >= 0 && !value) return selectedIds.filter(_id => id !== _id)
      else return selectedIds;
    })
  }

  useEffect(() => setSelectedIds([]), [checkType])

  useEffect(() => { onSelectedRowsChange(selectedIds) }, [selectedIds])

  const contextValue = { type: checkType, setLoading, selectAll, setSelectAll, selectRow }

  if(!checkType) return <></>;

  return (
    <CheckTableContext.Provider value={contextValue}>
      <div className={styles.root}>
        {children}
      </div>
    </CheckTableContext.Provider>
  )
}


// Header
const Header = ({ }) => {
  const { type, setSelectAll } = useContext(CheckTableContext);

  return (
    <ul className={styles.header}>
      <li className={styles.column}>
        <Checkbox className='u-margin-left--large' label={<span className='t-body'>Status</span>} onClick={setSelectAll} />
      </li>
      <li className={styles.column}>Candidate Name</li>
      {type.extra_reporting_columns?.map(extraReportingColumn => {
        const column = type.extra_result_details.find(column => extraReportingColumn === column.code);
        return (
          <li key={column.code} className={styles.column}>{column.title}</li>
        )
      })}
      {type?.has_result_flag &&
        <li className={styles.column}>Result</li>
      }
      {type?.has_expiration_date &&
        <li className={styles.column}>Expiry date</li>
      }
      <li className={styles.column}>Completed Date</li>
      <li className={cn(styles.column, styles.actionsColumn)}></li>
    </ul>
  )
}
CheckTable.Header = Header;


// Rows
const Rows = ({ children }) => <div className={styles.rows}>{children}</div>
CheckTable.Rows = Rows;


// Row
const Row = ({ candidateCheck, dateFormat }) => {
  const { type, setLoading, selectAll, selectRow } = useContext(CheckTableContext)

  const [showMetadata, setShowMetadata] = useState(false);
  const [showExtraDetails, setShowExtraDetails] = useState(false);

  const [selected, setSelected] = useState(false);

  useEffect(() => { setSelected(selectAll) }, [selectAll])

  useEffect(() => selectRow(candidateCheck.id, selected), [selected])

  const handleResultDownload = () => {
    setLoading(true);
    saveAs(candidateCheck.result_upload.url, `${candidateCheck.first_name} ${candidateCheck.last_name} ${type.title} Result`);
    setLoading(false);
  }

  const renderDynamicColumnValue = (column, fromExtraData = false) => {
    const value = fromExtraData ? candidateCheck?.extra_data?.[column.key] : candidateCheck[column.key];
    if (!value) return <li key={column.key} className={styles.column}></li>

    switch (column.type) {
      case 'datetime':
        return <li key={column.key} className={styles.column}><Moment format="DD/MM/YYYY @HH:mm">{value}</Moment></li>
      case 'date':
        return <li key={column.key} className={styles.column}><Moment format="DD/MM/YYYY">{value}</Moment></li>
      default:
        return <li key={column.key} className={styles.column}>{value}</li>
    }
  }

  return (
    <ul className={styles.row}>
      <MetadataDetailsModal visible={showMetadata} onClose={() => setShowMetadata(false)} userAgent={candidateCheck.user_agent} country={candidateCheck.country} />
      {candidateCheck.extra_data &&
        <KeyValueModal visible={showExtraDetails} onClose={() => setShowExtraDetails(false)} title="More Details" data={candidateCheck.extra_data} />
      }
      <li className={styles.column}>
        <Checkbox checked={selected} className='u-margin-left--large' label={<Badge className={`u-status-${candidateCheck.status}`}>{candidateCheck.status}</Badge>} onClick={setSelected}
      />
      </li>
      <li className={styles.column}>
        <Link to={`/check/${candidateCheck.candidate_id}/${type.candidate_tab}`} className='u-link dark'>{`${candidateCheck.first_name} ${candidateCheck.last_name}`}</Link>
      </li>
      {type.extra_reporting_columns?.map(extraReportingColumn => {
        return (
          <li key={extraReportingColumn} className={styles.column}>{candidateCheck.extra_data?.[extraReportingColumn]}</li>
        )
      })}
      {type?.has_result_flag &&
        <li className={styles.column}>
          {candidateCheck.completed_at && !candidateCheck.canceled &&
            <Badge className={cn({ ['u-status-completed-with-result']: candidateCheck.result_flag, ['u-status-completed']: !candidateCheck.result_flag })}>
              {candidateCheck.result_flag ? type.true_flag_label : type.false_flag_label}
            </Badge>
          }
        </li>
      }
      {type?.has_expiration_date &&
        <li className={styles.column}>
          {candidateCheck.completed_at && candidateCheck.expiration_date ?
            <Moment format={dateFormat}>{candidateCheck.expiration_date}</Moment> : '-'
          }
        </li>
      }
      <li className={styles.column}>
        {candidateCheck.completed_at && !candidateCheck.canceled ? <Moment format={dateFormat}>{candidateCheck.completed_at}</Moment> : '-'}
      </li>
      <li className={cn(styles.column, styles.actionsColumn)}>
        {candidateCheck.completed_at && candidateCheck.extra_data &&
          <IconButton className='u-margin-right' icon='file-text' tip='More details' onClick={() => setShowExtraDetails(true)} />
        }
        {candidateCheck.completed_at && candidateCheck.user_agent &&
          <IconButton className='u-margin-right' icon='monitor' tip='Metadata details' onClick={() => setShowMetadata(true)} />
        }
        {candidateCheck.completed_at && candidateCheck.result_upload &&
          <IconButton className='u-margin-right' icon='download' tip='Download result' onClick={handleResultDownload} />
        }
      </li>
    </ul>
  )
}
CheckTable.Row = Row;
