import { useState, useRef, useCallback } from 'react';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { CustomButton } from '../CustomButton/CustomButton';
import Webcam from "react-webcam";

export const CameraModal = ({
  showModal,
  candidate,
  brand,
  onClose      = () => {},
  selfieUpload = () => { },
  setLoading   = () => {},
  onCompleted  = () => {}
}) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);


  const selfieUploadFunction = async () => {
    onClose();
    setLoading(true);

    await selfieUpload({
      bucket_folder: 'background_applications',
      file_name: `${candidate.id}-virtual-id.jpeg`,
      body: imgSrc,
      extension: 'jpeg',
      content_type: `image/jpeg`,
      candidate_id: candidate.id
    });

    setLoading(false);
    onCompleted();
  }

  return (
    <AnimatedModal visible={showModal} onClose={onClose}>
      {!imgSrc ?
        <div>
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width="100%" />
        </div> :
        <img src={imgSrc} />
      }
      <br />
      {imgSrc && 
        <CustomButton brand={brand} onClick={() => setImgSrc(null)} type="outline">Re-Capture</CustomButton>
      }
      {!imgSrc ? 
        <CustomButton brand={brand} onClick={capture}>Capture</CustomButton> :
        <CustomButton brand={brand} onClick={selfieUploadFunction}>Save</CustomButton>
      }
    </AnimatedModal>
  )
}