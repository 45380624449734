import styles from './InputGroup.module.scss';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import cn from 'classnames';

export const InputGroup = ({ 
  children, 
  className,
  description,
  title,
  tooltip,
  error,
  strong=false,
  inline=false
}) => (
  <div className={cn(styles.root, { [className]: className, [styles.rootInline]: inline })}>
    <span className={cn(styles.title, { [styles.titleStrong]: strong, [styles.titleError]: error, [styles.titleNoMargin]: !!description })}>
      {title}
      {tooltip &&
        <OverlayTrigger placement='bottom' overlay={<Tooltip>{tooltip}</Tooltip>}>
          <span className={styles.tooltip}><FeatherIcon icon="info"/></span>
        </OverlayTrigger>
      }
    </span>
    {description &&
      <span className='u-margin-bottom--small d-block t-tiny text-secondary'>{description}</span>
    }
    <div className={styles.input}>
      {children}
    </div>
  </div>
)

InputGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  children: PropTypes.any,
  inline: PropTypes.bool,
  strong: PropTypes.bool,
};