import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCandidateApplication, updateCandidateCheck } from 'api/candidate-application-slice';
import { Checkbox, Button } from 'components/FormComponents';
import styles from './USCriminalCheckDisclosures.module.scss';
import cn from 'classnames';
import { CustomLink } from '../components/CustomLink/CustomLink';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { now } from 'helpers/date';

const USCriminalCheckDisclosures = ({}) => {

  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { application: { candidate_checks, brand }, fetched } = useSelector(selectCandidateApplication);

  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(1);
  const [check, setCheck] = useState();

  useEffect(() => { 
    if(!fetched) return;

    setCheck(candidate_checks.find(check => check.type === 'us_full_criminal_and_continuous_monitoring_check'));
  }, [fetched]);

  const change = async() => {
    if(key === 4) {
      setLoading(true);
      await dispatch(updateCandidateCheck({ token, id: check.id, data: { submitted_at: now() }}));
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    } else {
      setKey(curr => curr + 1);
      window.scrollTo(0, 0);
    }
  }

  return (
    <CandidatePage loading={loading} brand={brand} token={token} title='Criminal Check Disclosures' withTopLogo>
      <CandidatePage.View className='d-flex no-wrap align-items-start direction-row'>
        <DisclosureCard 
          cardKey={1}
          enabledKey={key}
          onClick={change}
          title='Disclosure about consumer report'
          content={
            <span>
              i9 Sports (“the Company”) may obtain one or more consumer reports about you at your written instruction in order for you to become a volunteer, vendor, independent contractor, student enrollee, student intern, and/or another non-employment purpose. The consumer reports may include information about your character, general reputation, personal characteristics, and mode of living.  <br />
              The Company will obtain these consumer reports from Checkmate, Inc., a third-party consumer reporting agency. Checkmate’s address is 651 N.Broad Street, Suite 206 Middletown, DE 19709. Checkmate’s telephone number is +1 415 655 1976. Checkmate’s email is support@checkmate.tech and its website is www.checkmate.tech. <br />
              In preparing the consumer report, Checkmate, Inc. may investigate your education, work history, professional license and credentials, references, address history, social security number validity, right to work, criminal record, lawsuits, driving record and other information from public or private sources.
            </span>
          }
        />
        <DisclosureCard 
          cardKey={2}
          enabledKey={key}
          onClick={change}
          title='Disclosure about investigative consumer report'
          content={
            <span>
              i9 Sports (“the Company”) may obtain one or more investigative consumer reports about you at your written instruction in order for you to become a volunteer, vendor, independent contractor, student enrollee, student intern, and/or another non-employment purpose.  An “investigative consumer report” means a consumer report that includes personal interviews with your neighbors, friends, employers, or associates. The investigative consumer reports may include information about your character, general reputation, personal characteristics, and mode of living.<br />
              The Company will obtain these investigative consumer reports from Checkmate, Inc., a third-party consumer reporting agency. Checkmate’s address is 651 N.Broad Street, Suite 206 Middletown, DE 19709. Checkmate’s telephone number is +1 415 655 1976. Checkmate’s email is support@checkmate.tech and its website is www.checkmate.tech.<br />
              You have the right to request additional disclosures and information about the nature and scope of an investigative consumer report by contacting the Company. You also have the right to request a written copy of “A Summary of Your Rights Under the Fair Credit Reporting Act” by contacting the Company.
            </span>
          }
        />
        <DisclosureCard 
          cardKey={3}
          enabledKey={key}
          onClick={change}
          title='A Summary of Your Rights Under the Fair Credit Reporting Act'
          content={
            <>
              <i className='t-small'>Para información en español, visite <CustomLink brand={brand} href='www.consumerfinance.gov/learnmore'>www.consumerfinance.gov/learnmore</CustomLink> o escribe a la  Consumer Financial Protection Bureau, 1700 G Street NW, Washington, DC 20552. </i><br /><br />

              The federal Fair Credit Reporting Act (FCRA) promotes the accuracy, fairness, and  privacy of information in the files of consumer reporting agencies. There are many types of  consumer reporting agencies, including credit bureaus and specialty agencies (such as agencies  that sell information about check writing histories, medical records, and rental history records). Here is a summary of your major rights under FCRA. <b>For more information, including information about additional rights, go to <CustomLink brand={brand} href='www.consumerfinance.gov/learnmore'>www.consumerfinance.gov/learnmore</CustomLink> or write to: Consumer Financial Protection Bureau, 1700 G Street NW, Washington, DC 20552. </b><br /><br />

              <ul>
                <li><b>You must be told if information in your file has been used against you.</b> Anyone who uses a credit report or another type of consumer report to deny your application for credit,  insurance, or employment – or to take another adverse action against you – must tell you, and must give you the name, address, and phone number of the agency that provided the  information. </li>
                <li className='u-padding-top--large'>
                  <b>You have the right to know what is in your file.</b> You may request and obtain all the  information about you in the files of a consumer reporting agency (your “file  disclosure”). You will be required to provide proper identification, which may include  your Social Security number. In many cases, the disclosure will be free. You are entitled  to a free file disclosure if: 
                  <ul className='u-padding-top u-padding-bottom'>
                    <li>a person has taken adverse action against you because of information in your  credit report; </li>
                    <li>o you are the victim of identity theft and place a fraud alert in your file; </li>
                    <li>o your file contains inaccurate information as a result of fraud; </li>
                    <li>o you are on public assistance; </li>
                    <li>o you are unemployed but expect to apply for employment within 60 days. </li>
                  </ul>
                  In addition, all consumers are entitled to one free disclosure every 12 months upon  request from each nationwide credit bureau and from nationwide specialty consumer reporting agencies. See <CustomLink brand={brand} href='www.consumerfinance.gov/learnmore'>www.consumerfinance.gov/learnmore</CustomLink> for additional  information. 
                </li>
                <li className='u-padding-top--large'>
                  <b>You have the right to ask for a credit score.</b> Credit scores are numerical summaries of  your credit-worthiness based on information from credit bureaus. You may request a  credit score from consumer reporting agencies that create scores or distribute scores used  in residential real property loans, but you will have to pay for it. In some mortgage  transactions, you will receive credit score information for free from the mortgage lender. 
                </li>

                <li className='u-padding-top--large'>
                  <b>You have the right to dispute incomplete or inaccurate information.</b> If you identify  information in your file that is incomplete or inaccurate, and report it to the consumer reporting agency, the agency must investigate unless your dispute is frivolous. See  www.consumerfinance.gov/learnmore for an explanation of dispute procedures. 
                </li>

                <li className='u-padding-top--large'>
                  <b>Consumer reporting agencies must correct or delete inaccurate, incomplete, or  unverifiable information.</b> Inaccurate, incomplete, or unverifiable information must be  removed or corrected, usually within 30 days. However, a consumer reporting agency  may continue to report information it has verified as accurate.
                </li>

                <li className='u-padding-top--large'>
                  <b>Consumer reporting agencies may not report outdated negative information.</b> In  most cases, a consumer reporting agency may not report negative information that is  more than seven years old, or bankruptcies that are more than 10 years old. 
                </li>

                <li className='u-padding-top--large'>
                  <b>Access to your file is limited.</b> A consumer reporting agency may provide information  about you only to people with a valid need – usually to consider an application with a  creditor, insurer, employer, landlord, or other business. The FCRA specifies those with a  valid need for access. 
                </li>

                <li className='u-padding-top--large'>
                  <b>You must give your consent for reports to be provided to employers.</b> A consumer  reporting agency may not give out information about you to your employer, or a potential  employer, without your written consent given to the employer. Written consent generally  is not required in the trucking industry. For more information, go to <CustomLink brand={brand} href='www.consumerfinance.gov/learnmore'>www.consumerfinance.gov/learnmore</CustomLink>
                </li>

                <li className='u-padding-top--large'>
                  <b>You may limit “prescreened” offers of credit and insurance you get based on information in your credit report.</b> Unsolicited “prescreened” offers for credit and  insurance must include a toll-free phone number you can call if you choose to remove your name and address from the lists these offers are based on. You may opt out with the  nationwide credit bureaus at 1-888-567-8688. 
                </li>

                <li className='u-padding-top--large'>
                  The following FCRA right applies with respect to nationwide consumer reporting  agencies: 
                  <h5 className='u-margin-y--large'>CONSUMERS HAVE THE RIGHT TO OBTAIN A SECURITY FREEZE </h5>
                  <b>You have a right to place a “security freeze” on your credit report, which will prohibit a consumer reporting agency from releasing information in your credit report without your express authorization.</b> The security freeze is designed to prevent credit, loans, and services from being approved in your name without your consent.  However, you should be aware that using a security freeze to take control over who gets  access to the personal and financial information in your credit report may delay, interfere  with, or prohibit the timely approval of any subsequent request or application you make  regarding a new loan, credit, mortgage, or any other account involving the extension of  credit. <br /><br />
                  As an alternative to a security freeze, you have the right to place an initial or extended  fraud alert on your credit file at no cost. An initial fraud alert is a 1-year alert that is placed on a consumer’s credit file. Upon seeing a fraud alert display on a consumer’s  credit file, a business is required to take steps to verify the consumer’s identity before  extending new credit. If you are a victim of identity theft, you are entitled to an extended  fraud alert, which is a fraud alert lasting 7 years. <br /><br />
                  A security freeze does not apply to a person or entity, or its affiliates, or collection  agencies acting on behalf of the person or entity, with which you have an existing  account that requests information in your credit report for the purposes of reviewing or  collecting the account. Reviewing the account includes activities related to account  maintenance, monitoring, credit line increases, and account upgrades and enhancements. 
                </li>

                <li className='u-padding-top--large'>
                  <b>You may seek damages from violators.</b> If a consumer reporting agency, or, in some  cases, a user of consumer reports or a furnisher of information to a consumer reporting  agency violates the FCRA, you may be able to sue in state or federal court.
                </li>

                <li className='u-padding-top--large'>
                  <b>Identity theft victims and active duty military personnel have additional rights.</b> For  more information, visit <CustomLink brand={brand} href='www.consumerfinance.gov/learnmore'>www.consumerfinance.gov/learnmore</CustomLink>. 
                </li>
              </ul>

              <p className='u-padding-top--large'>
                <b>States may enforce the FCRA, and many states have their own consumer reporting laws.  In some cases, you may have more rights under state law. For more information, contact  your state or local consumer protection agency or your state Attorney General. For  information about your federal rights, contact:</b>
              </p>

              <table className={styles.table}>
                <tr>
                  <th>TYPE OF BUSINESS</th>
                  <th>CONTACT</th>
                </tr>
                <tr>
                  <td>
                    1.a. Banks, savings associations, and credit unions with  total assets of over $10 billion and their affiliates <br />
                    b. Such affiliates that are not banks, savings associations, or credit unions also should list, in addition  to the CFPB:
                  </td>
                  <td>
                    a. Consumer Financial Protection Bureau 1700 G Street NW Washington, DC 20552 <br />
                    b. Federal Trade Commission Consumer Response Center 600 Pennsylvania Avenue NW Washington, DC 20580 (877) 382-4357
                  </td>
                </tr>
                <tr>
                  <td>
                    2. To the extent not included in item 1 above: a. National banks, federal savings associations, and  federal branches and federal agencies of foreign banks <br />
                    b. State member banks, branches and agencies of  foreign banks (other than federal branches, federal  agencies, and Insured State Branches of Foreign Banks),  commercial lending companies owned or controlled by  foreign banks, and organizations operating under section  25 or 25A of the Federal Reserve Act. <br />
                    c. Nonmember Insured Banks, Insured State Branches of  Foreign Banks, and insured state savings associations <br />
                    d. Federal Credit Unions
                  </td>
                  <td>
                    a. Office of the Comptroller of the Currency Customer Assistance Group P.O. Box 53570 Houston, TX 77052 <br />
                    b. Federal Reserve Consumer Help Center P.O. Box 1200 Minneapolis, MN 55480 <br />
                    c. Division of Depositor and Consumer Protection National Center for Consumer and Depositor Assistance Federal Deposit Insurance Corporation 1100 Walnut Street, Box #11 Kansas City, MO 64106 <br />
                    d. National Credit Union Administration Office of Consumer Financial Protection 1775 Duke Street Alexandria, VA 22314
                  </td>
                </tr>
                <tr>
                  <td>
                    3. Air carriers 
                  </td>
                  <td>
                    Assistant General Counsel for Office of Aviation Consumer Protection Department of Transportation 1200 New Jersey Avenue SE Washington, DC 20590
                  </td>
                </tr>
                <tr>
                  <td>
                    4. Creditors Subject to the Surface Transportation Board 
                  </td>
                  <td>
                    Office of Public Assistance, Governmental Affairs, and Compliance Surface Transportation Board 395 E Street SW Washington, DC 20423
                  </td>
                </tr>
                <tr>
                  <td>
                    5. Creditors Subject to the Packers and Stockyards Act,  1921
                  </td>
                  <td>
                    Nearest Packers and Stockyards Division Regional Office
                  </td>
                </tr>
                <tr>
                  <td>
                    6. Small Business Investment Companies 
                  </td>
                  <td>
                    Associate Administrator, Office of Capital Access United States Small Business Administration 409 Third Street SW, Suite 8200 Washington, DC 20416
                  </td>
                </tr>
                <tr>
                  <td>
                    7. Brokers and Dealers 
                  </td>
                  <td>
                    Securities and Exchange Commission 100 F Street NE Washington, DC 20549
                  </td>
                </tr>
                <tr>
                  <td>
                    8. Institutions that are members of the Farm Credit System
                  </td>
                  <td>
                    Farm Credit Administration 1501 Farm Credit Drive McLean, VA 22102-5090
                  </td>
                </tr>
                <tr>
                  <td>
                    9. Retailers, Finance Companies, and All Other  Creditors Not Listed Above
                  </td>
                  <td>
                    Federal Trade Commission Consumer Response Center 600 Pennsylvania Avenue NW Washington, DC 20580 (877) 382-4357
                  </td>
                </tr>
              </table>
            </>
          }
        />
        <DisclosureCard 
          cardKey={4}
          enabledKey={key}
          onClick={change}
          title='Notice regarding background checks per california law'
          content={
            <span>
              i9 Sports, the "Company," intends to obtain information about you for employment screening purposes from an investigative consumer reporting agency (“ICRA”). Therefore, you can expect to be the subject of "investigative consumer reports" obtained for employment purposes. The term “employment purposes” means a report used for the purpose of evaluating a consumer for employment, promotion, reassignment, or retention as an employee. Investigative consumer reports may include information about your character, general reputation, personal characteristics, and mode of living. The Company may investigate the information contained in your employment application and other background information about you, including but not limited to obtaining a criminal record report, verifying references, work history, your social security number, your educational achievements, licensure, and certifications, your driving record, and other information about you, and interviewing people who are knowledgeable about you. The results of this report may be used as a factor in making employment decisions. The source of any investigative consumer report will be Checkmate, Inc. Checkmate’s address is 651 N.Broad Street, Suite 206 Middletown, DE 19709. Checkmate’s telephone number is +1 415 655 1976. Checkmate’s email is team@checkmate.tech and its website is www.checkmate.tech. The Company agrees to provide you with a copy of an investigative consumer report when required to do so under California law. <br/><br/>
              Under California Civil Code Section 1786.22, you are entitled to find out what is in the ICRA's file on you with proper identification, as follows:<br/><br/>
              <ul>
                <li className='u-margin-bottom'>In person, by visual inspection of your file during normal business hours and on reasonable notice. You also may request a copy of the information in person. The ICRA may not charge you more than the actual copying costs for providing you with a copy of your file.</li>
                <li className='u-margin-bottom'>A summary of all information contained in the ICRA's file on you that is required to be provided by the California Civil Code Section 1786.10 will be provided to you via telephone, if you have made a written request, with proper identification, for telephone disclosure, and the toll charge, if any, for the telephone call is prepaid by or charged directly to you.</li>
                <li>By certified mail, if you make a written request, with proper identification, for copies to be sent to a specified addressee.  ICRAs complying with requests for certified mailings will not be liable for disclosures to third parties caused by mishandling of mail after such mailings leave the ICRAs.</li>
              </ul>
              "Proper Identification" includes documents such as a valid driver's license, social security account number, military identification card, and credit cards. Only if you cannot identify yourself with such information may the ICRA require additional information concerning your employment and personal or family history in order to verify your identity.<br/><br/>
              The ICRA will provide trained personnel to explain any information furnished to you and will provide a written explanation of any coded information contained in files maintained on you. This written explanation will be provided whenever a file is provided to you for visual inspection.<br/><br/>
              You may be accompanied by one other person of your choosing, who must furnish reasonable identification. An ICRA may require you to furnish a written statement granting permission to the ICRA to discuss your file in such person’s presence.
            </span>
          }
        />
      </CandidatePage.View>
    </CandidatePage>
  )
}


const DisclosureCard = ({ title, content, cardKey, enabledKey, onClick = () => {} }) => {

  const [acknowledge, setAcknowledge] = useState(false);

  return (
    <div className={cn('card', 'card-with-border', styles.disclosure, { [styles.disclosureVisible]: cardKey === enabledKey})}>
      <div className='card_content'>
        <h3>{title}</h3>
        <p className='u-margin-top'>{content}</p>
        <div className='d-flex align-items-center u-width-100'>
          <Checkbox checked={acknowledge} onClick={() => setAcknowledge(curr => !curr)} />
          <span className='t-small'>I acknowledge that I have received and understand this document</span>
        </div>
        <div className='d-flex u-width-100 u-margin-top--large justify-content-between'>
          <Button type='outline'>Download</Button>
          <Button disabled={!acknowledge} onClick={onClick}>Continue</Button>
        </div>
      </div>
    </div>
  )
}


export default USCriminalCheckDisclosures