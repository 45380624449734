import { useGetKnowMyJourneysQuery, useLazyGetKnowMyJourneyQuery, useLaunchKnowMyJourneyMutation } from 'api/integration-api';
import { useLocale } from 'hooks/locale';
import { useEffect, useState } from 'react';
import { Loading } from 'components/Loading/Loading';
import { Select, Input, InputGroup, DatePicker, Button } from 'components/FormComponents';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { CollapsibleBlock } from 'components/CollapsibleBlock/CollapsibleBlock';
import styles from './KnowMyIntegration.module.scss';
import { MESSAGE_STATE_SUCCESS } from 'constants/message-app-state-contants';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectCompany } from 'api/company-slice';

export const KnowMyIntegration = ({ 
  candidate,
  messagePopUp = () => {},
  onClose      = () => {}
}) => {

  const company = useSelector(selectCompany);

  const { data: journeys, isLoading } = useGetKnowMyJourneysQuery();
  const { dateHyphenFormat } = useLocale(company?.locale);
  
  const [getKnowMyJourney] = useLazyGetKnowMyJourneyQuery();
  const [launchKnowMyJourney] = useLaunchKnowMyJourneyMutation();

  const [loading, setLoading] = useState(false);
  
  const [externalStakeholder, setExternalStakeholder] = useState({});
  const [internalStakeholders, setInternalStakeholders] = useState([]);
  const [documentsWithCustomFields, setDocumentsWithCustomFields] = useState([]);

  const { register, control, setValue, reset, handleSubmit, formState: { errors }, watch } = useForm();
  useFieldArray({ control, name: "internal_stakeholders_attributes" });
  useFieldArray({ control, name: "documents_custom_fields" });

  const journeyIdValue = watch(`journey_id`);

  useEffect(() => {
    if(!journeyIdValue) return;

    setLoading(true);
    getKnowMyJourney(journeyIdValue).unwrap().then(
      ({ configuration: config, stakeholder_tags: stakeholderTags, blocks }) => {

        let externalStakeholder = config.external_stakeholder_attributes;
        externalStakeholder.tag = stakeholderTags.find(tags => tags.id === externalStakeholder.stakeholder_tag_id);
        setExternalStakeholder(externalStakeholder);

        let internalStakeholders = config.internal_stakeholders_attributes;
        internalStakeholders.forEach(stakeholder => {
          stakeholder.tag = stakeholderTags.find(tags => tags.id === stakeholder.stakeholder_tag_id)
        });
        setInternalStakeholders(internalStakeholders);

        let documentsWithCustomFields = config.documents_custom_fields;
        documentsWithCustomFields.forEach(documentWithCustomFields => {
          documentWithCustomFields.block = blocks.find(block => block.id === documentWithCustomFields.block_id)
          documentWithCustomFields.tag = stakeholderTags.find(tags => tags.id === documentWithCustomFields.stakeholder_tag_id)
        });
        setDocumentsWithCustomFields(documentsWithCustomFields);

        reset({
          journey_id: journeyIdValue,
          external_stakeholder_attributes: {
            first_name: candidate.first_name,
            last_name: candidate.last_name,
            email: candidate.email,
            phone_number: candidate.contact_number
          }
        });

        setLoading(false);
      }
    )
  }, [journeyIdValue])

  const onSubmit = async(data) => {
    setLoading(true);
    await launchKnowMyJourney(data);
    messagePopUp("KnowMy journey successfully launched", MESSAGE_STATE_SUCCESS);
    onClose();
    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loading active={loading || isLoading} />
      <InputGroup title='Journeys' className='u-margin-top--large'>
        <Select name={`journey_id`} register={register} value={journeyIdValue} useDefault>
          {journeys.map(journey => (
            <Select.Item key={journey.id} value={journey.id}>{journey.name}</Select.Item>
          ))}
        </Select>
      </InputGroup>
      
      <CollapsibleBlock color={externalStakeholder?.tag?.color} className={styles.marginBottom} startOpen>
        <CollapsibleBlock.Header>
          <h1 className={styles.blockTitle}>
            <span>
              <span style={{ color: externalStakeholder?.tag?.color }}>{externalStakeholder?.tag?.name}</span>
              Details
            </span>
            <p className={cn(styles.subTitle, "t-subtitle")}>Enter the details of the {externalStakeholder?.tag?.name} below</p>
          </h1>
        </CollapsibleBlock.Header>
        <CollapsibleBlock.Content className="margin-top">
          <ExternalStakeholderForm register={register} control={control} errors={errors} dateFormat={dateHyphenFormat} />
        </CollapsibleBlock.Content>
      </CollapsibleBlock>

      {internalStakeholders.map((internalStakeholder, index) => (
        <CollapsibleBlock key={index} color={internalStakeholder.tag.color} className={styles.marginBottom} startOpen>
          <CollapsibleBlock.Header>
            <h1 className={styles.blockTitle}>
              <span>
                <span style={{ color: internalStakeholder.tag.color }}>{internalStakeholder.tag.name}</span>
                Details
              </span>
              <p className={cn(styles.subTitle, "t-subtitle")}>Enter the details of the {internalStakeholder.tag.name} below</p>
            </h1>
          </CollapsibleBlock.Header>
          <CollapsibleBlock.Content className="margin-top">
            <InternalStakeholderForm
              register={register}
              registerName={`internal_stakeholders_attributes.${index}`}
              errors={errors}
            />
          </CollapsibleBlock.Content>
        </CollapsibleBlock>
      ))}

      {documentsWithCustomFields.map(({ block, tag, custom_fields }, index) => (
        <CollapsibleBlock key={index} color={tag.color} className={styles.marginBottom} startOpen>
          <CollapsibleBlock.Header>
            <h1 className={styles.blockTitle}>{block.name} Fields</h1>
          </CollapsibleBlock.Header>
          <CollapsibleBlock.Content className="margin-top">
            <DocumentBlockCustomFieldsForm
              register={register}
              registerName={`documents_custom_fields.${index}`}
              block={block}
              documentFields={custom_fields}
              control={control}
              setValue={setValue}
              errors={errors?.documents_custom_fields?.[index]}
            />
          </CollapsibleBlock.Content>
        </CollapsibleBlock>
      ))}

      <Button submit className='u-width-100 u-margin-top--large'>Launch</Button>
    </form>
  )

}


const ExternalStakeholderForm = ({ register, control, errors, dateFormat }) => (
  <>
    <div className="inline">
      <InputGroup title="First Name">
        <Input
          name="external_stakeholder_attributes.first_name"
          placeholder="Add first name..."
          register={register}
          validators={{ required: true }}
          error={errors?.external_stakeholder_attributes?.first_name?.type}
        />
      </InputGroup>
      <InputGroup title="Last Name">
        <Input
          name="external_stakeholder_attributes.last_name"
          placeholder="Add last name..."
          register={register}
          validators={{ required: true }}
          error={errors?.external_stakeholder_attributes?.last_name?.type}
        />
      </InputGroup>
    </div>
    <div className="inline">
      <InputGroup title="Email">
        <Input
          name="external_stakeholder_attributes.email"
          placeholder="Add email address..."
          register={register}
          validators={{ required: true }}
          error={errors?.external_stakeholder_attributes?.email?.type}
        />
      </InputGroup>
      <InputGroup title="Phone Number">
        <Input
          name="external_stakeholder_attributes.phone_number"
          placeholder="Add phone number..."
          register={register}
          validators={{ required: true }}
          error={errors?.external_stakeholder_attributes?.phone_number?.type}
        />
      </InputGroup>
    </div>
    <InputGroup title="Start Date">
      <Controller
        control={control}
        name="external_stakeholder_attributes.start_date"
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <DatePicker value={value} onChange={onChange} minDate={new Date()} format={dateFormat} outputFormat="DD/MM/YYYY" />
        )}
      />
    </InputGroup>
  </>
);

const InternalStakeholderForm = ({
  register,
  registerName,
  errors,
}) => {
  return (
    <>
      <div className="inline">
        <InputGroup title="First Name">
          <Input
            name={`${registerName}.first_name`}
            placeholder="Add first name..."
            register={register}
            validators={{ required: true }}
            error={errors?.internal_stakeholders_attributes ? errors?.internal_stakeholders_attributes[0]?.first_name?.type : null}
          />
        </InputGroup>
        <InputGroup title="Last Name">
          <Input
            name={`${registerName}.last_name`}
            placeholder="Add last name..."
            register={register}
            validators={{ required: true }}
            error={errors?.internal_stakeholders_attributes ? errors?.internal_stakeholders_attributes[0]?.last_name?.type : null}
          />
        </InputGroup>
      </div>
      <div className="inline">
        <InputGroup title="Email">
          <Input
            name={`${registerName}.email`}
            placeholder="Add email address..."
            register={register}
            validators={{ required: true }}
            error={errors?.internal_stakeholders_attributes ? errors?.internal_stakeholders_attributes[0]?.email?.type : null}
          />
        </InputGroup>
        <InputGroup title="Phone Number">
          <Input
            name={`${registerName}.phone_number`}
            placeholder="Add phone number..."
            register={register}
            validators={{ required: true }}
            error={errors?.internal_stakeholders_attributes ? errors?.internal_stakeholders_attributes[0]?.phone_number?.type : null}
          />
        </InputGroup>
      </div>
    </>
  );
};

const DocumentBlockCustomFieldsForm = ({ register, block, documentFields = null, control, registerName, setValue, errors }) => {
  const { fields: customFields, append } = useFieldArray({ control, name: `${registerName}.custom_fields` });

  useEffect(() => {
    setValue(registerName, { block_id: block.id });
    documentFields.forEach((customField) => {
      append({ name: customField.name, value: "" });
    });
  }, []);

  return (
    <>
      {customFields.map((customField, index) => (
        <DocumentBlockCustomField
          key={index}
          customField={customField}
          register={register}
          registerName={`${registerName}.custom_fields.${index}`}
          errors={errors?.custom_fields ? errors?.custom_fields[index] : null}
        />
      ))}
    </>
  );
};

const DocumentBlockCustomField = ({ register, registerName, customField, errors }) => {
  return (
    <InputGroup title={customField.name}>
      <Input
        name={`${registerName}.value`}
        placeholder="Enter value..."
        register={register}
        validators={{ required: true }}
        error={errors?.value?.type}
      />
    </InputGroup>
  );
};