import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { InputGroup, Input, Button } from "components/FormComponents";
import { useState } from 'react';
import { IntegrationCard } from '..';
import { useOAuth } from "hooks/oauth";

export const LeverIntegration = ({
  integration,
  config,
  setLoading    = () => {},
  popUpMessage  = () => {},
  connect       = () => {},
  disconnect    = () => {},
  updateCompany = () => {},
}) => {

  const [enabled, setEnabled] = useState(integration.enabled);

  const [stageId, setStageId] = useState(integration?.stage_id);
  const [signatureToken, setSignatureToken] = useState(integration?.signature_token);

  const onConnectSuccess = async(code) => {
    setLoading(true);
    try {
      await connect(code);
      popUpMessage("Lever integrated with Checkmate", MESSAGE_STATE_SUCCESS);
      setEnabled(true);
    } catch (e) {
      popUpMessage("Lever integration failed. Please check your credentials and try again.", MESSAGE_STATE_ERROR);
      setEnabled(false);
    }
    setLoading(false);
  }

  const onConnectError = ({ error, errorDescription }) => {
    popUpMessage(`Lever integration failed. Error: ${error}. Error description: ${errorDescription}`, MESSAGE_STATE_ERROR);
  }

  const openAuth = useOAuth({
    authUri: 'https://auth.lever.co/authorize',
    clientId: 'jGY5PAiAFsT9rA6q5IkZG3nZNudmdVYt', 
    responseType: 'code',
    redirectUri: `${process.env.REACT_APP_WEB_URL}/assets/lever_callback.html`,
    scope: 'offline_access users:read:admin postings:read:admin applications:read:admin opportunities:write:admin tags:read:admin notes:write:admin stages:read:admin files:write:admin webhooks:write:admin',
    callbackAction: 'lever_callback',
    extraParams: {
      audience: 'https://api.lever.co/v1/'
    },
    callback: onConnectSuccess,
    onError: onConnectError
  });

  const toggleIntegration = async(e) => {
    const value = e.target.checked;
    if(integration.enabled === value) return;

    if(value) {
      openAuth();
    } else {
      setLoading(true);
      await disconnect();
      setLoading(false);
      setEnabled(false);
    }
  }

  const update = async () => {
    setLoading(true);
    await updateCompany({
      lever_stage_id: stageId,
      lever_signature_token: signatureToken,
    });
    popUpMessage("Configuration updated", MESSAGE_STATE_SUCCESS);
    setLoading(false);
  };

  return (
    <IntegrationCard {...config} enabled={enabled} onToggle={toggleIntegration}>
      {enabled && 
        <>
          <InputGroup title='Lever Stage ID'>
          <Input 
            placeholder='fff60592-31dd-4ebe-ba8e-e7a397c30f8e'
            inputProps={{
                value: stageId,
                onChange: (e) => setStageId(e.target.value)
              }}
            />
          </InputGroup>

          <InputGroup title='Lever Webhook Signature Token'>
          <Input 
              placeholder='b3d4b930ecf666ee64c7ccee8148202d9c7bc44c75cf4448'
              inputProps={{
                value: signatureToken,
                onChange: (e) => setSignatureToken(e.target.value)
              }}
            />
          </InputGroup>
          <Button onClick={update} type='secondary' className='u-width-100 u-margin-top--large'>Save</Button>
        </>
      }
    </IntegrationCard>
  );
};
