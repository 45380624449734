import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import styles from './PhoneNumberPicker.module.scss';
import cn from 'classnames';

export const PhoneNumberPicker = ({
  value,
  className,
  tabIndex=-1,
  onChange = () => {},
  defaultCountry = 'NZ',
  error,
}) => (
  <PhoneInput
    className={cn(styles.root, { [styles.rootInvalid]: !!error, [className]: className })}
    placeholder="Enter phone number"
    value={value}
    defaultCountry={defaultCountry}
    onChange={onChange}
    tabIndex={tabIndex}
  />
)