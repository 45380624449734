import { useEffect, useState } from 'react';
import { useLazyGetReportQuery } from 'api/reports-api';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { Select, DatePicker, InputGroup, Button } from 'components/FormComponents';
import { useForm, useWatch, Controller } from 'react-hook-form';
import styles from './AdminReporting.module.scss';
import cn from 'classnames';
import { useDocumentTitle } from 'hooks/document-title';
import { Reports } from 'constants/reports';

const AdminReporting = () => {

  useDocumentTitle('Reporting');

  const [getReport] = useLazyGetReportQuery()

  const [loading, setLoading] = useState(false);
  const [reportInfo, setReportInfo] = useState();

  const { register, control, handleSubmit } = useForm();

  const reportValue = useWatch({ control, name: 'report' })

  useEffect(() => { setReportInfo(Reports.find(reportInfo => reportInfo.value == reportValue)) }, [reportValue])

  const onSubmit = async(params) => {
    if(!params.report) return;

    setLoading(true);
    await getReport({ id: params.report, params }).unwrap();
    setLoading(false);
  }

  return (
    <AdminPage loading={loading} title='Reporting'>
      <form className={cn('d-flex', 'justify-content-between', 'align-items-end', 'u-margin--large', 'card', 'card-with-border', 'u-padding')} onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex'>
          <InputGroup title='Report' className={styles.input}>
            <Select placeholder='Choose report...' name='report' register={register} value={reportValue} useDefault>
              {Reports.map(report => (
                <Select.Item key={report.value} value={report.value}>{report.name}</Select.Item>
              ))}
            </Select>
          </InputGroup>
          <InputGroup title='Date From' className={styles.input}>
            <Controller
              control={control}
              name='date_from'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker value={value} onChange={onChange} outputFormat='YYYY-MM-DD' />
              )}
            />
          </InputGroup>
          <InputGroup title='Date To' className={styles.input}>
            <Controller
              control={control}
              name='date_to'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker value={value} onChange={onChange} outputFormat='YYYY-MM-DD' />
              )}
            />
          </InputGroup>
        </div>
        <Button submit>Download</Button>
      </form>

      {reportInfo && 
        <div className={cn('card', 'card-with-border', 'u-padding', 'u-margin--large')}>
          <p><b>Description: </b>{reportInfo.description}</p>
          <p><b>Row Model: </b>{reportInfo.row_description}</p>
          <p><b>Columns </b></p>
          <table className={styles.columnsTable}>
            <thead>
              <tr>
                <th>Column name</th>
                <th>Column Description</th>
              </tr>
            </thead>
            <tbody>
              {reportInfo.columns?.map(column => (
                <tr>
                  <td>{column.name}</td>
                  <td>{column.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {reportInfo.notes?.map(note => (
             <p className='u-margin-top--small t-small'><i>{note}</i></p>
          ))}
        </div>
      }
    </AdminPage> 
  )
}

export default AdminReporting;