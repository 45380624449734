import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FormTypes } from 'constants/forms';

const initialState = {
  library: [],
  customs: [],
  intelligent: [],
  candidates: [],
  questionnaires: [],
  referees: [],
}

export const getForms = createAsyncThunk('getForms', async() => {
  const res = await apiRequest('forms', {}, 'get');
  return res.result;
});

export const deleteForm = createAsyncThunk('deleteForm', async(form) => {
  await apiRequest(`forms/${form.id}`, {}, 'delete');
  return form;
});

export const saveForm = createAsyncThunk('saveForm', async(form, { rejectWithValue }) => {
  try {
    return await apiRequest('forms', { form }, 'post');
  } catch(e) {
    return rejectWithValue(e)
  }
});

const getCategoryFromType = (type) => {
  switch(type) {
    case FormTypes.CUSTOM:
      return 'customs';
    case FormTypes.LIBRARY:
      return 'library';
    case FormTypes.INTELLIGENT:
      return 'intelligent';
    case FormTypes.CANDIDATE:
      return 'candidates';
    case FormTypes.REFEREE:
      return 'referees';
    case FormTypes.QUESTIONNAIRE:
      return 'questionnaires';
  }
}

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getForms.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(saveForm.fulfilled, (state, { payload: response }) => {
        const { form, updated_from } = response.result
        const category = getCategoryFromType(form.type);
        if(updated_from) {
          state[category] = [...state[category].filter(form =>form.id !== updated_from), form]
        } else {
          state[category] = [...state[category], form]
        }
      })
      .addCase(deleteForm.fulfilled, (state, { payload: deletedForm }) => {
        const category = getCategoryFromType(deletedForm.type);
        state[category] = state[category].filter(form => form.id !== deletedForm.id)
      })
  }
});

export const selectForms = (state) => state.forms;

export default formsSlice.reducer