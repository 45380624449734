import { useState } from 'react';
import { NewUserModal } from '../../components/NewUserModal/NewUserModal';
import { Table } from 'components/Table/Table';
import { Badge } from 'react-bootstrap';
import { Button } from 'components/FormComponents';
import { SingleSignOnModal } from '../../components/SingleSignOnModal/SingleSignOnModal';
import {  MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { IconButton } from 'components/IconButton/IconButton';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import styles from './UsersTab.module.scss';
import cn from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { useInviteUserMutation } from 'api/user-api';

export const UsersTab = ({
  company,
  roles,
  teams,
  getCompanyUsers        = () => {},
  messagePopUp           = () => {},
  setLoading             = () => {},
  deleteUser             = () => {},
  updateCompanyOwner     = () => {},
  createSSOOrganization  = () => {},
  disableSSOOrganization = () => {},
}) => {

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    records_per_page: 15,
    total_pages: 1,
    total_records: 0
  });

  const [inviteUser] = useInviteUserMutation();

  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showSSOModal, setShowSSOModal] = useState(false);

  const [userToSetAsAdmin, setUserToSetAsAdmin] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [didableSSOModal, setDidableSSOModal] = useState(false);
  

  const fetchUsers = async(params = {}) => {
    setLoading(true);
    const { data } = await getCompanyUsers({ id: company.id, params });
    setPagination(data.result.pagination);
    setUsers(data.result.users);
    setLoading(false);
  }

  const handleNewUser = async(userData) => {
    setShowNewUserModal(false);
    setLoading(true);
    const { data: response } = await inviteUser(userData);
    if(response.result) {
      setUsers(curr => [...curr, response.result]);
      messagePopUp({ text: `User ${userData.first_name} was successfully invited`, state: MESSAGE_STATE_SUCCESS, hide: true });
    } else {
      messagePopUp({ text: `Email ${userData.email} it's being use by another user`, state: MESSAGE_STATE_ERROR, hide: true });
    }
    setLoading(false)
  }

  const setCompanyOwner = async() => {
    setLoading(true);
    await updateCompanyOwner({ id: company.id, userId: userToSetAsAdmin.id });
    setUserToSetAsAdmin(false);
    setLoading(false);
  }

  const handleUserDeletion = async() => {
    setLoading(true);
    const { data: result } = await deleteUser(userToDelete.id);
    setUsers(users.filter(u => u.id !== userToDelete.id));
    messagePopUp({ text: `${userToDelete.name} Deleted. ${result} candidates reassigned`, state: MESSAGE_STATE_SUCCESS, hide: true });
    setUserToDelete(null);
    setLoading(false);
  }

  const handleDisableSSO = async() => {
    setLoading(true);
    await disableSSOOrganization();
    setDidableSSOModal(null);
    setLoading(false);
  }

  return (
    <div>
      <ConfirmationModal
        visible={!!userToSetAsAdmin}
        title={`Set ${userToSetAsAdmin?.name} as company owner`}
        subtitle={`Are you sure you want to set ${userToSetAsAdmin?.name} as Company owner? This will make them the main user for the company.`}
        onClose={() => { setUserToSetAsAdmin(null) }}
        onConfirmation={setCompanyOwner}
        buttonTitle='Set as owner'
      />

      <ConfirmationModal
        visible={!!userToDelete}
        title={`Delete ${userToDelete?.name}`}
        subtitle={
          <span>
            Are you sure you want to delete <b>{userToDelete?.name}</b> as user? <br />
            The user won't longer have access to Checkmate. We will also transfer all it's candidates to the company administrator. <br />
            <b>This cannot be undone.</b>
          </span>
        }
        onClose={() => { setUserToDelete(null) }}
        confirmationKey={userToDelete?.email}
        onConfirmation={handleUserDeletion}
        buttonTitle='Delete user'
      />

      <ConfirmationModal
        visible={didableSSOModal}
        title={`Disable Single Sign On`}
        subtitle={
          <span>
            <br></br>
            Are you sure you want to disable this SSO?
          </span>
        }
        onClose={() => { setDidableSSOModal(null) }}
        onConfirmation={handleDisableSSO}
        buttonTitle='Disable'
      />

      <NewUserModal 
        roles={roles}
        teams={teams}
        visible={showNewUserModal} 
        onSubmit={handleNewUser} 
        onClose={() => setShowNewUserModal(false)}
      />
      
      <SingleSignOnModal 
        company={company}
        visible={showSSOModal}
        setLoading={setLoading}
        onClose={() => setShowSSOModal(false)}
        createSSOOrganization={createSSOOrganization}
      />
      <Table.Context onQueryUpdate={fetchUsers} defaultQuery={{ page: 1, search: '' }}>
        <Table.Filters>
          <Table.LeftFilters>
            <Table.Search placeholder='Search for a user...' />
          </Table.LeftFilters>
          <Table.RightFilters>
            <Button className='u-margin-right' type={company?.sso_organization_id ? 'delete' : 'primary'} icon={company?.sso_organization_id ? 'trash-2' : 'cloud'} onClick={() => company?.sso_organization_id ? setDidableSSOModal(true) : setShowSSOModal(true)}>{company?.sso_organization_id ? 'Disable Single Sign On' : 'Single Sign On'}</Button>
            <Button icon='user-plus' onClick={() => setShowNewUserModal(true)}>Invite</Button>
          </Table.RightFilters>
        </Table.Filters>
        <Table className='u-margin-top--large'>
          <Table.Head>
            <Table.Row>
              <Table.Header>Name</Table.Header>
              <Table.Header>Email</Table.Header>
              <Table.Header>Phone number</Table.Header>
              <Table.Header>MFA</Table.Header>
              <Table.Header>Status</Table.Header>
              <Table.Header></Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {users.map(user => (
              <Table.Row key={user.id}>
                <Table.Col styles={{ 'vertical-align': 'center' }}>
                  <div className={styles.userNameColumn}><AdminUserToggle company={company} user={user} onClick={setUserToSetAsAdmin} /> {user.name}</div>
                </Table.Col>
                <Table.Col>{user.email}</Table.Col>
                <Table.Col>{user.contact_number}</Table.Col>
                <Table.Col>
                  <Badge className={user.two_factor_auth_enabled ? 'u-status-completed' : 'u-status-canceled'}>{user.two_factor_auth_enabled ? 'Enabled' : 'Disabled'}</Badge>
                </Table.Col>
                <Table.Col>
                  <Badge className={user.verified ? 'u-status-completed' : 'u-status-pending'}>{user.verified ? 'Verified' : 'Pending'}</Badge>
                </Table.Col>
                <Table.Col align='right'>
                  {user.id !== company.creator_id && 
                    <IconButton icon='trash-2' onClick={() => setUserToDelete(user)} tip={`Delete ${user.name}`} />
                  }
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Pagination {...pagination} />
        </Table>
      </Table.Context>
    </div>
  )

}


const AdminUserToggle = ({
  company, 
  user, 
  onClick = () => {}
}) => {

  const isCompanyCreator = company.creator_id === user.id;

  const handleClick = () => {
    if(isCompanyCreator) return;

    onClick(user);
  }

  return (
    <OverlayTrigger placement='top' overlay={<Tooltip>{isCompanyCreator ? 'Company owner' : 'Set as company owner'}</Tooltip>}>
      <div>
        <FeatherIcon 
          size={17}
          className={cn(styles.starIcon, { [styles.selected]: company.creator_id === user.id })}
          icon='star'
          onClick={handleClick}
        />
      </div>
    </OverlayTrigger>
  )
}