import { useEffect } from 'react';
import { useState } from 'react';
import styles from './CandidateCheckValues.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Input, InputGroup, Button } from 'components/FormComponents';

export const CandidateCheckValues = ({ 
  checkValues,
  updateCandidateChecksValues = () => {}
}) => {

  const [valuesKeys, setValuesKeys] = useState([]);

  const [valueToUpdate, setValueToUpdate] = useState(false);

  useEffect(() => {
    setValuesKeys(Object.keys(checkValues.values).sort())
  }, [checkValues])

  const onCopy = (value) => {
    navigator.clipboard.writeText(value)
  }

  const onUpdateValues = (name, value) => {
    setValueToUpdate();
    updateCandidateChecksValues({...checkValues.values, [name]: value })
  }

  const renderValue = (value) => {
    if(!value) return '-';

    switch(typeof value) {
      case 'object':
        return value.map((obj, i) => {
          if(!obj) return;

          return (
            <ul key={i}>
              {Object.keys(obj).map(key => (
                <li key={key}><span className={styles.key}>{key.replaceAll('_', ' ')}</span> : {obj[key]}</li>
              ))}
            </ul>
          )
        });
      default: 
        return value;
    }
  }

  return (
    <table className={styles.root}>
      {valueToUpdate && 
        <UpdateValueModal 
          visible={true} 
          value={valueToUpdate}
          onClose={() => setValueToUpdate()} 
          onSubmit={onUpdateValues}
        />
      }
      <tbody>
        {valuesKeys.map(key => (
          <tr key={key} className={styles.valuesItem}>
            <td className={styles.key} onClick={() => onCopy(checkValues.values[key])}>{key.replaceAll('_', ' ')}</td>
            <td className={styles.value} onClick={() => onCopy(checkValues.values[key])}>{renderValue(checkValues.values[key])}</td>
            <td>
              <OverlayTrigger overlay={<Tooltip>Copy value</Tooltip>}>
                <div style={{ display: 'inline-block'}} className='u-margin-right' >
                  <FeatherIcon icon='copy' size={14} onClick={() => onCopy(checkValues.values[key])} />
                </div>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip>Edit value</Tooltip>}>
                <div style={{ display: 'inline-block'}} className='u-margin-right' >
                  <FeatherIcon icon='edit' size={14} onClick={() => setValueToUpdate({ name: key, value: checkValues.values[key] })} />
                </div>
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}


const UpdateValueModal = ({ 
  visible, 
  value, 
  onClose   = () => {},
  onSubmit  = () => {}
}) => {

  const [updatedValue, setUpdatedValue] = useState(value.value);

  return (
    <AnimatedModal title={`Update '${value.name.replaceAll('_', ' ')}'`} visible={visible} onClose={onClose}>
      <InputGroup title='Value' className='u-margin-top'>
        <Input inputProps={{ value: updatedValue, onChange: (e) => setUpdatedValue(e.target.value) }} />
      </InputGroup>
      <Button className='u-width-100 u-margin-top--large' onClick={() => onSubmit(value.name, updatedValue)}>Update</Button>
    </AnimatedModal>
  )
}