import { Toggle } from 'components/FormComponents';
import styles from './RolePermissionsTab.module.scss';
import cn from 'classnames';

export const RolePermissionsTab = ({
  role,
  register,
  permissions
}) => (
  <div className='u-padding background-secondary'>
    <div className={cn('card', 'card-with-border')}>
      {permissions.map(permission => (
        <div key={permission.id} className={styles.permission}>
          <div>
            <p className='u-margin-bottom--tiny'>{permission.name}</p>
            <p className='t-small u-margin-bottom--0'>{permission.description}</p>
          </div>
          <Toggle disabled={role.locked} name={`permissions.${permission.id}`} tooltip={permission.description} register={register}/>
        </div>
      ))}
    </div>
  </div>
)