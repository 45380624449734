import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import styles from './LinkedinButton.module.scss';

const LinkedinButton = () => {
  const { linkedInLogin } = useLinkedIn({
    clientId: "78s6flqajccq2v",
    redirectUri: `${
      process.env.REACT_APP_WEB_URL + "/assets/linkedin_callback.html"
    }`
  });
  return (
    <div className={styles.linkedinBtn} onClick={linkedInLogin}>
      <FontAwesomeIcon icon={faLinkedinIn} className={styles.socialIcon} />
      Continue with LinkedIn
    </div>
  );
};

export default LinkedinButton;
