import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import cookie from 'react-cookies';

export const integrationApi = createApi({
  reducerPath: 'integrationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/integrations`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getKnowMyJourneys: builder.query({
      query: () => '/knowmy/journeys',
      transformResponse: ({ result }) => result
    }),
    getKnowMyJourney: builder.query({
      query: (journeyId) => `/knowmy/journeys/${journeyId}`,
      transformResponse: ({ result }) => result
    }),
    launchKnowMyJourney: builder.mutation({
      query: (params) => ({
        url: '/knowmy/journeys',
        method: 'POST',
        body: params,
      }),
      transformResponse: ({ result }) => result
    }),
  })
})

export const {
  useGetKnowMyJourneysQuery,
  useLazyGetKnowMyJourneyQuery,
  useLaunchKnowMyJourneyMutation,
} = integrationApi
