import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeatherIcon from 'feather-icons-react';
import styles from './VerificationCard.module.scss';
import cn from 'classnames';

export const Statuses = {
  Error: 'error',
  Success: 'success',
  Warn: 'warn',
}

export const VerificationCard = ({ children }) => (
  <div className={styles.root}>
    {children}
  </div>
)

const Header = ({ icon, fa=false, children }) => (
  <div className={styles.header}>
    {fa ?
      <FontAwesomeIcon icon={['fab', icon]} size="2x"/> :
      <FeatherIcon icon={icon}/>
    }
    {children}
  </div>
)

const Link = ({ href, children }) => (
  <a href={href} className={styles.link}>{children}</a>
)

const Value = ({ children }) => (
  <div className={styles.value}>{children}</div>
)

const AlertMessage = ({ status, children }) => (
  <div 
    className={cn(styles.alertMessage, {
      [styles.alertMessageError]: status === Statuses.Error,
      [styles.alertMessageSuccess]: status === Statuses.Success,
      [styles.alertMessageWarn]: status === Statuses.Warn,
    })}
  >
    {status === Statuses.Warn && <FeatherIcon icon="alert-triangle"/>}
    {status === Statuses.Error && <FeatherIcon icon="alert-circle"/>}
    {status === Statuses.Success && <FeatherIcon icon="check-circle"/>}
    {children}
  </div>
)

VerificationCard.Header = Header;
VerificationCard.Link = Link;
VerificationCard.AlertMessage = AlertMessage;
VerificationCard.Value = Value;