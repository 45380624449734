import { useState, useEffect } from 'react';
import styles from './BulkActionsBar.module.scss';
import { Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import FeatherIcon from 'feather-icons-react';
import { TagsMenu } from '../../../../components/TagsMenu/TagsMenu';
import cn from 'classnames';

export const BulkActionsBar = ({ 
  active,
  selectedIds,
  tags,
  onUseAllChange    = () => {},
  reminderAction    = () => {}, // Action: Send bulk reminders
  downloadCSV       = () => {}, // Action: Download candidates report 
  bulkTag           = () => {}, // Action: Assign tag
  bulkTagDeletion   = () => {}, // Action: remove all tags
  bulkDeletion      = () => {}, // Action: remove
  bulkArchive       = () => {} // Action: archive
}) => {
  
  const [useAll, setUseAll] = useState(false);

  useEffect(() => setUseAll(false), [selectedIds])

  useEffect(() => onUseAllChange(useAll), [useAll])

  // Tags
  const [ tagMenuVisible, setTagMenuVisible ] = useState(false);
  
  const onTagSelected = (tagId) => {
    setTagMenuVisible(false);
    bulkTag(tagId);
  }  

  const onTagsDeletion = () => {
    setTagMenuVisible(false);
    bulkTagDeletion();
  }

  return (
    <div className={cn(styles.root, { [styles.hidden]: !active })}>
      <Badge className={styles.badge} onClick={() => setUseAll(!useAll)}>
        {useAll ? 'use all' : `${selectedIds.length} selected`}
      </Badge>
      <div className={styles.actionsWrapper}>
        <OverlayTrigger placement="top" overlay={<Tooltip>Send reminder emails & SMS</Tooltip>}>
          <div><FeatherIcon icon="bell" className={styles.actionIcon} onClick={reminderAction}/></div>
        </OverlayTrigger>

        <div style={{position: "relative"}}>
          <OverlayTrigger placement="top" overlay={<Tooltip>Tag</Tooltip>}> 
            <div><FeatherIcon icon="tag" className={styles.actionIcon} onClick={() => setTagMenuVisible(!tagMenuVisible)}/></div>
          </OverlayTrigger>
          <TagsMenu 
            visible={tagMenuVisible} 
            tags={tags} 
            onClose={() => setTagMenuVisible(false)} 
            onSelect={onTagSelected} 
            bulkTagDeletion={onTagsDeletion}/>
        </div>

        <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}> 
          <div><FeatherIcon icon="trash-2" className={styles.actionIcon} onClick={bulkDeletion} /></div>
        </OverlayTrigger>

        <OverlayTrigger placement="top" overlay={<Tooltip>Archive</Tooltip>}> 
          <div><FeatherIcon icon="archive" className={styles.actionIcon} onClick={bulkArchive}/></div>
        </OverlayTrigger>
        
        <OverlayTrigger placement="top" overlay={<Tooltip>Export CSV</Tooltip>}> 
          <div><FeatherIcon icon="download" className={styles.actionIcon} onClick={downloadCSV}/></div>
        </OverlayTrigger>
      </div>
    </div>
  )
}