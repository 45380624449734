import { useState, useRef } from 'react'
import { Button } from 'components/FormComponents/';
import { ChecksSelector } from 'components/ChecksSelector/ChecksSelector';
import styles from './Step2Form.module.scss';
import cn from 'classnames';

export const Step2Form = ({ stepBack = () => {}, ...props }) => {

  const selectorRef = useRef(null);

  const [valid, setValid] = useState(false);
  
  return ( 
    <div className={cn(styles.root)}>
       <div className={cn('card', 'card-with-border')}>
        <div className='u-margin--large'>
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <h1 className='title-3 u-margin-y'>Step 2. Select Check Types</h1>
          </div>
        </div>
        <div className={cn('u-padding', 'card_border--top')}>
          <ChecksSelector ref={selectorRef} {...props} setValidity={setValid} className='u-margin-top' />
        </div>
        <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
          <Button onClick={stepBack} type='secondary'>Back</Button>
          <Button onClick={() => selectorRef.current.submit()} disabled={!valid}>Launch Check</Button>
        </div>
      </div>
    </div>
  )
}