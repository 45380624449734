import { useEffect } from 'react';
import { IconButton } from 'components/IconButton/IconButton';
import styles from './Questionnaire.module.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { FormResponse } from 'components/Form/FormResponse/FormResponse';
import { useGetFormQuery, useGetFormAnswersForCandidateQuery } from 'api/forms-api';
import { useLazyGetCandidateFormReportQuery } from 'api/candidates-api';

export const Questionnaire = ({
  check,
  candidate,
  setLoading = () => {},
}) => {

  const { data: form, isLoading: loadingForms } = useGetFormQuery(check.details.form_id);
  const { data: answers, isLoading: loadingAnswers } = useGetFormAnswersForCandidateQuery({ id: check.details.form_id, candidateId: candidate.id });

  const [getFormReport] = useLazyGetCandidateFormReportQuery();

  useEffect(() => { setLoading(loadingAnswers || loadingForms) }, [loadingAnswers, loadingForms])

  const download = async() => {
    setLoading(true);
    await getFormReport({ candidate, form }).unwrap();
    setLoading(false);
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          <div className={styles.headline}>Form</div>
          <div className={styles.name}>{form?.name}</div>
        </div>
        <div>
          <IconButton icon='download' tooltip='Download' onClick={download} />
        </div>
      </div>
      <Tabs id="questionnaireTabs" activeKey={1} defaultActiveKey={1} className="tabs">
        <Tab eventKey={1} title="Questions">
          {answers && 
            <FormResponse form={form} answers={answers} />
          }
        </Tab>
      </Tabs>      
    </div>
  )
}
