import { getTextWithReferences } from 'helpers/form';
import FeatherIcon from 'feather-icons-react';
import Moment from 'react-moment';
import { Button } from 'components/FormComponents';
import styles from './QuestionAnswerRow.module.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';

export const QuestionAnswerRow = ({
  field,
  order,
  title,
  answer,
  answers,
  candidate,
  logicChild
}) => {

  const [formattedAnswer, setFormattedAnswer] = useState();

  useEffect(() => {
    if(!field || !answer) return;

    switch(field.type){
      case 'opinion_scale':
        const customSteps = field.options.use_custom_steps;
        const stepsStart = customSteps ? field.options.steps_start : 1;
        const stepsEnd = customSteps ? field.options.steps_end : 10;

        var scale = [];
        for(let i=stepsStart; i <= stepsEnd; i++) { scale.push(i) }
        setFormattedAnswer(
          <div>
            <span className={`${styles.scaleLabel} ${styles['--poor']}`}>Poor</span>
            {
              scale.map(s => {
                return <span key={s} className={cn(styles.scale, {[styles['--selected']]: s == answer})}>{s}</span>
              })
            }
            <span className={`${styles.scaleLabel} ${styles['--fantastic']}`}>Fantastic</span>
          </div>
        )
        break;
      case 'rating':
        var scale = [1,2,3,4,5];
        setFormattedAnswer(
          <div>
            {scale.map(s => (
              <span key={s} className={cn(styles.rating, {[styles['--selected']]: s <= answer})}><FeatherIcon icon="star"/></span>
            ))}
          </div>
        )
        break;
      case 'multiple_choice':
        var answers = Array.isArray(answer) ? answer : answer.split(' / ');
        var answerNotInOptions = true;
        field.options.options.forEach(option => {
          if (answers.indexOf(option.value) >= 0)
            answerNotInOptions = false;
        });
        setFormattedAnswer(
          <div>
            {field.options.options.map(option => {
              if(option !== '')
                return <span key={option.value} className={cn(styles.option, {[styles['--selected']]: answers.indexOf(option.value) >= 0})}>{option.value}</span>
            })}
            {answerNotInOptions && answers.map(ans => (
              <span key="ans" className={`${styles.option} ${styles['--selected']}`}>{ans}</span>
            ))}
          </div>
        )
        break;
      case 'date':
        setFormattedAnswer(
          <span><Moment format="MMMM Do, YYYY">{answer}</Moment></span>
        )
        break;
      case 'file_upload':
        setFormattedAnswer(
          <span><Button small icon='download' onClick={() => this.props.downloadQuestionFile(answer)}>View document</Button></span>
        )
        break;
      case 'signature':
        setFormattedAnswer(
          <img className={styles.signature} src={answer} />
        )
        break;
      default:
        setFormattedAnswer(
          <span className={styles.text}>{answer}</span>
        )
    }
  }, [field, answer])

  return(
    <div className={cn(styles.root, {[styles['--title']]: title, [styles['--logicChild']]: logicChild})}>
      <div className={styles.questionRow}>
        {field &&
          <div className={styles.number}>{order ? order : field.order}</div>
        }
        <div className={styles.question}>
          {title ? title : getTextWithReferences(field.text, candidate.first_name, answers, { job: candidate.job_role })}
        </div>
      </div>
      <div className={styles.answer}>
        {title ? '' : formattedAnswer}
      </div>
    </div>
  )
}