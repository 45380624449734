import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import styles from './IconButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const IconButton = ({ 
  icon, 
  className, 
  tip, 
  placement = 'top', 
  prefix = 'fi',
  active = false,
  disabled = false,
  small = false,
  type = 'default',
  onClick = () => {}
}) => (
  <OverlayTrigger trigger={tip === undefined ? 'none' : ['hover', 'focus']} placement={placement} overlay={<Tooltip>{tip}</Tooltip>}>
    <div 
      className={cn(styles.root, styles[type], { 
        [className]: className, 
        [styles.rootSmall]: small, 
        [styles.rootDisabled]: disabled, 
        [styles.rootActive]: active,
        ['disabled']: disabled
      })} 
      onClick={(e) => !disabled && onClick(e)}
    >
      {prefix === 'fa' ?
        <FontAwesomeIcon icon={icon} /> :
        <FeatherIcon size={17} icon={icon} className={styles.icon} />
      }
    </div>
  </OverlayTrigger>
)

IconButton.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};