import { configureStore } from '@reduxjs/toolkit';
import UserReducer from 'api/user-slice';
import CompanyReducer from 'api/company-slice';
import CandidateReducer from 'api/candidates-slice';
import AppReducer from 'api/app-slice';
import FormsReducer from 'api/forms-slice';
import AuthenticationReducer from 'api/authentication-slice';
import TagReducer from 'api/tags-slice';
import CompanyChecksReducer from 'api/company-checks-slice';
import UserPermissionsReducer from 'api/user-permissions-slice';
import CandidateApplicationReducer from 'api/candidate-application-slice';
import CustomFieldReducer from 'api/candidate-custom-fields-slice';
import TeamsReducer from 'api/teams-slice';
import RolesReducer from 'api/roles-slice';
import BrandsReducer from 'api/brands-slice';
import BundlesReducer from 'api/bundles-slice';
import PartnerReducer from 'api/partner-slice';
import { idVerificationsApi } from 'api/id-verifications-api';
import { candidatesApi } from 'api/candidates-api';
import { candidateChecksApi } from 'api/candidate-checks-api';
import { companyApi } from 'api/company-api';
import { userApi } from 'api/user-api';
import { billingApi } from 'api/billing-api';
import { uploadApi } from 'api/upload-api';
import { formsApi } from 'api/forms-api';
import { notificationSettingsApi } from 'api/notification-settings-slice';
import { checkTypeApi } from 'api/check-types-api';
import { candidateChecksValuesApi } from 'api/candidate-checks-values-api';
import { companyChecksApi } from 'api/company-checks-api';
import { adminLogsApi } from 'api/admin-logs-api';
import { reportsApi } from 'api/reports-api';
import { authenticationApi } from 'api/authentication-api';
import { oauthClientApi } from 'api/oauth-client-api';
import { brandApi } from 'api/brands-api';
import { refereesApi } from 'api/referees-api';
import { subChecksApi } from 'api/sub-checks-api';
import { partnerApi } from 'api/partner-api';
import { linkedinApi } from 'api/linkedin-api';
import { auditLogsApi } from 'api/audit-logs-api';
import { integrationApi } from 'api/integration-api';
import { partnerV1Api } from 'api/partner-v1-api';
import { permissionsApi } from 'api/permissions-api';
import { purchasesApi } from 'api/purchases-api';

export const store = configureStore({
  reducer: {
    authentication: AuthenticationReducer,
    candidates: CandidateReducer,
    bundles: BundlesReducer,
    company: CompanyReducer,
    permissions: UserPermissionsReducer,
    user: UserReducer,
    app: AppReducer,
    forms: FormsReducer,
    companyChecks: CompanyChecksReducer,
    tags: TagReducer,
    candidateApplication: CandidateApplicationReducer,
    teams: TeamsReducer,
    roles: RolesReducer,
    brands: BrandsReducer,
    customFields: CustomFieldReducer,
    partners: PartnerReducer,
    [idVerificationsApi.reducerPath]: idVerificationsApi.reducer,
    [candidatesApi.reducerPath]: candidatesApi.reducer,
    [candidateChecksApi.reducerPath]: candidateChecksApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [billingApi.reducerPath]: billingApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [notificationSettingsApi.reducerPath]: notificationSettingsApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [checkTypeApi.reducerPath]: checkTypeApi.reducer,
    [candidateChecksValuesApi.reducerPath]: candidateChecksValuesApi.reducer,
    [companyChecksApi.reducerPath]: companyChecksApi.reducer,
    [adminLogsApi.reducerPath]: adminLogsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [oauthClientApi.reducerPath]: oauthClientApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [refereesApi.reducerPath]: refereesApi.reducer,
    [subChecksApi.reducerPath]: subChecksApi.reducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [linkedinApi.reducerPath]: linkedinApi.reducer,
    [auditLogsApi.reducerPath]: auditLogsApi.reducer,
    [integrationApi.reducerPath]: integrationApi.reducer,
    [partnerV1Api.reducerPath]: partnerV1Api.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [purchasesApi.reducerPath]: purchasesApi.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
    idVerificationsApi.middleware,
    candidatesApi.middleware,
    candidateChecksApi.middleware,
    companyApi.middleware,
    userApi.middleware,
    billingApi.middleware,
    uploadApi.middleware,
    notificationSettingsApi.middleware,
    formsApi.middleware,
    checkTypeApi.middleware,
    candidateChecksValuesApi.middleware,
    companyChecksApi.middleware,
    adminLogsApi.middleware,
    reportsApi.middleware,
    authenticationApi.middleware,
    oauthClientApi.middleware,
    brandApi.middleware,
    refereesApi.middleware,
    subChecksApi.middleware,
    partnerApi.middleware,
    linkedinApi.middleware,
    auditLogsApi.middleware,
    integrationApi.middleware,
    partnerV1Api.middleware,
    permissionsApi.middleware,
    purchasesApi.middleware
  ]),
  devTools: process.env.REACT_APP_DEBUG
})
