
import styles from './ReportingFilters.module.scss';
import { useEffect, useState } from 'react';
import { Select, InputGroup } from 'components/FormComponents';
import cn from 'classnames';

const STATUSES = {
  All: 'all',
  Active: 'active',
  Completed: 'completed',
  Overdue: 'overdue',
  InProgress: 'in_progress',
  Canceled: 'canceled',
}

export const ReportingFilters = ({
  companyChecks,
  onChange = () => {}
}) => {

  // Filters
  const [type, setType] = useState();
  const [status, setStatus] = useState()

  // Notify changes
  useEffect(() => onChange({ type, status }), [type, status])

  return (
    <div className={styles.root}>
      <div className={styles.showing}>
        <InputGroup title='Filter by Type' className={cn(styles.filter, 'u-no-margin-bottom')}>
          <Select inputProps={{ value: type, onChange: (e) => setType(e.target.value) }} value={type} useDefault>
            {companyChecks.map(companyCheck => (
              <Select.Item key={companyCheck.type} value={companyCheck.type}>{companyCheck.check_type.title}</Select.Item>
            ))}
          </Select>
        </InputGroup>

        <InputGroup title='Status' className={cn(styles.filter, 'u-no-margin-bottom')}>
          <Select inputProps={{ value: status, onChange: (e) => setStatus(e.target.value) }} value={status} useDefault>
            <Select.Item value={STATUSES.All}>All</Select.Item>
            <Select.Item value={STATUSES.Active}>Active</Select.Item>
            <Select.Item value={STATUSES.InProgress}>In progress</Select.Item>
            <Select.Item value={STATUSES.Completed}>Completed</Select.Item>
            <Select.Item value={STATUSES.Overdue}>Overdue</Select.Item>
            <Select.Item value={STATUSES.Canceled}>Canceled</Select.Item>
          </Select>
        </InputGroup>
      </div>
    </div>
  )
}