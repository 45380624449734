
import { useColor } from 'hooks/color';
import styles from './ReportingWidget.module.scss';
import { Badge } from 'react-bootstrap';

export const ReportingWidget = ({
  children,
}) => (
  <div className='card card-with-border u-padding background-secondary'>
    <div className='card_content'>
      <div className={styles.wrapper}>
        {children}
      </div>
    </div>
  </div>
)

const WidgetBadge = ({ hexColor = '#FFF', children }) => {

  const color = useColor(hexColor);

  return (
    <Badge style={{ color: hexColor, backgroundColor: `rgba(${color.rgb}, 0.2)`, textTransform: 'none', fontSize: '13px' }}>
      {children}
    </Badge>
  )
}

const Value = ({ children }) => <h1 className={styles.title}>{children}</h1>
const Label = ({ children }) => <p className={styles.subtitle}>{children}</p>


ReportingWidget.Badge = WidgetBadge;
ReportingWidget.Value = Value;
ReportingWidget.Label = Label;