import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import cookie from 'react-cookies';

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/uploads`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getUploads: builder.query({
      query: (params) => `?${toQueryParams(params)}`,
      transformResponse: ({ result }) => result
    }),
    getUpload: builder.query({
      query: (id) => `/${id}`,
      transformResponse: ({ result }) => result
    }),
    signUpload: builder.query({
      query: (params) => `/sign?${toQueryParams(params)}`,
      transformResponse: ({ result }) => result
    }),
    createUpload: builder.mutation({
      query: (params) => ({
        url: '/',
        method: 'POST',
        body: params,
      }),
      transformResponse: ({ result }) => result
    }),
    deleteUpload: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE'
      }),
      transformResponse: ({ result }) => result
    })
  })
})

export const {
  useGetUploadsQuery,
  useGetUploadQuery,
  useLazyGetUploadQuery,
  useLazySignUploadQuery,
  useCreateUploadMutation,
  useDeleteUploadMutation
} = uploadApi
