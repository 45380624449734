import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { IdVerificationForm } from './components/IdVerificationForm/IdVerificationForm';
import { IdVerificationCard } from './components/IdVerificationCard/IdVerificationCard';

export const IdVerificationModal = ({
  checkValues,
  idVerification,
  visible = false,
  onClose   = () => {},
  onVerify  = () => {}
}) => {

  return (
    <AnimatedModal title='ID Verification' visible={visible} onClose={onClose}>
      {idVerification ?
        <IdVerificationCard idVerification={idVerification} /> :
        <IdVerificationForm checkValues={checkValues} onVerify={onVerify} />
      }
    </AnimatedModal>
  )
}