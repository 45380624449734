import { useForm, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { Button, EditableTitle } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import { Tabs, Tab } from 'react-bootstrap';
import { RolePermissionsTab } from './tabs/RolePermissionsTab/RolePermissionsTab';
import { RoleUsersTab } from './tabs/RoleUsersTab/RoleUsersTab';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import cn from 'classnames';
import { useState } from 'react';

export const RoleConfig = ({ 
  role,
  users,
  permissions, 
  onClose            = () => {},
  onDelete           = () => {},
  onUpdate           = () => {},
}) => {

  const { register, reset, control, handleSubmit } = useForm();

  const nameValue = useWatch({ control, name: 'name' })

  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

  useEffect(() => {
    let permissionsState = {}

    permissions.forEach(permission => {
      permissionsState[permission.id] = role.permissions.includes(permission.id);
    })

    reset({ ...role, permissions: permissionsState, users: role.users.map(userId => ({ id: userId })) })
  }, [role.id])

  const onSubmit = (data) => {
    data.permissions = Object.keys(data.permissions).filter(id => data.permissions[id] === true);
    data.users = data.users.map(user => user.id);
    onUpdate(role.id, data);
  }

  return (
    <>
      <ConfirmationModal 
        visible={confirmationModalOpened}
        title='Delete Role Confirmation'
        subtitle='This Role will be permanently deleted and all users assigned to it, will lose the priviledge grant by it. This data cannot be recovered'
        confirmationKey={role.name}
        onClose={() => { setConfirmationModalOpened(false) }}
        onConfirmation={() => onDelete(role.id)}
        buttonTitle='Delete Role'
      />
      <form className={cn('card', 'card-with-border', 'card--small', 'u-width-50')} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className='u-margin--large'>
            <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
              <EditableTitle register={register} name='name' value={nameValue} disabled={role.locked} />
              {!role.locked && <IconButton icon='trash-2' tip='Delete role' onClick={() => setConfirmationModalOpened(true)}/>}
            </div>
            <p className='t-small'>Manage this roles permissions and users below</p>
          </div>
          <Tabs defaultActiveKey='permissions' className='tabs padding-small'>
            <Tab eventKey='permissions' title='Permissions'>
              <RolePermissionsTab role={role} register={register} permissions={permissions} />
            </Tab>
            <Tab eventKey='users' title='Users'>
              <RoleUsersTab role={role} control={control} users={users} />
            </Tab>
          </Tabs>
        </div>
        <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
          <Button onClick={onClose} type='secondary'>Cancel</Button>
          <Button submit>Save</Button>
        </div>
      </form>
    </>
  )
}