import React, { useEffect, useState, useRef } from 'react';
import styles from './AssessmentDetails.module.scss';
import { ChecksSelector } from 'components/ChecksSelector/ChecksSelector';
import { 
  useGetBrandsQuery,
  useLazyGetBrandUploadsQuery,
  useGetFormsQuery,
  useGetCompanyChecksQuery,
  useGetCompanyQuery,
  useGetCandidateQuery,
  useGetBundlesQuery,
  useUpdateCandidateMutation,
  useAddCandidateChecksMutation,
  useStartPartnerAssessmentMutation
} from 'api/partner-v1-api';
import { Select, Button, Input, InputGroup, ToggleSwitchInput } from 'components/FormComponents';
import useLocalStorage from 'react-use-localstorage';
import { Loading } from 'components/Loading/Loading';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryParams } from 'hooks/query-params';
import { usePartnerV1UploadActions } from 'hooks/upload-actions';

export const AssessmentDetails = () => {

  const { partner, token } = useParams();
  const [ checkType, partnerApplicationId ] = useQueryParams(['type', 'partner_application_id']);

  const navigate = useNavigate();

  const { data: candidate, error: candidateFetchError } = useGetCandidateQuery({ token, applicationId: partnerApplicationId }, { skip: !partnerApplicationId });

  const { data: brands } = useGetBrandsQuery(token, { skip: !partnerApplicationId });
  const { data: forms, isLoading: loadingForms } = useGetFormsQuery(token, { skip: !partnerApplicationId });
  const { data: company } = useGetCompanyQuery(token, { skip: !partnerApplicationId });
  const { data: companyChecks, isLoading: loadingCompanyChecks } = useGetCompanyChecksQuery(token, { skip: !partnerApplicationId });
  const { data: bundles } = useGetBundlesQuery(token, { skip: !partnerApplicationId });

  const { getUpload, signUpload, createUpload, deleteUpload } = usePartnerV1UploadActions(token);

  const [getBrandUploads] = useLazyGetBrandUploadsQuery();
  const [updateCandidate] = useUpdateCandidateMutation();
  const [addCandidateChecks] = useAddCandidateChecksMutation();
  const [startPartnerAssessment] = useStartPartnerAssessmentMutation();

  const selectorRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [check, setCheck] = useState();
  const [documents, setDocuments] = useState([]);

  const [valid, setValidity] = useState(false);

  const [brandId, setBrandId] = useState(null);
  const [ccNotificationEmailsEnabled, setCcNotificationEmailsEnabled] = useLocalStorage('ccNotificationEmailsEnabled', "false");
  const [ccNotificationEmails, setCcNotificationEmails] = useLocalStorage('ccNotificationEmails', "");

  // Redirect if candidate exists
  useEffect(() => {
    if(candidate?.partner_application_started) navigate(`/assessments/progress/${partner}/${token}${window.location.search}`);
  }, [candidate])

  // Get brand documents
  useEffect(() => {
    if(!brandId) return;
    setLoading(true);
    getBrandUploads({ token, brandId }).unwrap().then((documents) => {
      setDocuments(documents);
      setLoading(false);
    });
  }, [brandId])

  // Errors redirect 
  useEffect(() => {
    if(!candidateFetchError) return;

    switch(candidateFetchError.status) {
      case 404: 
        navigate(`/not_found_error?message=Candidate Not Found`);
        break;
      case 500: 
        navigate('/checkmate_error');
        break;
    }
  }, [candidateFetchError])

  // Get current check based on type
  useEffect(() => {
    if(!candidate?.candidate_checks) return;
    setCheck(candidate.candidate_checks.find(check => check.type === checkType))
  }, [candidate?.candidate_checks?.length])


  const submit = async(checksData) => {
    setLoading(true);

    // Update candidate
    let candidateData = {}
    if(brandId) candidateData.brand_id = brandId;
    if(ccNotificationEmailsEnabled) candidateData.cc_notification_emails = ccNotificationEmails;
    await updateCandidate({ token, applicationId: partnerApplicationId, candidateData });

    // Mark candidate as started for partners
    await startPartnerAssessment({ token, applicationId: partnerApplicationId });

    // Create checks
    await addCandidateChecks({ token, applicationId: partnerApplicationId, checks: checksData });

    // Redirect to progress
    setLoading(false);
    navigate(`/assessments/progress/${partner}/${token}${window.location.search}`)
  }

  if(loadingForms || loadingCompanyChecks) return <Loading active={true} />

  return ( 
    <div className={styles.root}>
      <Loading active={loading} />
      {company &&
        <div className={styles.wrapper}>
          <ToggleSwitchInput
            text="Notification List"
            subtext='Add external emails to get notified about status updates'
            onChange={e => setCcNotificationEmailsEnabled(e.target.checked)}
            value={JSON.parse(ccNotificationEmailsEnabled)}
          >
            <InputGroup title='Emails'>
              <Input 
                placeholder='email_1@example.com,email_2@example.com'
                inputProps={{ value: ccNotificationEmails, onChange: (e) => setCcNotificationEmails(e.target.value) }}
              />
            </InputGroup>
          </ToggleSwitchInput>

          {brands.length > 1 &&
            <InputGroup title='Branding' className='u-margin-bottom--large'>
              <Select inputProps={{ value: brandId, onChange: (e) => setBrandId(e.target.value) }} value={brandId} placeholder='Select Brand...' useDefault>
                {brands.map(brand => (
                  <Select.Item key={brand.id} value={brand.id}>{brand.name}</Select.Item>
                ))}
              </Select>
            </InputGroup>
          }

          <ChecksSelector
            ref={selectorRef}
            type={checkType}
            form={check?.details?.typeform_id}
            forms={forms}
            company={company}
            bundles={bundles}
            documents={documents}
            companyChecks={companyChecks}
            allowUploads
            setValidity={setValidity}
            onSubmit={submit}
            getUpload={getUpload}
            deleteUpload={deleteUpload}
            signUpload={signUpload}
            createUpload={createUpload}
          />
          <Button className='u-width-100' disabled={!valid} large onClick={() => selectorRef.current.submit()}>Start</Button>
        </div>
      }
    </div>
  )
}