import FeatherIcon from 'feather-icons-react';
import { Area, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import styles from './ReportSummary.module.scss';
import { useEffect, useState } from 'react';
import cn from 'classnames';

export const ReportSummary = ({
  candidate,
  referee,
  answers,
}) => {

  const [chartData, setChartData] = useState({})

  useEffect(() => {
    setChartData([
      { name: '11', val: answers[11] },
      { name: '12', val: answers[12] },
      { name: '13', val: answers[13] },
      { name: '14', val: answers[14] },
      { name: '15', val: answers[15] },
      { name: '16', val: answers[16] },
      { name: '17', val: answers[17] },
      { name: '18', val: answers[18] },
      { name: '19', val: answers[19] },
      { name: '20', val: answers[20] },
    ]);
  }, [answers])

  return(
    <div>
      { answers &&
        <div className={styles.root}>
          <div className="u-flex-box u-flex-justify-between u-flex-align-stretch">
            <div className={cn(styles.criticalResponses, styles.section)}>
              <div className={styles.title}>Critical Responses</div>
              <div className={styles.answersQuantity}>5 answers</div>
              <div className={styles.content}>
                <p>
                  <FeatherIcon icon={answers[32] === 'Yes' ? "check-circle" : "alert-circle"}/>
                  {`${referee.first_name} would` + (answers[32] === 'Yes' ? ' ' : ' not ') + `recommend ${candidate.first_name}`}
                </p>
                <p>
                  <FeatherIcon icon={answers[35] === 'Yes' ? "check-circle" : "alert-circle"}/>
                  {`${referee.first_name} would` + (answers[35] === 'Yes' ? ' ' : ' not ') + `rehire ${candidate.first_name}`}
                </p>
                <p>
                  <FeatherIcon icon={answers[21] === 'Yes' ? "alert-circle" : "check-circle"}/>
                  {candidate.first_name + (answers[21] === 'Yes' ? ' may not be ' : ' is ') + ' able to perform job safely'}
                </p>
                <p>
                  <FeatherIcon icon={answers[24] === 'Yes' ? "alert-circle" : "check-circle"}/>
                  {candidate.first_name + (answers[24] === 'Yes' ? ' has ' : ' has not ') + ' been issued formal warnings'}
                </p>
                <p>
                  <FeatherIcon icon={answers[28] === 'Yes' ? "alert-circle" : "check-circle"}/>
                  {candidate.first_name + (answers[28] === 'Yes' ? ' has ' : ' has no ') + ' history of work place accidents'}
                </p>
              </div>
            </div>
            <div className={cn(styles.skills, styles.section)}>
              <div className={styles.title}>Skills</div>
              <div className={styles.answersQuantity}>10 answers</div>
              <div className="u-margin-top">
              <AreaChart width={350} height={170} data={chartData} margin={{ top: 5, right: 15, bottom: 5 }}>
                <CartesianGrid horizontal={false} />
                <XAxis dataKey="name" label={{ value: 'Questions', position: 'insideBottom', offset: 0 }} tick={{ fontSize: 10 }} style={{ fontFamily: 'inter' }} />
                <YAxis domain={[0, 10]} interval={0} label={{ value: 'Exemplary', position: 'center', angle: -90 }} tick={{ fontSize: 10 }} />
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" dataKey="val" stroke="#bc9bff" fill="#bc9bff" />
              </AreaChart>
              </div>
            </div>
          </div>
          <div className={cn(styles.additionalComment, styles.section)}>
            <div className={styles.title}>Additional comments</div>
            <div className={styles.answersQuantity}>1 answer</div>
            <div className={styles.content}>"{answers[37]}"</div>
          </div>
        </div>
      }
    </div>
  )
}


const CustomTooltip = ({ active, payload, label }) => {

  const getQuestion = (label) => {
    switch(label) {
      case '11':
        return 'Attendance, Reliability and Punctuality';
      case '12':
        return 'Personal Presentation';
      case '13':
        return 'Communication Skills';
      case '14':
        return 'Technical Competency';
      case '15':
        return 'Initiative and Willingness';
      case '16':
        return 'Teamwork and engagement with colleagues';
      case '17':
        return 'Ability to meet expectations and KPIS';
      case '18':
        return 'Ability to learn and adapt';
      case '19':
        return 'Organisation and time management skills';
      case '20':
        return 'Support for others';
    }
  }


  if (active && payload && payload.length) {
    return (
      <div className="chartTooltip">
        <p className="chartTooltipLabel">{getQuestion(label)}</p>
        <p className="chartTooltipValue">{payload[0].value}</p>
      </div>
    );
  }

  return null;
};