import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = []

export const getBundles = createAsyncThunk('getBundles', async () => {
  const res = await apiRequest(`checks_bundles`, {}, 'get');
  return res.result;
});

export const createBundle = createAsyncThunk('createBundle', async (data) => {
  const res = await apiRequest('checks_bundles', data, 'post');
  return res.result;
});

export const updateBundle = createAsyncThunk('updateBundle', async ({ id, data }) => {
  const res = await apiRequest(`checks_bundles/${id}`, data, 'put');
  return res.result;
});

export const deleteBundle = createAsyncThunk('deleteBundle', async (id) => {
  await apiRequest(`checks_bundles/${id}`, {}, 'delete');
  return id;
});


const bundlesSlice = createSlice({
  name: 'bundles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBundles.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(createBundle.fulfilled, (state, { payload }) => {
        return [...state, payload];
      })
      .addCase(updateBundle.fulfilled, (state, { payload }) => {
        const updatedBundle = payload;
        return state.map(bundle => bundle.id === updatedBundle.id ? updatedBundle : bundle);
      })
      .addCase(deleteBundle.fulfilled, (state, { payload: id }) => {
        return state.filter(bundle => bundle.id !== id);
      })
  }
});

export const selectBundles = (state) => state.bundles;

export default bundlesSlice.reducer