import { useSendUserRecoveryPasswordEmailMutation } from 'api/user-api';
import { Input, InputGroup, Button } from 'components/FormComponents';
import { Link, useNavigate } from 'react-router-dom';
import styles from './ForgotPassword.module.scss';
import { Loading } from 'components/Loading/Loading';
import { useState, useEffect } from 'react';
import cn from 'classnames';
import { useDocumentTitle } from 'hooks/document-title';
import { useSelector } from 'react-redux';
import { selectAuthentication } from 'api/authentication-slice';

const ForgotPassword = () => {

  useDocumentTitle('Forgot Password');

  const navigate = useNavigate();

  const auth = useSelector(selectAuthentication);

  const [sendRecoverPasswordMail] = useSendUserRecoveryPasswordEmailMutation()

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if(auth.checked && auth.logged) navigate('/dashboard');
  }, [auth])

  const sendEmail = async() => {
    setLoading(true);
    await sendRecoverPasswordMail(email);
    setEmailSent(true);
    setLoading(false);
  }

  return (
    <div className={styles.root}>
      <Loading active={loading} />
      <div className={cn('card u-margin-top', 'card-with-shadow', styles.card)}>
        <div className={cn('card_content', styles.content)}>
          <img onClick={() => window.location.replace('https://www.checkmate.tech')}  className={cn(styles.logo, 'u-margin-bottom--large')} src='/assets/images/checkmate/icon-label.svg' alt='KnowMy Logo' />
          {emailSent ? 
            <>
             <h2 className='design-marker title-2 u-margin-bottom--large'>Check Your Inbox</h2>
             <p className='u-text-centered t-body'>You should receive an email with a link to reset your password</p>
             <img className={styles.emailIllustration} src='assets/images/email-illustration.png' alt="Illustration"/>
             <Link className={cn('u-link', 'dark', 'u-margin-top--large')} to="/sign_in">Back to Sign in</Link>
            </> :
            <>
              <h2 className='design-marker title-2 u-margin-bottom--large'>Forgot Your Password?</h2>
              <InputGroup title='Email'>
                <Input 
                  icon='mail'
                  placeholder='brad@checkmate.tech'
                  className='u-margin-bottom'
                  inputProps={{ onChange: (e) => setEmail(e.target.value) }}
                />
              </InputGroup>
              <Button onClick={sendEmail} className='u-width-100 u-margin-top'>Send me the link</Button>
              <Link className={cn('u-link', 'dark', 'u-margin-top--large')} to="/sign_in">or Sign in</Link>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword