import React, { useEffect, useState } from 'react';
import { Input, Button } from 'components/FormComponents';
import styles from './Tags.module.scss';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectTags } from 'api/tags-slice';

export const Tags = ({ 
  getTags     = () => {},
  createTag   = () => {},
  deleteTag   = () => {},
  setLoading  = () => {} 
}) => {

  const tags = useSelector(selectTags)

  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    setLoading(true);
    getTags().then(_ => setLoading(false));
  }, []);

  const handleCreate = async() => {
    setLoading(true);
    try { 
      await createTag({ name: inputValue });
    } catch (error) {
    } finally {
      setLoading(false);
    } 
  }

  const handleDelete = async(id) => {
    setLoading(true);
    await deleteTag(id);
    setLoading(false);
  }

  return ( 
    <div className={cn('u-flex-box', 'u-flex-justify-center')}>
      <div className={cn('card--medium', 'card', 'card-with-border')}>
        <div className='u-margin--large'>
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center')}>
            <h1 className='title-2'>Tags<span className='title-5'> ({tags.length})</span></h1>
          </div>
          <p className='t-small'>Create and manage tags to group and filter candidates.</p>
          <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-margin-top--large')}>
            <Input 
              inputProps={{
                value: inputValue,
                onChange: (e) => setInputValue(e.target.value)
              }}
              placeholder='Create a new tag'
              className={cn('u-width-100', 'u-margin-right--small')}
            /> 
            <Button onClick={handleCreate} icon='plus'>Add</Button>
          </div>
          <div className={cn('card', 'card-with-border', 'background-secondary', 'u-padding', 'u-margin-top')}>
            {tags.map(tag => (
              <div key={tag.id} className={styles.tag}>
                <div className={cn('u-flex-box', 'u-flex-align-center')}>
                  <FeatherIcon icon='tag' className={cn(styles.icon, 'u-margin-right--small')} />
                  <span>{tag.name}</span>
                </div>
                <FeatherIcon icon="trash-2" className={cn(styles.icon, 'u-pointer')} onClick={() => handleDelete(tag.id)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}