import { InputGroup, Input, Button } from 'components/FormComponents';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { IntegrationCard } from '..';

export const LivehireIntegration = ({ 
  config, 
  integration,
  setLoading      = () => {},
  popUpMessage    = () => {},
  connectLivehire    = () => {},
  disconnectLivehire = () => {},
}) => {

  const [enabled, setEnabled] = useState(integration.enabled);

  const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues: integration });

  useEffect(() => {
    if(enabled || !integration.enabled) return;

    disconnectLivehire();
    popUpMessage("Livehire disabled.", MESSAGE_STATE_SUCCESS);
  }, [enabled])

  const onSubmit = async(data) => {
    delete data.enabled;
    setLoading(true);
    const { payload } = await connectLivehire(data);

    if(payload.error)
      popUpMessage("Livehire couldn't be integrated. Please check your credentials and try again.", MESSAGE_STATE_ERROR);
    else
      popUpMessage("Livehire successfully integrated.", MESSAGE_STATE_SUCCESS);

    setLoading(false)
  }

  return ( 
    <IntegrationCard {...config} enabled={enabled} onToggle={e => setEnabled(e.target.checked)}>
      {enabled && 
        <form onSubmit={handleSubmit(onSubmit)} className='u-margin-top'>
          <InputGroup title='Client ID'>
            <Input 
              name='livehire_client_id'
              register={register}
              placeholder='Client ID'
              validators={{ required: true }}
              error={errors?.livehire_client_id?.type}
              inputProps={{ tabIndex: 1 }}
            />
          </InputGroup>
          <InputGroup title='Client Secret'>
            <Input 
              name='livehire_client_secret'
              register={register}
              placeholder='Client Secret'
              validators={{ required: true }}
              error={errors?.livehire_client_secret?.type}
              inputProps={{ tabIndex: 2 }}
            />
          </InputGroup>
          <InputGroup title='Integration Email'>
            <Input 
              name='livehire_integration_email'
              register={register}
              placeholder='Integration email'
              validators={{ required: true }}
              error={errors?.livehire_integration_email?.type}
              inputProps={{ tabIndex: 3 }}
            />
          </InputGroup>
          <Button submit type='secondary' className='u-width-100 u-margin-top--large'>Save</Button>
        </form>
      }
    </IntegrationCard>
  )
}