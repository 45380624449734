import { Input, InputGroup } from "components/FormComponents";
import { useWatch } from "react-hook-form";
import { EditableSettings } from "components/EditableSettings/EditableSettings";
import cn from 'classnames';


export const Clicktrought = ({
  register,
  control,
  watch,
}) => {

  const clickTroughActiveValue = useWatch({ control, name: 'click_through_active' });
  const clickThroughCandidateActiveValue = useWatch({ control, name: 'click_through_candidate_active' });
  const clickThroughRefereeActiveValue = useWatch({ control, name: 'click_through_referee_active' });

  return (
    <div className='background-secondary u-padding'>
      <div className={cn('card', 'card-with-border', 'u-padding')}>
        <EditableSettings title='Use customized redirections' open={clickTroughActiveValue} toggleable register={register} name='click_through_active' className="u-margin-0">
          <EditableSettings title='Use candidate redirection' open={clickThroughCandidateActiveValue} toggleable register={register} name='click_through_candidate_active'>
            <InputGroup title='URL'>
              <Input register={register} name='click_through_candidate' placeholder='https://www.checkmate.tech' />
              <a className="t-small u-link dark u-padding-top--small" target='_blank' href={watch('click_through_candidate')}>Preview URL</a>
            </InputGroup>
          </EditableSettings>
          <EditableSettings title='Use referee redirection' open={clickThroughRefereeActiveValue} toggleable register={register} name='click_through_referee_active'>
            <InputGroup title='URL'>
              <Input register={register} name='click_through_referee' placeholder='https://www.checkmate.tech' />
              <a className="t-small u-link dark u-padding-top--small" target='_blank' href={watch('click_through_referee')}>Preview URL</a>
            </InputGroup>
          </EditableSettings>
        </EditableSettings>
      </div>
    </div>
  )
}