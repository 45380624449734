import { useEffect, useState } from 'react';

export const useLocale = (locale) => {
  const [ dateSlashFormat, setDateSlashFormat ] = useState('DD/MM/YYYY');
  const [ dateHyphenFormat, setDateHyphenFormat ] = useState('dd-MM-yyyy');

  useEffect(() => { 
    switch(locale) {
      case 'en_GB': 
        setDateSlashFormat('DD/MM/YYYY');
        setDateHyphenFormat('dd-MM-yyyy');
        break;
      case 'en_US':
        setDateSlashFormat('MM/DD/YYYY');
        setDateHyphenFormat('MM-dd-yyyy');
        break;
      default: 
        setDateSlashFormat('DD/MM/YYYY');
        setDateHyphenFormat('dd-MM-yyyy');
    }
  }, [locale])

  return { dateHyphenFormat, dateSlashFormat }
}
