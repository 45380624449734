import { useEffect } from "react";
import ReactGA from 'react-ga';
import styles from './CriminalCheckConsent.module.scss';
import { Link } from "react-router-dom";

export const CriminalCheckConsent = () => {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    window.scrollTo(0, 0);
    document.title = 'Background Checking Consent Statement | Checkmate';
  }, [])

  return ( 
    <div className={styles.root}>
      <Link to='/'><img src="assets/images/checkmate/icon-label.svg" alt="checkmate"/></Link> 
      <h1 className={styles.title}>Background Checking Consent Statement</h1>
      <br/>
      <p>
        Checkmate Technology Limited and partners, in this case Sterling RISQ and/or Owens OnLine LLC are conducting your
        background screen on behalf of your prospective or current i) employer or ii) principal
        to whom you or your company provides services, hereby referred to as (“the Company”).
      </p>
      <p>
        The purpose of this Consent is to explicitly inform you and to obtain your consent to release
        to Sterling RISQ and/or Owens OnLine LLC, (or “us”), agents of the Company, all personal information provided by you
        to us or information about you collected, complied or retained by us (the “Information”) in
        the course of or in relation to your recruitment, engagement or employment with the
        Company.
      </p>
      <p>
        By signing this Consent, you appoint and authorise us (and/or our agents) as your agent to
        act on your behalf to exercise your rights as access seeker under Part IIIA of the Privacy Act
        1988 (Cth) (Privacy Act) to the extent permitted by Privacy Act to request access to your
        credit information (Credit Information) from DBCC Pty Ltd or other credit reporting body
        (Credit Reporter) and authorise us to disclose your identification information to the Credit
        Reporter and authorise the Credit Reporter to collect, use, store and disclose the
        Information disclosed to the Credit Reporter by us.
      </p>
      <p>
        The Information and Credit Information will be released to Sterling RISQ and/or Owens OnLine LLC and/or its agents to
        conduct searches and verification on your background information, which may include
        employment references, civil and criminal records, local language media and social media
        information, directorship information, property ownership information, credit records,
        driver’s licence verification and offence records, education, employment history and
        credential verification, and other background information on you as requested by the
        Company (the “Collected Information”).
      </p>
      <p>
        You hereby authorise the Company through Sterling RISQ and/or Owens OnLine LLC and/or its agents, to contact
        various government agencies, any and all corporations, former employers, educational
        institutions, consumer credit information agencies, law enforcement agencies and courts
        and other contributors (the “Contributors”) as a relevant party acting on your behalf for the
        purposes of obtaining and/or verifying the Collected Information.
      </p>
      <p>
        This letter releases the Contributors and the Credit Reporter from any liability and
        responsibility for releasing the Credit Information and the Collected Information to the
        Company or Sterling RISQ and/or Owens OnLine LLC. Sterling RISQ and/or Owens OnLine LLC and/or its agent may release the Information and
        this consent letter to the Contributors, Credit Reporter, our related bodies corporate and
        such other persons as may be necessary for the purposes of performing such searches and
        verification and the information may be disclosed to Contributors in overseas locations. You
        further release and forever discharge the Company, its partners including Sterling RISQ and/or Owens OnLine LLC, its
        agents, clients, employees and the individuals and companies contacted as part of the
        enquiries, from any and all claims, demands, damages, actions, causes of action, or suits of
        any kind or nature whatsoever arising from the Company’s investigation of my credentials.
      </p>
      <p>
        This letter releases the Contributors and the Credit Reporter from any liability and
        responsibility for releasing the Credit Information and the Collected Information to the
        Company or Sterling RISQ and/or Owens OnLine LLC. Sterling RISQ and/or Owens OnLine LLC and/or its agent may release the Information and
        this consent letter to the Contributors, Credit Reporter, our related bodies corporate and
        such other persons as may be necessary for the purposes of performing such searches and
        verification and the information may be disclosed to Contributors in overseas locations. You
        further release and forever discharge the Company, its partners including Sterling RISQ and/or Owens OnLine LLC, its
        agents, clients, employees and the individuals and companies contacted as part of the
        enquiries, from any and all claims, demands, damages, actions, causes of action, or suits of
        any kind or nature whatsoever arising from the Company’s investigation of my credentials.
      </p>
    </div>
  )
}