import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = []

export const createCandidate = createAsyncThunk('createCandidate', async(data, { rejectWithValue }) => {
  try {
    return await apiRequest('candidates', data, 'post');
  } catch(e) {
    return rejectWithValue(e)
  }
});

export const getCandidate = createAsyncThunk('getCandidate', async(id, { rejectWithValue }) => {
  try {
    const res = await apiRequest(`candidates/${id}`, {}, 'get');
    return res.result;
  } catch(e) {
    return rejectWithValue(e)
  }
});

export const updateCandidate = createAsyncThunk('updateCandidate', async({ id, params }) => {
  const res = await apiRequest(`candidates/${id}`, params, 'put');
  return res.result;
});

export const deleteCandidate = createAsyncThunk('deleteCandidate', async(id) => {
  await apiRequest(`candidates/${id}`, {}, 'delete');
  return { id };
});

export const getCandidates = createAsyncThunk('getCandidates', async({ params={}, refresh=false }) => {
  const res = await apiRequest('candidates', params, 'get');
  return { result: res.result, refresh }
});

const candidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCandidate.fulfilled, (state, { payload }) => {
        return [...state, payload.result];
      })
      .addCase(updateCandidate.fulfilled, (state, action) => {
        const updatedCandidate = action.payload;
        return state.map(candidate => candidate.id === updatedCandidate.id ? updatedCandidate : candidate);
      })
      .addCase(getCandidate.fulfilled, (state, { payload: updatedCandidate }) => {
        return state.map(candidate => candidate.id === updatedCandidate.id ? updatedCandidate : candidate);
      })
      .addCase(deleteCandidate.fulfilled, (state, action) => {
        const { id } = action.payload; 
        return state.filter(candidate => candidate.id !== id);
      })
      .addCase(getCandidates.fulfilled, (state, { payload }) => {
        const { result, refresh } = payload;
        return refresh ? result.candidates : [...state, ...result.candidates]
      })
  }
});

export const selectCandidates = (state) => state.candidates;


export default candidatesSlice.reducer