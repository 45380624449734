import { useEffect, useState } from 'react';
import { BrandingEdit } from './BrandingEdit/BrandingEdit';
import { List } from 'components/List/List';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectBrands } from 'api/brands-slice';
import { MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';

export const Branding = ({
  user,
  messagePopUp      = () => {},
  setLoading        = () => {},
  createBrand       = () => {},
  getBrands         = () => {},
  updateBrand       = () => {},
  getBrandUploads   = () => {},
  deleteBrand       = () => {},
  removeBrandUpload = () => {},
  addBrandUpload    = () => {}
}) => {

  const brands = useSelector(selectBrands)

  const [brandId, setBrandId] = useState();

  useEffect(() => {
    setLoading(true);
    getBrands().then(_ => setLoading(false))
  }, []);

  const createNewBrand = async() => {
    setLoading(true);
    const { payload: newBrand } = await createBrand('New Brand');
    setBrandId(newBrand.id);
    setLoading(false);
  }

  const handleUpdate = async(data) => {
    setLoading(true);
    const brand = brands.find(brand => brand.id === brandId);
    const signatureUpdated = (data.brand_email && (brand.brand_email !== data.brand_email || brand.brand_name !== data.brand_name))
    data = { ...data, signature_updated: signatureUpdated }
    const { payload: response } = await updateBrand(brand.id, data);

    if(response.error) messagePopUp(response.error.message, MESSAGE_STATE_ERROR);

    setLoading(false);
  }

  const handleDelete = async(id) => {
    setLoading(true);
    setBrandId(null);
    await deleteBrand(id);
    setLoading(false);
  }

  const onChangeDefault = async(id) => {
    setLoading(true);
    const { payload: response } = await updateBrand(id, { default: true });
    if(response.error) messagePopUp(response.error.message, MESSAGE_STATE_ERROR);

    setBrands(brands.map(brand => {
      brand.default = brand.id === id;
      return brand;
    }));
    setLoading(false);
  }

  return ( 
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <List 
        title='Branding'
        subtitle='Create and manage your companies branding, which is used when interacting with candidates and references via Checkmate.'
        className='u-margin-right--large u-flex-align-self-normal'
        onNew={createNewBrand}
      >
        <List.Category title='Brands'>
          {brands.map(brand => (
            <List.Item
              key={brand.id}
              title={brand.name}
              value={brand.id}
              onClick={setBrandId}
            />
          ))}
        </List.Category>
      </List>
      {brandId &&
        <BrandingEdit 
          user={user}
          brand={brands.find(brand => brand.id === brandId)}
          onClose={() => setBrand(null)}
          setLoading={setLoading}
          messagePopUp={messagePopUp}
          updateBrand={handleUpdate}
          changeDefault={onChangeDefault}
          getBrandUploads={getBrandUploads}
          removeBrandUpload={removeBrandUpload}
          addBrandUpload={addBrandUpload}
          onDelete={handleDelete}
        />
      }
    </div>
  )
}