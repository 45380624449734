import apiRequest from 'helpers/api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = []

export const getCompanyChecks = createAsyncThunk('getCompanyChecks', async() => {
  const res = await apiRequest('company_checks', {}, 'get');
  return res.result;
});

export const updateCompanyCheck = createAsyncThunk('updateCompanyCheck', async({ id, data }) => {
  const res = await apiRequest(`company_checks/${id}`, data, 'put');
  return res.result;
});

const companyChecksSlice = createSlice({
  name: 'companyChecks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyChecks.fulfilled, (_, { payload }) => {
        return payload;
      })
      .addCase(updateCompanyCheck.fulfilled, (state, { payload }) => {
        return state.map(companyCheck => companyCheck.id === payload.id ? payload : companyCheck);
      })
  }
});


export const selectCompanyChecks = (state) => state.companyChecks;


export default companyChecksSlice.reducer
