import { FieldTypes } from 'constants/field-types-enum';

export const operatorTypes = [
  { 
    value: 'is', 
    label: 'Equal to',
    allowedTypes: [FieldTypes.MultipleChoice, FieldTypes.YesNo, FieldTypes.Country] 
  },
  { 
    value: 'is_not', 
    label: 'Not equal to',
    allowedTypes: [FieldTypes.MultipleChoice, FieldTypes.YesNo, FieldTypes.Country]
  },
  {
    value: 'lower_than', 
    label: 'Lower than',
    allowedTypes: [FieldTypes.OpinionScale, FieldTypes.StarRating, FieldTypes.Date]
  },
  { 
    value: 'lower_equal_than', 
    label: 'Lower or equal than',
    allowedTypes: [FieldTypes.OpinionScale, FieldTypes.StarRating, FieldTypes.Date]
  },
  { 
    value: 'greater_than', 
    label: 'Greater than',
    allowedTypes: [FieldTypes.OpinionScale, FieldTypes.StarRating, FieldTypes.Date]
  },
  { 
    value: 'greater_equal_than', 
    label: 'Greater or equal than',
    allowedTypes: [FieldTypes.OpinionScale, FieldTypes.StarRating, FieldTypes.Date]
  },
]

export const typesWithLogic = [
  FieldTypes.MultipleChoice,
  FieldTypes.Date,
  FieldTypes.YesNo,
  FieldTypes.OpinionScale,
  FieldTypes.StarRating,
  FieldTypes.Country
];


// Logic
export const getIgnoredFields = (logic, value) => {
  let ignoredFields = [];
  if(meetsLogic(logic, value)) {
    for (let i=logic.from + 1; i < logic.to; i++) {
      ignoredFields.push(i);
    }
  }
  return ignoredFields;
}

export const meetsLogic = (logic, value) => {
  if(!value) return true;

  switch(logic.operator) {
    case 'is': 
      return value === logic.value;
    case 'is_not': 
      return value !== logic.value;
    case 'lower_than':
      return value < logic.value;
    case 'lower_equal_than':
      return value <= logic.value;
    case 'greater_than':
      return value > logic.value;
    case 'greater_equal_than':
      return value >= logic.value;
  }
}