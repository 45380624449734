import styles from './BulkChecksActionBar.module.scss';
import { Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import { useEffect, useState } from 'react';

export const BulkChecksActionBar = ({
  selectedIds,
  onExport = () => { },
  onUseAllChange = () => { },
}) => {

  const [useAll, setUseAll] = useState(false);

  useEffect(() => setUseAll(false), [selectedIds])

  useEffect(() => onUseAllChange(useAll), [useAll])

  return (
    <div className={cn(styles.root, { [styles.hidden]: selectedIds.length < 1 })}>
      <Badge className={styles.badge} onClick={() => setUseAll(!useAll)}>
        {useAll ? 'use all' : `${selectedIds.length} selected`}
      </Badge>
      <div className={styles.actionsWrapper}>
        <OverlayTrigger placement='top' overlay={<Tooltip>Export CSV</Tooltip>}>
          <div className={styles.iconWrapper}><FeatherIcon icon="download" className={styles.actionIcon} onClick={onExport} /></div>
        </OverlayTrigger>
      </div>
    </div>
  )
}