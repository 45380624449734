export const FormTypes = {
  CUSTOM: "type:custom",
  LIBRARY: "type:library",
  INTELLIGENT: "type:intelligent",
  CANDIDATE: "type:candidate",
  REFEREE: "type:referee",
  QUESTIONNAIRE: "type:questionnaire"
}

export const FormFieldTypes = [
  { name: 'Free Text', value: 'long_text', help: 'E.g. Do you have any further comment?', available_for: [FormTypes.CUSTOM, FormTypes.QUESTIONNAIRE, FormTypes.REFEREE, FormTypes.CANDIDATE] },
  { name: 'Free Text', value: 'short_text', available_for: [] },
  { name: 'Multi Choice', value: 'multiple_choice', help: 'E.g Which of these sports have you played before?', available_for: [FormTypes.CUSTOM, FormTypes.QUESTIONNAIRE, FormTypes.REFEREE, FormTypes.CANDIDATE], other_option_for: [FormTypes.CUSTOM, FormTypes.CANDIDATE, FormTypes.REFEREE, FormTypes.QUESTIONNAIRE] },
  { name: 'Yes/No', value: 'yes_no', help: 'E.g. Do you like Checkmate?', available_for: [FormTypes.CUSTOM, FormTypes.QUESTIONNAIRE, FormTypes.REFEREE, FormTypes.CANDIDATE] },
  { name: 'Opinion Scale', value: 'opinion_scale', help: 'E.g. On a scale of 1-7, how much do you like Checkmate?', available_for: [FormTypes.CUSTOM] },
  { name: 'Date', value: 'date', help: 'E.g. When did you start working on Checkmate?', available_for: [FormTypes.CUSTOM, FormTypes.QUESTIONNAIRE, FormTypes.REFEREE, FormTypes.CANDIDATE] },
  { name: 'Phone Number', value: 'phone_number', help: 'E.g. What is his phone number?', available_for: [FormTypes.CUSTOM, FormTypes.QUESTIONNAIRE, FormTypes.REFEREE, FormTypes.CANDIDATE] },
  { name: 'Star Rating', value: 'rating', help: 'E.g. Can you rate his performance?', available_for: [FormTypes.CUSTOM] },
  { name: 'Statement', value: 'statement', help: 'E.g. This is a statement', available_for: [FormTypes.CUSTOM, FormTypes.QUESTIONNAIRE, FormTypes.REFEREE, FormTypes.CANDIDATE] },
  { name: 'Upload', value: 'file_upload', help: 'E.g. Upload proof of identity', available_for: [FormTypes.CUSTOM, FormTypes.QUESTIONNAIRE, FormTypes.REFEREE, FormTypes.CANDIDATE] },
  { name: 'Signature', value: 'signature', help: 'E.g. We require your signature', available_for: [FormTypes.QUESTIONNAIRE] },
  { name: 'Country Selector', value: 'country', help: 'E.g. Select country of birth', available_for: [FormTypes.QUESTIONNAIRE] }
];

export const ValidationFields = [
  { order: 1, text: 'Where did you work with {{candidate}}?', type: 'short_text', check_part: 'VALIDATION', required: true },
  { order: 2, text: 'What was your relationship to {{candidate}}?', type: 'multiple_choice', check_part: 'VALIDATION', required: true, options: { other_option: true, multiple_selection: false, options: [{ value: 'Manager or Supervisor' }, { value: 'CEO or General Manager' }, { value: 'Business Owner' }, { value: 'Customer or Client' }] } },
  { order: 3, text: "How long did you work with {{candidate}}?", type: 'short_text', check_part: 'VALIDATION', required: true, options: null },
  { order: 4, text: 'What was {{candidate}}’s role at {{1}}?', type: 'short_text', check_part: 'VALIDATION', required: true, options: null },
  { order: 5, text: 'Why did {{candidate}} finish their employment at {{1}}?', type: 'multiple_choice', check_part: 'VALIDATION', required: true, options: { other_option: true, multiple_selection: false, options: [{ value: 'The project ended' }, { value: 'Relocated away from the job' }, { value: 'Found a new opportunity' }, { value: 'Redundancy' }, { value: 'Asked to leave' }] } }
]

export const ContactDetailFields = [
  { order: 1, text: 'First name', type: 'long_text', check_part: 'VALIDATION', required: true, locked: true, tag: 'first_name' },
  { order: 2, text: 'Last name', type: 'long_text', check_part: 'VALIDATION', required: true, locked: true, tag: 'last_name' },
  { order: 3, text: 'Email', type: 'long_text', check_part: 'VALIDATION', required: true, locked: true, tag: 'email' },
  { order: 4, text: 'Mobile contact number', type: 'phone_number', check_part: 'VALIDATION', required: true, locked: true, tag: 'contact_number' },
]