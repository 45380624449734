import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import cookie from 'react-cookies';

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/authentication`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    authorizeOAuth: builder.query({
      query: (params) => `/oauth?${toQueryParams(params)}`
    }),
    getSSOUrl: builder.query({
      query: (domain) => `/sso?domain=${domain}`,
      transformResponse: ({ result }) => result
    }),
  })
})

export const {
  useLazyGetSSOUrlQuery,
  useLazyAuthorizeOAuthQuery
} = authenticationApi
