import { List } from 'components/List/List';
import { ValidationFields } from 'constants/forms';
import Alert from 'components/Alert/Alert';
import { FormTypes } from 'constants/forms';
import { useEffect, useState } from 'react';
import { SectionedFormEdit } from 'components/SectionedForm/SectionedFormEdit/SectionedFormEdit';
import cn from 'classnames';

export const CustomForms = ({
  copyFrom,
  forms     = [],
  onSave    = () => {},
  onDelete  = () => {}
}) => {

  const [deleteFormConfirmation, setDeleteFormConfirmation] = useState(false);
  const [deleteFormParams, setDeleteFormParams] = useState({});

  const [form, setForm] = useState();

  useEffect(() => { if(copyFrom) copyFromForm(copyFrom) }, [copyFrom])

  const handleSave = async(data) => {
    const updatedForm = await onSave(data);
    if(updatedForm) setForm(updatedForm);
  }

  const handleFormDelete = (params) => {
    setDeleteFormParams(false);
    setDeleteFormConfirmation(false);
    onDelete(params.form);
  }

  const createNewForm = () => {
    const form = { 
      name: 'New Form', 
      team_id: null, 
      type: FormTypes.CUSTOM, 
      fields: ValidationFields
    }

    setForm(form);
  }

  const copyFromForm = (form) => {
    let newForm = {...form}
    delete newForm.id;
    newForm.public = false;
    newForm.type = FormTypes.CUSTOM;
    newForm.name += ' (Copy)';
    newForm.fields = newForm?.fields.map(field => {
      let newField = {...field}
      delete newField.id;
      if(newField.check_part !== 'VALIDATION') newField.check_part = 'CUSTOM';
      return newField;
    });

    setForm(newForm);
  }

  const handleFormRemove = () => {
    if(form?.id) {
      setDeleteFormConfirmation(true);
      setDeleteFormParams({ form });
    } else {
      setForm();
    }
  }

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <Alert  
        show={deleteFormConfirmation}
        title="Are you sure you want to delete this form?"
        message="If you chose to proceed. No data will be lost."
        ok="Delete" onOk={handleFormDelete}
        params={deleteFormParams}
        cancel="Cancel" onCancel={() => setDeleteFormConfirmation(false)}
      />
      <List 
        title='Custom Forms'
        subtitle='Create and configure custom forms for reference checks.'
        className='u-margin-right--large u-flex-align-self-normal'
        onNew={createNewForm}
      >
        <List.Category title='Custom Reference Forms'>
          {forms.map(form => (
            <List.Item
              key={form.id}
              title={form.name}
              value={form}
              onClick={setForm}
            />
          ))}
        </List.Category>
      </List>
      {form && 
        <SectionedFormEdit 
          form={form}
          onCopy={() => copyFromForm(form)}
          onSave={handleSave}
          onDelete={handleFormRemove}
          onClose={() => setForm(null)}
        />
      }
    </div>
  )
}
