import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import styles from './RefereeCard.module.scss';
import cn from 'classnames';

export const RefereeCard = ({
  title='REFEREE NAME',
  name,
  referee,
  selected,
  disabled,
  tag,
  combined=false,
  onClick = () => {}
}) => {

  const [status, setStatus] = useState('candidate');

  // On referee refresh
  useEffect(() => {
    if(!referee) return;

    // Set status
    if(referee.completed) setStatus('completed');
    else if(referee.canceled) setStatus('canceled');
    else if(referee.overdue) setStatus('overdue');
    else setStatus('in-progress');

  }, [referee]);

  return ( 
    <div 
      className={cn(styles.root, { 
          [styles.rootSelected]: selected,
          [styles.rootDisabled]: disabled,
        }
      )} 
      onClick={() => onClick(referee)}
    >
      <div className={styles.details}>
        <div className={styles.title}>{title}</div>
        <div className={styles.name}>{name}</div>

        {!combined && 
          <Badge className={cn(styles.status, {
            [`u-status-${status}`]: status,
            [styles.tag]: disabled,
          }
          )}>{!disabled ? status : tag}</Badge>
        }
      </div>
    </div>
  )
}