import { useState } from 'react';
import { updateCandidateCheck, selectCandidateApplication, submitCandidateApplication } from 'api/candidate-application-slice';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { Select } from 'components/FormComponents';
import { CustomLink } from '../components/CustomLink/CustomLink';
import { CustomButton } from '../components/CustomButton/CustomButton';
import { now } from 'helpers/date';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUserAgent } from 'hooks/user-agent';
import styles from './CandidateAFPPayedByCandidate.module.scss';
import cn from 'classnames';

const CandidateAFPPayedByCandidate = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useParams();
  const { user_agent } = useUserAgent();

  const { application: { brand, candidate_checks } } = useSelector(selectCandidateApplication);

  const [loading, setLoading] = useState(false);

  const [alreadyHaveReport, setAlreadyHaveReport] = useState(null);

  // Complete assessment
  const markAsCompleted = async () => {
    setLoading(true);
    const afpCheck = candidate_checks.find(check => check.type === 'afp_candidate_pays');
    await dispatch(updateCandidateCheck({ token, id: afpCheck.id, data: { submitted_at: now() }}));

    if (candidate_checks.length === 1) {
      await dispatch(submitCandidateApplication({ token, user_agent }));
      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);
    } else {
      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    }
  }

  return (

    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <CandidatePage.Card className={cn('d-flex', 'align-items-center', 'justify-content-center', 'flex-column')}>
          <img src='https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/afp_logo.svg' style={{ width: 70 }} />
          <h1 className={cn('title-4', 'u-margin-top--large', )}>Australian Federal Police report</h1>
          <p className={cn('t-body', 'u-margin-top--small')}>Please follow the steps below to complete the National Police Check Online Application Form</p>
          
          <div className='u-width-75 u-margin-top--large u-margin-bottom--large'>
            <p className='u-text-centered'>Do you already have a completed Australian Federal Police report?</p>
            <Select inputProps={{ value: alreadyHaveReport, onChange: (e) => setAlreadyHaveReport(e.target.value) }} value={alreadyHaveReport} placeholder='Select Bundle...'>
              <Select.Item value='yes'>Yes</Select.Item>
              <Select.Item value='no'>No</Select.Item>
            </Select>
          </div>

          {alreadyHaveReport === 'yes' &&
            <div className='card card-with-border u-padding u-width-75'>
              <div className={styles.item}><span>1</span><div>Send a copy to us at <CustomLink href='mailto:team@checkmate.tech' brand={brand}>team@checkmate.tech</CustomLink> as soon as possible.</div></div>
              <CustomButton className="u-margin-top--large u-width--100" small brand={brand} onClick={markAsCompleted}>I have sent the report</CustomButton>    
            </div>
          }

          {alreadyHaveReport === 'no' &&
            <div className='card card-with-border u-padding u-width-75'>
              <div className={styles.item}><span>1</span><div>Visit the <CustomLink href='https://afpnationalpolicechecks.converga.com.au' brand={brand}>Australian Federal Police website</CustomLink></div></div>
              <div className={styles.item}><span>2</span><div>Complete the application for a National Police Check and use the following information:
                <ul className='u-margin-top--small'>
                  <li><b>Purpose type:</b> Commonwealth Employment/Purpose</li>
                  <li><b>Purpose of Check:</b> 40 - Other Commonwealth Purpose ONLY</li>
                </ul>
                </div>
              </div>
              <div className={styles.item}><span>3</span><div>Pay the applicable fee directly to the AFP.</div></div>
              <div className={styles.item}><span>4</span><div>Once you receive your police check certificate, send a copy to us via email at <CustomLink href='mailto:team@checkmate.tech' brand={brand}>team@checkmate.tech</CustomLink> as soon as possible.</div></div>
              <CustomButton className="u-margin-top--large u-width--100" small brand={brand} onClick={markAsCompleted}>I have sent the report</CustomButton>              
            </div>
          }
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  )
}

export default CandidateAFPPayedByCandidate