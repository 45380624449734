import React, { useState } from 'react';
import { Loader } from '../Loader/Loader';
import styles from './async-image.module.scss';
import cn from 'classnames';

export default function AsyncImage({src, className}) {
  const [loading, setLoading] = useState(true);

  return (
    <div className={cn(styles.root, {[className]: className})}>
      <img src={src} className={cn(styles.image, { [styles.imageDisabled]: loading })} onLoad={() => setLoading(false)}/>
      {(!src && loading) &&
        <Loader />
      }
    </div>
  )
}