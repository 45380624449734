import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from 'react';
import { CustomButton } from '../components/CustomButton/CustomButton';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { shouldSubmitCandidate } from 'utils/checks-utils';
import styles from './CandidateSignDocument.module.scss';
import { Signature, InputGroup } from "components/FormComponents";
import { now } from 'helpers/date';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCandidateApplication, submitCandidateApplication, updateCandidateCheck } from 'api/candidate-application-slice';
import { useCandidateApplicationUploadActions } from 'hooks/upload-actions';
import { useUserAgent } from "hooks/user-agent";

export const CandidateSignDocument = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user_agent, mobile } = useUserAgent()
  const { token } = useParams()

  const { application: { candidate, candidate_checks, brand, check_types }, fetched } = useSelector(selectCandidateApplication);

  const { getUpload, signUpload, createUpload, deleteUpload } = useCandidateApplicationUploadActions(token);

  const [check, setCheck] = useState();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();

  const { handleSubmit, control } = useForm();

  useEffect(() => { 
    if(!fetched) return;

    setCheck(candidate_checks.find(check => check.type === 'document_signing'));
  }, [fetched]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(updateCandidateCheck({ token, id: check.id, data: { submitted_at: now(), extra_data: data }}));
    if(shouldSubmitCandidate(check, candidate_checks, check_types)) {
      await dispatch(submitCandidateApplication({ token, user_agent }));
      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.SUCCESS}/${token}`);
    } else {
      setLoading(false);
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    }
  };

  useEffect(() => {
    if(file || !check) return;
    getUpload(check.details.upload_id).then(upload => {
      setFile(upload);
      setLoading(false);
    });
  }, [file, check])

  const onMobileDownload = () => {
    window.open(file?.url, '_blank');
  }

  return (
    <CandidatePage loading={loading} token={token} title='Sign Document' fullScreen>
      <CandidatePage.View className={cn({[styles.mobileView]: mobile })}>
        <p className={styles.title}>{check?.details.title}</p>
        { mobile ? 
          <CustomButton className="u-margin-bottom--large" brand={brand} onClick={onMobileDownload}>
            View File
          </CustomButton> :
          <iframe className={styles.embed} src={file?.url}></iframe>
        }
        <form className={styles.form}>
          <div className={styles.content}>
            <InputGroup title='Signature'>
              <Controller
                control={control}
                name="signature_id"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Signature
                    className={styles.signature}
                    modelParams={{ candidate_id: candidate.id }}
                    signatureId={value}
                    onChange={onChange}
                    getUpload={getUpload}
                    signUpload={signUpload}
                    createUpload={createUpload}
                    onDeleteUpload={deleteUpload}
                  />
                )}
              />
            </InputGroup>
            <CustomButton className="u-margin-top--large" brand={brand} onClick={handleSubmit(onSubmit)}>
              Submit
            </CustomButton>
          </div>
        </form>
      </CandidatePage.View>
    </CandidatePage>
  )
}

export default CandidateSignDocument