import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import { saveAs } from 'file-saver';
import { stringDate } from 'helpers/date';
import cookie from 'react-cookies';

export const auditLogsApi = createApi({
  reducerPath: 'auditLogsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/audit_logs`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getAuditLogs: builder.query({
      query: (params) => `?${toQueryParams(params)}`,
      transformResponse: ({ result }) => result
    }),
    downloadAuditLogs: builder.query({
      queryFn: async (_, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: '/download',
          responseHandler: async (response) => {
            const blob = await response.blob();
            return blob;
          },
          cache: "no-cache",
        });
        saveAs(file, `${stringDate()}_audit_logs`);
        return { data: null }
      }
    }),
  })
})

export const {
  useLazyGetAuditLogsQuery,
  useLazyDownloadAuditLogsQuery,
} = auditLogsApi
