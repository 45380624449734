import { getTextWithReferences } from 'helpers/form';
import styles from './QuestionAnswerVerificationRow.module.scss';
import cn from 'classnames';

export const QuestionAnswerVerificationRow = ({
  number,
  text,
  candidateName,
  title,
  logicChild,
  candidateAnswer,
  refereeAnswer
}) => (
  <div className={cn(styles.root, {[styles.title]: title, [styles.logicChild]: logicChild })}>
    {number &&
      <div className={styles.number}>{number}</div>
    }
    <div className={styles.text}>
      {getTextWithReferences(text, candidateName)}
    </div>
    <div className={styles.candidateAnswer}>
      {candidateAnswer}
    </div>
    <div className={styles.refereeAnswer}>
      {refereeAnswer}
    </div>
  </div>
)
