import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import { ToggleLabel, Input } from 'components/FormComponents';
import { Collapse } from 'react-bootstrap';

export const DefaultSettings = ({ register, name, field }) => (
  <EditableSettings title='Settings' className='u-margin-top' startOpen={true}>
    <ToggleLabel register={register} name={`${name}.required`} label='Required' />
    <div className='u-divider u-margin-y--small' />
    <>
      <ToggleLabel register={register} name={`${name}.use_description`} label='Add description' />
      <Collapse in={field.use_description}>
        <div><Input register={register} name={`${name}.description`} className='u-margin-top--small' placeholder='Enter description...'/></div>
      </Collapse>
    </>
  </EditableSettings>
)