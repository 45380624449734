import { useDispatch } from 'react-redux';
import { Loading } from 'components/Loading/Loading';
import styles from './SingleSignOn.module.scss';
import { useState } from 'react';
import { useDocumentTitle } from 'hooks/document-title';
import { Input, InputGroup, Button, FormMessage } from 'components/FormComponents';
import { verifySSO } from 'api/authentication-slice';
import { useLazyGetSSOUrlQuery } from 'api/authentication-api';
import { useQueryParams } from 'hooks/query-params';
import cn from 'classnames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const SingleSignOn = () => {

  useDocumentTitle('Single Sign On');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ code ] = useQueryParams(['code']);

  const [loading, setLoading] = useState(true);
  const [messageText, setMessageText] = useState();

  const [domain, setDomain] = useState('');

  const [getSSOUrl] = useLazyGetSSOUrlQuery()

  useEffect(() => {
    if(code) {
      dispatch(verifySSO(code)).then(
        () => navigate('/dashboard'),
        error => {
          setMessageText('Something went wrong. Please contact support');
          setLoading(false);
        }
      )
    } else {
      setLoading(false);
    }
  }, [code])

  const getAuthUrl = async() => {
    setLoading(true);
    try {
      const result = await getSSOUrl(domain).unwrap();
      window.location.replace(result);
    } catch(e) {
      setMessageText('Domain not found.');
      setLoading(false);
    }
  }

  return (
    <div className={styles.root}>
      <Loading active={loading} />
      <div className={cn('card u-margin-top', 'card-with-shadow', styles.card)}>
        <div className={cn('card_content', styles.content)}>
          <img onClick={() => window.location.replace('https://www.checkmate.tech')} className={cn(styles.logo, 'u-margin-bottom--large')} src='/assets/images/checkmate/icon-label.svg' alt='Checkmate Logo' />
          <h2 className='design-marker title-2 u-margin-bottom--large'>Single Sign On</h2>
          <FormMessage message={messageText} className='u-margin-top--0' onClose={() => setMessageText(null)} />
          <InputGroup title='Company domain' className='u-margin-top'>
            <Input placeholder='checkmate.tech' inputProps={{ value: domain, onChange: (e) => setDomain(e.target.value) }} />
          </InputGroup>
          <Button disabled={!domain} className='u-width-100' onClick={getAuthUrl}>Submit</Button>
        </div>
      </div>
    </div>
  )
}

export default SingleSignOn