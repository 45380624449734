import { useState } from 'react';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { CameraModal } from '../components/CameraModal/CameraModal';
import { CustomButton } from '../components/CustomButton/CustomButton';
import styles from './CandidateVirtualId.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { selectCandidateApplication, createBase64CandidateUpload } from 'api/candidate-application-slice';
import { useDispatch, useSelector } from 'react-redux';

const CandidateVirtualId = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { token } = useParams();

  const { application: { candidate, brand } } = useSelector(selectCandidateApplication);

  const handleBase64Upload = (params) => dispatch(createBase64CandidateUpload({ token, params }));

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
  }

  return ( 
    <CandidatePage loading={loading} token={token} withTopLogo brand={brand}>
      <CameraModal 
        showModal={showModal}
        candidate={candidate}
        brand={brand} 
        onClose={() => setShowModal(false)}
        selfieUpload={handleBase64Upload} 
        setLoading={setLoading}
        onCompleted={handleSubmit}
      />
      <CandidatePage.View>
        <CandidatePage.Card className={styles.root}>
          <img src='assets/images/icons/webcam_icon.svg' className={styles.icon} />
          <h1 className="title-4 u-padding-top u-padding-bottom--large">Virtual ID</h1>
          <p className='t-subtitle'>We need to confirm you are who you say you are, to do this virtually we'll need you to upload a selfie of you holding your photographic ID.</p>
          <img src="assets/images/sample-virtual-id.jpg" className="u-width-100 u-margin-top--large" />
          <CustomButton className='u-margin-top--large' brand={brand} onClick={() => setShowModal(true)}>Open Camera</CustomButton>
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  )
}

export default CandidateVirtualId