import { useScript } from 'hooks/load-script';
import { Button } from 'components/FormComponents';
import { useEffect, useState } from 'react';
import styles from './Error.module.scss';

export const Error = ({ message, children }) => {
  
  const [loading, setLoading] = useState(true);

  useScript(`${process.env.REACT_APP_WEB_URL}/assets/js/main_animation.js`, () => { setLoading(false) });

  useEffect(() => {
    if(!loading) window.loadAnimations(true)
  }, [loading])

  return(
    <div className={styles.root}>
      <div className={styles.errorCode}>{children}</div>
      <div id="lottie" className={styles.anim}></div>
      <div className={styles.message}>{message || "Oops, something went wrong..."}</div>
      <p>You can let us know by emailing <a href="mailto:team@checkmate.tech">team@checkmate.tech</a>, and we’ll get back to you as soon as possible</p>
      <div className={styles.buttonsContainer}>
        <Button className={styles.button} large onClick={() => window.history.back()}>Go back</Button>
        <Button className={styles.button} type="outline" onClick={() => { window.location.href = `${process.env.REACT_APP_WEB_URL}/dashboard` }}>Dashboard</Button>
      </div>
    </div>
  )
}