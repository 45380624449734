import { Badge } from 'react-bootstrap';
import { FrankieOneStates } from 'constants/id-verification';
import { Alpha3Countries } from 'constants/countries';

export const IdVerificationCard = ({ idVerification }) => (
  <div className="u-width-100">
    <div className='u-margin-top--large d-flex justify-content-center'>
      <Badge className={`u-status-${FrankieOneStates[idVerification.status]}`}>{FrankieOneStates[idVerification.status]}</Badge>
    </div>
    <div className='u-padding-top--large t-body'>
      <b>ID Type:</b> <span className='u-capitalize'>{idVerification.id_type.replace('_', ' ').toLowerCase()}</span>
    </div>
    <div className='u-padding-top'>
      <b>Country:</b> {Alpha3Countries.find(c => c.code === idVerification.id_country).name}
    </div>
    <div className='u-padding-top'>
      <b>Document Number:</b> {idVerification.id_number}
    </div>
  </div>
)