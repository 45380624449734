import { useDispatch, useSelector } from 'react-redux';
import AppPage from 'layouts/AppPage/AppPage';
import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { updateCompanyCheck } from 'api/company-checks-slice';
import styles from './Renewals.module.scss';
import { 
  useUpdateCandidateCheckMutation,
  useRenewCandidateCheckMutation,
  useBulkUpdateCandidateChecksMutation,
  useBulkRenewCandidateChecksMutation
} from 'api/candidate-checks-api';
import { RenewalsConfigurationTab } from './tabs/RenewalsConfigurationTab/RenewalsConfigurationTab';
import { RenewalsCandidateChecksTab } from './tabs/RenewalsCandidateChecksTab/RenewalsCandidateChecksTab';
import { useLazyGetRenewalsQuery } from 'api/candidate-checks-api';
import { messagePopUp } from 'api/app-slice';
import { useNavigate } from 'react-router-dom';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectCompany } from 'api/company-slice';

const Renewals = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const companyChecks = useSelector(selectCompanyChecks);
  const company = useSelector(selectCompany);

  const [getRenewals]               = useLazyGetRenewalsQuery();
  const [updateCandidateCheck]      = useUpdateCandidateCheckMutation();
  const [renewCandidateCheck]       = useRenewCandidateCheckMutation();
  const [bulkUpdateCandidateChecks] = useBulkUpdateCandidateChecksMutation();
  const [bulkRenewCandidateChecks]  = useBulkRenewCandidateChecksMutation();

  const handleMessagePopUp = (text, state, hide = true) => dispatch(messagePopUp({ text, state, hide }))
  const handleUpdateCompanyCheck = (id, data) => dispatch(updateCompanyCheck({ id, data }))

  const [loading, setLoading] = useState(false);

  return (
    <AppPage loading={loading}>
      <div className={styles.root}>
        <AppPage.Header 
          title='Renewals'
          subtitle='Configure and approve renewal checks for existing employees.'
          ctaText='Import Renewals'
          ctaClick={() => navigate('/bulk_import/renewals')}
        />
        <Tabs defaultActiveKey='candidates' className='tabs'>
          <Tab eventKey="candidates" title="Candidates">
            <RenewalsCandidateChecksTab 
              company={company}
              companyChecks={companyChecks}
              setLoading={setLoading}
              getRenewals={getRenewals}
              updateCandidateCheck={updateCandidateCheck}
              renewCandidateCheck={renewCandidateCheck}
              bulkUpdateCandidateChecks={bulkUpdateCandidateChecks}
              bulkRenewCandidateChecks={bulkRenewCandidateChecks}
              messagePopUp={handleMessagePopUp}
            />
          </Tab>
          <Tab eventKey="configuration" title="Configuration">
            <RenewalsConfigurationTab 
              companyChecks={companyChecks}
              setLoading={setLoading}
              updateCompanyCheck={handleUpdateCompanyCheck}
            />
          </Tab>
        </Tabs>
      </div>
    </AppPage>
  )
}

export default Renewals