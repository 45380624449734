import { IconButton } from 'components/IconButton/IconButton';
import styles from './AddRefereeCard.module.scss';

export const AddRefereeCard = ({ onClick = () => {} }) => (
  <div className={styles.root}>
    <div className={styles.card} onClick={onClick}>
      <span>Add referee</span><IconButton className={styles.icon} icon="plus"/>
    </div>
  </div>
)
