import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toQueryParams } from 'helpers/api';
import { saveAs } from 'file-saver';
import { stringDate } from 'helpers/date';
import cookie from 'react-cookies';

export const adminLogsApi = createApi({
  reducerPath: 'adminLogsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URl}/admin_logs`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${cookie.load('token')}`)
      return headers
    },
  }),
  endpoints: builder => ({
    getAdminLogs: builder.query({
      query: (params) => `?${toQueryParams(params)}`
    }),
    downloadAdminLogs: builder.query({
      queryFn: async ({ queryParams }, api, extraOptions, baseQuery) => {
        const { data: file } = await baseQuery({
          url: `/download?${toQueryParams(queryParams)}`,
          responseHandler: async (response) => {
            const blob = await response.blob();
            return blob;
          },
          cache: "no-cache",
        });
        saveAs(file, `${stringDate()}_admin_logs`);
        return { data: null }
      }
    }),
  })
})

export const {
  useLazyGetAdminLogsQuery,
  useLazyDownloadAdminLogsQuery
} = adminLogsApi
