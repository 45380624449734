import { useState } from 'react';
import { List } from 'components/List/List';
import { FormEdit } from 'components/Form/FormEdit/FormEdit';
import { FormTypes } from 'constants/forms';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { Button, FileContentsPicker, InputGroup } from 'components/FormComponents';
import cn from 'classnames';
import { useGetFormFromFileMutation } from 'api/forms-api';

export const ReferenceForms = ({ 
  forms, 
  company,
  teams,
  onSave      = () => {}, 
  deleteForm  = () => {},
  setLoading  = () => {},
}) => {

  const [form, setForm] = useState();
  const [showJDAIGenerator, setShowJDAIGenerator] = useState(false);
  const [showQuestionnaireGenerator, setShowQuestionnaireGenerator] = useState(false);

  const [createFromFile] = useGetFormFromFileMutation();

  const createNewForm = () => {
    setForm({ name: 'New Form', team_id: null, type: FormTypes.REFEREE, fields: [] })
  }

  const onCreateFromFormDescription = async(fileData) => {
    setLoading(true);
    setShowJDAIGenerator(false);

    const { data: questions } = await createFromFile({
      from: 'job_description',
      file_data: fileData
    });

    setForm({...form, fields: questions })
    setLoading(false);
  }

  const onCreateFromFormQuestionnaire = async(fileData) => {
    setLoading(true);
    setShowQuestionnaireGenerator(false);

    const { data: questions } = await createFromFile({
      from: 'questionnaire',
      file_data: fileData
    });

    setForm({...form, fields: questions })
    setLoading(false);
  }

  const handleSave = async(data) => {
    const updatedForm = await onSave(data);
    if(updatedForm) setForm(updatedForm);
  }

  const copyFromForm = (copyFrom) => {
    if(!copyFrom) return;

    let newForm = {...copyFrom}
    delete newForm.id;

    newForm.name += ' (Copy)';

    newForm.fields.map(field => {
      let newField = {...field} 
      delete newField.id;
      return newField;
    });

    setForm(newForm);
  }

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <FileSubmittionModal 
        title='Checkmate AI Generator'
        description="Upload your job description and we'll use AI to create a custom refrence check for you to review"
        visible={showJDAIGenerator}
        onClose={() => setShowJDAIGenerator(false)}
        onSubmit={onCreateFromFormDescription}
      />

      <FileSubmittionModal 
        title='Checkmate AI Generator'
        description="Upload a document with your current reference questionnaires, and we'll use it to create the questions for you"
        visible={showQuestionnaireGenerator}
        onClose={() => setShowQuestionnaireGenerator(false)}
        onSubmit={onCreateFromFormQuestionnaire}
      />

      <List 
        title='Forms'
        subtitle='Create and configure forms used to complete a reference check.'
        className='u-margin-right--large u-flex-align-self-normal'
        onNew={createNewForm}
      >
        <List.Category title='Questionnaire Forms'>
          {forms.map(form => (
            <List.Item
              key={form.id}
              title={form.name}
              value={form}
              onClick={setForm}
            />
          ))}
        </List.Category>
      </List>
      {form && 
        <FormEdit 
          company={company}
          form={form}
          teams={teams}
          onCopy={copyFromForm}
          onSave={handleSave}
          onDelete={deleteForm}
          onClose={() => setForm(null)}
          aiGenerators
          onJDAIGenerator={() => setShowJDAIGenerator(true)}
          onAIQuestionnaireGenerator={() => setShowQuestionnaireGenerator(true)}
        />
      }
    </div>
  )
}


const FileSubmittionModal = ({
  title,
  description,
  visible, 
  onClose  = () => {},
  onSubmit = () => {}
}) => {

  const [fileData, setFileData] = useState();

  const handleSubmittion = () => onSubmit(fileData);

  return (
    <AnimatedModal title={title} visible={visible} onClose={onClose}>
      <div>
        <InputGroup>
          <p className='t-small u-margin-bottom--large u-text-centered'>{description}</p>
          <FileContentsPicker
            accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'] }}
            onChange={setFileData}
          />
        </InputGroup>
        <div className="d-flex u-width-100 justify-content-end">
          <Button type='secondary' onClick={onClose} className='u-width-100 u-margin-top u-margin-right'>Cancel</Button>
          <Button icon="search" className='u-width-100 u-margin-top' onClick={handleSubmittion}>Process Document</Button>
        </div>
      </div>
    </AnimatedModal>
  )
}