import { useWatch, Controller } from "react-hook-form";
import { InputGroup, MultipleChoice, Select } from "components/FormComponents";
import { EditableSettings } from "components/EditableSettings/EditableSettings";
import cn from 'classnames';

export const Reminders = ({
  control,
  register,
}) => {

  const remindersActiveValue = useWatch({ control, name: 'reminders_active'});
  const remindersValue = useWatch({ control, name: 'reminders'});
  const firstReminderValue = useWatch({ control, name: 'first_reminder'});
  const secondReminderValue = useWatch({ control, name: 'second_reminder'});
  const thirdReminderValue = useWatch({ control, name: 'third_reminder'});

  return (
    <div className='background-secondary u-padding'>
      <div className={cn('card', 'card-with-border', 'u-padding')}>
        <EditableSettings title='Use candidate & referee reminders' open={remindersActiveValue} toggleable register={register} name='reminders_active' className="u-margin-0">
          <InputGroup title='I want to set...'>
            <Select name='reminders' register={register} value={remindersValue} useDefault>
              <Select.Item value={1}>1 Reminder</Select.Item>
              <Select.Item value={2}>2 Reminders</Select.Item>
              <Select.Item value={3}>3 Reminders</Select.Item>
            </Select>
          </InputGroup>

          <InputGroup title='Set first reminder...'>
            <Select name='first_reminder' register={register} value={firstReminderValue} useDefault>
              <Select.Item value={24}>24 hours after launch</Select.Item>
              <Select.Item value={48}>48 hours after launch</Select.Item>
              <Select.Item value={72}>72 hours after launch</Select.Item>
              <Select.Item value={168}>1 week after launch</Select.Item>
            </Select>
          </InputGroup>
          {remindersValue > 1 &&
            <InputGroup title='Set second reminder...'>
              <Select name='second_reminder' register={register} value={secondReminderValue} useDefault>
                <Select.Item value={24}>24 hours after 1st reminder</Select.Item>
                <Select.Item value={48}>48 hours after 1st reminder</Select.Item>
                <Select.Item value={72}>72 hours after 1st reminder</Select.Item>
                <Select.Item value={168}>1 week after 1st reminder</Select.Item>
              </Select>
            </InputGroup>
          }
          {remindersValue > 2 &&
            <InputGroup title='Set third reminder...'>
              <Select name='third_reminder' register={register} value={thirdReminderValue} useDefault>
                <Select.Item value={24}>24 hours after 2nd reminder</Select.Item>
                <Select.Item value={48}>48 hours after 2nd reminder</Select.Item>
                <Select.Item value={72}>72 hours after 2nd reminder</Select.Item>
                <Select.Item value={168}>1 week after 2nd reminder</Select.Item>
              </Select>
            </InputGroup>
          }

          {remindersValue > 1 &&
            <InputGroup title='Set status to overdue after...'>
              <Controller
                control={control}
                name='overdue_after_reminder'
                render={({ field: { onChange, value } }) => (
                  <MultipleChoice 
                    value={value}
                    onChange={onChange}
                    radioButtonStyle
                    options={{
                      options: [
                        { name: 'First', value: 1 },
                        { name: 'Second', value: 2 },
                        { name: 'Third', value: 3 }
                      ]
                    }}
                  />
                )}
              />
            </InputGroup>
          }
        </EditableSettings>
      </div>
    </div>
  )
}