import { useState } from 'react';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { useForm } from 'react-hook-form';
import { Button, InputGroup, Input, Checkbox } from 'components/FormComponents';
import styles from './RejectModal.module.scss';
import cn from 'classnames';

export const RejectModal = ({ 
  visible, 
  checkValues = {},
  uploads = [],
  onClose = () => {},
  onReject = () => {}
}) => {

  const { register, handleSubmit } = useForm();

  const [valuesToReject, setValuesToReject] = useState({})
  const [filesToReject, setFilesToReject] = useState([]);
  const [reason, setReason] = useState(null);

  const handleFileChange = (id) => {
    const index = filesToReject.indexOf(id);
    if(index >= 0) { 
      let _filesToReject = [...filesToReject]
      _filesToReject.splice(index, 1);
      setFilesToReject(_filesToReject);
    } else {
      setFilesToReject([...filesToReject, id])
    }
  }

  const handleReject = () => {
    // Filter true checkboxes keys
    const values = Object.keys(valuesToReject).filter(key => valuesToReject[key]);
    handleClose();
    onReject({ values, files: filesToReject, reason });
  }

  const handleClose = () => {
    setValuesToReject({});
    setFilesToReject([]);
    setReason(null);
    onClose();
  }

  const onSubmit = (data) => {
    handleClose();
    const values = Object.keys(data).filter(key => data[key] && key !== 'reason');
    onReject({ values, files: filesToReject, reason: data['reason'] });
  }

  return ( 
    <AnimatedModal visible={visible} onClose={handleClose} className={styles.root} title='Decline Candidate Details'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup title='Reason' className='u-margin-bottom--large'>
          <Input name='reason' register={register} placeholder='Decline reason' />
        </InputGroup>
        {Object.keys(checkValues).map(field => (
          <Checkbox register={register} key={field} name={field} label={field.replace(/_/g, ' ')} />
        ))}
        <div className={styles.filesWrapper}> 
          {uploads.map(upload => (
            <div key={upload.id} className={cn(styles.fileWrapper, { [styles.fileWrapperSelected]: filesToReject.includes(upload.id)})} onClick={() => handleFileChange(upload.id)}>
              <img src={upload.url}/>
            </div>
          ))}
        </div>
        <Button type="delete" className="u-margin-top--large u-width-100" submit>
          Decline
        </Button>
      </form>
    </AnimatedModal>
  )
}

