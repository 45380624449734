import { Toggle } from 'components/FormComponents';
import styles from './IntegrationCard.module.scss';
import cn from 'classnames';

export const IntegrationCard = ({ 
  logo, 
  title, 
  subtitle,
  children,
  enabled, 
  onToggle,
}) => (
  <div className={cn(styles.root, 'card', 'card-with-border', 'u-padding')}>
    <div className='card_content'>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={logo} />
      </div>
      <div className='d-flex u-width-100 justify-content-between u-margin-top'>
        <div>
          <p className='title-4'>{title}</p>
          {subtitle}
        </div>
        {onToggle &&
          <Toggle inputProps={{ checked: enabled, onChange: onToggle }} />
        }
      </div>
      <div className='u-width-100'>
        {children}
      </div>
    </div>
  </div>
)