import { useEffect, useState } from 'react';

export const useColor = (hexColor) => {

  const [color, setColor] = useState({ hex: '', rgb: '' });

  useEffect(() => {
    if (!hexColor) return;

    setColorTransformation(hexColor);
  }, [hexColor])


  const setColorTransformation = (hexColor) => {
    setColor({
      rgb: hexColor.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b).substring(1).match(/.{2}/g).map(x => parseInt(x, 16)).join(','),
      hex: hexColor,
    })
  }

  return color;
}