import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';
import { useEffect, useRef } from 'react';

export const TypeformForm = ({ 
  params,
  onSubmit = () => {},
}) => {

  const containerRef = useRef(null);

  useEffect(() => {
    if(!containerRef.current) return;

    const { typeform_id, hidden } = params;
    createWidget(typeform_id, {
      container: containerRef.current, 
      hidden,
      onSubmit,
    });
  }, [params, containerRef.current])

  return <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />
}