import FeatherIcon from 'feather-icons-react';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR, MESSAGE_STATE_INFO } from 'constants/message-app-state-contants';
import styles from './AppMessage.module.scss';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { messagePopOut } from 'api/app-slice';

const ICONS = {
  [MESSAGE_STATE_SUCCESS]: 'check',
  [MESSAGE_STATE_INFO]: 'info',
  [MESSAGE_STATE_ERROR]: 'alert-circle'
}

const AppMessage = ({
  text,
  state,
  show,
  hide,
  params,
  onClick = () => {}
}) => {

  const dispatch = useDispatch();

  const [linkedText, setLinkedText] = useState(''); 

  useEffect(() => {
    if(!text) return;

    let linkedText;

    if(text.indexOf('{') < 0) {
      linkedText = <div>{text}</div>
    } else {
      const links = text.replace(/{{((?!}).)*}}/g, (match) => (`<a>${match.replace(/[{}]/g, '')}</a>`));
      linkedText = <div onClick={() => onClick(params)} dangerouslySetInnerHTML={{__html: links}}></div>
    }

    setLinkedText(linkedText);
  }, [text])

  useEffect(() => { if(show && hide) setTimeout(() => dispatch(messagePopOut()), 5000) }, [show, hide])

  return (
    <div className={cn(styles.root, {
      [styles.hidden]: !show,
      [styles.success]: state === MESSAGE_STATE_SUCCESS,
      [styles.info]:    state === MESSAGE_STATE_INFO,
      [styles.error]:   state === MESSAGE_STATE_ERROR
    })}>
      <FeatherIcon icon={ICONS[state]} /> {linkedText}
    </div>
  )
}

export default AppMessage;