import React, { useState, useEffect } from 'react';
import { ToggleSwitchInput } from 'components/FormComponents/ToggleSwitchInput/ToggleSwitchInput';
import { InputGroup, Select, Button, FilePicker } from 'components/FormComponents';
import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';

export const DocumentSigningSelectorDetails = ({
  index,
  append,
  remove,
  credits=0,
  watch,
  documents=[],
  register = () => {},
  setValue = () => {},
  getUpload = () => {},
  signUpload = () => {},
  createUpload = () => {},
  deleteUpload = () => {},
}) => {

  const [enabled, setEnabled] = useState(false);

  const uploadId = watch(`checks.${index}.details.upload_id`);
  const [upload, setUpload] = useState();

  const [showFilePicker, setShowFilePicker] = useState(false);

  useEffect(() => {
    if(enabled && isNaN(index)) {
      append({ 
        type: 'document_signing', 
        details: {}
      })
    } else if (!enabled && index >= 0) {
      remove(index);
    }
  }, [enabled])

  useEffect(() => {
    if(index >= 0 && !enabled)  setEnabled(true);
    else if (isNaN(index) && enabled) setEnabled(false);
  }, [index])

  // Handle new document
  const handleDocumentUpload = (upload) => {
    setUpload(upload);
    setValue(`checks.${index}.details.upload_id`, upload.id);
  }

  return (
    <ToggleSwitchInput
      text='Document Signing'
      logo='https://checkmate-prod.s3.ap-southeast-2.amazonaws.com/assets/checkmate_check_logo.svg'
      subtext={(credits < 0 ? "Unlimited" : credits) + " remaining"}
      onChange={e => setEnabled(e.target.checked)}
      value={enabled}
    >
      {enabled && index >= 0 &&
        <>
          <AnimatedModal title='New Document' visible={showFilePicker} onClose={() => setShowFilePicker(false)}>
            <FilePicker
              accept={{ 'application/pdf': ['.pdf'] }}
              onChange={handleDocumentUpload}
              value={upload}
              className='u-margin-top--large'
              bucketFolder='company_documents'
              getUpload={getUpload}
              signUpload={signUpload}
              createUpload={createUpload}
              onDeleteUpload={deleteUpload}
              useModel
            />
            <Button className='u-width-100 u-margin-top' onClick={() => setShowFilePicker(false)}>Select</Button>
          </AnimatedModal>

          <InputGroup title='Document'>
            <Select name={`checks.${index}.details.upload_id`} placeholder='Document' register={register} value={uploadId}>
              {upload && 
                <Select.Item value={upload.id}>{upload.file_name}</Select.Item>
              }
              {documents.map(document => (
                <Select.Item key={document.id} value={document.id}>{document.file_name}</Select.Item>
              ))}
            </Select>
            <a className='u-link dark t-small u-margin-top--small' onClick={() => setShowFilePicker(true)}>Upload new document</a>
          </InputGroup>
        </>
      }
    </ToggleSwitchInput>
  )
}